import { Vector3 } from 'three';
import { createContext, useContext, useState } from 'react';
import { CameraPlacementMode, ControlModes } from '../../helpers/enums';
import { create } from 'zustand';
import {convertDataToThreeJS} from "./dataConverter";

const ThreeDPropsContext = createContext(undefined);

export const ThreeDProvider = ({
  data,
  adminMode,
  onClose,
  onChange,
  onMediaSelect,
  children,
}) => {
  data = convertDataToThreeJS(data);
  const { cameraRef } = useThreeDState();
  const { startPosition, directionOfView } = data;
  const [ initialCameraPosition, setInitialCameraPosition ] = useState(startPosition);
  const [ initialCameraDirection, setInitialCameraDirection ] = useState(directionOfView);

  const handleChange = (json) => {
    const cameraDirection = new Vector3();
    cameraRef.current.getWorldDirection(cameraDirection);
    setInitialCameraPosition(cameraRef.current.position.clone());
    setInitialCameraDirection(cameraDirection);
    onChange(json);
  };

  return (
    <ThreeDPropsContext.Provider
      value={{
        data,
        adminMode,
        onClose,
        onChange: handleChange,
        onMediaSelect,
        initialCameraPosition,
        initialCameraDirection,
      }}
    >
      {children}
    </ThreeDPropsContext.Provider>
  );
};

export const SET_INITIAL_STATE = 'SET_INITIAL_STATE';
export const SET_GPU_TIER = 'SET_GPU_TIER';
export const SET_USER_INTERACTED = 'SET_USER_INTERACTED';
export const SET_CAMERA_REF = 'SET_CAMERA_REF';
export const SET_CAMERA_PLACEMENT_MODE = 'SET_CAMERA_PLACEMENT_MODE';
export const SET_OUTLINE_CANVAS = 'SET_OUTLINE_CANVAS';
export const SET_CANVAS_SELECTION = 'SET_CANVAS_SELECTION';
export const SET_INTERACTIVE_MODE = 'SET_INTERACTIVE_MODE';
export const SET_MODELVIEWER_DATA = 'SET_MODELVIEWER_DATA';
export const SET_GROUP_MANAGER_DIALOG_OPEN = 'SET_GROUP_MANAGER_DIALOG_OPEN';
export const SET_HAS_AUDIO = 'SET_HAS_AUDIO';
export const SET_AUDIO_MUTE = 'SET_AUDIO_MUTE';
export const SET_HELP_DIALOG_OPEN = 'SET_HELP_DIALOG_OPEN';
export const SET_ABOUT_DIALOG_OPEN = 'SET_ABOUT_DIALOG_OPEN';
export const SET_SHOW_GROUPS = 'SET_SHOW_GROUPS';
export const SET_ROTATION_ENABLED = 'SET_ROTATION_ENABLED';
export const SET_INVERTED_MOUSE_BUTTONS = 'SET_INVERTED_MOUSE_BUTTONS';
export const SET_CANVAS_ADDITION_ITEM_TYPE = 'SET_CANVAS_ADDITION_ITEM_TYPE';
export const SET_DRAWER_OPEN = 'SET_DRAWER_OPEN';
export const SET_SELECTED_TOUR = 'SET_SELECTED_TOUR';
export const SET_ACTIVE_QUIZ = 'SET_ACTIVE_QUIZ';
export const SET_SCENE_ANIMATIONS = 'SET_SCENE_ANIMATIONS';
export const SET_POINTER_LOCKED = 'SET_POINTER_LOCKED';
export const SET_CONTROL_MODE = 'SET_CONTROL_MODE';

const initialState = {
  GPUTier: null,
  userInteracted: false,
  cameraRef: null,
  cameraPlacementMode: null,
  outlineCanvas: null,
  canvasSelection: null,
  interactiveMode: true,
  modelViewerData: false,
  groupManagerDialogOpen: false,
  hasAudio: false,
  audioMute: false,
  helpDialogOpen: true,
  aboutDialogOpen: false,
  showGroups: false,
  rotationEnabled: true,
  invertedMouseButtons: false,
  canvasAdditionItemType: null,
  drawerOpen: false,
  selectedTour: null,
  activeQuiz: null,
  sceneAnimations: null,
  pointerLocked: false,
  controlMode: ControlModes.ORBIT_CONTROL,
};

const reducer = (state, action) => {
  switch (action.type) {
    case SET_INITIAL_STATE:
      return {
        ...state,
      };
    case SET_GPU_TIER:
      return {
        ...state,
        GPUTier: action.GPUTier,
      };
    case SET_USER_INTERACTED:
      return {
        ...state,
        userInteracted: action.userInteracted,
      };
    case SET_CAMERA_REF:
      return {
        ...state,
        cameraRef: action.cameraRef,
      };
    case SET_CAMERA_PLACEMENT_MODE:
      return {
        ...state,
        cameraPlacementMode: action.cameraPlacementMode,
        interactiveMode: action.cameraPlacementMode?.mode !== CameraPlacementMode.EDIT,
      };
    case SET_OUTLINE_CANVAS:
      return {
        ...state,
        outlineCanvas: action.outlineCanvas,
      };
    case SET_CANVAS_SELECTION:
      return {
        ...state,
        canvasSelection: action.canvasSelection,
      };
    case SET_INTERACTIVE_MODE:
      return {
        ...state,
        interactiveMode: action.interactiveMode,
      };
    case SET_MODELVIEWER_DATA:
      return {
        ...state,
        modelViewerData: action.modelViewerData,
      };
    case SET_GROUP_MANAGER_DIALOG_OPEN:
      return {
        ...state,
        groupManagerDialogOpen: action.groupManagerDialogOpen,
      };
    case SET_HAS_AUDIO:
      return {
        ...state,
        hasAudio: action.hasAudio,
      };
    case SET_AUDIO_MUTE:
      return {
        ...state,
        audioMute: action.audioMute,
      };
    case SET_HELP_DIALOG_OPEN:
      return {
        ...state,
        helpDialogOpen: action.helpDialogOpen,
      };
    case SET_ABOUT_DIALOG_OPEN:
      return {
        ...state,
        aboutDialogOpen: action.aboutDialogOpen,
      };
    case SET_SHOW_GROUPS:
      return {
        ...state,
        showGroups: action.showGroups,
      };
    case SET_ROTATION_ENABLED:
      return {
        ...state,
        rotationEnabled: action.rotationEnabled,
      };
    case SET_INVERTED_MOUSE_BUTTONS:
      return {
        ...state,
        invertedMouseButtons: action.invertedMouseButtons,
      };
    case SET_CANVAS_ADDITION_ITEM_TYPE:
      return {
        ...state,
        canvasAdditionItemType: action.canvasAdditionItemType,
      };
    case SET_DRAWER_OPEN:
      return {
        ...state,
        drawerOpen: action.drawerOpen,
      };
    case SET_SELECTED_TOUR:
      return {
        ...state,
        selectedTour: action.selectedTour,
      };
    case SET_ACTIVE_QUIZ:
      return {
        ...state,
        activeQuiz: action.activeQuiz,
      };
    case SET_SCENE_ANIMATIONS:
      return {
        ...state,
        sceneAnimations: action.sceneAnimations,
      };
    case SET_POINTER_LOCKED:
      return {
        ...state,
        pointerLocked: action.pointerLocked,
      };
    case SET_CONTROL_MODE:
      return {
        ...state,
        controlMode: action.controlMode,
      };
    default:
      return undefined;
  }
};

export const useThreeDProps = () => {
  const context = useContext(ThreeDPropsContext);

  if (undefined === context) {
    throw new Error(
      'useThreeDProps must be used within a ThreeDProvider'
    );
  } else {
    return context;
  }
};

const useStore = create(set => {
  return {
    ...initialState,
    dispatch: args => set(state => reducer(state, args)),
  };
});

export const useThreeDState = () => useStore();

export const useThreeDDispatch = () => useStore(state => state.dispatch);
