import * as THREE from 'three';
import * as Icon from '@mui/icons-material';
import {AccordionDetails, Box, Button, Checkbox, Divider, FormControlLabel, Typography} from '@mui/material';
import {useEffect, useState} from 'react';
import {ColorInput} from '../../../components/ColorInput';
import {SliderInput} from '../../../components/SliderInput';
import {Paragraphs} from "../../paragraph/Paragraphs";

export const TextSettings = ({
  index,
  textItem,
  canvasHeight,
  canvasWidth,
  canvasRotation,
  onUpdate,
  onRemove,
  getSceneElements,
}) => {
  const [params, setParams] = useState();

  const update = (text) => {
    setParams(getParams(text));
    onUpdate && onUpdate(index, text);
  };

  const getWidthLimit = (localPosition) => {
    const rightEdge = localPosition.x + (textItem.maxWidth / 2);
    const leftEdge = localPosition.x - (textItem.maxWidth / 2);

    const rightLimit = canvasWidth / 2;
    const leftLimit = canvasWidth / -2;

    const rightWidthMax = textItem.maxWidth + ((rightLimit - rightEdge) * 2);
    const leftWidthMax = textItem.maxWidth - ((leftLimit - leftEdge) * 2);

    return Math.min(rightWidthMax, leftWidthMax);
  };

  const getParams = (obj) => {
    textItem = obj ?? textItem;
    const { canvasRef, itemRef: textRef, rescaleButtonsRef } = getSceneElements();

    if (!canvasRef || !textRef) return null;

    const localPosition = canvasRef.worldToLocal(textItem.position.clone());
    const widthLimit = getWidthLimit(localPosition);

    return {
      xPosition: {
        value: localPosition.x + (canvasWidth / 2),
        min: textRef.scale.x / 2,
        max: canvasWidth - (textRef.scale.x / 2),
        step: 0.001,
        onChange: (value, last) => {
          let newPosition = localPosition.clone();
          newPosition.setX(value - (canvasWidth / 2));
          textItem.position.copy(canvasRef.localToWorld(newPosition));
          textRef.parent.position.copy(textItem.position);
          (rescaleButtonsRef || getSceneElements().rescaleButtonsRef).position.copy(textItem.position);
          last && update(textItem);
        },
      },
      yPosition: {
        value: localPosition.y + (canvasHeight / 2),
        min: textRef.scale.y / 2,
        max: canvasHeight - (textRef.scale.y / 2),
        step: 0.001,
        onChange: (value, last) => {
          let newPosition = localPosition.clone();
          newPosition.setY(value - (canvasHeight / 2));
          textItem.position.copy(canvasRef.localToWorld(newPosition));
          textRef.parent.position.copy(textItem.position);
          (rescaleButtonsRef || getSceneElements().rescaleButtonsRef).position.copy(textItem.position);
          last && update(textItem);
        },
      },
      maxWidth: {
        value: textItem.maxWidth,
        min: textItem.boundToGrid ? 1 : 0.1,
        max: widthLimit,
        step: textItem.boundToGrid ? 1 : 0.01,
        onChange: (value, last) => {
          if (last) {
            textItem.maxWidth = parseFloat(value);
            update(textItem);
          }
        },
      },
      rotation: {
        value: 180 + THREE.MathUtils.radToDeg(canvasRotation.z) - THREE.MathUtils.radToDeg(textItem.rotation.z),
        min: 0,
        max: 360,
        step: 1,
        onChange: (value, last) => {
          if (last) {
            const zRotation = THREE.MathUtils.degToRad(180) + canvasRotation.z - THREE.MathUtils.degToRad(value);
            textItem.rotation.z = zRotation;
            update(textItem);
          }
        },
      },
      color: {
        value: textItem.color ?? '#171717',
        onChange: (value) => {
          if (textItem.color !== value) {
            textItem.color = value;
            update(textItem);
          }
        },
      },
      boundToGrid: {
        value: textItem.boundToGrid,
        onChange: (event) => {
          textItem.boundToGrid = event.target.checked;
          update(textItem);
        },
      },
    };
  }

  useEffect(() => {
    setParams(getParams());
  }, [textItem, textItem.maxWidth, textItem.rotation.z, textItem.color,
    textItem.boundToGrid, textItem.position.x, textItem.position.y]);

  const handleAddParagraph = () => {
    const lastParagraph = textItem.paragraphs[textItem.paragraphs.length - 1];
    const newParagraph = {
      fontSize: lastParagraph.fontSize,
      fontFamily: lastParagraph.fontFamily,
      margin: {...lastParagraph.margin},
      lineHeight: lastParagraph.lineHeight,
      textAlign: lastParagraph.textAlign,
    };
    textItem.paragraphs.push(newParagraph);
    onUpdate(index, textItem);
  };

  return (
    <>
      {params && (
        <>
          <AccordionDetails>
            <div>
              <Typography>Posisjon</Typography>
              <SliderInput title={'X'} {...params.xPosition} />
              <SliderInput title={'Y'} {...params.yPosition} />
            </div>
            <div>
              <Typography>Bredde</Typography>
              <SliderInput {...params.maxWidth} />
            </div>
            <div>
              <Typography>Rotasjon</Typography>
              <SliderInput {...params.rotation} />
            </div>
            <Box>
              <Typography>Tekstfarge</Typography>
              <ColorInput
                color={params.color.value}
                onChange={params.color.onChange}
              />
            </Box>
          </AccordionDetails>
          <Divider light />
          <AccordionDetails className='compact'>
            <Paragraphs
                paragraphs={textItem.paragraphs}
                textItem={textItem}
                onUpdate={update}
            />
          </AccordionDetails>
          <Divider light />
          <AccordionDetails>
            <div>
              <FormControlLabel
                label="Følg grid"
                control={
                  <Checkbox
                    checked={params.boundToGrid.value}
                    onChange={params.boundToGrid.onChange}
                  />
                }
              />
            </div>
          </AccordionDetails>
          <Divider light />
          <AccordionDetails>
            <Button
              variant="outlined"
              size="small"
              onClick={handleAddParagraph}
            >
              + NY PARAGRAF
            </Button>
            <Button
              variant="outlined"
              size="small"
              startIcon={<Icon.DeleteOutlined fontSize="small" />}
              onClick={() => onRemove(index)}
            >
              Slett tekst
            </Button>
          </AccordionDetails>
        </>
      )}
    </>
  );
};
