import { useMemo } from 'react';
import { useThreeDProps, useThreeDState } from '../App/ThreeDContext';
import { getExhibitionItems } from '../../helpers';
import { TextItem } from './TextItem';

export const TextItems = () => {
  const { data, adminMode } = useThreeDProps();
  const { interactiveMode } = useThreeDState();
  const { texts } = data;
  const filteredTexts = useMemo(() =>
    getExhibitionItems(texts, adminMode, interactiveMode),
    [texts, adminMode, interactiveMode]
  );

  return filteredTexts.map((text, index) => (
    <TextItem
      key={index}
      {...text}
      editMode={!interactiveMode}
    />
  ));
};
