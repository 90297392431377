export const demo22 = {
    roomModelDmsId: '013AkPi11hgV',
    startPosition: { x: -9, y: 1.75, z: 0 },
    directionOfView: { x: 0.001, y: 0, z: 0 },
    lights: [
        {
            type: 'ambient',
            intensity: 1,
        },
    ],
    images: [
        {
            dmsId: '013AkPhx4Q5e',
            mediaType: 'image/png',
            scale: 2,
            position: { x: 9.985, y: 0.5, z: -0.95 },
            rotation: { x: 0, y: Math.PI / -2, z: 0 },
            addCanvasDepth: false,
            boundToGrid: false,
        },
        {
            dmsId: '013AkPbcP8iH',
            scale: 4.735,
            position: { x: 3.045, y: 2.03, z: 3.705 },
            rotation: { x: 0, y: 0, z: 0 },
            addCanvasDepth: false,
            boundToGrid: false,
        },
        {
            dmsId: '013AkPbcP8nc',
            scale: 4.5,
            position: { x: 4.79, y: 2.25, z: 11.125 },
            rotation: { x: 0, y: Math.PI / -2, z: 0 },
            addCanvasDepth: true,
            boundToGrid: false,
            viewerData: {
                model: {
                    type: 'ImageSingle',
                    dmsId: '013AkPbcP8nc',
                },
            },
        },
        {
            dmsId: '013AkPbcP8nd',
            scale: 1.515,
            position: { x: 4.79, y: 2.25, z: 4.8 },
            rotation: { x: 0, y: Math.PI / -2, z: 0 },
            addCanvasDepth: true,
            boundToGrid: false,
            viewerData: {
                model: {
                    type: 'ImageSingle',
                    dmsId: '013AkPbcP8nd',
                },
            },
        },
        {
            dmsId: '013AkPbcP8ne',
            scale: 1.518,
            position: { x: 4.79, y: 2.25, z: 6.3 },
            rotation: { x: 0, y: Math.PI / -2, z: 0 },
            addCanvasDepth: true,
            boundToGrid: false,
            viewerData: {
                model: {
                    type: 'ImageSingle',
                    dmsId: '013AkPbcP8ne',
                },
            },
        },
        {
            dmsId: '013AkPbcP8nf',
            scale: 1.498,
            position: { x: 4.79, y: 2.25, z: 7.8 },
            rotation: { x: 0, y: Math.PI / -2, z: 0 },
            addCanvasDepth: true,
            boundToGrid: false,
            viewerData: {
                model: {
                    type: 'ImageSingle',
                    dmsId: '013AkPbcP8nf',
                },
            },
        },
        {
            dmsId: '013AkPbcP8nh',
            scale: 1.386,
            position: { x: 3.755, y: 2.75, z: 13.79 },
            rotation: { x: 0, y: Math.PI, z: 0 },
            addCanvasDepth: true,
            boundToGrid: false,
            viewerData: {
                model: {
                    type: 'ImageSingle',
                    dmsId: '013AkPbcP8nh',
                },
            },
        },
        {
            dmsId: '013AkPbcP8nk',
            scale: 1.092,
            position: { x: 3.755, y: 1.25, z: 13.79 },
            rotation: { x: 0, y: Math.PI, z: 0 },
            addCanvasDepth: true,
            boundToGrid: false,
            viewerData: {
                model: {
                    type: 'ImageSingle',
                    dmsId: '013AkPbcP8nk',
                },
            },
        },
        {
            dmsId: '013AkPbcPTfF',
            scale: 2.616,
            position: { x: 9.985, y: 2.25, z: -14 },
            rotation: { x: 0, y: Math.PI / -2, z: 0 },
            addCanvasDepth: true,
            boundToGrid: false,
            viewerData: {
                model: {
                    type: 'ImageSingle',
                    dmsId: '013AkPbcPTfF',
                },
            },
        },
        {
            dmsId: '013AkPbcP8s1',
            scale: 3.146,
            position: { x: 9.985, y: 2.25, z: -10 },
            rotation: { x: 0, y: Math.PI / -2, z: 0 },
            addCanvasDepth: true,
            boundToGrid: false,
            viewerData: {
                model: {
                    type: 'ImageSingle',
                    dmsId: '013AkPbcP8s1',
                },
            },
        },
        {
            dmsId: '013AkPbcPTfG',
            scale: 2.617,
            position: { x: 9.985, y: 2.25, z: -6 },
            rotation: { x: 0, y: Math.PI / -2, z: 0 },
            addCanvasDepth: true,
            boundToGrid: false,
            viewerData: {
                model: {
                    type: 'ImageSingle',
                    dmsId: '013AkPbcPTfG',
                },
            },
        },
        {
            dmsId: '013AkPbcPTfH',
            scale: 2.574,
            position: { x: 9.985, y: 2.25, z: 6 },
            rotation: { x: 0, y: Math.PI / -2, z: 0 },
            addCanvasDepth: true,
            boundToGrid: false,
            viewerData: {
                model: {
                    type: 'ImageSingle',
                    dmsId: '013AkPbcPTfH',
                },
            },
        },
        {
            dmsId: '013AkPbcPTfJ',
            scale: 2.347,
            position: { x: 9.985, y: 2.25, z: 10 },
            rotation: { x: 0, y: Math.PI / -2, z: 0 },
            addCanvasDepth: true,
            boundToGrid: false,
            viewerData: {
                model: {
                    type: 'ImageSingle',
                    dmsId: '013AkPbcPTfJ',
                },
            },
        },
        {
            dmsId: '013AkPbcPTfK',
            scale: 2.5,
            position: { x: 9.985, y: 2.25, z: 14 },
            rotation: { x: 0, y: Math.PI / -2, z: 0 },
            addCanvasDepth: true,
            boundToGrid: false,
            viewerData: {
                model: {
                    type: 'ImageSingle',
                    dmsId: '013AkPbcPTfK',
                },
            },
        },
        {
            dmsId: '013AkPbcPTfM',
            scale: 2.446,
            position: { x: 4.79, y: 2.25, z: -11.9 },
            rotation: { x: 0, y: Math.PI / -2, z: 0 },
            addCanvasDepth: true,
            boundToGrid: false,
            viewerData: {
                model: {
                    type: 'ImageSingle',
                    dmsId: '013AkPbcPTfM',
                },
            },
        },
        {
            dmsId: '013AkPbcPTfN',
            scale: 2.442,
            position: { x: 4.79, y: 2.25, z: -9.8 },
            rotation: { x: 0, y: Math.PI / -2, z: 0 },
            addCanvasDepth: true,
            boundToGrid: false,
            viewerData: {
                model: {
                    type: 'ImageSingle',
                    dmsId: '013AkPbcPTfN',
                },
            },
        },
        {
            dmsId: '013AkPbcPTjd',
            scale: 2.461,
            position: { x: 4.79, y: 2.25, z: -7.7 },
            rotation: { x: 0, y: Math.PI / -2, z: 0 },
            addCanvasDepth: true,
            boundToGrid: false,
            viewerData: {
                model: {
                    type: 'ImageSingle',
                    dmsId: '013AkPbcPTjd',
                },
            },
        },
        {
            dmsId: '013AkPbcPTje',
            scale: 2.46,
            position: { x: 4.79, y: 2.25, z: -5.6 },
            rotation: { x: 0, y: Math.PI / -2, z: 0 },
            addCanvasDepth: true,
            boundToGrid: false,
            viewerData: {
                model: {
                    type: 'ImageSingle',
                    dmsId: '013AkPbcPTje',
                },
            },
        },
        {
            dmsId: '013AkPbcPTjg',
            scale: 2.25,
            position: { x: -4.79, y: 2.25, z: -5.6 },
            rotation: { x: 0, y: Math.PI / 2, z: 0 },
            addCanvasDepth: true,
            boundToGrid: false,
            viewerData: {
                model: {
                    type: 'ImageSingle',
                    dmsId: '013AkPbcPTjg',
                },
            },
        },
        {
            dmsId: '013AkPbcPTjh',
            scale: 2.253,
            position: { x: -4.79, y: 2.25, z: -7.7 },
            rotation: { x: 0, y: Math.PI / 2, z: 0 },
            addCanvasDepth: true,
            boundToGrid: false,
            viewerData: {
                model: {
                    type: 'ImageSingle',
                    dmsId: '013AkPbcPTjh',
                },
            },
        },
        {
            dmsId: '013AkPbcPTji',
            scale: 2.229,
            position: { x: -4.79, y: 2.25, z: -9.8 },
            rotation: { x: 0, y: Math.PI / 2, z: 0 },
            addCanvasDepth: true,
            boundToGrid: false,
            viewerData: {
                model: {
                    type: 'ImageSingle',
                    dmsId: '013AkPbcPTji',
                },
            },
        },
        {
            dmsId: '013AkPbcPTjj',
            scale: 2.197,
            position: { x: -4.79, y: 2.25, z: -11.9 },
            rotation: { x: 0, y: Math.PI / 2, z: 0 },
            addCanvasDepth: true,
            boundToGrid: false,
            viewerData: {
                model: {
                    type: 'ImageSingle',
                    dmsId: '013AkPbcPTjj',
                },
            },
        },
        {
            dmsId: '013AkPbcPTjk',
            scale: 2.624,
            position: { x: -4.79, y: 2.25, z: 11.9 },
            rotation: { x: 0, y: Math.PI / 2, z: 0 },
            addCanvasDepth: true,
            boundToGrid: false,
            viewerData: {
                model: {
                    type: 'ImageSingle',
                    dmsId: '013AkPbcPTjk',
                },
            },
        },
        {
            dmsId: '013AkPbcPTjm',
            scale: 2.593,
            position: { x: -4.79, y: 2.25, z: 9.8 },
            rotation: { x: 0, y: Math.PI / 2, z: 0 },
            addCanvasDepth: true,
            boundToGrid: false,
            viewerData: {
                model: {
                    type: 'ImageSingle',
                    dmsId: '013AkPbcPTjm',
                },
            },
        },
        {
            dmsId: '013AkPbcPTjn',
            scale: 2.573,
            position: { x: -4.79, y: 2.25, z: 7.7 },
            rotation: { x: 0, y: Math.PI / 2, z: 0 },
            addCanvasDepth: true,
            boundToGrid: false,
            viewerData: {
                model: {
                    type: 'ImageSingle',
                    dmsId: '013AkPbcPTjn',
                },
            },
        },
        {
            dmsId: '013AkPbcPTp3',
            scale: 2.567,
            position: { x: -4.79, y: 2.25, z: 5.6 },
            rotation: { x: 0, y: Math.PI / 2, z: 0 },
            addCanvasDepth: true,
            boundToGrid: false,
            viewerData: {
                model: {
                    type: 'ImageSingle',
                    dmsId: '013AkPbcPTp3',
                },
            },
        },
        {
            dmsId: '013AkPbcPTp5',
            scale: 6,
            position: { x: 0, y: 3, z: -19.995 },
            rotation: { x: 0, y: 0, z: 0 },
            addCanvasDepth: true,
            boundToGrid: false,
            viewerData: {
                model: {
                    type: 'ImageSingle',
                    dmsId: '013AkPbcPTp5',
                },
            },
        },
        {
            dmsId: '013AkPbcPTp6',
            scale: 2.576,
            position: { x: 6, y: 2.5, z: -19.995 },
            rotation: { x: 0, y: 0, z: 0 },
            addCanvasDepth: true,
            boundToGrid: false,
            viewerData: {
                model: {
                    type: 'ImageSingle',
                    dmsId: '013AkPbcPTp6',
                },
            },
        },
        {
            dmsId: '013AkPbcPTp7',
            scale: 2.533,
            position: { x: -6, y: 2.5, z: -19.995 },
            rotation: { x: 0, y: 0, z: 0 },
            addCanvasDepth: true,
            boundToGrid: false,
            viewerData: {
                model: {
                    type: 'ImageSingle',
                    dmsId: '013AkPbcPTp7',
                },
            },
        },
        {
            dmsId: '013AkPhx4Pn1',
            scale: 5.379,
            position: { x: -3.895, y: 1.7, z: -3.495 },
            rotation: { x: 0, y: 0, z: 0 },
            addCanvasDepth: false,
            boundToGrid: false,
        },
        {
            dmsId: '013AkPhx4PrQ',
            scale: 5.081,
            position: { x: -3.895, y: 1.86, z: 3.495 },
            rotation: { x: 0, y: Math.PI, z: 0 },
            addCanvasDepth: false,
            boundToGrid: false,
        },
        {
            dmsId: '013AkPhx4Pvv',
            scale: 5.705,
            position: { x: 3.144, y: 1.55, z: 3.495 },
            rotation: { x: 0, y: Math.PI, z: 0 },
            addCanvasDepth: false,
            boundToGrid: false,
        },
        {
            dmsId: '013AkPhx4Pn6',
            scale: 5.595,
            position: { x: 3.145, y: 1.6, z: -3.495 },
            rotation: { x: 0, y: 0, z: 0 },
            addCanvasDepth: false,
            boundToGrid: false,
        },
        {
            dmsId: '013AkPhx4Pvw',
            scale: 3.5,
            position: { x: -5, y: 2.25, z: 11 },
            rotation: { x: 0, y: Math.PI / -2, z: 0 },
            addCanvasDepth: true,
            boundToGrid: false,
            viewerData: {
                model: {
                    type: 'ImageSingle',
                    dmsId: '013AkPhx4Pvw',
                },
            },
        },
        {
            dmsId: '013AkPhx4Q1D',
            scale: 3.5,
            position: { x: -5, y: 2.25, z: 6.35 },
            rotation: { x: 0, y: Math.PI / -2, z: 0 },
            addCanvasDepth: true,
            boundToGrid: false,
            viewerData: {
                model: {
                    type: 'ImageSingle',
                    dmsId: '013AkPhx4Q1D',
                },
            },
        },
        {
            dmsId: '013AkPhx4Q1E',
            scale: 3.5,
            position: { x: -5, y: 2.25, z: -11 },
            rotation: { x: 0, y: Math.PI / -2, z: 0 },
            addCanvasDepth: true,
            boundToGrid: false,
            viewerData: {
                model: {
                    type: 'ImageSingle',
                    dmsId: '013AkPhx4Q1E',
                },
            },
        },
        {
            dmsId: '013AkPhx4Q1F',
            scale: 3.5,
            position: { x: -5, y: 2.25, z: -6.35 },
            rotation: { x: 0, y: Math.PI / -2, z: 0 },
            addCanvasDepth: true,
            boundToGrid: false,
            viewerData: {
                model: {
                    type: 'ImageSingle',
                    dmsId: '013AkPhx4Q1F',
                },
            },
        },
        {
            dmsId: '013AkPhx4Q1G',
            scale: 2.852,
            position: { x: 5, y: 2.25, z: 11 },
            rotation: { x: 0, y: Math.PI / 2, z: 0 },
            addCanvasDepth: true,
            boundToGrid: false,
            viewerData: {
                model: {
                    type: 'ImageSingle',
                    dmsId: '013AkPhx4Q1G',
                },
            },
        },
        {
            dmsId: '013AkPhx4Q1H',
            scale: 2.924,
            position: { x: 5, y: 2.25, z: 6.35 },
            rotation: { x: 0, y: Math.PI / 2, z: 0 },
            addCanvasDepth: true,
            boundToGrid: false,
            viewerData: {
                model: {
                    type: 'ImageSingle',
                    dmsId: '013AkPhx4Q1H',
                },
            },
        },
        {
            dmsId: '013AkPhx4Q1J',
            scale: 2.99,
            position: { x: 5, y: 2.25, z: -11 },
            rotation: { x: 0, y: Math.PI / 2, z: 0 },
            addCanvasDepth: true,
            boundToGrid: false,
            viewerData: {
                model: {
                    type: 'ImageSingle',
                    dmsId: '013AkPhx4Q1J',
                },
            },
        },
        {
            dmsId: '013AkPhx4Q1K',
            scale: 3.175,
            position: { x: 5, y: 2.25, z: -6.35 },
            rotation: { x: 0, y: Math.PI / 2, z: 0 },
            addCanvasDepth: true,
            boundToGrid: false,
            viewerData: {
                model: {
                    type: 'ImageSingle',
                    dmsId: '013AkPhx4Q1K',
                },
            },
        },
        {
            dmsId: '013AkPhx4Q1L',
            scale: 3.5,
            position: { x: -9.985, y: 2.25, z: -8.25 },
            rotation: { x: 0, y: Math.PI / 2, z: 0 },
            addCanvasDepth: true,
            boundToGrid: false,
            viewerData: {
                model: {
                    type: 'ImageSingle',
                    dmsId: '013AkPhx4Q1L',
                },
            },
        },
        {
            dmsId: '013AkPhx4Q1M',
            scale: 3.5,
            position: { x: -9.985, y: 2.25, z: 8.25 },
            rotation: { x: 0, y: Math.PI / 2, z: 0 },
            addCanvasDepth: true,
            boundToGrid: false,
            viewerData: {
                model: {
                    type: 'ImageSingle',
                    dmsId: '013AkPhx4Q1M',
                },
            },
        },
        {
            dmsId: '013AkPhx4Q1N',
            scale: 3.872,
            position: { x: -9.985, y: 2.75, z: 14.5 },
            rotation: { x: 0, y: Math.PI / 2, z: 0 },
            addCanvasDepth: true,
            boundToGrid: false,
            viewerData: {
                model: {
                    type: 'ImageSingle',
                    dmsId: '013AkPhx4Q1N',
                },
            },
        },
        {
            dmsId: '013AkPhx4Q5d',
            scale: 4.167,
            position: { x: -9.985, y: 2.75, z: -14.5 },
            rotation: { x: 0, y: Math.PI / 2, z: 0 },
            addCanvasDepth: true,
            boundToGrid: false,
            viewerData: {
                model: {
                    type: 'ImageSingle',
                    dmsId: '013AkPhx4Q5d',
                },
            },
        },
    ],
    texts: [
        {
            position: { x: 9.985, y: 4.05, z: 0 },
            rotation: { x: 0, y: Math.PI / -2, z: 0 },
            maxWidth: 4,
            paragraphs: [
                {
                    text: 'Gateplagg fra Helly Hansen 1950 - 2015',
                    fontSize: 0.62,
                    lineHeight: 1.2,
                },
            ],
            boundToGrid: false,
        },
        {
            position: { x: 9.985, y: 2.1, z: -1 },
            rotation: { x: 0, y: Math.PI / -2, z: 0 },
            maxWidth: 1.9,
            paragraphs: [
                {
                    text: 'Tøft og skiftende klima har gitt nordmenn grunn til å lage og bruke regntøy. Allerede rundt 1860 vokste de første fabrikkene fram. Norsk regntøykonfeksjon ble etterhvert markedsledende med Helly J. Hansen, etablert i Moss i 1877, i spissen.',
                    fontSize: 0.08,
                    lineHeight: 1.2,
                },
                {
                    text: 'Hovedproduktet var i starten vanntett arbeidstøy til bruk på sjøen og på land, men etter plastens inntog fra 1950 og utover ble det produsert en rekke nye modeller til fritids- og spaserbruk.',
                    fontSize: 0.08,
                    lineHeight: 1.2,
                    margin: {
                        top: 0.1,
                    },
                },
                {
                    text: 'Moss by- og industrimuseum mottok i 2009 Helly Hansens bedriftshistoriske samling.',
                    fontSize: 0.08,
                    lineHeight: 1.2,
                    margin: {
                        top: 0.1,
                    },
                },
            ],
            boundToGrid: false,
        },
        {
            position: { x: 9.985, y: 1.95, z: 1 },
            rotation: { x: 0, y: Math.PI / -2, z: 0 },
            maxWidth: 1.9,
            paragraphs: [
                {
                    text: 'Mange av plaggene vi registrerte har benevnelsen Gateplagg, men designprosess, produksjon, distribusjon og bruk har variert. Noen ble opprinnelig designet som spesialplagg for sjø, fiske eller arbeid, men ble moteplagg i større eller mindre grupper av befolkningen. Andre produkter ble bevisst utformet som trendy moteplagg for bestemte målgrupper.',
                    fontSize: 0.08,
                    lineHeight: 1.2,
                },
                {
                    text: 'Økonomiske, teknologiske, sosiale og kulturelle faktorer har påvirket plaggenes utforming, men også deres popularitet og nedslagsfelt gjennom tidene. Denne utstillingen viser klassikerne som slo an som bruksplagg på gata. Markedsmateriell, fotografier, klipp fra aviser og bransjeblader i bedriftsarkivet gir et innblikk i ulike faktorer som spiller inn for at et produkt blir et moteplagg.',
                    fontSize: 0.08,
                    lineHeight: 1.2,
                    margin: {
                        top: 0.1,
                    },
                },
            ],
            boundToGrid: false,
        },
        {
            position: { x: -3.047, y: 2.25, z: -13.787 },
            rotation: { x: 0, y: 0, z: 0 },
            maxWidth: 1.7,
            paragraphs: [
                {
                    text: 'Regntøy i plast til fritidsbruk',
                    fontSize: 0.25,
                    lineHeight: 1.2,
                },
                {
                    text: 'I årene rett etter 2. verdenskrig begynte Helly J. Hansen å produsere regntøy i plast. I motsetning til de gamle, var de nye plaggene i PVC lettere og mer komfortable å ha på enn de gammeldagse oljeklærne som kunne være tunge og klebrige. Vanntette klær ble moderne.',
                    fontSize: 0.125,
                    lineHeight: 1.2,
                    margin: {
                        top: 0.2,
                    }
                },
            ],
            boundToGrid: false,
        },
        {
            position: { x: 3.047, y: 2.25, z: -3.705 },
            rotation: { x: 0, y: Math.PI, z: 0 },
            maxWidth: 1.75,
            paragraphs: [
                {
                    text: 'De første Helox-frakkene ble levert i fargene grå til herrer og gjennomsiktig melkehvit til damer. Gjennom frakken kunne selv den vakreste vårkåpe eller kjole være synlig selv om det regnet.',
                    fontSize: 0.125,
                    lineHeight: 1.2,
                },
                {
                    text: 'Etter hvert ble de levert i flere farger, og plasten fikk tekstilstruktur. Søm, knappe-hull og spesialkonstruerte knapper med tekstur og illudert søm ble sveiset fast i folien. Med dette fikk plastfrakkene et mer tekstilt uttrykk.',
                    fontSize: 0.125,
                    lineHeight: 1.2,
                    margin: {
                        top: 0.1,
                    }
                },
            ],
            boundToGrid: false,
        },
        {
            position: { x: 3.85, y: 2.25, z: -13.787 },
            rotation: { x: 0, y: 0, z: 0 },
            maxWidth: 1.3,
            paragraphs: [
                {
                    text: 'Kolleksjonen var inspirert av parisisk mote og ble markedsført i ukeblader, bransjeblader, aviser og på kinoplakater.',
                    fontSize: 0.125,
                    lineHeight: 1.2,
                },
                {
                    text: 'I den danske illustratøren Christels fargerike og elegante illustrasjoner figurerte den moderne mannen og kvinnen.',
                    fontSize: 0.125,
                    lineHeight: 1.2,
                    margin: {
                        top: 0.1,
                    }
                },
                {
                    text: 'Iført Helly Hansens Helox plastregntøy var man både velkledd og praktisk antrukket.',
                    fontSize: 0.125,
                    lineHeight: 1.2,
                    margin: {
                        top: 0.1,
                    }
                },
            ],
            boundToGrid: false,
        },
        {
            position: { x: -3.85, y: 2.25, z: -3.705 },
            rotation: { x: 0, y: Math.PI, z: 0 },
            maxWidth: 1.3,
            paragraphs: [
                {
                    text: 'Da folk flest fikk mer fritid oppsto et behov for lettere, mer funksjonelle og moderne plagg til turer i skog og mark eller gatelangs.',
                    fontSize: 0.125,
                    lineHeight: 1.2,
                },
                {
                    text: 'Allerede fra 1924 lanserte Helly Hansen kolleksjoner i kvaliteten Linox som var tynt lerret belagt med linolje.',
                    fontSize: 0.125,
                    lineHeight: 1.2,
                    margin: {
                        top: 0.1,
                    }
                },
                {
                    text: 'Regnet det i byen, kunne folk ta på seg sine vide Slikkers og stramme beltet i livet som amerikanske tegneseriehelter og filmstjerner.',
                    fontSize: 0.125,
                    lineHeight: 1.2,
                    margin: {
                        top: 0.1,
                    }
                },
            ],
            boundToGrid: false,
        },
        {
            position: { x: -3.047, y: 2.25, z: 13.787 },
            rotation: { x: 0, y: Math.PI, z: 0 },
            maxWidth: 2.1,
            paragraphs: [
                {
                    text: 'Raff i regn! Smil når englene gråter!',
                    fontSize: 0.25,
                    lineHeight: 1.2,
                },
                {
                    text: 'Hvem husker ikke med trangpustet gru barndommens triste regnfrakker med sydvest og gummistøvler? Det var ikke akkurat et antrekk å bli i perlehumør av på skolevei i kulingkast og annen utrivelighet? Etterpå kom de gjennomsiktige, lette plastfrakkene med posehette til å klemme nedover krøllene.',
                    fontSize: 0.125,
                    lineHeight: 1.2,
                    margin: {
                        top: 0.2,
                    }
                },
                {
                    text: 'Det var først etter krigen at en regntøyprodusent ved Oslofjorden slapp smilet løs og introduserte hele paletten av farver i kåper og caper, og dermed var det gjort og slaget tapt for det deprimerende tordengrå. Parolen ble heretter: Raff i regn! Smil når englene gråter!',
                    fontSize: 0.125,
                    lineHeight: 1.2,
                    margin: {
                        top: 0.1,
                    }
                },
            ],
            boundToGrid: false,
        },
        {
            position: { x: -3.85, y: 2.25, z: 3.705 },
            rotation: { x: 0, y: 0, z: 0 },
            maxWidth: 1.4,
            paragraphs: [
                {
                    text: 'Blant unge piker ble Sneppert-Coat et populært friluftsplagg. Navnet kom av de praktiske og dekorative lukkeordningene, Sneppertene. Modellen var romslig med store sidelommer og fast hette med fôr i kontrastfarge.',
                    fontSize: 0.125,
                    lineHeight: 1.2,
                },
                {
                    text: 'På byturen kunne unge damer kle seg i Zip-Coat med glidelås-lukning i fronten eller i Lakk-Trench og Lets gummistøvler fra Viking. Stil var nå viktigere enn funksjon.',
                    fontSize: 0.125,
                    lineHeight: 1.2,
                    margin: {
                        top: 0.1,
                    }
                },
            ],
            boundToGrid: false,
        },
    ],
    videos: [
        {
            dmsId: '013AkPbcP8Qd',
            width: 6.667,
            height: 5,
            position: { x: -9.985, y: 3, z: 0 },
            rotation: { x: 0, y: Math.PI / 2, z: 0 },
            autoPlay: false,
        },
    ],
    quizzes: [
        {
            position: { x: 1, y: 1.3, z: 18 },
            scale: { x: 4.5, y: 4.5, z: 4.5 },
            content: {
                title: 'Quiz',
                questions: [
                    {
                        id: 1,
                        question: 'Når ble Helly J. Hansen etablert?',
                        a: '1860',
                        b: '1872',
                        c: '1877',
                        d: '1886',
                        answer: 'c',
                    },
                    {
                        id: 2,
                        question: 'Hva heter tekstilen som Helly Hansen lanserte i 1924?',
                        a: 'Linux',
                        b: 'Linox',
                        c: 'Helox',
                        d: 'Helix',
                        answer: 'b',
                    },
                ],
            },
        },
    ],
}