import { cloneElement, useCallback, useState } from 'react';
import { Html } from '@react-three/drei';
import { animated, useSpring } from '@react-spring/three';
import { useGesture } from '@use-gesture/react';
import { PlaybackButton } from './PlaybackButton';

const AnimatedPlaybackButton = animated(PlaybackButton);

export const AudioController = ({
  name,
  position,
  rotation,
  enabled = true,
  selected = false,
  scale,
  onClick,
  onMove,
  onMoved,
  children,
}) => {
  const [started, setStarted] = useState(false);
  const [paused, setPaused] = useState(true);
  const [hover, setHover] = useState(false);
  const [progress, setProgress] = useState(0);
  const opacity = useSpring({ opacity: !position ? 0 : (hover ? 1 : 0.65) });

  const handleEnded = useCallback((loop) => {
    !loop && setPaused(true) && setStarted(false);
    setProgress(0);
  });

  const handleProgressChange = useCallback((val) => {
    setProgress(val);
  });

  const handleDrag = ({ tap, last, dragging, xy }) => {
    if (tap) {
      if (enabled) {
        setPaused(!paused);
        !started && setStarted(true);
      }

      onClick && onClick();
    }
    else if (selected) {
      const point = { x: xy[0], y: xy[1] };
      dragging && onMove(point);
      last && onMoved(point);
    }
  };

  const handleHover = ({ first, last }) => {
    if (!enabled) return;

    first && setHover(true);
    last && setHover(false);
  };

  const useGestures = () => {
    return useGesture(
      {
        onDrag: handleDrag,
        onHover: handleHover,
      },
      { drag: { filterTaps: true }},
    )();
  };

  return (
    <>
      <Html
        name={name}
        occlude={!selected}
        transform
        distanceFactor={1}
        position={position}
        rotation={rotation}
      >
        <div
          style={{ touchAction: 'none' }}
          {...useGestures()}
        >
          <AnimatedPlaybackButton
            started={started}
            paused={paused}
            hover={hover}
            progress={progress}
            selected={selected}
            scale={scale}
            {...opacity}
          />
        </div>
      </Html>
      {children && cloneElement(children, {
        paused,
        onProgressChange: handleProgressChange,
        onEnded: handleEnded,
      })}
    </>
  );
};
