import * as THREE from 'three';
// import * as BufferGeometryUtils from 'three/examples/jsm/utils/BufferGeometryUtils.js'
import { useState } from 'react';
import { useSpring } from 'react-spring';
import { useThree } from '@react-three/fiber';
import { Pathfinding, PathfindingHelper } from 'three-pathfinding';
import { easePolyInOut } from 'd3-ease';
import { getPositionFromCamera, getPositionInDirection } from '../../helpers';
import { SET_ROTATION_ENABLED, useThreeDDispatch } from '../App/ThreeDContext';
import { store } from '../store';

export const usePathfindingTraversal = () => {
  const dispatch = useThreeDDispatch();
  const { camera, scene } = useThree();
  const { 1: springApi } = useSpring(() => ({ }));
  const [ isTraversing, setIsTraversing ] = useState(false);
  const setCameraPosition = store(state => state.setCameraPosition);

  const getPath = (startPosition, endPosition) => {
    const navMesh = scene.getObjectByName('NavMesh');

    if (!navMesh) return [];

    const ZONE = 'level';
    const pathfinder = new Pathfinding();
    // const helper = new PathfindingHelper();
    const navMeshGeometry = navMesh.geometry.clone();
    // const navMeshGeometry = BufferGeometryUtils.mergeBufferGeometries([
    //   scene.getObjectByName('Floor_Scandi_Wash_01003').geometry.clone(),
    //   scene.getObjectByName('Plane020').geometry.clone(),
    //   scene.getObjectByName('Plane020_1').geometry.clone(),
    //   scene.getObjectByName('Floor_Scandi_Wash_03003').geometry.clone(),
    // ]);

    pathfinder.setZoneData(ZONE, Pathfinding.createZone(navMeshGeometry));
    const groupID = pathfinder.getGroup(ZONE, startPosition);
    let path = pathfinder.findPath(startPosition, endPosition, ZONE, groupID) ?? [];

    path = [...new Map(path.map(position => [JSON.stringify(position), position])).values()];
    path.forEach((value, index, array) => {
      if (index < array.length - 1) {
        const nextValue = array[index + 1];
        const distance = value.distanceTo(nextValue);

        if (distance < 1) {
          value.lerp(nextValue, 0.5);
          array.splice(index + 1, 1);
        }
      }
    });

    // helper.setPlayerPosition(startPosition).setTargetPosition(endPosition);
    // helper.setPath(path);
    // scene.add(helper);

    return path;
  };

  const getTravelCurve = (startPosition, path) => {
    const travelPath = [startPosition, ...path];
    return new THREE.CatmullRomCurve3(travelPath, false, 'chordal');
  };

  const getCameraCurve = (startPosition, path, destinationRotation) => {
    const startViewDirection = getPositionFromCamera(camera, 0.00011);
    startViewDirection.setY(startViewDirection.y - 1.75);
    const intermediatePositions = path.slice(0, -1);
    const stopPosition = getPositionInDirection([...path].pop(), destinationRotation, 2);
    const stopViewDirection = getPositionInDirection([...path].pop(), destinationRotation, 2.00011);
    const cameraPath = [
      startPosition,
      startViewDirection,
      ...intermediatePositions,
      stopPosition,
      stopViewDirection,
    ];
    return new THREE.CatmullRomCurve3(cameraPath, false, 'chordal');
  };

  const handleTraversalStart = () => {
    setIsTraversing(true);
    dispatch({ type: SET_ROTATION_ENABLED, rotationEnabled: false });
  };

  const handleTraversalChange = (travelProgress, travelCurve, cameraCurve, controls) => {
    const cameraPosition = new THREE.Vector3();
    const cameraDirection = new THREE.Vector3();

    travelCurve.getPointAt(travelProgress, cameraPosition);
    cameraCurve.getTangentAt(travelProgress, cameraDirection);

    cameraPosition.setY(1.75);
    camera.position.copy(cameraPosition);
    controls.target.copy(cameraPosition.clone().add(cameraDirection));
  };

  const handleTraversalEnd = (controls) => {
    const target = getPositionFromCamera(camera, 0.001);
    controls.target.copy(target);
    setCameraPosition(camera.position);
    dispatch({ type: SET_ROTATION_ENABLED, rotationEnabled: true });
    setIsTraversing(false);
  };

  const start = (destinationPosition, destinationRotation, controls, speedFactor = 1500) => {
    const startPosition = camera.position.clone().setY(camera.position.y - 1.75);
    destinationPosition.setY(0);
    const path = getPath(startPosition, destinationPosition);

    if (path.length > 0 && startPosition.distanceTo(destinationPosition) > 0.01) {
      const travelCurve = getTravelCurve(startPosition, path);
      const cameraCurve = getCameraCurve(startPosition, path, destinationRotation);

      springApi.start({
        from: {
          travelProgress: 0,
        },
        to: {
          travelProgress: 1,
        },
        config: {
          duration: speedFactor * Math.sqrt(travelCurve.getLength() * 3),
          easing: easePolyInOut,
        },
        onStart: () => {
          handleTraversalStart();
        },
        onChange: (res) => {
          handleTraversalChange(res.value.travelProgress, travelCurve, cameraCurve, controls);
        },
        onRest: () => {
          handleTraversalEnd(controls);
        },
      });

      // const travelTubeGeometry = new THREE.TubeGeometry(travelCurve, Math.round(travelCurve.getLength() * 10), 0.1, 10, false);
      // const travelWireframeMaterial = new THREE.MeshBasicMaterial({ color: 0xff0000, opacity: 0.5, wireframe: true, transparent: true });
      // const travelWireframe = new THREE.Mesh(travelTubeGeometry, travelWireframeMaterial);
      // travelWireframe.position.setY(1.75);
      // scene.add(travelWireframe);

      // const cameraTubeGeometry = new THREE.TubeGeometry(cameraCurve, Math.round(cameraCurve.getLength() * 10), 0.1, 10, false);
      // const cameraWireframeMaterial = new THREE.MeshBasicMaterial({ color: 0x0000ff, opacity: 0.5, wireframe: true, transparent: true });
      // const cameraWireframe = new THREE.Mesh(cameraTubeGeometry, cameraWireframeMaterial);
      // cameraWireframe.position.setY(1.75);
      // scene.add(cameraWireframe);
    }
    else {
      const currentRotation = new THREE.Vector3(0, 0, 1).applyEuler(camera.rotation).negate();
      const targetRotation = new THREE.Vector3().setFromEuler(destinationRotation);
      const rotationAngle = currentRotation.angleTo(targetRotation);

      if (rotationAngle !== 0) {
        springApi.start({
          from: {
            x: currentRotation.x,
            y: currentRotation.y,
            z: currentRotation.z,
          },
          to: {
            x: targetRotation.x,
            y: targetRotation.y,
            z: targetRotation.z,
          },
          config: {
            duration: 1000 * (0.5 + currentRotation.angleTo(targetRotation)),
            easing: easePolyInOut,
          },
          onStart: () => {
            dispatch({ type: SET_ROTATION_ENABLED, rotationEnabled: false });
          },
          onChange: (res) => {
            const cameraDirection = new THREE.Vector3(res.value.x, res.value.y, res.value.z).normalize().multiplyScalar(0.001);
            controls.target.copy(camera.position.clone().add(cameraDirection));
          },
          onRest: () => {
            dispatch({ type: SET_ROTATION_ENABLED, rotationEnabled: true });
          },
        });
      }
    }
  };

  const stop = () => {
    springApi.stop();
  };

  return {
    start,
    stop,
    isTraversing: () => { return isTraversing },
  };
};
