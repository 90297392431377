export const RectAreaLights = ({ lights }) =>
  lights.map(({ intensity, position, width, height, rotation }, index) => (
    <rectAreaLight
      intensity={intensity}
      position={position}
      width={width}
      height={height}
      rotation={rotation}
      key={index}
    />
  ));
