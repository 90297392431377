export const demo14 = {
    roomModelDmsId: '013AkP2pH25Q',
    startPosition: { x: 0, y: 1.75, z: -18 },
    directionOfView: { x: 0, y: 0, z: 0.001 },
    lights: [
        {
            type: 'ambient',
            intensity: 1,
        },
    ],
    images: [
        {
            dmsId: '013AhtPrnst3',
            scale: 2.5,
            position: { x: 4.78, y: 1.9, z: 5.7 },
            rotation: { x: 0, y: Math.PI / -2, z: 0 },
            viewerData: {
                model: {
                    type: 'ImageSingle',
                    dmsId: '013AhtPrnst3',
                },
            },
        },
        {
            dmsId: '013AhtPrnsok',
            scale: 2.5,
            position: { x: 4.78, y: 1.9, z: 8.7 },
            rotation: { x: 0, y: Math.PI / -2, z: 0 },
            viewerData: {
                model: {
                    type: 'ImageSingle',
                    dmsId: '013AhtPrnsok',
                },
            },
        },
        {
            dmsId: '013AhtPrnsoi',
            scale: 2.5,
            position: { x: 4.78, y: 1.9, z: 11.7 },
            rotation: { x: 0, y: Math.PI / -2, z: 0 },
            viewerData: {
                model: {
                    type: 'ImageSingle',
                    dmsId: '013AhtPrnsoi',
                },
            },
        },
        {
            dmsId: '013AhtPrnst9',
            scale: 2.5,
            position: { x: -4.79, y: 1.9, z: 5.7 },
            rotation: { x: 0, y: Math.PI / 2, z: 0 },
            viewerData: {
                model: {
                    type: 'ImageSingle',
                    dmsId: '013AhtPrnst9',
                },
            },
        },
        {
            dmsId: '013AhtPrnstB',
            scale: 2.5,
            position: { x: -4.79, y: 1.9, z: 8.7 },
            rotation: { x: 0, y: Math.PI / 2, z: 0 },
            viewerData: {
                model: {
                    type: 'ImageSingle',
                    dmsId: '013AhtPrnstB',
                },
            },
        },
        {
            dmsId: '013AhtPrnt2s',
            scale: 2.5,
            position: { x: -4.79, y: 1.9, z: 11.7 },
            rotation: { x: 0, y: Math.PI / 2, z: 0 },
            viewerData: {
                model: {
                    type: 'ImageSingle',
                    dmsId: '013AhtPrnt2s',
                },
            },
        },
        {
            dmsId: '013AhtPrnst4',
            scale: 2.5,
            position: { x: 4.78, y: 1.9, z: -5.7 },
            rotation: { x: 0, y: Math.PI / -2, z: 0 },
            viewerData: {
                model: {
                    type: 'ImageSingle',
                    dmsId: '013AhtPrnst4',
                },
            },
        },
        {
            dmsId: '013AhtPrnst6',
            scale: 2.5,
            position: { x: 4.78, y: 1.9, z: -8.7 },
            rotation: { x: 0, y: Math.PI / -2, z: 0 },
            viewerData: {
                model: {
                    type: 'ImageSingle',
                    dmsId: '013AhtPrnst6',
                },
            },
        },
        {
            dmsId: '013AhtPrnt7H',
            scale: 2.5,
            position: { x: 4.78, y: 1.9, z: -11.7 },
            rotation: { x: 0, y: Math.PI / -2, z: 0 },
            viewerData: {
                model: {
                    type: 'ImageSingle',
                    dmsId: '013AhtPrnt7H',
                },
            },
        },
        {
            dmsId: '013AjtVKmfMK',
            scale: 2.5,
            position: { x: -4.79, y: 1.9, z: -5.7 },
            rotation: { x: 0, y: Math.PI / 2, z: 0 },
            viewerData: {
                model: {
                    type: 'ImageSingle',
                    dmsId: '013AjtVKmfMK',
                },
            },
        },
        {
            dmsId: '013AjtVKmff1',
            scale: 2.5,
            position: { x: -4.79, y: 1.9, z: -8.7 },
            rotation: { x: 0, y: Math.PI / 2, z: 0 },
            viewerData: {
                model: {
                    type: 'ImageSingle',
                    dmsId: '013AjtVKmff1',
                },
            },
        },
        {
            dmsId: '013AjtVMCczb',
            scale: 2.5,
            position: { x: -4.79, y: 1.9, z: -11.7 },
            rotation: { x: 0, y: Math.PI / 2, z: 0 },
            viewerData: {
                model: {
                    type: 'ImageSingle',
                    dmsId: '013AjtVMCczb',
                },
            },
        },
    ],
    texts: [
        {
            position: { x: 3.1, y: 1.9, z: 3.71 },
            rotation: { x: 0, y: 0, z: 0 },
            maxWidth: 2.4,
            paragraphs: [
                {
                    text: 'Nære og kjære',
                    fontSize: 0.3,
                },
                {
                    text: 'Våre kjære og nære, ja venner og relasjoner, det er jo for noen kanskje det viktigste man har i livet. Det stemte for Niels Juel, vi vet at familien hans var noe han satt utrolig stor pris på. Det er derfor kanskje ikke så rart at vi har så mange flotte gruppebilder der amatørfotografen er omringet av familien sin. Han dokumenterte stadig både venner, sommer- og vintergjester, arbeidere som jobbet på Viken Gård, og ikke mist de flotte dyrene som bodde på gården. Noe annet som også var ganske flott var vennskapet til Niels Juel og Niels Strand. Av alle vennene til Niels er det herr Strand som går mest igjen i fotografiene. Det sies at et bilde sier mer enn tusen ord, og disse bildene sier mye om det gode vennskapet deres.',
                    fontSize: 0.1,
                    lineHeight: 1.35,
                    margin: {
                        top: 0.05,
                    },
                },
            ],
            audio: {
                dmsId: '0138Ytponu6z',
                refDistance: 1.5,
                rolloffFactor: 3,
                volume: 2.5,
                loop: false,
                maxDistance: 10,
            },
        },
        {
            position: { x: -3.2, y: 1.9, z: 13.79 },
            rotation: { x: 0, y: Math.PI, z: 0 },
            maxWidth: 2.4,
            paragraphs: [
                {
                    text: 'Bygdeliv',
                    fontSize: 0.3,
                },
                {
                    text: 'Norske gårder og små landbruk på begynnelsen av 1900-tallet var levende steder hvor det samme familietreet kunne ha bodd i flere generasjoner. Petra Juel, Niels Juel sin mor, arvet Viken gården etter at hennes far, Bestefar Larsen. Her fikk Niels tilhøre hele livet, sammen med hans far, mor, tanter, og senere kone og barn. I tillegg til kjernefamilien bodde det både tjenestepiker, arbeidere, og til tider forpaktere på gården. En stor gård har plass til mange, så det var ofte flust med gjester på gården. Alle disse bildene som Hobbyfotografen tok, gir oss et unikt innblikk i hvordan gårdsdrift og bygdeliv var på begynnelsen av 1900-tallets Norge.',
                    fontSize: 0.1,
                    lineHeight: 1.35,
                    margin: {
                        top: 0.1,
                    },
                },
            ],
            audio: {
                dmsId: '0138Ytponu71',
                refDistance: 1.5,
                rolloffFactor: 3,
                volume: 1,
                loop: false,
                maxDistance: 10,
            },
        },
        {
            position: { x: 3, y: 1.9, z: -3.71 },
            rotation: { x: 0, y: Math.PI, z: 0 },
            maxWidth: 2.4,
            paragraphs: [
                {
                    text: 'Ut i verden',
                    fontSize: 0.3,
                },
                {
                    text: 'For hundre år siden, reiste folk litt mindre enn vi gjør, og de tok i hvert fall færre bilder på reisene deres. Hobbyfotografen Niels Juel sitt hjem var alltid Viken Gård i Hurdal, men han hadde reising i blodet. Han var veldig ofte i København i Danmark både alene og sammen med andre, men også andre byer som blant annet Göteborg, Stockholm, og Wiesbaden i Tyskland. Det var ikke bare det store utland han reiste rundt i, han dro også til flere steder i Norge. Det var nok Oslo som han hadde gått på skole i som var det enkleste reisemålet siden det var ganske i nærheten. Når han først var på tur var han mye mer fascinert av arkitektur og bylandskap enn portretter, og han tok betraktelig mindre bilder av folk når han var på tur.',
                    fontSize: 0.1,
                    lineHeight: 1.35,
                    margin: {
                        top: 0.1,
                    },
                },
            ],
            audio: {
                dmsId: '0138Ytponu72',
                refDistance: 1.5,
                rolloffFactor: 3,
                volume: 1,
                loop: false,
                maxDistance: 10,
            },
        },
        {
            position: { x: -3, y: 2.1, z: -13.79 },
            rotation: { x: 0, y: 0, z: 0 },
            maxWidth: 2.6,
            paragraphs: [
                {
                    text: 'Hva har du med deg, herr Hobbyfotograf?',
                    fontSize: 0.25,
                },
                {
                    text: 'Kamera - Hasselblad Svensk Express',
                    fontSize: 0.15,
                    margin: {
                        top: 0.1,
                    },
                },
                {
                    text: 'Det er Juels kamera du ser, og det er i god stand til å være over nesten 100 år gammelt. Han tok godt vare på det, og kameraet har nok sett litt av hvert siden Juel tok det med seg på reise i Europa.',
                    fontSize: 0.1,
                    lineHeight: 1.35,
                    margin: {
                        top: 0.1,
                    },
                },
                {
                    text: 'Kamera hans ble først produsert i 1883 og det lød navnet «Svenska Express». Dette kamera ble markedsført hos Hasselblad, og produsert på bestilling i nabohuset Svensson i Göteborg, Sverige. Kameraet er relativt liten og lett å ta med seg på reise (18x10x22cm), og har en stropp på toppen for å kunne bæres. Det er plass til tolv glassplater inne i kameraet, og det er en bakluke på det for å enkelt kunne fylle på flere plater til flere blinkskudd. ',
                    fontSize: 0.1,
                    lineHeight: 1.35,
                    margin: {
                        top: 0.05,
                    },
                },
                {
                    text: 'Hasselbladmerket var først en liten familieproduksjon, og det var nemlig i 1842 alt startet med en god idé og en nyåpnet butikk av Fritz Victor Hasselblad. Bedriften fikk sin kickstart i 1941 da det svenske forsvaret trengte et skikkelig kamera til flyspaning. NASA hjalp også til med verdensberømmelsen, da de i 1962 tok i bruk Hasselblad 500C for å ta bilde av jordkloden sett fra månen.',
                    fontSize: 0.1,
                    lineHeight: 1.35,
                    margin: {
                        top: 0.05,
                    },
                },
            ],
            audio: {
                dmsId: '0138Ytponu73',
                refDistance: 1.5,
                rolloffFactor: 3,
                volume: 2.5,
                loop: false,
                maxDistance: 10,
            },
        },
    ],
}