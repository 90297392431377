import { ImageEditor } from './ImageEditor';
import { getCanvasItemName } from '../../helpers';
import { CanvasItemTypes } from '../../helpers/enums';

export const ImageEditors = ({
  images,
  groups,
  canvasName,
  canvasIndex,
  canvasWidth,
  canvasHeight,
  cellSize,
  onUpdate,
  onRemove,
}) => {
  const handleUpdate = (index, image) => {
    onUpdate && onUpdate(index, image, CanvasItemTypes.IMAGE);
  };

  const handleRemove = (index) => {
    onRemove && onRemove(index, CanvasItemTypes.IMAGE);
  };

  const handleGroupUpdate = (index, image, groupChange) => {
    onUpdate && onUpdate(index, image, CanvasItemTypes.IMAGE, groupChange);
  };

  return (
    <group>
      {images.map((image, index) => (
        <ImageEditor
          key={`${index}${image.dmsId}`}
          imageName={getCanvasItemName(CanvasItemTypes.IMAGE, canvasIndex, index)}
          imageItem={image}
          canvasName={canvasName}
          canvasIndex={canvasIndex}
          canvasWidth={canvasWidth}
          canvasHeight={canvasHeight}
          cellSize={cellSize}
          index={index}
          groups={groups}
          onUpdate={handleUpdate}
          onRemove={handleRemove}
          onGroupUpdate={handleGroupUpdate}
        />
      ))}
    </group>
  );
};
