import { useMemo } from 'react';
import { useThreeDProps, useThreeDState } from '../App/ThreeDContext';
import { getExhibitionItems } from '../../helpers';
import { ImageSphere } from './ImageSphere';

export const ImageSpheres = () => {
  const { data, adminMode } = useThreeDProps();
  const { interactiveMode } = useThreeDState();
  const { imageSpheres } = data;
  const filteredImageSpheres = useMemo(() =>
    getExhibitionItems(imageSpheres, adminMode, interactiveMode),
    [imageSpheres, adminMode, interactiveMode]
  );

  return filteredImageSpheres.map((imageSphere, index) => (
    <ImageSphere
      key={index}
      index={index}
      {...imageSphere}
      name={`ImageSphere-${index}`}
      editMode={!interactiveMode}
    />
  ));
};
