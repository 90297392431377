import { useState } from 'react';
import { Circle, useTexture } from '@react-three/drei';
import { getDmsUrl } from '@ekultur/dms-url-generator';
import { CursorTypes } from '../helpers/enums';
import { setCursor } from '../helpers';
import { SET_ROTATION_ENABLED, useThreeDDispatch } from '../3droom/App/ThreeDContext';

export const RescaleButton = ({
  position,
  distance,
  cursor,
  onMove,
}) => {
  const dispatch = useThreeDDispatch();
  const texture = useTexture(`https://ems.dimu.org/image/013AjtbtkTjb?mediaType=image/svg%2Bxml`);
  const [pointerDown, setPointerDown] = useState(false);
  const radius = Math.min(distance, 20) / 100;

  const handlePointerEnter = (event) => {
    event.stopPropagation();
    !pointerDown && setCursor(cursor);
  };

  const handlePointerLeave = (event) => {
    event.stopPropagation();
    !pointerDown && setCursor(CursorTypes.GRAB);
  }

  const handlePointerDown = (event) => {
    event.stopPropagation();
    const intersection = event.intersections.reduce((acc, obj) => (obj.distance < acc.distance ? obj : acc));

    if (intersection.object === event.object && event.buttons === 1) {
      setCursor(cursor);
      setPointerDown(true);
      dispatch({ type: SET_ROTATION_ENABLED, rotationEnabled: false });
    }
  };

  const handlePointerUp = (event) => {
    event.stopPropagation();
    const intersection = event.intersections.find(obj => obj.object.name === 'dragPlane');

    if (intersection && pointerDown) {
      setPointerDown(false);
      dispatch({ type: SET_ROTATION_ENABLED, rotationEnabled: true });
      onMove(event.point, true);
    }

    setCursor(CursorTypes.GRAB);
  };

  const handlePointerMove = (event) => {
    event.stopPropagation();

    if (event.buttons === 1 && pointerDown) {
      onMove(event.point, false);
    }
  };

  return (
    <group
      position={position}
      onPointerEnter={handlePointerEnter}
      onPointerLeave={handlePointerLeave}
      onPointerDown={handlePointerDown}
      onPointerUp={handlePointerUp}
      onPointerMove={handlePointerMove}
    >
      <Circle
        args={[radius, 30]}
        renderOrder={4}
        userData={{ isResize: true }}
      >
        <meshBasicMaterial
          color={0x4484F1}
          depthWrite={false}
          transparent
        />
      </Circle>
      <Circle
        args={[radius * 0.6, 30]}
        renderOrder={5}
        onBeforeRender={renderer => renderer.clearDepth()}
        userData={{ isResize: true }}
      >
        <meshBasicMaterial
          map={texture}
          transparent
        />
      </Circle>
      {pointerDown &&
        <mesh
          name='dragPlane'
          visible={false}
        >
          <planeGeometry args={[100, 100]} />
          <meshBasicMaterial transparent opacity={0} />
        </mesh>
      }
    </group>
  );
};
