import { Link } from 'react-router-dom';
import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles({
  container: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '30px',
    overflow: 'auto',
    height: 'calc(100% - 60px)',
  },
  list: {
    padding: '8px 0',
    margin: 0,
    listStyle: 'none',
    '& > li': {
      padding: '12px 16px',
      transition: 'background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
      '&:hover': {
        backgroundColor: 'rgba(0, 0, 0, 0.04)',
      },
    },
  },
});

export const DemoLinks = () => {
  const styles = useStyles();

  return (
    <div className={styles.container}>
      <div>
        <h2>Scener</h2>
        <ul className={styles.list}>
          <li><Link to="/room/1">Rom 1</Link></li>
          <li><Link to="/room/2">Rom 2</Link></li>
          <li><Link to="/room/3">Rom 3</Link></li>
          <li><Link to="/room/4">Rom 4</Link></li>
          <li><Link to="/room/5">Rom 5</Link></li>
          <li><Link to="/room/6">Rom 6</Link></li>
          <li><Link to="/room/7">Rom 7</Link></li>
          <li><Link to="/room/8">Rom 8</Link></li>
          <li><Link to="/room/9">Rom 9</Link></li>
          <li><Link to="/room/10">Rom 10</Link></li>
          <li><Link to="/room/11">Rom 11</Link></li>
          <li><Link to="/room/12">Rom 12</Link></li>
          <li><Link to="/room/13">Rom 13</Link></li>
          <li><Link to="/room/14">Rom 14</Link></li>
          <li><Link to="/room/15">Rom 15</Link></li>
          <li><Link to="/room/16">Rom 16</Link></li>
          <li><Link to="/room/17">Rom 17</Link></li>
          <li><Link to="/room/18">Rom 18</Link></li>
          <li><Link to="/room/19">Rom 19</Link></li>
          <li><Link to="/room/20">Rom 20</Link></li>
          <li><Link to="/room/21">Rom 21</Link></li>
          <li><Link to="/room/22">Rom 22</Link></li>
          <li><Link to="/room/23">Rom 23</Link></li>
          <li><Link to="/room/24">Rom 24</Link></li>
          <li><Link to="/room/25">Rom 25</Link></li>
          <li><Link to="/room/26">Rom 26</Link></li>
          <li><Link to="/room/27">Rom 27</Link></li>
          <li><Link to="/room/28">Rom 28</Link></li>
          <li><Link to="/room/29">Rom 29</Link></li>
          <li><Link to="/room/30">Rom 30</Link></li>
          <li><Link to="/room/31">Hobbyfotografens verden</Link></li>
        </ul>
      </div>
    </div>
  );
};
