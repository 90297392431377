import * as Icon from '@mui/icons-material';
import { useEffect } from 'react';
import { Group } from './Group';
import { Accordion, AccordionDetails, AccordionSummary, Button } from '@mui/material';
import { createUseStyles } from 'react-jss';
import { CameraPlacementMode } from '../../helpers/enums';
import { SET_CAMERA_PLACEMENT_MODE, SET_CANVAS_SELECTION, useThreeDDispatch, useThreeDState } from '../../3droom/App/ThreeDContext';

const useStyles = createUseStyles({
  accordion: {
    '& .MuiAccordionSummary-root': {
      display: 'flex',
      flexDirection: 'row',
      padding: 8,
      minHeight: 'unset',
      '&:hover': {
        cursor: 'move !important',
      },
      '&.Mui-expanded': {
        minHeight: 'unset',
      },
      '& .MuiAccordionSummary-content': {
        margin: 0,
        alignItems: 'center',
        justifyContent: 'space-between',
      },
    },
    '& .MuiAccordionDetails-root': {
      display: 'flex',
      flexDirection: 'column',
      padding: '4px 16px 8px 16px',
      gap: 8,
    },
  },
  active: {
    color: 'rgba(0, 0, 0, 0.6)',
    cursor: 'pointer',
  },
  unactive: {
    color: 'rgba(0, 0, 0, 0.26)',
  },
});

export const IncludedGroup = ({
  name,
  color,
  position,
  rotation,
  index,
  isExpanded,
  onExpansionChange,
  onCameraPlacementChange,
  isDragging,
  preview,
}) => {
  const { canvasSelection } = useThreeDState();
  const dispatch = useThreeDDispatch();
  const styles = useStyles();

  useEffect(() => {
    if (isDragging && isExpanded) {
      onExpansionChange(index);
    }
  }, [isDragging]);

  const handleSetCameraPlacement = () => {
    dispatch({
      type: SET_CANVAS_SELECTION,
      canvasSelection: null,
    });
    dispatch({
      type: SET_CAMERA_PLACEMENT_MODE,
      cameraPlacementMode: {
        mode: CameraPlacementMode.EDIT,
        index,
        name,
        position,
        rotation,
        canvasSelection,
        onCameraPlacementChange,
      },
    });
  };

  const handleViewCameraPlacement = (event) => {
    event.stopPropagation();

    dispatch({
      type: SET_CAMERA_PLACEMENT_MODE,
      cameraPlacementMode: {
        mode: CameraPlacementMode.PREVIEW,
        index,
        name,
        position,
        rotation,
        canvasSelection,
      },
    });
  };

  return (
    <Accordion
      expanded={isExpanded}
      className={styles.accordion}
    >
      <AccordionSummary
        ref={preview}
        expandIcon={<Icon.ExpandMore />}
        onClick={() => onExpansionChange(index)}
      >
        <Group name={name} color={color} />
        {position
          ? <Icon.VideoCameraFront
              fontSize="small"
              className={styles.active}
              onClick={handleViewCameraPlacement}
            />
          : <Icon.VideoCameraFrontOutlined
              fontSize="small"
              className={styles.unactive}
            />
        }
      </AccordionSummary>
      <AccordionDetails>
        <Button
          variant="outlined"
          size="small"
          startIcon={<Icon.VideoCameraFront fontSize="small" className={styles.active} />}
          onClick={handleSetCameraPlacement}
        >
          PLASSER KAMERA
        </Button>
      </AccordionDetails>
    </Accordion>
  );
};
