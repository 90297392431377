import * as Icon from '@mui/icons-material';
import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles({
  container: {
    position: 'absolute',
    bottom: 50,
    left: '50%',
    transform: 'translate(-50%, 0)',
    background: '#FEFEFE',
    border: '2px solid #0C0B0F',
    borderRadius: 999,
    zIndex: 1,
    display: 'flex',
    flexDirection: 'row',
    boxShadow: `0px 8px 9px -5px rgba(0, 0, 0, 0.2),
                0px 15px 22px 2px rgba(0, 0, 0, 0.14),
                0px 6px 28px 5px rgba(0, 0, 0, 0.12)`,
  },
  button: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '10px 20px',
    cursor: 'pointer',
    fontSize: 14,
    gap: 6,
    '& > svg': {
      width: 22,
      height: 22,
    },
    '&:first-child': {
      borderRight: '1px solid #0C0B0F',
      borderRadius: '999px 0px 0px 999px',
    },
    '&:last-child': {
      borderLeft: '1px solid #0C0B0F',
      borderRadius: '0px 999px 999px 0px',
    },
    '&:hover': {
      color: '#ffffff',
      backgroundColor: '#595959',
    },
  },
  disabled: {
    color: '#B6B6B6',
    '&:hover': {
      color: '#B6B6B6',
      backgroundColor: 'inherit',
    },
  },
});

export const TourActiveStepper = ({
  selectedIndex,
  itemCount,
  onClick,
}) => {
  const styles = useStyles();
  const prevDisabled = selectedIndex <= 0;
  const nextDisabled = selectedIndex >= itemCount - 1;

  const handlePrevClick = () => {
    if (onClick && !prevDisabled) {
      onClick(selectedIndex - 1);
    }
  };

  const handleNextClick = () => {
    if (onClick && !nextDisabled) {
      onClick(selectedIndex + 1);
    }
  };

  return (
    <div className={styles.container}>
      <div
        className={`${styles.button} ${prevDisabled ? styles.disabled : ''}`}
        onClick={handlePrevClick}
      >
        <Icon.ArrowBack />
        Tilbake i omvisningen
      </div>
      <div
        className={`${styles.button} ${nextDisabled ? styles.disabled : ''}`}
        onClick={handleNextClick}
      >
        Videre i omvisningen
        <Icon.ArrowForward />
      </div>
    </div>
  );
};
