import * as THREE from 'three';
import {useEffect, useMemo, useRef} from 'react';
import {useTexture} from '@react-three/drei';
import {useDrag} from '@use-gesture/react';
import {CanvasItemTypes, ControlModes} from '../../helpers/enums';
import {useThreeDState} from '../App/ThreeDContext';

export const Image = ({
  url,
  dmsId,
  mediaType,
  scale,
  rotation,
  position,
  dimension = `dimension=${window._env_.DIMENSION_MAX === 'true' ? '4096' : '2048'}`,
  viewerData,
  name,
  addCanvasDepth = true,
  editMode = false,
  onClick,
}) => {
  const { controlMode } = useThreeDState();
  const modelRef = useRef();
  // const texture = useTexture(dmsId ? `${getDmsUrl(dmsId, '01')}?${dimension}${mediaType ? `&mediaType=${mediaType}` : ''}` : url, texture => texture.encoding = THREE.sRGBEncoding);
  const texture = useTexture(dmsId ? `https://ems.dimu.org/image/${dmsId}?dimension=1024x1024` : url, texture => texture.encoding = THREE.SRGBColorSpace);
  const canvasDepth = 0.015 * scale;

  const materials = useMemo(() => [
    new THREE.MeshStandardMaterial( { color: 0xC0C0C0 } ),
    new THREE.MeshStandardMaterial( { color: 0xC0C0C0 } ),
    new THREE.MeshStandardMaterial( { color: 0xC0C0C0 } ),
    new THREE.MeshStandardMaterial( { color: 0xC0C0C0 } ),
    new THREE.MeshStandardMaterial( { map: texture, side: THREE.FrontSide, transparent: true, depthWrite: false } ),
    new THREE.MeshStandardMaterial( { color: 0xC0C0C0 } )
  ], [texture]);

  useEffect(() => {
    if (addCanvasDepth) {
      const normal = new THREE.Vector3(0, 0, 1).applyEuler(rotation).normalize();
      modelRef.current.position.add(normal.multiplyScalar(canvasDepth / 2));
    }
  }, [addCanvasDepth]);

  const handleClick = ({ event, tap }) => {
    if (tap) {
      const pointerLockElement = document.pointerLockElement || document.mozPointerLockElement || document.webkitPointerLockElement;

      if (!(controlMode === ControlModes.POINTER_LOCK_CONTROL && !pointerLockElement)) {
        const intersection = event.intersections.reduce((acc, obj) => (obj.distance < acc.distance ? obj : acc));

        if (onClick && intersection.eventObject === event.object) {
          onClick();
        }
      }
    }
  }

  const useGestures = () => {
    const drag = useDrag(handleClick, { filterTaps: true });
    return !editMode ? drag() : {};
  };

  return (
    <mesh
      ref={modelRef}
      name={name}
      position={[position.x, position.y, position.z]}
      rotation={[rotation.x, rotation.y, rotation.z]}
      scale={[1, 1, 1]}
      material={materials}
      receiveShadow={true}
      renderOrder={3}
      userData={{
        itemName: name,
        itemType: CanvasItemTypes.IMAGE,
        interactable: !!viewerData,
      }}
      {...useGestures()}
    >
      <boxGeometry
        attach="geometry"
        args={[
          (texture.image.width < texture.image.height ? (texture.image.width / texture.image.height) : 1) * scale,
          (texture.image.height < texture.image.width ? (texture.image.height / texture.image.width) : 1) * scale,
          addCanvasDepth ? canvasDepth : 0,
        ]}
      />
    </mesh>
  );
};
