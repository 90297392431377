import { useState } from 'react';
import { Question } from './Question';
import { Results } from './Results';
import { Dialog } from '../../../components/Dialog';
import { CloseButton } from '../../../components/CloseButton';
import { createUseStyles } from 'react-jss';
import { ControlModes } from '../../../helpers/enums';
import * as Icon from 'react-feather';
import { SET_ACTIVE_QUIZ, SET_POINTER_LOCKED, useThreeDDispatch, useThreeDState } from '../../App/ThreeDContext';

const useStyles = createUseStyles({
  dialog: {
    color: 'white',
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: 'rgba(0, 0, 0, .5)',
  },
  title: {
    marginBlockStart: 0,
  },
  stepper: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    backgroundColor: '#fafafa',
    color: 'rgba(0, 0, 0, 0.87)',
    padding: 8,
  },
  button: {
    display: 'flex',
    padding: 4,
    cursor: 'pointer',
    '&.disabled': {
      color: 'rgba(0, 0, 0, 0.26)',
      cursor: 'default',
    },
    '& > span': {
      textTransform: 'uppercase',
    },
  },
});

export const QuizDialog = () => {
  const { activeQuiz, controlMode } = useThreeDState();
  let { content, isAllAnswersCorrect, handleAllAnswersCorrect } = activeQuiz;
  const { title, questions } = content;
  const dispatch = useThreeDDispatch();
  const styles = useStyles();
  const [current, setCurrent] = useState(0);
  const [answers, setAnswers] = useState([]);
  const [showResults, setShowResults] = useState(false);
  const [selectedAnswer, setSelectedAnswer] = useState(null);

  const handleClose = () => {
    isAllAnswersCorrect && handleAllAnswersCorrect();
    dispatch({ type: SET_ACTIVE_QUIZ, activeQuiz: null });
  };

  const handleNext = () => {
    const nextVal = answers.find(a => a.id === questions[current + 1].id);
    registerAnswer(questions[current].id, selectedAnswer);
    setCurrent(prevCurrent => prevCurrent + 1);
    setSelectedAnswer(nextVal ? nextVal.value : null);
  };

  const handleBack = () => {
    const prevValue = answers.find(a => a.id === questions[current - 1].id);
    registerAnswer(questions[current].id, selectedAnswer);
    setCurrent(prevCurrent => prevCurrent - 1);
    setSelectedAnswer(prevValue ? prevValue.value : null);
  };

  const handleAnswerClick = (id, answer) => {
    registerAnswer(id, answer);
    if (current !== questions.length - 1) {
      setCurrent(prevCurrent => prevCurrent + 1);
    } else {
      setShowResults(true);
    }
  };

  const handleComplete = () => {
    registerAnswer(questions[current].id, selectedAnswer);
    setShowResults(true);
  };

  const registerAnswer = (id, value) => {
    setAnswers([...answers.filter(a => a.id !== id), { id, value }]);
  };

  const resetState = () => {
    setCurrent(0);
    setAnswers([]);
    setShowResults(false);
    setSelectedAnswer(null);
  };

  return (
    <Dialog
      open={!!content}
      className={styles.dialog}
      fullScreen
    >
      <CloseButton
        color="#F0EDE6"
        onClick={e => {
          e.preventDefault();
          handleClose();
          resetState();
          controlMode === ControlModes.POINTER_LOCK_CONTROL && dispatch({ type: SET_POINTER_LOCKED, pointerLocked: true });
        }}
      />
      <h1 className={styles.title}>{title}</h1>
      {!showResults ? (
        <>
          <Question
            question={questions[current]}
            answerClick={(id, answer) => handleAnswerClick(id, answer)}
            selectedAnswer={selectedAnswer}
          />
          <div className={styles.stepper}>
            <div
              className={`${styles.button} ${current === 0 ? 'disabled' : ''}`}
              onClick={() => current > 0 && handleBack()}
            >
              <Icon.ChevronLeft />
              <span>Forrige</span>
            </div>
            <div>
              {current + 1} / {questions.length}
            </div>
            {current !== questions.length - 1 ? (
              <div
                className={`${styles.button} ${selectedAnswer === null ? 'disabled' : ''}`}
                onClick={() => selectedAnswer !== null && handleNext()}
              >
                <span>Neste</span>
                <Icon.ChevronRight />
              </div>
            ) : (
              <div
                className={`${styles.button} ${selectedAnswer === null && answers.length - 1 !== questions.length ? 'disabled' : ''}`}
                onClick={() => selectedAnswer !== null && answers.length - 1 === questions.length && handleComplete()}
              >
                <span>Ferdig</span>
                <Icon.ChevronRight />
              </div>
            )}
          </div>
        </>
      ) : (
        <Results
          answers={answers}
          questions={questions}
          resetClick={() => resetState()}
          onAllAnswersCorrect={() => isAllAnswersCorrect = true}
        />
      )}
    </Dialog>
  );
};
