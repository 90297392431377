import { Suspense, useMemo } from 'react';
import { useThreeDProps, useThreeDState } from '../App/ThreeDContext';
import { getExhibitionItems } from '../../helpers';
import { Audio } from './Audio';
import { AudioController } from './AudioController';
import { PositionalAudio } from './PositionalAudio';

export const Audios = () => {
  const { data, adminMode } = useThreeDProps();
  const { GPUTier, interactiveMode, userInteracted } = useThreeDState();
  const { audios } = data;
  const filteredAudios = useMemo(() =>
    getExhibitionItems(audios, adminMode, interactiveMode),
    [audios, adminMode, interactiveMode]
  );

  if (!userInteracted || GPUTier.tier === 0 || GPUTier.isMobile) {
    return [];
  }

  return filteredAudios.map((audio, index) => (
    <group name={`Audio-${index}`} key={index}>
      {audio.positioning && audio.interactive &&
        <Suspense fallback={null}>
          <AudioController
            position={audio.position}
            rotation={audio.rotation}
            scale={audio.scale}
          >
            <PositionalAudio {...audio} />
          </AudioController>
        </Suspense>
      }
      {!audio.positioning && audio.interactive &&
        <Suspense fallback={null}>
          <AudioController
            position={audio.position}
            rotation={audio.rotation}
            scale={audio.scale}
          >
            <Audio {...audio} />
          </AudioController>
        </Suspense>
      }
      {audio.positioning && !audio.interactive &&
        <PositionalAudio {...audio} />
      }
      {!audio.positioning && !audio.interactive &&
        <Audio {...audio} />
      }
    </group>
  ));
};
