import {useMarginOptions} from "../font/useMarginOptions";
import * as Icon from "@mui/icons-material";
import {SelectInput} from "../../components/SelectInput";

export const TextMargin = ({margin, onChange}) => {
    const marginOptions = useMarginOptions()

    return (
        <SelectInput
            value={margin}
            options={marginOptions}
            icon={<Icon.Splitscreen fontSize='small'/>}
            onChange={onChange}
        />
    )
}