export const demo7 = {
    roomModelDmsId: '013AjPGU83FN',
    startPosition: { x: -2, y: 1.855, z: 0 },
    directionOfView: { x: 0, y: 0, z: -0.001 },
    dome: {
        skyBoxDmsId: '0136NvAQy2GR',
    },
    lights: [
        {
            type: 'ambient',
            intensity: 1,
        },
    ],
    models: [
        {
            dmsId: '0136NvAPSgAB',
            position: { x: 11.12, y: 1.17, z: -3.47 },
            rotation: { x: 0, y: Math.PI / -2, z: 0.01 },
            scale: { x: 0.06, y: 0.06, z: 0.06 },
            viewerData: {
                model: {
                    type: 'Object',
                    dmsId: '0136NvAPSgAB',
                },
                license: {
                    creatorName: '3dhdscan',
                    creatorUrl: 'https://sketchfab.com/3dhdscan',
                    licenseName: 'CC BY 4.0',
                    licenseUrl: 'https://creativecommons.org/licenses/by/4.0/',
                    sourceName: 'Statue of a hunter',
                    sourceUrl:
                        'https://sketchfab.com/3d-models/statue-of-a-hunter-f777e0a58bd84e7f945a9dd688eab27a',
                },
            },
        },
    ],
    images: [
        {
            dmsId: '032yhzAN47jq',
            scale: 1.5,
            rotation: { x: 0, y: 0, z: 0 },
            position: { x: 4.3, y: 1.5, z: -6.02 },
            viewerData: {
                model: {
                    type: 'ImageSingle',
                    dmsId: '032yhzAN47jq',
                },
                metaData: {
                    title: 'Interiør fra bondestue, Strålsjøhaugen',
                    year: 1893,
                    artist: 'Backer, Harriet',
                    url: 'https://digitaltmuseum.no/011041108535',
                },
            },
        },
        {
            dmsId: '022ykyZvZZq5',
            scale: 1.5,
            rotation: { x: 0, y: 0, z: 0 },
            position: { x: 6.6, y: 1.5, z: -6.02 },
            viewerData: {
                model: {
                    type: 'ImageSingle',
                    dmsId: '022ykyZvZZq5',
                },
                metaData: {
                    title: 'Fra Strålsjøen, høst',
                    year: 1894,
                    artist: 'Backer, Harriet',
                    url: 'https://digitaltmuseum.no/011041107816',
                },
                annotations: [
                    {
                        position: [0, 0.05, 0],
                        title: 'Setertun',
                        content: {
                            paragraphs: [''],
                        },
                        zoom: 0.5,
                    },
                    {
                        position: [0.38, -0.06, 0],
                        title: 'Stabbur',
                        content: {
                            paragraphs: [''],
                        },
                        zoom: 0.25,
                    },
                    {
                        position: [-0.15, -0.3, 0],
                        title: 'Skigard',
                        content: {
                            paragraphs: [''],
                        },
                        zoom: 0.4,
                    },
                ],
            },
        },
        {
            dmsId: '0331vyU1w4P1',
            scale: 1.5,
            rotation: { x: 0, y: Math.PI, z: 0 },
            position: { x: 4.3, y: 1.5, z: -6.285 },
            viewerData: {
                model: {
                    type: 'ImageSingle',
                    dmsId: '0331vyU1w4P1',
                },
                metaData: {
                    title: 'Gamlestua på Kolbotn',
                    year: 1896,
                    artist: 'Backer, Harriet',
                    url: 'https://digitaltmuseum.no/011041108191',
                },
                annotations: [
                    {
                        position: [-0.5, -0.2, 0],
                        title: 'Gyngestol',
                        content: {
                            paragraphs: [''],
                        },
                        zoom: 0.5,
                    },
                    {
                        position: [0.56, 0.06, 0],
                        title: 'Fele',
                        content: {
                            paragraphs: [''],
                        },
                        zoom: 0.5,
                    },
                    {
                        position: [0, 0.4, 0],
                        title: 'Hjørneskap',
                        content: {
                            paragraphs: [''],
                        },
                        zoom: 0.5,
                    },
                ],
            },
        },
        {
            dmsId: '0231wTu7qX5t',
            scale: 1.5,
            rotation: { x: 0, y: Math.PI, z: 0 },
            position: { x: 6.6, y: 1.5, z: -6.285 },
            viewerData: {
                model: {
                    type: 'ImageSingle',
                    dmsId: '0231wTu7qX5t',
                },
            },
        },
        {
            dmsId: '022yhzAN47Mj',
            scale: 1.5,
            rotation: { x: 0, y: Math.PI, z: 0 },
            position: { x: 8.5, y: 1.5, z: -1.04 },
            viewerData: {
                model: {
                    type: 'ImageSingle',
                    dmsId: '022yhzAN47Mj',
                },
                metaData: {
                    title: 'Fra Foldalen',
                    year: 1894,
                    artist: 'Backer, Harriet',
                    url: 'https://digitaltmuseum.no/011041108341',
                },
                annotations: [
                    {
                        position: [-0.2, -0.2, 0],
                        title: 'Elv',
                        content: {
                            paragraphs: [
                                'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla tristique sodales felis nec blandit. Nam fermentum laoreet varius. Mauris mattis imperdiet ante non finibus. Quisque ac est aliquet, fringilla leo non, volutpat tortor. Nunc ut nisi gravida, tincidunt felis mollis, accumsan nisl.',
                            ],
                        },
                        zoom: 0.5,
                    },
                    {
                        position: [0.43, -0.23, 0],
                        title: 'Gård',
                        content: {
                            paragraphs: ['Der ingen skulle tru at nokon kunne bu'],
                        },
                        zoom: 0.5,
                    },
                ],
            },
        },
        {
            dmsId: '0371B4DJG',
            scale: 1.5,
            rotation: { x: 0, y: Math.PI, z: 0 },
            position: { x: 6, y: 1.5, z: -1.04 },
            viewerData: {
                model: {
                    type: 'ImageSingle',
                    dmsId: '0371B4DJG',
                },
            },
        },
        {
            dmsId: '032yhzAN4Sqf',
            scale: 1.5,
            rotation: { x: 0, y: Math.PI, z: 0 },
            position: { x: 3.5, y: 1.5, z: -1.04 },
            viewerData: {
                model: {
                    type: 'ImageSingle',
                    dmsId: '032yhzAN4Sqf',
                },
                metaData: {
                    title: 'Kortspillere',
                    year: 1897,
                    artist: 'Backer, Harriet',
                    url: 'https://digitaltmuseum.no/011041107451',
                },
                annotations: [
                    {
                        position: [-0.1, -0.27, 0],
                        title: 'Krakk',
                        content: {
                            paragraphs: [''],
                        },
                        zoom: 0.5,
                    },
                    {
                        position: [-0.9, 0, 0],
                        title: 'Skap',
                        content: {
                            paragraphs: [''],
                        },
                        zoom: 0.5,
                    },
                    {
                        position: [0.13, 0.4, 0],
                        title: 'Flettekurv',
                        content: {
                            paragraphs: [''],
                        },
                        zoom: 0.5,
                    },
                ],
            },
        },
        {
            dmsId: '02349SYBET27',
            scale: 0.4,
            rotation: { x: 0, y: Math.PI / -2, z: 0 },
            position: { x: -0.26, y: 1.9, z: -2.14 },
            viewerData: {
                model: {
                    type: 'ImageDouble',
                    dmsId: '02349SYBET27',
                    backsideDmsId: '02349SYBET2B',
                },
                metaData: {
                    title: 'Vykort med motiv över Skölemon i Matfors',
                    url: 'https://digitaltmuseum.org/021018470938',
                },
            },
        },
        {
            dmsId: '02349SY8Gonf',
            scale: 0.4,
            rotation: { x: 0, y: Math.PI / -2, z: 0 },
            position: { x: -0.26, y: 1.9, z: -1.39 },
            viewerData: {
                model: {
                    type: 'ImageDouble',
                    dmsId: '02349SY8Gonf',
                    backsideDmsId: '03349SY8Gorx',
                },
            },
        },
        {
            dmsId: '02349SCUCLgk',
            scale: 0.4,
            rotation: { x: 0, y: Math.PI / -2, z: 0 },
            position: { x: -0.26, y: 1.9, z: -0.64 },
            viewerData: {
                model: {
                    type: 'ImageDouble',
                    dmsId: '02349SCUCLgk',
                    backsideDmsId: '03349SCUCLm5',
                },
            },
        },
        {
            dmsId: '03349SCUCLgp',
            scale: 0.4,
            rotation: { x: 0, y: Math.PI / -2, z: 0 },
            position: { x: -0.26, y: 1.9, z: 0.11 },
            viewerData: {
                model: {
                    type: 'ImageDouble',
                    dmsId: '03349SCUCLgp',
                    backsideDmsId: '03349SCUCLm8',
                },
            },
        },
        {
            dmsId: '02349SCUCLm6',
            scale: 0.4,
            rotation: { x: 0, y: Math.PI / -2, z: 0 },
            position: { x: -0.26, y: 1.4, z: -2.14 },
            viewerData: {
                model: {
                    type: 'ImageDouble',
                    dmsId: '02349SCUCLm6',
                    backsideDmsId: '03349SCUCLmE',
                },
            },
        },
        {
            dmsId: '03349SCUCLP6',
            scale: 0.4,
            rotation: { x: 0, y: Math.PI / -2, z: 0 },
            position: { x: -0.26, y: 1.4, z: -1.39 },
            viewerData: {
                model: {
                    type: 'ImageDouble',
                    dmsId: '03349SCUCLP6',
                    backsideDmsId: '03349SCUCLTS',
                },
            },
        },
        {
            dmsId: '03349SCUCLXv',
            scale: 0.4,
            rotation: { x: 0, y: Math.PI / -2, z: 0 },
            position: { x: -0.26, y: 1.4, z: -0.64 },
            viewerData: {
                model: {
                    type: 'ImageDouble',
                    dmsId: '03349SCUCLXv',
                    backsideDmsId: '02349SCUCLXy',
                },
            },
        },
        {
            dmsId: '03349SCUCLTX',
            scale: 0.4,
            rotation: { x: 0, y: Math.PI / -2, z: 0 },
            position: { x: -0.26, y: 1.4, z: 0.11 },
            viewerData: {
                model: {
                    type: 'ImageDouble',
                    dmsId: '03349SCUCLTX',
                    backsideDmsId: '03349SCUCLXr',
                },
            },
        },
        {
            dmsId: '02349SCUCLXw',
            scale: 0.4,
            rotation: { x: 0, y: Math.PI / -2, z: 0 },
            position: { x: -0.26, y: 0.9, z: -2.14 },
            viewerData: {
                model: {
                    type: 'ImageDouble',
                    dmsId: '02349SCUCLXw',
                    backsideDmsId: '03349SCUCLXz',
                },
                metaData: {
                    title: 'Vykort med motiv över Sörfors bruk i Matfors',
                    url: 'https://digitaltmuseum.org/021018451851',
                },
            },
        },
        {
            dmsId: '03349SCUCLcH',
            scale: 0.4,
            rotation: { x: 0, y: Math.PI / -2, z: 0 },
            position: { x: -0.26, y: 0.9, z: -1.39 },
            viewerData: {
                model: {
                    type: 'ImageDouble',
                    dmsId: '03349SCUCLcH',
                    backsideDmsId: '02349SCUCLcP',
                },
            },
        },
        {
            dmsId: '02349SCUCLcJ',
            scale: 0.4,
            rotation: { x: 0, y: Math.PI / -2, z: 0 },
            position: { x: -0.26, y: 0.9, z: -0.64 },
            viewerData: {
                model: {
                    type: 'ImageDouble',
                    dmsId: '02349SCUCLcJ',
                    backsideDmsId: '02349SCUCLcN',
                },
            },
        },
        {
            dmsId: '02349SCUCLcM',
            scale: 0.4,
            rotation: { x: 0, y: Math.PI / -2, z: 0 },
            position: { x: -0.26, y: 0.9, z: 0.11 },
            viewerData: {
                model: {
                    type: 'ImageDouble',
                    dmsId: '02349SCUCLcM',
                    backsideDmsId: '03349SCUCLgh',
                },
            },
        },
        {
            dmsId: '02349SCUCLgg',
            scale: 0.4,
            rotation: { x: 0, y: 0, z: 0 },
            position: { x: -2.75, y: 1.9, z: -6.03 },
            viewerData: {
                model: {
                    type: 'ImageDouble',
                    dmsId: '02349SCUCLgg',
                    backsideDmsId: '03349SCUCLgi',
                },
                metaData: {
                    title: 'Vykort med motiv över bruket i Matfors',
                    url: 'https://digitaltmuseum.org/021018451862',
                },
            },
        },
        {
            dmsId: '02349SCSfzt2',
            scale: 0.4,
            rotation: { x: 0, y: 0, z: 0 },
            position: { x: -2, y: 1.9, z: -6.03 },
            viewerData: {
                model: {
                    type: 'ImageDouble',
                    dmsId: '02349SCSfzt2',
                    backsideDmsId: '02349SCSfzt7',
                },
            },
        },
        {
            dmsId: '02349SCSfzoi',
            scale: 0.4,
            rotation: { x: 0, y: 0, z: 0 },
            position: { x: -1.25, y: 1.9, z: -6.03 },
            viewerData: {
                model: {
                    type: 'ImageDouble',
                    dmsId: '02349SCSfzoi',
                    backsideDmsId: '02349SCSfzoh',
                },
            },
        },
        {
            dmsId: '02349SCSfzt8',
            scale: 0.4,
            rotation: { x: 0, y: 0, z: 0 },
            position: { x: -2.75, y: 1.4, z: -6.03 },
            viewerData: {
                model: {
                    type: 'ImageDouble',
                    dmsId: '02349SCSfzt8',
                    backsideDmsId: '02349SCSfzxU',
                },
            },
        },
        {
            dmsId: '02349SCSg12t',
            scale: 0.4,
            rotation: { x: 0, y: 0, z: 0 },
            position: { x: -2, y: 1.4, z: -6.03 },
            viewerData: {
                model: {
                    type: 'ImageDouble',
                    dmsId: '02349SCSg12t',
                    backsideDmsId: '02349SCSg17D',
                },
            },
        },
        {
            dmsId: '03348xLPmtjf',
            scale: 0.4,
            rotation: { x: 0, y: 0, z: 0 },
            position: { x: -1.25, y: 1.4, z: -6.03 },
            viewerData: {
                model: {
                    type: 'ImageDouble',
                    dmsId: '03348xLPmtjf',
                    backsideDmsId: '02349SCSfzVv',
                },
            },
        },
        {
            dmsId: '02349SCSfzog',
            scale: 0.4,
            rotation: { x: 0, y: 0, z: 0 },
            position: { x: -2.75, y: 0.9, z: -6.03 },
            viewerData: {
                model: {
                    type: 'ImageDouble',
                    dmsId: '02349SCSfzog',
                    backsideDmsId: '02349SCSfzt5',
                },
                metaData: {
                    title: 'Vykort med motiv över Lucksta sett från Sörforsåsen i Matfors',
                    url: 'https://digitaltmuseum.org/021018450796',
                },
            },
        },
        {
            dmsId: '03349SCSfzod',
            scale: 0.4,
            rotation: { x: 0, y: 0, z: 0 },
            position: { x: -2, y: 0.9, z: -6.03 },
            viewerData: {
                model: {
                    type: 'ImageDouble',
                    dmsId: '03349SCSfzod',
                    backsideDmsId: '02349SCSfzoh',
                },
            },
        },
        {
            dmsId: '03349SCSfzaN',
            scale: 0.4,
            rotation: { x: 0, y: 0, z: 0 },
            position: { x: -1.25, y: 0.9, z: -6.03 },
            viewerData: {
                model: {
                    type: 'ImageDouble',
                    dmsId: '03349SCSfzaN',
                    backsideDmsId: '03349SCSfzaT',
                },
            },
        },
    ],
}