import * as THREE from 'three';
import { useSpring } from '@react-spring/three';

export const usePulseAnimation = ({ scene }) => {
  const { 1: springApi } = useSpring(() => ({ }));
  const geometry = new THREE.CircleGeometry(1, 32);
  const material = new THREE.MeshBasicMaterial({ color: 0x0db5ba, transparent: true });
  const mesh = new THREE.Mesh(geometry, material);

  const add = (position) => {
    mesh.position.copy(position);
    mesh.rotation.set(Math.PI / -2, 0, 0);

    springApi.start({
      from: {
        scale: 0.1,
        opacity: 1,
      },
      to: {
        scale: 1,
        opacity: 0,
      },
      config: {
        duration: 750,
      },
      onStart: () => {
        scene.add(mesh);
      },
      onChange: (res) => {
        mesh.scale.set(res.value.scale, res.value.scale, res.value.scale);
        mesh.material.opacity = res.value.opacity;
      },
      onRest: () => {
        scene.remove(mesh);
      },
    });
  };

  return {
    add
  };
};
