const vertexShader = `
  varying vec2 vUv;

  void main()
  {
    vUv = uv;
    gl_Position = projectionMatrix * viewMatrix * modelMatrix * vec4(position, 1.0);
  }
`;

const fragmentShader = `
  uniform vec2 uGridDimensions;
  uniform vec3 uPrimaryColor;
  uniform vec3 uSecondaryColor;
  uniform float uAlpha;

  varying vec2 vUv;

  void main() {
    vec2 repeatedUV = vUv * uGridDimensions;
    float patternMask = mod(floor(repeatedUV.x) + mod(floor(repeatedUV.y), 2.0), 2.0);
    gl_FragColor = mix(vec4(uPrimaryColor, uAlpha), vec4(uSecondaryColor, uAlpha), patternMask);
  }
`;

export { vertexShader, fragmentShader };
