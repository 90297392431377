import { useMemo } from 'react';
import { exitPointerLock, getImageDimension, getExhibitionItems } from '../../helpers';
import { CanvasItemTypes } from '../../helpers/enums';
import { SET_MODELVIEWER_DATA, useThreeDDispatch, useThreeDProps, useThreeDState } from '../App/ThreeDContext';
import { Image } from './Image';

export const Images = () => {
  const { data, adminMode } = useThreeDProps();
  const { GPUTier, interactiveMode } = useThreeDState();
  const { images } = data;
  const dispatch = useThreeDDispatch();
  const dimension = getImageDimension(GPUTier);
  const filteredImages = useMemo(() =>
    getExhibitionItems(images, adminMode, interactiveMode),
    [images, adminMode, interactiveMode]
  );

  if (!images) {
    return [];
  }

  const handleImageClick = (image, index) => {
    if (!interactiveMode) return;
    exitPointerLock();
    image.viewerData && dispatch({
      type: SET_MODELVIEWER_DATA,
      modelViewerData: {
        index: index,
        type: CanvasItemTypes.IMAGE,
        viewerData: image.viewerData,
      },
    });
  };

  return filteredImages.map((image, index) => (
    <Image
      {...image}
      key={index}
      name={`Image-${index}`}
      dimension={dimension}
      onClick={() => handleImageClick(image, index)}
    />
  ));
};
