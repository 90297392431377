import { useEffect, useRef } from 'react';
import { OrbitMode } from './OrbitMode';
import { PointerLockMode } from './PointerLockMode';
import { ControlModes } from '../../helpers/enums';
import { SET_CAMERA_REF, useThreeDDispatch, useThreeDProps, useThreeDState } from '../App/ThreeDContext';
import { PerspectiveCamera } from '@react-three/drei';

export const CameraControls = () => {
  const { initialCameraPosition } = useThreeDProps();
  const { modelViewerData, groupManagerDialogOpen, helpDialogOpen, aboutDialogOpen, activeQuiz, controlMode } = useThreeDState();
  const dispatch = useThreeDDispatch();
  const dialogOpen = !!modelViewerData || helpDialogOpen || aboutDialogOpen || groupManagerDialogOpen || !!activeQuiz;
  const camera = useRef();

  useEffect(() => {
    dispatch({ type: SET_CAMERA_REF, cameraRef: camera });
  }, []);

  return (
    <>
      <PerspectiveCamera
        ref={camera}
        position={initialCameraPosition}
        makeDefault
      />
      {(controlMode === ControlModes.ORBIT_CONTROL || dialogOpen) &&
        <OrbitMode dialogOpen={dialogOpen} />
      }
      {controlMode === ControlModes.POINTER_LOCK_CONTROL && !dialogOpen &&
        <PointerLockMode />
      }
    </>
  );
};
