import { computeBoundingBoxSize } from '../helpers';
import { GroupIndexMarker } from './GroupIndexMarker';

export const GroupIndexMarkerBox = ({
  name,
  text,
  color,
  object,
  position,
  rotation,
  scale,
}) => {
  const size = computeBoundingBoxSize(object);

  return (
    <group
      name={`${name}-gim`}
      position={position}
      rotation={rotation}
    >
      <GroupIndexMarker
        text={text}
        color={color}
        position={[size.x / 2, 0, 0]}
        rotation={[0, Math.PI / 2, 0]}
        scale={[scale.y, scale.y, scale.y]}
      />
      <GroupIndexMarker
        text={text}
        color={color}
        position={[size.x / -2, 0, 0]}
        rotation={[0, Math.PI / -2, 0]}
        scale={[scale.y, scale.y, scale.y]}
      />
      <GroupIndexMarker
        text={text}
        color={color}
        position={[0, size.y / 2, 0]}
        rotation={[Math.PI / -2, 0, 0]}
        scale={[scale.y, scale.y, scale.y]}
      />
      <GroupIndexMarker
        text={text}
        color={color}
        position={[0, size.y / -2, 0]}
        rotation={[Math.PI / 2, 0, 0]}
        scale={[scale.y, scale.y, scale.y]}
      />
      <GroupIndexMarker
        text={text}
        color={color}
        position={[0, 0, size.z / 2]}
        rotation={[0, 0, 0]}
        scale={[scale.y, scale.y, scale.y]}
      />
      <GroupIndexMarker
        text={text}
        color={color}
        position={[0, 0, size.z / -2]}
        rotation={[0, Math.PI, 0]}
        scale={[scale.y, scale.y, scale.y]}
      />
    </group>
  );
};
