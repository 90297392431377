import * as THREE from 'three';
import { Box, Plane, useTexture } from '@react-three/drei';
import { getDmsUrl } from '@ekultur/dms-url-generator';

export const Pedestal = ({
  position,
  rotation,
  scale,
  name,
}) => {
  const floorShadowTexture = useTexture(`${getDmsUrl('013AjtpvsVmC', '01')}?dimension=original`);
  const topTexture = useTexture(`${getDmsUrl('013AjtiSGczR', '01')}?dimension=original`);
  const sideATexture = useTexture(`${getDmsUrl('013AjtiSGczS', '01')}?dimension=original`);
  const sideBTexture = useTexture(`${getDmsUrl('013AjtiSGczT', '01')}?dimension=original`);
  const baseTexture = useTexture(`${getDmsUrl('013AjtiSGd4m', '01')}?dimension=original`);

  return (
    <group
      name={`${name}-pedestal`}
      position={[position.x, position.y, position.z]}
      rotation={[rotation?.x ?? 0, rotation?.y ?? 0, rotation?.z ?? 0]}
      scale={new THREE.Vector3(1, 1, 1)}
      onClick={(event) => event.stopPropagation()}
      onPointerDown={(event) => event.stopPropagation()}
      onPointerUp={(event) => event.stopPropagation()}
      onPointerMove={(event) => event.stopPropagation()}
    >
      <Box
        position={[0, 0.025, 0]}
        scale={[scale.x, scale.y - 0.05, scale.z]}
      >
        <meshStandardMaterial
          attachArray="material"
          map={sideATexture}
          side={THREE.FrontSide}
        />
        <meshStandardMaterial
          attachArray="material"
          map={sideATexture}
          side={THREE.FrontSide}
        />
        <meshStandardMaterial
          attachArray="material"
          map={topTexture}
          side={THREE.FrontSide}
        />
        <meshStandardMaterial
          attachArray="material"
        />
        <meshStandardMaterial
          attachArray="material"
          map={sideBTexture}
          side={THREE.FrontSide}
        />
        <meshStandardMaterial
          attachArray="material"
          map={sideBTexture}
          side={THREE.FrontSide}
        />
      </Box>
      <Box
        position={[0, (scale.y - 0.05) / -2, 0]}
        scale={[scale.x - 0.04, 0.05, scale.z - 0.04]}
      >
        <meshStandardMaterial
          attachArray="material"
          map={baseTexture}
          side={THREE.FrontSide}
        />
        <meshStandardMaterial
          attachArray="material"
          map={baseTexture}
          side={THREE.FrontSide}
        />
        <meshStandardMaterial
          attachArray="material"
        />
        <meshStandardMaterial
          attachArray="material"
        />
        <meshStandardMaterial
          attachArray="material"
          map={baseTexture}
          side={THREE.FrontSide}
        />
        <meshStandardMaterial
          attachArray="material"
          map={baseTexture}
          side={THREE.FrontSide}
        />
      </Box>
      <Plane
        position={[0, (scale.y / -2) + 0.001, 0]}
        rotation={[Math.PI / -2, 0, 0]}
        args={[(scale.x - 0.04) * 1.1045, (scale.z - 0.04) * 1.1045]}
        renderOrder={4}
      >
        <meshStandardMaterial
          attach="material"
          map={floorShadowTexture}
          side={THREE.FrontSide}
          transparent
        />
      </Plane>
    </group>
  );
};
