import * as THREE from 'three';
import * as Icon from '@mui/icons-material';
import { AccordionDetails, Button, Checkbox, Divider, FormControlLabel, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { SliderInput } from '../../../components/SliderInput';
import { useCanvasItemEditor } from '../../Canvases/useCanvasItemEditor';

export const VideoSettings = ({
  index,
  videoItem,
  canvasHeight,
  canvasWidth,
  canvasRotation,
  cellSize,
  onUpdate,
  onRemove,
  getSceneElements,
}) => {
  const [params, setParams] = useState();
  const canvasItemEditor = useCanvasItemEditor({
    canvasSize: { height: canvasHeight, width: canvasWidth },
  });

  const update = (video) => {
    setParams(getParams(video));
    onUpdate && onUpdate(index, video);
  };

  const getParams = (obj) => {
    videoItem = obj ?? videoItem;
    const { canvasRef, itemRef: videoRef, rescaleButtonsRef } = getSceneElements();

    if (!canvasRef || !videoRef) return null;

    const localPosition = canvasRef.worldToLocal(videoItem.position.clone());
    const scale = Math.max(videoItem.width, videoItem.height);
    const size = { width: videoItem.width, height: videoItem.height };
    const scaleLimit = canvasItemEditor.getScaleLimit(localPosition, scale, size);

    return {
      xPosition: {
        value: videoItem.boundToGrid
          ? (localPosition.x - (videoItem.width / 2) + (canvasWidth / 2) + cellSize) / cellSize
          : localPosition.x - (videoItem.width / 2) + (canvasWidth / 2),
        min: videoItem.boundToGrid ? 1 : 0,
        max: videoItem.boundToGrid
          ? (canvasWidth / cellSize) - Math.ceil(videoItem.width / cellSize) + 1
          : canvasWidth - videoItem.width,
        step: videoItem.boundToGrid ? 1 : 0.001,
        onChange: (value, last) => {
          const scaleOffset = (Math.ceil(videoItem.width / cellSize) - 1) * cellSize / 2;
          value = videoItem.boundToGrid
            ? ((value - 1) * cellSize) - ((videoItem.width - cellSize) / 2) + scaleOffset
            : value;
          const newPosition = localPosition.clone().setX(value - (canvasWidth / 2) + (videoItem.width / 2));
          videoItem.position.copy(canvasRef.localToWorld(newPosition));
          videoRef.parent.position.copy(videoItem.position);
          (rescaleButtonsRef || getSceneElements().rescaleButtonsRef).position.copy(videoItem.position);
          last && update(videoItem);
        },
      },
      yPosition: {
        value: videoItem.boundToGrid
          ? (localPosition.y - (videoItem.height / 2) + (canvasHeight / 2) + cellSize) / cellSize
          : localPosition.y - (videoItem.height / 2) + (canvasHeight / 2),
        min: videoItem.boundToGrid ? 1 : 0,
        max: videoItem.boundToGrid
          ? (canvasHeight / cellSize) - Math.ceil(videoItem.height / cellSize) + 1
          : canvasHeight - videoItem.height,
        step: videoItem.boundToGrid ? 1 : 0.001,
        onChange: (value, last) => {
          const scaleOffset = (Math.ceil(videoItem.height / cellSize) - 1) * cellSize / 2;
          value = videoItem.boundToGrid
            ? ((value - 1) * cellSize) - ((videoItem.height - cellSize) / 2) + scaleOffset
            : value;
          const newPosition = localPosition.clone().setY(value - (canvasHeight / 2) + (videoItem.height / 2));
          videoItem.position.copy(canvasRef.localToWorld(newPosition));
          videoRef.parent.position.copy(videoItem.position);
          (rescaleButtonsRef || getSceneElements().rescaleButtonsRef).position.copy(videoItem.position);
          last && update(videoItem);
        },
      },
      scale: {
        value: Math.max(videoItem.width, videoItem.height),
        min: 0.1,
        max: scaleLimit,
        step: 0.001,
        onChange: (value, last) => {
          if (last) {
            const scale = Math.min(scaleLimit, value);
            const width = videoItem.width > videoItem.height ? scale : scale * videoItem.width / videoItem.height;
            const height = videoItem.height > videoItem.width ? scale : scale * videoItem.height / videoItem.width;
            videoItem.width = width;
            videoItem.height = height;
            update(videoItem);
          }
        },
      },
      rotation: {
        value: 180 + THREE.MathUtils.radToDeg(canvasRotation.z) - THREE.MathUtils.radToDeg(videoItem.rotation.z),
        min: 0,
        max: 360,
        step: 1,
        onChange: (value, last) => {
          if (last) {
            const zRotation = THREE.MathUtils.degToRad(180) + canvasRotation.z - THREE.MathUtils.degToRad(value);
            videoItem.rotation.z = zRotation;
            update(videoItem);
          }
        },
      },
      volume: {
        value: videoItem.volume,
        min: 0,
        max: 1,
        step: 0.001,
        onChange: (value, last) => {
          if (last) {
            videoItem.volume = value;
            update(videoItem);
          }
        },
      },
      autoPlay: {
        value: videoItem.autoPlay,
        onChange: (event) => {
          videoItem.autoPlay = event.target.checked;
          update(videoItem);
        },
      },
      loop: {
        value: videoItem.loop,
        onChange: (event) => {
          videoItem.loop = event.target.checked;
          update(videoItem);
        },
      },
      boundToGrid: {
        value: videoItem.boundToGrid,
        onChange: (event) => {
          videoItem.boundToGrid = event.target.checked;
          update(videoItem);
        },
      },
    };
  }

  useEffect(() => {
    setParams(getParams());
  }, [videoItem, videoItem.autoPlay, videoItem.boundToGrid, videoItem.height, videoItem.loop,
    videoItem.position.x, videoItem.position.y, videoItem.rotation.z, videoItem.volume, videoItem.width]);

  return (
    <>
      {params && (
        <>
          <AccordionDetails>
            <div>
              <Typography>Posisjon</Typography>
              <SliderInput title={'X'} {...params.xPosition} />
              <SliderInput title={'Y'} {...params.yPosition} />
            </div>
            <div>
              <Typography>Skalering</Typography>
              <SliderInput {...params.scale} />
            </div>
            <div>
              <Typography>Rotasjon</Typography>
              <SliderInput {...params.rotation} />
            </div>
            <div>
              <Typography>Volum</Typography>
              <SliderInput {...params.volume} />
            </div>
            <div>
              <FormControlLabel
                label="Automatisk avspilling"
                control={
                  <Checkbox
                    checked={params.autoPlay.value}
                    onChange={params.autoPlay.onChange}
                  />
                }
              />
              <FormControlLabel
                label="Loop"
                control={
                  <Checkbox
                    checked={params.loop.value}
                    onChange={params.loop.onChange}
                  />
                }
              />
              <FormControlLabel
                label="Følg grid"
                control={
                  <Checkbox
                    checked={params.boundToGrid.value}
                    onChange={params.boundToGrid.onChange}
                  />
                }
              />
            </div>
          </AccordionDetails>
          <Divider light />
          <AccordionDetails>
            <Button
              variant="outlined"
              size="small"
              startIcon={<Icon.DeleteOutlined fontSize="small" />}
              onClick={() => onRemove(index)}
            >
              Slett video
            </Button>
          </AccordionDetails>
        </>
      )}
    </>
  );
};
