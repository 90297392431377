import { useState } from 'react';
import { AppBar as AppBarContainer } from '../appBar/AppBar';
import { Button } from '../appBar/Button';
import { SET_ABOUT_DIALOG_OPEN, SET_AUDIO_MUTE, SET_HELP_DIALOG_OPEN,
  useThreeDDispatch, useThreeDProps, useThreeDState } from './App/ThreeDContext';
import * as Icon from 'react-feather';

export const AppBar = () => {
  const { data, onClose } = useThreeDProps();
  const { about } = data;
  const { hasAudio, audioMute, helpDialogOpen, aboutDialogOpen } = useThreeDState();
  const dispatch = useThreeDDispatch();
  const [settingsOpen, setSettingsOpen] = useState(false);

  const handleHelpDialogToggle = () => {
    dispatch({ type: SET_HELP_DIALOG_OPEN, helpDialogOpen: !helpDialogOpen });
    dispatch({ type: SET_ABOUT_DIALOG_OPEN, aboutDialogOpen: false });
    setSettingsOpen(false);
  }

  const handleAboutDialogToggle = () => {
    dispatch({ type: SET_ABOUT_DIALOG_OPEN, aboutDialogOpen: !aboutDialogOpen });
    dispatch({ type: SET_HELP_DIALOG_OPEN, helpDialogOpen: false });
    setSettingsOpen(false);
  }

  const handleAudioMuteToggle = () => {
    dispatch({ type: SET_AUDIO_MUTE, audioMute: !audioMute });
  }

  return (
    <AppBarContainer
      {...about}
      onClose={onClose}
    >
      <Button
        text="Hjelp"
        icon={<Icon.LifeBuoy />}
        active={helpDialogOpen}
        onClick={handleHelpDialogToggle}
      />
      {about?.imageUrl &&
        <Button
          text="Om"
          icon={<Icon.Info />}
          active={aboutDialogOpen}
          onClick={handleAboutDialogToggle}
        />
      }
      {hasAudio &&
        <Button
          text="Lyd"
          icon={audioMute ? <Icon.VolumeX /> : <Icon.Volume2 />}
          active={settingsOpen}
          onClick={handleAudioMuteToggle}
        />
      }
    </AppBarContainer>
  );
};
