import * as THREE from 'three';
import { useEffect } from 'react';
import { useTexture } from '@react-three/drei';
import { useGesture } from '@use-gesture/react';
import { animated, useSpring } from '@react-spring/three';
import { getDmsUrl } from '@ekultur/dms-url-generator';
import { CanvasItemTypes, ControlModes } from '../../helpers/enums';
import { SET_MODELVIEWER_DATA, useThreeDDispatch, useThreeDState } from '../App/ThreeDContext';

export const Image = ({
  imageIndex,
  imageSphereIndex,
  name,
  url,
  dmsId,
  viewerData,
  radius,
  spacing,
  thetaOffset,
  staggeredLayout,
  retainImageAspectRatio,
  opacity,
  inverted,
  segments,
  segmentIndices,
  editMode,
}) => {
  const { controlMode } = useThreeDState();
  const dispatch = useThreeDDispatch();
  const texture = useTexture(dmsId ? `${getDmsUrl(dmsId, '01')}?dimension=2048x2048` : url, texture => texture.encoding = THREE.SRGBColorSpace);
  const sphereParameters = {
    phiStart: (Math.PI * 2 / segments.width * (segmentIndices.width + 1)) + spacing +
              (staggeredLayout ? (Math.PI * 2 / segments.width / 2 * (segmentIndices.height % 2)) : 0),
    phiLength: (Math.PI * 2 / segments.width) - spacing,
    thetaStart: (Math.PI / segments.height * segmentIndices.height) +
                (spacing / segments.height * (segments.height - segmentIndices.height)) +
                ((thetaOffset / segments.height * (segments.height - segmentIndices.height)) - (thetaOffset / 2)),
    thetaLength: (Math.PI / segments.height) - spacing - (thetaOffset / segments.height),
  };

  if (retainImageAspectRatio && texture.image.width / texture.image.height !== 0) {
    let adjustedLength = sphereParameters.thetaLength * (texture.image.width / texture.image.height);

    if (adjustedLength < sphereParameters.phiLength) {
      sphereParameters.phiStart += (sphereParameters.phiLength - adjustedLength) / 2;
      sphereParameters.phiLength = adjustedLength;
    } else {
      adjustedLength = sphereParameters.phiLength * (texture.image.height / texture.image.width);
      sphereParameters.thetaStart += (sphereParameters.thetaLength - adjustedLength) / 2;
      sphereParameters.thetaLength = adjustedLength;
    }
  }

  const [spring, springApi] = useSpring(() => ({
    opacity: opacity,
    config: {
      duration: 500,
    },
  }));

  useEffect(() => {
    springApi.start({ opacity: opacity });
  }, [springApi, opacity]);

  const handleClick = ({ event, tap }) => {
    if (tap) {
      const pointerLockElement = document.pointerLockElement || document.mozPointerLockElement || document.webkitPointerLockElement;

      if (!(controlMode === ControlModes.POINTER_LOCK_CONTROL && !pointerLockElement)) {
        const intersection = event.intersections.reduce((acc, obj) => (obj.distance < acc.distance ? obj : acc));

        if (intersection.object === event.object) {
          dispatch({
            type: SET_MODELVIEWER_DATA,
            modelViewerData: {
              index: imageSphereIndex,
              subIndex: imageIndex,
              type: CanvasItemTypes.IMAGE_SPHERE,
              viewerData: viewerData,
            },
          });
        }
      }
    }
  };

  const handleHover = ({ first, last }) => {
    first && springApi.start({ opacity: 1 });
    last && springApi.start({ opacity: opacity });
  };

  const useGestures = () => {
    const gestures = useGesture(
      {
        onDrag: handleClick,
        onHover: handleHover,
      },
      { drag: { filterTaps: true }}
    );
    return !editMode ? gestures() : {};
  };

  return (
    <mesh
      name={name}
      scale={new THREE.Vector3((inverted ? -1 : 1), 1, 1)}
      userData={{
        itemName: name,
        itemType: CanvasItemTypes.IMAGE_SPHERE,
        interactable: !!viewerData,
      }}
      {...useGestures()}
    >
      <sphereGeometry args={[
        radius,
        16,
        16,
        sphereParameters.phiStart,
        sphereParameters.phiLength,
        sphereParameters.thetaStart,
        sphereParameters.thetaLength,
      ]} />
      <animated.meshBasicMaterial
        map={texture}
        side={THREE.DoubleSide}
        transparent
        {...spring}
      />
    </mesh>
  );
}
