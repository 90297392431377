import { useDrop } from 'react-dnd';
import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles({
  container: {
    fontSize: 12,
    margin: '6px 12px',
    padding: '8px 16px',
    color: 'rgba(0, 0, 0, 0.6)',
    border: '1px solid rgba(0, 0, 0, 0.12)',
    borderRadius: 5,
    background: '#EEEEEE',
    textAlign: 'center',
    lineHeight: '150%',
  },
});

export const DragBox = ({
  text,
  index,
  onGroupDrag,
}) => {
  const styles = useStyles();

  const [{ canDrop, isOver }, drop] = useDrop({
    accept: 'group',
    collect: (monitor) => ({
      canDrop: monitor.canDrop(),
      isOver: monitor.isOver(),
    }),
    hover(item) {
      if (item.index === index) {
        return;
      }

      onGroupDrag(item.id, index);
      item.index = index;
    },
  });

  const opacity = canDrop && isOver ? 0 : 1;

  return (
    <div
      ref={drop}
      className={styles.container}
      style={{ opacity }}
    >
      {text}
    </div>
  );
};
