import * as THREE from 'three';
import { Suspense, useRef, useState } from 'react';
import { Plane } from '@react-three/drei';
import { Paragraphs } from './Paragraphs';
import { PositionalAudio } from '../Audio/PositionalAudio';
import { AudioController } from '../Audio/AudioController';
import { CanvasItemTypes } from '../../helpers/enums';
import { useThreeDState } from '../App/ThreeDContext';

export const TextItem = ({
  name,
  position,
  rotation,
  color,
  maxWidth,
  paragraphs,
  audio,
  dragMode = false,
  editMode = false,
  onClick,
}) => {
  const { userInteracted } = useThreeDState();
  const [ audioControllerPosition, setAudioControllerPosition ] = useState();
  const container = useRef();
  const background = useRef();

  const handlePointerUp = (event) => {
    onClick && onClick(event);
  };

  const handlePointerMove = (event) => {
    event.stopPropagation();
  };

  const handleReflow = (width, height) => {
    background.current?.scale?.set(maxWidth, height, 1);
    calculateAudioControllerPosition();
  };

  const calculateAudioControllerPosition = () => {
    if (userInteracted && audio) {
      const texts = [];
      container.current.children[0].traverse(obj => {
        obj instanceof THREE.Mesh && texts.push(obj);
      });

      const boundingBox = new THREE.Box3().setFromObject(texts[0]);
      const paragraphHeight = boundingBox.max.y - boundingBox.min.y;
      let controllerPosition = texts[0].worldToLocal(position.clone());
      controllerPosition.setX(controllerPosition.x - maxWidth - 0.25);
      controllerPosition.setY(controllerPosition.y + background.current.scale.y - paragraphHeight);

      if (!audioControllerPosition || !audioControllerPosition.equals(controllerPosition)) {
        setAudioControllerPosition(controllerPosition);
      }
    }
  };

  return (
    <group
      ref={container}
      position={[position.x, position.y, position.z]}
      rotation={[rotation.x, rotation.y, rotation.z]}
      onPointerUp={!editMode ? handlePointerUp : null}
      onPointerMove={!editMode ? handlePointerMove : null}
    >
      <Paragraphs
        name={name}
        color={color}
        maxWidth={maxWidth}
        paragraphs={paragraphs}
        onReflow={handleReflow}
      />
      {userInteracted && audio && (
        <Suspense fallback={null}>
          <AudioController
            position={audioControllerPosition}
            scale={audio.scale}
            enabled={!editMode}
          >
            <PositionalAudio {...audio} />
          </AudioController>
        </Suspense>
      )}
      <Plane
        ref={background}
        name={name}
        args={[1, 1]}
        renderOrder={3}
        visible={editMode}
        userData={{
          itemName: name,
          itemType: CanvasItemTypes.TEXT,
        }}
      >
        <meshBasicMaterial
          color={0x000000}
          opacity={0.2}
          transparent
          depthWrite={false}
        />
      </Plane>
      {dragMode &&
        <mesh visible={false}>
          <planeGeometry args={[100, 100]} />
          <meshBasicMaterial transparent opacity={0} depthWrite={false} />
        </mesh>
      }
    </group>
  );
};
