import * as Icon from '@mui/icons-material';
import { AccordionDetails, Button, Checkbox, Divider, FormControlLabel, Radio, RadioGroup, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { SliderInput } from '../../../components/SliderInput';

export const AudioSettings = ({
  index,
  audioItem,
  canvasHeight,
  canvasWidth,
  canvasRotation,
  cellSize,
  onUpdate,
  onRemove,
  getSceneElements,
}) => {
  const [params, setParams] = useState();

  const update = (audio) => {
    setParams(getParams(audio));
    onUpdate && onUpdate(index, audio);
  };

  const getParams = (obj) => {
    audioItem = obj ?? audioItem;
    let { canvasRef, itemRef: audioRef, audibleSphereRef } = getSceneElements();

    if (!canvasRef || !audioRef) return null;

    const localPosition = canvasRef.worldToLocal(audioItem.position.clone());

    return {
      positioning: {
        value: audioItem.positioning,
        onChange: (event) => {
          audioItem.positioning = event.target.checked;
          update(audioItem);
        },
      },
      xPosition: {
        value: audioItem.boundToGrid
          ? (localPosition.x - (0.5 / 2) + (canvasWidth / 2) + cellSize) / cellSize
          : localPosition.x + (canvasWidth / 2),
        min: audioItem.boundToGrid ? 1 : 0,
        max: audioItem.boundToGrid
          ? (canvasWidth / cellSize) - Math.ceil(0.5 / cellSize) + 1
          : canvasWidth - 0.5,
        step: audioItem.boundToGrid ? 1 : 0.001,
        onChange: (value, last) => {
          audibleSphereRef = audibleSphereRef ?? getSceneElements().audibleSphereRef;
          const scaleOffset = (Math.ceil(0.5 / cellSize) - 1) * cellSize / 2;
          value = audioItem.boundToGrid
            ? ((value - 1) * cellSize) - ((0.5 - cellSize) / 2) + scaleOffset
            : value;
          const newPosition = localPosition.clone().setX(value - (canvasWidth / 2) + (0.5 / 2));
          audioItem.position.copy(canvasRef.localToWorld(newPosition));
          audioRef.position.copy(audioItem.position);
          audibleSphereRef.position.copy(audioItem.position);
          last && update(audioItem);
        },
      },
      yPosition: {
        value: audioItem.boundToGrid
          ? (localPosition.y - (0.5 / 2) + (canvasHeight / 2) + cellSize) / cellSize
          : localPosition.y + (canvasHeight / 2),
        min: audioItem.boundToGrid ? 1 : 0,
        max: audioItem.boundToGrid
          ? (canvasHeight / cellSize) - Math.ceil(0.5 / cellSize) + 1
          : canvasHeight - 0.5,
        step: audioItem.boundToGrid ? 1 : 0.001,
        onChange: (value, last) => {
          audibleSphereRef = audibleSphereRef ?? getSceneElements().audibleSphereRef;
          const scaleOffset = (Math.ceil(0.5 / cellSize) - 1) * cellSize / 2;
          value = audioItem.boundToGrid
            ? ((value - 1) * cellSize) - ((0.5 - cellSize) / 2) + scaleOffset
            : value;
          const newPosition = localPosition.clone().setY(value - (canvasHeight / 2) + (0.5 / 2));
          audioItem.position.copy(canvasRef.localToWorld(newPosition));
          audioRef.position.copy(audioItem.position);
          audibleSphereRef.position.copy(audioItem.position);
          last && update(audioItem);
        },
      },
      volume: {
        value: audioItem.volume,
        min: 0,
        max: 2,
        step: 0.01,
        onChange: (value, last) => {
          if (last) {
            audioItem.volume = value;
            update(audioItem);
          }
        },
      },
      maxDistance: {
        value: audioItem.maxDistance,
        min: 5,
        max: 100,
        step: 0.1,
        onChange: (value, last) => {
          if (last) {
            audioItem.maxDistance = value;
            update(audioItem);
          }
        },
      },
      interactive: {
        value: audioItem.interactive,
        onChange: (event) => {
          audioItem.interactive = JSON.parse(event.target.value);
          update(audioItem);
        },
      },
      scale: {
        value: audioItem.scale,
        min: 1,
        max: 3,
        step: 0.1,
        onChange: (value, last) => {
          if (last) {
            audioItem.scale = value;
            update(audioItem);
          }
        },
      },
      boundToGrid: {
        value: audioItem.boundToGrid,
        onChange: (event) => {
          audioItem.boundToGrid = event.target.checked;
          update(audioItem);
        },
      },
    };
  };

  useEffect(() => {
    setParams(getParams());
  }, [audioItem, audioItem.positioning, audioItem.position.x, audioItem.position.y,
    audioItem.volume, audioItem.maxDistance, audioItem.boundToGrid, audioItem.interactive]);

  return (
    <>
      {params && (
        <>
          <AccordionDetails>
            <div>
              <Typography>Lydpunktets posisjon</Typography>
              <SliderInput title={'X'} {...params.xPosition} />
              <SliderInput title={'Y'} {...params.yPosition} />
            </div>
            <div>
              <Typography>Volum</Typography>
              <SliderInput {...params.volume} />
            </div>
            <div>
              <Typography>Radius for hørbarhet</Typography>
              <SliderInput {...params.maxDistance} />
            </div>
            <RadioGroup
              value={params.interactive.value}
              onChange={params.interactive.onChange}
            >
              <FormControlLabel
                value={false}
                control={<Radio />}
                label={'Automatisk avspilling i loop'}
              />
              <FormControlLabel
                value={true}
                control={<Radio />}
                label={'Brukerstyrt avspilling'}
              />
            </RadioGroup>
            <div>
              <Typography>Lydknappens størrelse</Typography>
              <SliderInput
                {...params.scale}
                disabled={!params.interactive.value}
              />
            </div>
            <div>
              <FormControlLabel
                label='Følg grid'
                control={
                  <Checkbox
                    checked={params.boundToGrid.value}
                    onChange={params.boundToGrid.onChange}
                  />
                }
              />
            </div>
          </AccordionDetails>
          <Divider light />
          <AccordionDetails>
            <Button
              variant='outlined'
              size='small'
              startIcon={<Icon.DeleteOutlined fontSize='small' />}
              onClick={() => onRemove(index)}
            >
              Slett lydpunkt
            </Button>
          </AccordionDetails>
        </>
      )}
    </>
  );
};
