import * as THREE from 'three';
import * as Icon from '@mui/icons-material';
import { AccordionDetails, Box, Button, Checkbox, Divider, FormControlLabel, MenuItem, TextField, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { SliderInput } from '../../../components/SliderInput';
import { GroupSegment } from '../../../components/GroupSegment';
import { useCanvasItemEditor } from '../../Canvases/useCanvasItemEditor';
import { CanvasItemTypes } from '../../../helpers/enums';
import { SET_MODELVIEWER_DATA, useThreeDDispatch } from '../../App/ThreeDContext';

export const ImageSettings = ({
  index,
  imageItem,
  canvasHeight,
  canvasWidth,
  canvasRotation,
  cellSize,
  groups,
  onUpdate,
  onRemove,
  onGroupUpdate,
  getSceneElements,
}) => {
  const dispatch = useThreeDDispatch();
  const [params, setParams] = useState();
  const canvasItemEditor = useCanvasItemEditor({
    canvasSize: { height: canvasHeight, width: canvasWidth },
  });

  const update = (image) => {
    setParams(getParams(image));
    onUpdate && onUpdate(index, image);
  };

  const getOverlappingCellsBoundries = (localPosition, imageSize) => {
    const topEdge = Number((localPosition.y + (imageSize.height / 2)).toFixed(12));
    const bottomEdge = Number((localPosition.y - (imageSize.height / 2)).toFixed(12));
    const rightEdge = Number((localPosition.x + (imageSize.width / 2)).toFixed(12));
    const leftEdge = Number((localPosition.x - (imageSize.width / 2)).toFixed(12));

    const cellHeightOffset = canvasHeight / cellSize % 2 * cellSize / 2;
    const cellWidthOffset = canvasWidth / cellSize % 2 * cellSize / 2;

    const topPosition = (Math.ceil((topEdge + cellHeightOffset) / cellSize) * cellSize) - cellHeightOffset;
    const bottomPosition = (Math.floor((bottomEdge - cellHeightOffset) / cellSize) * cellSize) + cellHeightOffset;
    const rightPosition = (Math.ceil((rightEdge + cellWidthOffset) / cellSize) * cellSize) - cellWidthOffset;
    const leftPosition = (Math.floor((leftEdge - cellWidthOffset) / cellSize) * cellSize) + cellWidthOffset;

    return {
      top: topPosition,
      bottom: bottomPosition,
      right: rightPosition,
      left: leftPosition,
    };
  };

  const getParams = (obj) => {
    imageItem = obj ?? imageItem;
    const { canvasRef, itemRef: imageRef, rescaleButtonsRef, gimRef } = getSceneElements();

    if (!canvasRef || !imageRef) return null;

    const localPosition = canvasRef.worldToLocal(imageItem.position.clone());
    const imageSize = {
      width: Math.min(imageRef.geometry.parameters.width / imageRef.geometry.parameters.height, 1) * imageItem.scale,
      height: Math.min(imageRef.geometry.parameters.height / imageRef.geometry.parameters.width, 1) * imageItem.scale,
    };
    const scaleLimit = canvasItemEditor.getScaleLimit(localPosition, imageItem.scale, imageSize);
    const boundries = getOverlappingCellsBoundries(localPosition, imageSize);

    return {
      xPosition: {
        value: imageItem.boundToGrid
          ? (localPosition.x - (imageSize.width / 2) + (canvasWidth / 2) + cellSize) / cellSize
          : localPosition.x - (imageSize.width / 2) + (canvasWidth / 2),
        min: imageItem.boundToGrid ? 1 : 0,
        max: imageItem.boundToGrid
          ? (canvasWidth / cellSize) - Math.ceil(imageSize.width / cellSize) + 1
          : canvasWidth - imageSize.width,
        step: imageItem.boundToGrid ? 1 : 0.001,
        onChange: (value, last) => {
          const scaleOffset = (Math.ceil(imageSize.width / cellSize) - 1) * cellSize / 2;
          value = imageItem.boundToGrid
            ? ((value - 1) * cellSize) - ((imageSize.width - cellSize) / 2) + scaleOffset
            : value;
          const newPosition = localPosition.clone().setX(value - (canvasWidth / 2) + (imageSize.width / 2));
          imageItem.position.copy(canvasRef.localToWorld(newPosition));
          imageRef.position.copy(imageItem.position);
          gimRef?.position?.copy(imageItem.position);
          (rescaleButtonsRef || getSceneElements().rescaleButtonsRef).position.copy(imageItem.position);
          last && update(imageItem);
        },
      },
      yPosition: {
        value: imageItem.boundToGrid
          ? (localPosition.y - (imageSize.height / 2) + (canvasHeight / 2) + cellSize) / cellSize
          : localPosition.y - (imageSize.height / 2) + (canvasHeight / 2),
        min: imageItem.boundToGrid ? 1 : 0,
        max: imageItem.boundToGrid
          ? (canvasHeight / cellSize) - Math.ceil(imageSize.height / cellSize) + 1
          : canvasHeight - imageSize.height,
        step: imageItem.boundToGrid ? 1 : 0.001,
        onChange: (value, last) => {
          const scaleOffset = (Math.ceil(imageSize.height / cellSize) - 1) * cellSize / 2;
          value = imageItem.boundToGrid
            ? ((value - 1) * cellSize) - ((imageSize.height - cellSize) / 2) + scaleOffset
            : value;
          const newPosition = localPosition.clone().setY(value - (canvasHeight / 2) + (imageSize.height / 2));
          imageItem.position.copy(canvasRef.localToWorld(newPosition));
          imageRef.position.copy(imageItem.position);
          gimRef?.position?.copy(imageItem.position);
          (rescaleButtonsRef || getSceneElements().rescaleButtonsRef).position.copy(imageItem.position);
          last && update(imageItem);
        },
      },
      scale: {
        value: imageItem.scale,
        min: 0.1,
        max: scaleLimit,
        step: 0.001,
        onChange: (value, last) => {
          if (last) {
            imageItem.scale = Math.min(scaleLimit, value);
            update(imageItem);
          }
        },
      },
      rotation: {
        value: 180 + THREE.MathUtils.radToDeg(canvasRotation.z) - THREE.MathUtils.radToDeg(imageItem.rotation.z),
        min: 0,
        max: 360,
        step: 1,
        onChange: (value, last) => {
          if (last) {
            const zRotation = THREE.MathUtils.degToRad(180) + canvasRotation.z - THREE.MathUtils.degToRad(value);
            imageItem.rotation.z = zRotation;
            update(imageItem);
          }
        }
      },
      xAlign: {
        value: Number(localPosition.x.toFixed(12)) === Number((boundries.right - ((boundries.right - boundries.left) / 2)).toFixed(12))
          ? 'center'
          : (Number(localPosition.x.toFixed(12)) === Number((boundries.right - (imageSize.width / 2)).toFixed(12))
            ? 'right'
            : (Number(localPosition.x.toFixed(12)) === Number((boundries.left + (imageSize.width / 2)).toFixed(12))
              ? 'left'
              : '')),
        onChange: (event) => {
          let newPosition = localPosition.clone();

          switch (event.target.value) {
            case 'left':
              newPosition.setX(boundries.left + (imageSize.width / 2));
              break;
            case 'center':
              newPosition.setX(boundries.right - ((boundries.right - boundries.left) / 2));
              break;
            case 'right':
              newPosition.setX(boundries.right - (imageSize.width / 2));
              break;
            default:
              break;
          }

          imageItem.position.copy(canvasRef.localToWorld(newPosition.clone()));
          update(imageItem);
        },
      },
      yAlign: {
        value: Number(localPosition.y.toFixed(12)) === Number((boundries.top - ((boundries.top - boundries.bottom) / 2)).toFixed(12))
          ? 'center' 
          : (Number(localPosition.y.toFixed(12)) === Number((boundries.top - (imageSize.height / 2)).toFixed(12))
            ? 'top'
            : (Number(localPosition.y.toFixed(12)) === Number((boundries.bottom + (imageSize.height / 2)).toFixed(12))
              ? 'bottom'
              : '')),
        onChange: (event) => {
          let newPosition = localPosition.clone();

          switch (event.target.value) {
            case 'top':
              newPosition.setY(boundries.top - (imageSize.height / 2));
              break;
            case 'center':
              newPosition.setY(boundries.top - ((boundries.top - boundries.bottom) / 2));
              break;
            case 'bottom':
              newPosition.setY(boundries.bottom + (imageSize.height / 2));
              break;
            default:
              break;
          }

          imageItem.position.copy(canvasRef.localToWorld(newPosition.clone()));
          update(imageItem);
        },
      },
      addCanvasDepth: {
        value: imageItem.addCanvasDepth,
        onChange: (event) => {
          imageItem.addCanvasDepth = event.target.checked;
          update(imageItem);
        },
      },
      boundToGrid: {
        value: imageItem.boundToGrid,
        onChange: (event) => {
          imageItem.boundToGrid = event.target.checked;
          update(imageItem);
        },
      },
    };
  };

  const handleGroupChange = (groupId) => {
    const groupChange = {};

    if (Number.isInteger(groupId)) {
      imageItem.group && (groupChange.oldGroupId = imageItem.group.id);
      groupChange.newGroupId = groupId;

      imageItem.group = {
        id: groupId,
        index: groups.find(group => group.id === groupId).itemCount,
      };
    }
    else {
      groupChange.oldGroupId = imageItem.group.id;
      delete imageItem.group;
    }

    onGroupUpdate(index, imageItem, groupChange);
  };

  const handleGroupIndexChange = (groupIndex) => {
    const groupChange = {
      groupId: imageItem.group.id,
      oldIndex: imageItem.group.index,
    };

    imageItem.group.index = groupIndex;
    onGroupUpdate(index, imageItem, groupChange);
  };

  const handleModelViewerOpen = () => {
    dispatch({
      type: SET_MODELVIEWER_DATA,
      modelViewerData: {
        index: index,
        type: CanvasItemTypes.IMAGE,
        viewerData: imageItem.viewerData ?? {
          model: {
            type: 'ImageSingle',
            dmsId: imageItem.dmsId,
          },
        },
      },
    });
  };

  useEffect(() => {
    setParams(getParams());
  }, [imageItem, imageItem.addCanvasDepth, imageItem.boundToGrid, imageItem.position.x,
    imageItem.position.y, imageItem.rotation.z, imageItem.scale]);

  return (
    <>
      {params && (
        <>
          <AccordionDetails>
            <div>
              <Typography>Posisjon</Typography>
              <SliderInput title={'X'} {...params.xPosition} />
              <SliderInput title={'Y'} {...params.yPosition} />
            </div>
            <div>
              <Typography>Skalering</Typography>
              <SliderInput {...params.scale} />
            </div>
            <div>
              <Typography>Rotasjon</Typography>
              <SliderInput {...params.rotation} />
            </div>
            <div>
              <FormControlLabel
                label="Vis rammetykkelse"
                control={
                  <Checkbox
                    checked={params.addCanvasDepth.value}
                    onChange={params.addCanvasDepth.onChange}
                  />
                }
              />
              <FormControlLabel
                label="Følg grid"
                control={
                  <Checkbox
                    checked={params.boundToGrid.value}
                    onChange={params.boundToGrid.onChange}
                  />
                }
              />
            </div>
            <div>
              <Typography>Plassering</Typography>
              <Box>
                <TextField
                  variant='outlined'
                  value={params.xAlign.value}
                  onChange={params.xAlign.onChange}
                  disabled={!imageItem.boundToGrid}
                  select
                >
                  <MenuItem key={'left'} value={'left'}>
                    <Icon.AlignHorizontalLeft fontSize='small' />
                  </MenuItem>
                  <MenuItem key={'center'} value={'center'}>
                    <Icon.AlignHorizontalCenter fontSize='small' />
                  </MenuItem>
                  <MenuItem key={'right'} value={'right'}>
                    <Icon.AlignHorizontalRight fontSize='small' />
                  </MenuItem>
                </TextField>
                <TextField
                  variant='outlined'
                  value={params.yAlign.value}
                  onChange={params.yAlign.onChange}
                  disabled={!imageItem.boundToGrid}
                  select
                >
                  <MenuItem key={'top'} value={'top'}>
                    <Icon.VerticalAlignTop fontSize='small' />
                  </MenuItem>
                  <MenuItem key={'center'} value={'center'}>
                    <Icon.VerticalAlignCenter fontSize='small' />
                  </MenuItem>
                  <MenuItem key={'bottom'} value={'bottom'}>
                    <Icon.VerticalAlignBottom fontSize='small' />
                  </MenuItem>
                </TextField>
              </Box>
            </div>
          </AccordionDetails>
          <Divider light />
          <GroupSegment
            id={imageItem.group?.id}
            index={imageItem.group?.index}
            groups={groups}
            onGroupChange={handleGroupChange}
            onGroupIndexChange={handleGroupIndexChange}
          />
          <Divider light />
          <AccordionDetails>
            <Button
              variant="outlined"
              size="small"
              startIcon={<Icon.EditOutlined fontSize="small" />}
              onClick={handleModelViewerOpen}
            >
              Rediger visning
            </Button>
            <Button
              variant="outlined"
              size="small"
              startIcon={<Icon.DeleteOutlined fontSize="small" />}
              onClick={() => onRemove(index)}
            >
              Slett bilde
            </Button>
          </AccordionDetails>
        </>
      )}
    </>
  );
};