import { useEffect, useMemo, useRef, useState } from 'react';
import { useThree } from '@react-three/fiber';
import { getSceneElementByName, getPlaneSize, setCursor, areVectorsEqual,
  areVectorsEqualOnOneOrMoreAxis, isCanvasItem } from '../../helpers';
import { CanvasItemTypes, CursorTypes } from '../../helpers/enums';
import { useCanvasItemEditor } from '../Canvases/useCanvasItemEditor';
import { GroupIndexMarker } from '../../components/GroupIndexMarker';
import { Image } from '../Images/Image';
import { Buttons } from './Buttons';
import { SET_CANVAS_SELECTION, SET_OUTLINE_CANVAS, SET_ROTATION_ENABLED,
  useThreeDDispatch, useThreeDState } from '../App/ThreeDContext';

export const ImageEditor = ({
  imageName,
  imageItem,
  canvasName,
  canvasIndex,
  canvasWidth,
  canvasHeight,
  cellSize,
  index,
  groups,
  onUpdate,
  onRemove,
  onGroupUpdate,
}) => {
  const { showGroups, canvasSelection, outlineCanvas } = useThreeDState();
  const dispatch = useThreeDDispatch();
  const { scene } = useThree();
  const [dragPosition, setDragPosition] = useState(imageItem.position);
  const [pointerDown, setPointerDown] = useState(false);
  const [positionOffset, setPositionOffset] = useState();
  const [dragMode, setDragMode] = useState(false);
  const canvas = getSceneElementByName(scene.children, canvasName);
  const image = getSceneElementByName(scene.children, imageName);
  const canvasItemEditor = useCanvasItemEditor({
    canvas,
    canvasItem: image,
    canvasSize: { height: canvasHeight, width: canvasWidth },
    cellSize,
  });
  const point = useRef();

  const isSelected = useMemo(() => {
    return canvasSelection?.canvasIndex === canvasIndex
      && canvasSelection?.itemType === CanvasItemTypes.IMAGE
      && canvasSelection?.itemIndex === index;
  }, [canvasSelection, canvasIndex, index]);

  useEffect(() => {
    if (isSelected) {
      updateOutlineCanvas();
      !canvasSelection.getSceneElements && updateCanvasSelection();
    }
  });

  useEffect(() => {
    isSelected && updateCanvasSelection();
  }, [groups.length, imageItem.group?.id, showGroups]);

  useEffect(() => {
    setDragPosition(imageItem.position);
  }, [imageItem.position, imageItem.position.x, imageItem.position.y, imageItem.position.z]);

  const updateOutlineCanvas = () => {
    if (outlineCanvas !== imageName) {
      dispatch({ type: SET_OUTLINE_CANVAS, outlineCanvas: imageName });
    }
  };

  const updateCanvasSelection = () => {
    dispatch({
      type: SET_CANVAS_SELECTION,
      canvasSelection: {
        canvasIndex: canvasIndex,
        itemIndex: index,
        itemType: CanvasItemTypes.IMAGE,
        onUpdate: onUpdate,
        onRemove: onRemove,
        onGroupUpdate: onGroupUpdate,
        getSceneElements: () => ({
          canvasRef: canvas ?? getSceneElementByName(scene.children, canvasName),
          itemRef: getSceneElementByName(scene.children, imageName),
          rescaleButtonsRef: getSceneElementByName(scene.children, `${imageName}-buttons`),
          gimRef: getSceneElementByName(scene.children, `${imageName}-gim`),
        }),
      },
    });
  };

  const handleClick = (event) => {
    if (!isCanvasItem(event)) return null;
    const intersection = event.intersections.reduce((acc, obj) => (obj.distance < acc.distance ? obj : acc));

    if (event.button === 0 && !dragMode && intersection.object === event.object && point.current?.x === event.screenX && point.current?.y === event.screenY) {
      updateOutlineCanvas();
      !isSelected && updateCanvasSelection();
    }
  };

  const handlePointerDown = (event) => {
    event.stopPropagation();
    const intersection = event.intersections.reduce((acc, obj) => (obj.distance < acc.distance ? obj : acc));

    if (intersection.object === event.object && event.button === 0 && isSelected) {
      const localPosition = image.worldToLocal(event.point.clone());
      setPositionOffset({ x: localPosition.x, y: localPosition.y });
      dispatch({ type: SET_ROTATION_ENABLED, rotationEnabled: false });
      setPointerDown(true);
      setCursor(CursorTypes.MOVE);
    }

    point.current = { x: parseInt(event.screenX), y: parseInt(event.screenY) };
  };

  const handlePointerUp = (event) => {
    event.stopPropagation();

    if (event.button === 0 && isSelected) {
      dispatch({ type: SET_ROTATION_ENABLED, rotationEnabled: true });
      setPointerDown(false);
      setPositionOffset(null);
      setCursor(CursorTypes.GRAB);

      if (dragMode) {
        const imageSize = getPlaneSize(image);
        canvasItemEditor.applyPositionOffset2D(event.point, positionOffset);
        imageItem.boundToGrid && canvasItemEditor.snapPositionToGrid(event.point, imageSize);
        canvasItemEditor.applyPositionLimits(event.point, imageSize);

        if (!areVectorsEqual(imageItem.position, event.point)) {
          imageItem.position.copy(event.point);
          onUpdate(index, imageItem);
        }
      }

      updateOutlineCanvas();
    }

    pointerDown && setPointerDown(false);
    dragMode && setDragMode(false);
  };

  const handlePointerMove = (event) => {
    if (event.buttons === 1) {
      if (pointerDown && dragMode && areVectorsEqualOnOneOrMoreAxis(imageItem.position, event.point)) {
        const imageSize = getPlaneSize(image);
        canvasItemEditor.applyPositionOffset2D(event.point, positionOffset);
        imageItem.boundToGrid && canvasItemEditor.snapPositionToGrid(event.point, imageSize);
        canvasItemEditor.applyPositionLimits(event.point, imageSize);
        setDragPosition(event.point);
      }

      pointerDown && !dragMode && setDragMode(true);
    }
  };

  const handleRescale = (point, direction, last) => {
    const minimumScale = 0.1;
    const localPoint = image.worldToLocal(point.clone());

    if ((direction === 'topLeft' && (localPoint.x > 0 || localPoint.y < 0)) ||
      (direction === 'topRight' && (localPoint.x < 0 || localPoint.y < 0)) ||
      (direction === 'bottomLeft' && (localPoint.x > 0 || localPoint.y > 0)) ||
      (direction === 'bottomRight' && (localPoint.x < 0 || localPoint.y > 0))
    ) {
      localPoint.setX(0);
      localPoint.setY(0);
    };

    const imageSize = getPlaneSize(image);
    const localPosition = image.worldToLocal(imageItem.position.clone());
    const scale = imageItem.boundToGrid ? Math.round(imageItem.scale / cellSize) * cellSize : imageItem.scale;
    const width = scale * Math.min(imageSize.width / imageSize.height, 1);
    const height = scale * Math.min(imageSize.height / imageSize.width, 1);
    const positionDiff = localPosition.clone().sub(localPoint);
    const positionDiffX = Math.abs(positionDiff.x) - (width / 2);
    const positionDiffY = Math.abs(positionDiff.y) - (height / 2);
    const scaleDiffX = positionDiffX / width * scale;
    const scaleDiffY = positionDiffY / height * scale;
    let scaleDiff = Math.min(scaleDiffX, scaleDiffY);
    imageItem.boundToGrid && (scaleDiff = Math.round(scaleDiff / cellSize) * cellSize);

    const pos = canvas.worldToLocal(imageItem.position.clone());
    const scaleLimit = canvasItemEditor.getScaleLimit(pos, imageItem.scale, imageSize, direction);
    scaleDiff = scale + (scaleDiff * 2) > scaleLimit ? (scaleLimit - scale) / 2 : scaleDiff;
    scaleDiff = scale + scaleDiff > minimumScale ? scaleDiff : (minimumScale - scale) / 2;

    const positionChangeX = scaleDiff * (width / scale) / 2;
    const positionChangeY = scaleDiff * (height / scale) / 2;

    if (scaleDiff !== 0 || last) {
      switch (direction) {
        case 'topLeft':
          localPosition.setX(localPosition.x - positionChangeX);
          localPosition.setY(localPosition.y + positionChangeY);
          break;
        case 'topRight':
          localPosition.setX(localPosition.x + positionChangeX);
          localPosition.setY(localPosition.y + positionChangeY);
          break;
        case 'bottomLeft':
          localPosition.setX(localPosition.x - positionChangeX);
          localPosition.setY(localPosition.y - positionChangeY);
          break;
        case 'bottomRight':
          localPosition.setX(localPosition.x + positionChangeX);
          localPosition.setY(localPosition.y - positionChangeY);
          break;
        default:
          break;
      }

      const adjustedPosition = image.localToWorld(localPosition);
      setDragPosition(adjustedPosition);
      imageItem.position.copy(adjustedPosition);
      imageItem.scale += scaleDiff;
      last && onUpdate(index, imageItem);
    }
  };

  return (
    <>
      {isSelected && !dragMode && (
        <Buttons
          position={dragPosition}
          rotation={imageItem.rotation}
          scale={imageItem.scale}
          imageName={imageName}
          onRescale={handleRescale}
        />
      )}
      <group
        onClick={handleClick}
        onPointerDown={handlePointerDown}
        onPointerUp={handlePointerUp}
        onPointerMove={handlePointerMove}
      >
        {/* <Suspense fallback={null}> */}
          <Image
            {...imageItem}
            name={imageName}
            position={dragPosition}
            scale={imageItem.scale}
            editMode={true}
            addCanvasDepth={false}
          />
        {/* </Suspense> */}
        {isSelected && dragMode &&
          <mesh
            visible={false}
            position={dragPosition}
            rotation={imageItem.rotation}
          >
            <planeGeometry args={[100, 100]} />
            <meshBasicMaterial transparent opacity={0} depthWrite={false} />
          </mesh>
        }
      </group>
      {showGroups && imageItem.group && (
        <GroupIndexMarker
          name={imageName}
          text={imageItem.group.index + 1}
          color={groups.find(group => group.id === imageItem.group.id)?.color}
          position={dragPosition}
          rotation={imageItem.rotation}
          scale={imageItem.scale}
        />
      )}
    </>
  );
};
