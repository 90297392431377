import * as THREE from 'three';
import { useMemo, useLayoutEffect, useRef } from 'react';
import { getDmsUrl } from '@ekultur/dms-url-generator';
import { useLoader } from '@react-three/fiber';
import { useSpring } from '@react-spring/three';
import { SVGLoader } from 'three/examples/jsm/loaders/SVGLoader';
import { CursorTypes } from '../helpers/enums';
import { setCursor } from '../helpers';

export const NavigationIcon = ({
  position,
  scale = new THREE.Vector3(1, 1, 1),
  hoverEffect = false,
  onClick,
}) => {
  const iconGroup = useRef();
  const scaleVector = new THREE.Vector3(0.0025 * scale.x, 0.0025 * scale.y, hoverEffect || onClick ? 1 : 0);
  const { xml, paths } = useLoader(
    SVGLoader,
    `https://ems.dimu.org/image/0138Yu3gxJqf?mediaType=image/svg%2Bxml`
  );

  const { 1: springApi } = useSpring(() => ({
    scale: 1,
    config: {
      duration: 100,
    },
    onChange: (res) => {
      iconGroup.current.scale.copy(scaleVector.clone().multiplyScalar(res.value.scale));
    },
  }));

  const handlePointerEnter = () => {
    springApi.start({ scale: 1.1 });
    setCursor(CursorTypes.POINTER);
  };

  const handlePointerLeave = () => {
    springApi.start({ scale: 1 });
    setCursor(CursorTypes.DEFAULT);
  };

  const shapes = useMemo(
    () =>
      paths.flatMap((path, index) =>
        path.toShapes(true).map(shape => ({
          index,
          shape,
          color: path.color,
          opacity: path.userData.style.opacity,
        }))
      ),
    [paths]
  );

  const Shape = ({ shape, color, opacity, index }) => {
    const geometry = useRef();

    useLayoutEffect(() => {
      geometry.current.translate(
        xml.viewBox.baseVal.width / -2,
        xml.viewBox.baseVal.height / -2,
        0
      );
    });

    return (
      <mesh renderOrder={3}>
        <shapeGeometry
          ref={geometry}
          attach="geometry"
          args={[shape]}
        />
        <meshBasicMaterial
          attach="material"
          color={color}
          opacity={opacity}
          side={THREE.DoubleSide}
          transparent
          polygonOffset
          polygonOffsetFactor={index * -0.5}
        />
      </mesh>
    );
  };

  return (
    <group
      ref={iconGroup}
      scale={scaleVector}
      rotation={[Math.PI / 2, 0, 0]}
      position={position}
      onClick={onClick}
      onPointerEnter={() => hoverEffect && handlePointerEnter()}
      onPointerLeave={() => hoverEffect && handlePointerLeave()}
    >
      {shapes.map((props, key) => (
        <Shape key={key} {...props} />
      ))}
    </group>
  );
};
