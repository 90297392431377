import {ThreeDProvider} from './ThreeDContext';
import {DndProvider} from 'react-dnd';
import {HTML5Backend} from 'react-dnd-html5-backend';
import {Room3d} from '../Room3d';
import {useAddCanvasIdIfMissing} from "../Canvases/useAddCanvasIdIfMissing";

export const App = ({
                        data,
                        adminMode = false,
                        onClose,
                        onChange,
                        onMediaSelect,
                    }) => {

    const dataWithCanvasId = useAddCanvasIdIfMissing(data)

    return (
        <DndProvider backend={HTML5Backend}>
            <ThreeDProvider
                data={dataWithCanvasId}
                adminMode={adminMode}
                onClose={onClose}
                onChange={onChange}
                onMediaSelect={onMediaSelect}
            >
                <Room3d/>
            </ThreeDProvider>
        </DndProvider>
    );
};
