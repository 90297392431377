export const demo25 = {
    adminMode: true,
    room: {
        model: 'OutdoorRoom',
        floorTextureDmsId: '013AkP9FrDJd',
        dimensions: {
            x: 150,
            y: 200,
        },
        dome: {
            skyShader: {
                distance: 10000,
                elevation: 2,
                azimuth: -20,
                turbidity: 0.5,
                rayleigh: 4,
                mieCoefficient: 0.005,
                mieDirectionalG: 0.999,
                exposure: 0.4,
            },
        },
    },
    startPosition: { x: 45, y: 1.75, z: 0 },
    directionOfView: { x: -0.001, y: 0, z: 0 },
    lights: [
        {
            type: 'ambient',
            intensity: 0.4,
        },
    ],
    canvases: [
        {
            type: 'Floor',
            position: { x: 0, y: 0.002, z: 0 },
            rotation: { x: Math.PI / -2, y: 0, z: 0 },
            dimensions: {
                cellSize: 1,
                columns: 75,
                rows: 100,
            },
        },
    ],
}