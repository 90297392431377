import cx from "classnames";
import { createUseStyles } from "react-jss";
import PropTypes from "prop-types";

const useStyles = createUseStyles(
    {
        button: {
            minWidth: 48,
            width: "fit-content",
            display: "flex",
            flexDirection: (props) => (props.iconRight ? "row-reverse" : "row"),
            justifyContent: "center",
            alignItems: "center",
            cursor: "pointer",
            "&.type-normal": {
                height: 48,
                borderRadius: 999,
                color: "black",
                backgroundColor: (props) => props.color || "transparent",
                border: "none",
                padding: (props) => (props.label ? "11px 20px" : "0"),
                "&:hover": {
                    backgroundColor: "white",
                },
                "&:focus": {
                    border: "2px solid black",
                },
                "&:active": { opacity: 0.7 },
            },
            "&.type-large": {
                height: 72,
                borderRadius: 999,
                color: "black",
                backgroundColor: (props) => props.color || "#FF990E",
                border: "none",
                padding: (props) => (props.label ? "23px 32px" : "0"),
                "&:hover": {
                    opacity: 0.7,
                },
                "&:focus": {
                    border: "2px solid black",
                },
                "&:active": { opacity: 1.2 },
            },
            "&.type-outline": {
                height: 48,
                borderRadius: 999,
                color: "black",
                backgroundColor: (props) => props.color || "transparent",
                padding: (props) => (props.label ? "11px 20px" : "0"),
                border: "2px solid black",
                "&:hover": {
                    backgroundColor: "white",
                },
            },
        },
        label: {
            fontSize: "16px",
            lineHeight: "16px",
            paddingLeft: ({ label, Icon }) => (label && Icon ? "9px" : 0),
            cursor: "pointer",
        },
    },
    { name: "vm" }
);

export const Button = (props) => {
    const { label, Icon, color, type, iconRight, className, ...rest } = props;
    const style = useStyles(props);
    return (
        <button
            className={cx(style.button, className, `type-${type}`)}
            {...rest}
        >
            {Icon && Icon}
            {label && <label className={style.label}>{label}</label>}
        </button>
    );
};

Button.propTypes = {
    label: PropTypes.string,
    Icon: PropTypes.node,
    color: PropTypes.string,
    iconRight: PropTypes.bool,
    type: PropTypes.oneOf(["normal", "outline", "large"]),
    className: PropTypes.string,
};

Button.defaultProps = {
    label: "",
    type: "normal",
    iconRight: false,
    className: "",
};
