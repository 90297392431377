import { Clock } from 'three';

export class Stopwatch {
  constructor() {
    this.state = {
      running: false,
      elapsedTime: 0,
      clock: new Clock(),
    };
  };

  start = () => {
    if (!this.state.running) {
      this.state.running = true;
      this.state.clock.start();
    }
  };

  stop = () => {
    this.state.running = false;
    this.state.clock.stop();
    this.state.elapsedTime += this.state.clock.getElapsedTime();
  };

  reset = () => {
    this.state.running = false;
    this.state.clock.stop();
    this.state.elapsedTime = 0;
  };

  elapsedTime = () => {
    return this.state.elapsedTime + (this.state.running ? this.state.clock.getElapsedTime() : 0);
  };
};
