import * as THREE from 'three';
import { Suspense } from 'react';
import { Text } from '@react-three/drei';
import { Box, useReflow } from '@react-three/flex';
import { CanvasItemTypes } from '../../helpers/enums';

export const Paragraph = ({
  name,
  text = '',
  fontFamily,
  fontSize,
  color = '#000000',
  textAlign = 'left',
  lineHeight = 1,
  maxWidth,
  margin,
}) => {
  const reflow = useReflow();
  const linearColor = new THREE.Color().set(color).convertSRGBToLinear();

  return (
    <Box
      marginTop={margin?.top}
      marginBottom={margin?.bottom}
      marginLeft={textAlign === 'right' ? maxWidth : 0}
      marginRight={textAlign === 'left' ? maxWidth : 0}
      width={maxWidth}
      centerAnchor
    >
      <Suspense fallback={null}>
        <Text
          text={text}
          font={fontFamily}
          fontSize={fontSize}
          color={linearColor}
          maxWidth={maxWidth}
          lineHeight={lineHeight}
          textAlign={textAlign}
          anchorX={textAlign}
          renderOrder={4}
          onSync={() => reflow()}
          userData={{
            itemName: name,
            itemType: CanvasItemTypes.TEXT,
          }}
        >
          <meshStandardMaterial />
        </Text>
      </Suspense>
    </Box>
  );
};
