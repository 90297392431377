import * as THREE from "three";
import {getGroupItems} from "../group/groups";

export const convertDataToThreeJS = scene => {
    const sceneCopy = JSON.parse(JSON.stringify(scene));
    convertToThreeJsTypes(sceneCopy);
    setGroupData(sceneCopy);
    return sceneCopy;
}

const convertToThreeJsTypes = object => {
    if (object !== null && typeof object === 'object') {
        Object.entries(object).forEach(([key, value]) => {
            if (['position', 'startPosition', 'scale'].includes(key) && !Array.isArray(value) && typeof value === 'object') {
                convertFloatToVector3(value, object, key);
            }
            else if (['rotation', 'directionOfView'].includes(key) && !Array.isArray(value) && typeof value === 'object') {
                convertFloatToEuler(value, object, key);
            }
            else {
                convertToThreeJsTypes(value);
            }
        });
    }

    return object;
}

const convertFloatToVector3 = (value, object, key) => {
    Object.keys(value).forEach((k) => {
        if (!isNaN(parseFloat(value[k]))) {
            value[k] = parseFloat(value[k]);
        }
    });
    object[key] = Object.assign(new THREE.Vector3(), value);
}

const convertFloatToEuler = (value, object, key) => {
    Object.keys(value).forEach((k) => {
        if (!isNaN(parseFloat(value[k]))) {
            value[k] = parseFloat(value[k]);
        }
    });
    object[key] = Object.assign(new THREE.Euler(), value);
}

const setGroupData = data => {
    data.groups = data.groups
        ? data.groups.map((group, index) =>
            ({
                ...group,
                id: index,
                itemCount: getGroupItems(data, index).length,
            }))
        : [];
}
