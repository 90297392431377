import { useState } from 'react';
import { DragBox } from './DragBox';
import { DragItem } from './DragItem';
import { IncludedGroup } from './IncludedGroup';

export const IncludedGroups = ({
  groups,
  onGroupDrag,
  onGroupDrop,
  onGroupReset,
  onCameraPlacementChange,
}) => {
  const [expandedIndex, setExpandedIndex] = useState();

  const handleExpansionChange = (index) => {
    setExpandedIndex(expandedIndex !== index ? index : null);
  };

  const handleGroupDrag = (groupId, hoverIndex) => {
    setExpandedIndex(null);
    onGroupDrag(groupId, hoverIndex);
  };

  return (
    <>
      <div>
        {groups.map((group, index) =>
          <DragItem
            key={group.id}
            id={group.id}
            index={index}
            onGroupDrag={handleGroupDrag}
            onGroupDrop={onGroupDrop}
            onGroupReset={onGroupReset}
          >
            <IncludedGroup
              {...group}
              index={index}
              isExpanded={expandedIndex === index}
              onExpansionChange={handleExpansionChange}
              onCameraPlacementChange={onCameraPlacementChange}
            />
          </DragItem>
        )}
      </div>
      {groups.length === 0 &&
        <DragBox
          text={'Dra grupper hit, eller klikk på pluss-symbolet ved gruppenavnet'}
          index={-1}
          onGroupDrag={onGroupDrag}
        />
      }
    </>
  );
};
