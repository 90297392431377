export const demo23 = {
    adminMode: true,
    roomModelDmsId: '019EBwErxb1aU',
    startPosition: { x: -9, y: 1.75, z: 0 },
    directionOfView: { x: 0.001, y: 0, z: 0 },
    lights: [
        {
            type: 'ambient',
            intensity: 1,
        },
    ],
    canvases: [
        {
            type: 'Wall',
            position: { x: 9.985, y: 3, z: 0 },
            rotation: { x: 0, y: Math.PI / -2, z: 0 },
            dimensions: {
                cellSize: 0.5,
                columns: 80,
                rows: 12,
            },
        },
        {
            type: 'Wall',
            position: { x: -9.985, y: 3, z: 0 },
            rotation: { x: 0, y: Math.PI / 2, z: 0 },
            dimensions: {
                cellSize: 0.5,
                columns: 80,
                rows: 12,
            },
        },
        {
            type: 'Wall',
            position: { x: 0, y: 3, z: -19.98 },
            rotation: { x: 0, y: 0, z: 0 },
            dimensions: {
                cellSize: 0.5,
                columns: 40,
                rows: 12,
            },
        },
        {
            type: 'Wall',
            position: { x: 0, y: 3, z: 19.98 },
            rotation: { x: 0, y: Math.PI, z: 0 },
            dimensions: {
                cellSize: 0.5,
                columns: 40,
                rows: 12,
            },
        },
        {
            type: 'Wall',
            position: { x: 5, y: 2.15, z: -8.75 },
            rotation: { x: 0, y: Math.PI / 2, z: 0 },
            dimensions: {
                cellSize: 0.5,
                columns: 21,
                rows: 9,
            },
        },
        {
            type: 'Wall',
            position: { x: 5, y: 2.15, z: 8.75 },
            rotation: { x: 0, y: Math.PI / 2, z: 0 },
            dimensions: {
                cellSize: 0.5,
                columns: 21,
                rows: 9,
            },
        },
        {
            type: 'Wall',
            position: { x: -5, y: 2.15, z: -8.75 },
            rotation: { x: 0, y: Math.PI / -2, z: 0 },
            dimensions: {
                cellSize: 0.5,
                columns: 21,
                rows: 9,
            },
        },
        {
            type: 'Wall',
            position: { x: -5, y: 2.15, z: 8.75 },
            rotation: { x: 0, y: Math.PI / -2, z: 0 },
            dimensions: {
                cellSize: 0.5,
                columns: 21,
                rows: 9,
            },
        },
        {
            type: 'Wall',
            position: { x: 4.79, y: 2.125, z: -8.75 },
            rotation: { x: 0, y: Math.PI / -2, z: 0 },
            dimensions: {
                cellSize: 0.505,
                columns: 20,
                rows: 9,
            },
        },
        {
            type: 'Wall',
            position: { x: 4.79, y: 2.125, z: 8.75 },
            rotation: { x: 0, y: Math.PI / -2, z: 0 },
            dimensions: {
                cellSize: 0.505,
                columns: 20,
                rows: 9,
            },
        },
        {
            type: 'Wall',
            position: { x: -4.79, y: 2.125, z: -8.75 },
            rotation: { x: 0, y: Math.PI / 2, z: 0 },
            dimensions: {
                cellSize: 0.505,
                columns: 20,
                rows: 9,
            },
        },
        {
            type: 'Wall',
            position: { x: -4.79, y: 2.125, z: 8.75 },
            rotation: { x: 0, y: Math.PI / 2, z: 0 },
            dimensions: {
                cellSize: 0.505,
                columns: 20,
                rows: 9,
            },
        },
        {
            type: 'Wall',
            position: { x: 3.147, y: 2.12, z: -3.495 },
            rotation: { x: 0, y: 0, z: 0 },
            dimensions: {
                cellSize: 0.529,
                columns: 7,
                rows: 8,
            },
        },
        {
            type: 'Wall',
            position: { x: 3.147, y: 2.12, z: 3.495 },
            rotation: { x: 0, y: Math.PI, z: 0 },
            dimensions: {
                cellSize: 0.529,
                columns: 7,
                rows: 8,
            },
        },
        {
            type: 'Wall',
            position: { x: -3.147, y: 2.12, z: 14.005 },
            rotation: { x: 0, y: 0, z: 0 },
            dimensions: {
                cellSize: 0.529,
                columns: 7,
                rows: 8,
            },
        },
        {
            type: 'Wall',
            position: { x: -3.147, y: 2.12, z: -14.005 },
            rotation: { x: 0, y: Math.PI, z: 0 },
            dimensions: {
                cellSize: 0.529,
                columns: 7,
                rows: 8,
            },
        },
        {
            type: 'Wall',
            position: { x: -3.047, y: 2.145, z: -13.787 },
            rotation: { x: 0, y: 0, z: 0 },
            dimensions: {
                cellSize: 0.5,
                columns: 7,
                rows: 9,
            },
        },
        {
            type: 'Wall',
            position: { x: -3.047, y: 2.145, z: 13.787 },
            rotation: { x: 0, y: Math.PI, z: 0 },
            dimensions: {
                cellSize: 0.5,
                columns: 7,
                rows: 9,
            },
        },
        {
            type: 'Wall',
            position: { x: 3.047, y: 2.145, z: 3.705 },
            rotation: { x: 0, y: 0, z: 0 },
            dimensions: {
                cellSize: 0.5,
                columns: 7,
                rows: 9,
            },
        },
        {
            type: 'Wall',
            position: { x: 3.047, y: 2.145, z: -3.705 },
            rotation: { x: 0, y: Math.PI, z: 0 },
            dimensions: {
                cellSize: 0.5,
                columns: 7,
                rows: 9,
            },
        },
        {
            type: 'Wall',
            position: { x: -3.899, y: 2.195, z: 3.49 },
            rotation: { x: 0, y: Math.PI, z: 0 },
            dimensions: {
                cellSize: 0.55,
                columns: 4,
                rows: 8,
            },
        },
        {
            type: 'Wall',
            position: { x: -3.899, y: 2.195, z: -3.49 },
            rotation: { x: 0, y: 0, z: 0 },
            dimensions: {
                cellSize: 0.55,
                columns: 4,
                rows: 8,
            },
        },
        {
            type: 'Wall',
            position: { x: 3.895, y: 2.195, z: -14.005 },
            rotation: { x: 0, y: Math.PI, z: 0 },
            dimensions: {
                cellSize: 0.55,
                columns: 4,
                rows: 8,
            },
        },
        {
            type: 'Wall',
            position: { x: 3.895, y: 2.195, z: 14.005 },
            rotation: { x: 0, y: 0, z: 0 },
            dimensions: {
                cellSize: 0.55,
                columns: 4,
                rows: 8,
            },
        },
        {
            type: 'Wall',
            position: { x: 3.895, y: 2.195, z: 13.787 },
            rotation: { x: 0, y: Math.PI, z: 0 },
            dimensions: {
                cellSize: 0.55,
                columns: 4,
                rows: 8,
            },
        },
        {
            type: 'Wall',
            position: { x: 3.895, y: 2.195, z: -13.787 },
            rotation: { x: 0, y: 0, z: 0 },
            dimensions: {
                cellSize: 0.55,
                columns: 4,
                rows: 8,
            },
        },
        {
            type: 'Wall',
            position: { x: -3.895, y: 2.195, z: -3.705 },
            rotation: { x: 0, y: Math.PI, z: 0 },
            dimensions: {
                cellSize: 0.55,
                columns: 4,
                rows: 8,
            },
        },
        {
            type: 'Wall',
            position: { x: -3.895, y: 2.195, z: 3.705 },
            rotation: { x: 0, y: 0, z: 0 },
            dimensions: {
                cellSize: 0.55,
                columns: 4,
                rows: 8,
            },
        },
        {
            type: 'Floor',
            position: { x: 3.04, y: 0.01, z: 8.75 },
            rotation: { x: Math.PI / -2, y: 0, z: 0 },
            dimensions: {
                cellSize: 0.504,
                columns: 7,
                rows: 20,
            },
        },
        {
            type: 'Floor',
            position: { x: -3.04, y: 0.01, z: 8.75 },
            rotation: { x: Math.PI / -2, y: 0, z: 0 },
            dimensions: {
                cellSize: 0.504,
                columns: 7,
                rows: 20,
            },
        },
        {
            type: 'Floor',
            position: { x: 3.04, y: 0.01, z: -8.75 },
            rotation: { x: Math.PI / -2, y: 0, z: 0 },
            dimensions: {
                cellSize: 0.504,
                columns: 7,
                rows: 20,
            },
        },
        {
            type: 'Floor',
            position: { x: -3.04, y: 0.01, z: -8.75 },
            rotation: { x: Math.PI / -2, y: 0, z: 0 },
            dimensions: {
                cellSize: 0.504,
                columns: 7,
                rows: 20,
            },
        },
    ],
}