import {createUseStyles} from 'react-jss';

const useStyles = createUseStyles({
    betaLogo: {
        filter: "drop-shadow(3px 3px 3px rgba(0, 0, 0, 0.7))",
        position: "absolute",
        top: "12px",
        left: "12px",
        zIndex: 20
    },
})

export const BetaLogo = () => {
    const classes = useStyles()
    return (
        <div className={classes.betaLogo}>
            <a href={"https://dok.digitaltmuseum.org/virtuelle-opplevelser"} target={"_blank"} rel="noreferrer">
                <img src={"https://dms-cf-01.dimu.org/image/019EBvjs37nME?mediaType=image/svg%2Bxml"} alt={"beta version of app"}/>
            </a>
        </div>
    )
}
