export const demo24 = {
    adminMode: false,
    room: {
        model: 'OutdoorRoom',
        floorTextureDmsId: '013AkP9FrDJd',
        dimensions: {
            x: 150,
            y: 200,
        },
        dome: {
            skyShader: {
                distance: 10000,
                elevation: 50,
                azimuth: -55,
                turbidity: 1.0,
                rayleigh: 0.5,
                mieCoefficient: 0.1,
                mieDirectionalG: 0.999,
                exposure: 0.8,
            },
        },
    },
    startPosition: { x: 40, y: 1.75, z: 0 },
    directionOfView: { x: -0.001, y: 0.0002, z: 0 },
    lights: [
        {
            type: 'ambient',
            intensity: 0.5,
        },
    ],
    models: [
        {
            dmsId: '0138WvJrNUWb',
            position: { x: 0, y: 21.65, z: -35 },
            rotation: { x: 0, y: Math.PI / 2, z: 0 },
            scale: { x: 10, y: 10, z: 10 },
            canvasIndex: 0,
            boundToGrid: true,
            viewerData: {
                model: {
                    type: 'Object',
                    dmsId: '0138WvJrNUWb',
                },
            },
        },
        {
            dmsId: '0138WvJymkBb',
            position: { x: 0, y: 7.442, z: 35 },
            rotation: { x: 0, y: Math.PI, z: 0 },
            scale: { x: 9, y: 9, z: 9 },
            canvasIndex: 0,
            boundToGrid: true,
            viewerData: {
                model: {
                    type: 'Object',
                    dmsId: '0138WvJymkBb',
                },
            },
        },
    ],
    images: [
        {
            dmsId: '013AkPi6vJRf',
            scale: 2.25,
            position: { x: 8.49, y: 1.9, z: 38.25 },
            rotation: { x: 0, y: 4.427, z: 0 },
            addCanvasDepth: true,
            boundToGrid: false,
            viewerData: {
                model: {
                    type: 'ImageSingle',
                    dmsId: '013AkPi6vJRf',
                },
            },
        },
        {
            dmsId: '013AkPi6vJRd',
            scale: 2.262,
            position: { x: 7.62, y: 1.9, z: 41.25 },
            rotation: { x: 0, y: 4.427, z: 0 },
            addCanvasDepth: true,
            boundToGrid: false,
            viewerData: {
                model: {
                    type: 'ImageSingle',
                    dmsId: '013AkPi6vJRd',
                },
            },
        },
        {
            dmsId: '013AkPi6vJRe',
            scale: 2.25,
            position: { x: 6.73, y: 1.9, z: 44.25 },
            rotation: { x: 0, y: 4.427, z: 0 },
            addCanvasDepth: true,
            boundToGrid: false,
            viewerData: {
                model: {
                    type: 'ImageSingle',
                    dmsId: '013AkPi6vJRe',
                },
            },
        },
        {
            dmsId: '013AkPi6vJjL',
            scale: 4,
            position: { x: 20.18, y: 2.1, z: 25 },
            rotation: { x: -1.15, y: -1.47, z: -1.15 },
            addCanvasDepth: true,
            boundToGrid: false,
            viewerData: {
                model: {
                    type: 'ImageSingle',
                    dmsId: '013AkPi6vJjL',
                },
            },
        },
    ],
    texts: [
        {
            position: { x: 9.510, y: 1.9, z: 34.800 },
            rotation: { x: 0, y: 4.427, z: 0 },
            maxWidth: 2.9,
            paragraphs: [
                {
                    text: 'Tregulvet i hvelvkjelleren',
                    fontSize: 0.25,
                },
                {
                    text: 'I 1988 ble det funnet et svært gammelt tregulv nede i en hvelvkjeller på museet. Gulvet lå i en bygning som ble revet allerede på 1450-tallet. Mot slutten av middelalderen har man foretatt en ombygging av selve bispegården, og huset med tregulvet ble trolig revet i den forbindelse.',
                    fontSize: 0.12,
                    lineHeight: 1.2,
                    margin: {
                        top: 0.2,
                    }
                },
                {
                    text: 'Deretter ble kjellerruinen brukt som avfallsplass, slik at den ble fylt med avfall, stein og jord. I 1987 begynte en utgraving av kjelleren. Under alle jorda og søppelet dukket det gamle gulvet opp. Gulvet er minst 600 år gammelt.',
                    fontSize: 0.12,
                    lineHeight: 1.2,
                    margin: {
                        top: 0.1,
                    }
                },
            ],
            boundToGrid: false,
        },
    ],
    imageSpheres: [
        {
            position: { x: 2.5, y: 2, z: 29 },
            radius: 2.25,
            segments: {
                height: 2,
                width: 5,
            },
            spacing: 0.15,
            thetaOffset: 1.5,
            rotationSpeed: 0.1,
            staggeredLayout: true,
            retainImageAspectRatio: true,
            opacity: 0.9,
            inverted: false,
            images: [
                {
                    dmsId: '013AkPi6vJoh',
                    viewerData: {
                        model: {
                            type: 'ImageSingle',
                            dmsId: '013AkPi6vJoh',
                        },
                    },
                },
                {
                    dmsId: '013AkPi6ve4X',
                    viewerData: {
                        model: {
                            type: 'ImageSingle',
                            dmsId: '013AkPi6ve4X',
                        },
                    },
                },
                {
                    dmsId: '013AkPi6vJjR',
                    viewerData: {
                        model: {
                            type: 'ImageSingle',
                            dmsId: '013AkPi6vJjR',
                        },
                    },
                },
                {
                    dmsId: '013AkPi6vJRk',
                    viewerData: {
                        model: {
                            type: 'ImageSingle',
                            dmsId: '013AkPi6vJRk',
                        },
                    },
                },
                {
                    dmsId: '013AkPi6ve4W',
                    viewerData: {
                        model: {
                            type: 'ImageSingle',
                            dmsId: '013AkPi6ve4W',
                        },
                    },
                },
                {
                    dmsId: '013AkPi6ve4V',
                    viewerData: {
                        model: {
                            type: 'ImageSingle',
                            dmsId: '013AkPi6ve4V',
                        },
                    },
                },
                {
                    dmsId: '013AkPi6ve4R',
                    viewerData: {
                        model: {
                            type: 'ImageSingle',
                            dmsId: '013AkPi6ve4R',
                        },
                    },
                },
                {
                    dmsId: '013AkPi6ve4U',
                    viewerData: {
                        model: {
                            type: 'ImageSingle',
                            dmsId: '013AkPi6ve4U',
                        },
                    },
                },
                {
                    dmsId: '013AkPi6ve4S',
                    viewerData: {
                        model: {
                            type: 'ImageSingle',
                            dmsId: '013AkPi6ve4S',
                        },
                    },
                },
                {
                    dmsId: '013AkPi6ve4T',
                    viewerData: {
                        model: {
                            type: 'ImageSingle',
                            dmsId: '013AkPi6ve4T',
                        },
                    },
                },
            ],
        },
    ],
    canvases: [
        {
            type: 'Floor',
            position: { x: 0, y: 0.002, z: 0 },
            rotation: { x: Math.PI / -2, y: 0, z: 0 },
            dimensions: {
                cellSize: 1,
                columns: 75,
                rows: 100,
            },
        },
    ],
}