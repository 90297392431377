import { useEffect, useRef, useState } from 'react';
import { ChromePicker } from 'react-color';
import { Box, InputAdornment, TextField } from '@mui/material';
import * as Icon from '@mui/icons-material';

export const ColorInput = ({
  title,
  color,
  className,
  error = false,
  onChange,
}) => {
  const refContainer = useRef();
  const refInput = useRef();
  const [pickedColor, setPickedColor] = useState(color);
  const [displayColorPicker, setDisplayColorPicker] = useState(false);

  useEffect(() => {
    if (displayColorPicker) {
      document.addEventListener('keyup', handleKeyUp);
      document.addEventListener('mousedown', handleMouseDown);
    }

    return () => {
      document.removeEventListener('keyup', handleKeyUp);
      document.removeEventListener('mousedown', handleMouseDown);
    };
  }, [displayColorPicker]);

  const handleKeyUp = (event) => {
    if (event.code === 'Escape' || (event.code === 'Tab' && !refInput.current.contains(event.target))) {
      setDisplayColorPicker(false);
    }
  };

  const handleMouseDown = (event) => {
    if (!refContainer.current.contains(event.target) && !refInput.current.contains(event.target)) {
      setDisplayColorPicker(false);
    }
  };

  const handleChange = (color) => {
    setPickedColor(color.hex);
  };

  const handlePointerUp = (event) => {
    if (event.button === 0 && pickedColor !== color) {
      onChange && onChange(pickedColor);
    }
  };

  const handleClick = () => {
    !displayColorPicker && setDisplayColorPicker(true);
  };

  const handleFocus = () => {
    setDisplayColorPicker(true);
  };

  const handleBlur = (event) => {
    const reg = /^#([0-9a-f]{3}){1,2}$/i;

    if (reg.test(event.target.value) && event.target.value !== color) {
      onChange && onChange(event.target.value);
    }
  };

  return (
    <Box
      className={className}
      sx={{
        position: 'relative',
      }}
    >
      {displayColorPicker &&
        <div
          ref={refContainer}
          className='colorPickerContainer'
          onPointerUp={handlePointerUp}
        >
          <ChromePicker
            color={pickedColor ?? '#FFFFFF'}
            onChange={handleChange}
          />
        </div>
      }
      <TextField
        ref={refInput}
        variant='outlined'
        autoComplete='off'
        size='small'
        label={title}
        value={pickedColor ?? ''}
        error={error}
        onClick={handleClick}
        onFocus={handleFocus}
        onBlur={handleBlur}
        InputProps={{
          startAdornment: (
            <InputAdornment position='start'>
              <Icon.Square sx={{color: pickedColor ?? '#FFFFFF'}} />
            </InputAdornment>
          ),
          endAdornment: (
            <InputAdornment position='end'>
              <Icon.ColorLens />
            </InputAdornment>
          ),
        }}
      />
    </Box>
  );
};
