import { useState } from 'react';
import { createUseStyles } from 'react-jss';
import { ExcludedGroups } from './ExcludedGroups';
import { IncludedGroups } from './IncludedGroups';
import { CameraPlacementMode } from '../../helpers/enums';
import { SET_CAMERA_PLACEMENT_MODE, SET_CANVAS_SELECTION, useThreeDDispatch, useThreeDState } from '../../3droom/App/ThreeDContext';

const useStyles = createUseStyles({
  header: {
    margin: '4px 0 4px 12px',
    fontWeight: 'bold',
    '&:last-of-type': {
      marginTop: 20,
    }
  },
});

export const Groups = ({
  groups = [],
  allGroups,
  onChange,
  onCameraPlacementChange,
}) => {
  const { canvasSelection } = useThreeDState();
  const dispatch = useThreeDDispatch();
  const styles = useStyles();
  const groupIndexes = groups.map(group => group.index);

  const getIncludedGroups = () => {
    return allGroups
      .map((group, index) => {
        const includedGroup = groups.find(includedGroup => includedGroup.index === index);
        return {
          ...group,
          ...includedGroup,
        };
      })
      .filter(group => groupIndexes.includes(group.id))
      .sort((a, b) => groupIndexes.indexOf(a.id) - groupIndexes.indexOf(b.id));
  };

  const getExcludedGroups = () => {
    return allGroups.filter(group => !groupIndexes.includes(group.id));
  };

  const [ includedGroups, setIncludedGroups ] = useState(getIncludedGroups());
  const [ excludedGroups, setExcludedGroups ] = useState(getExcludedGroups());

  const handleChange = (groupId) => {
    const isNew = !groups.find(group => group.index === groupId);

    if (isNew) {
      const group = allGroups.find(group => group.id === groupId);
      initCameraPlacementMode(group);
    }

    const updatedGroups = includedGroups.map(includedGroup => {
      const group = groups.find(group => group.index === includedGroup.id);
      return group ?? { index: groupId };
    });

    onChange(updatedGroups);
  };

  const initCameraPlacementMode = (group) => {
    dispatch({
      type: SET_CANVAS_SELECTION,
      canvasSelection: null,
    });
    dispatch({
      type: SET_CAMERA_PLACEMENT_MODE,
      cameraPlacementMode: {
        mode: CameraPlacementMode.EDIT,
        index: includedGroups.indexOf(group),
        name: group.name,
        position: group.position,
        rotation: group.rotation,
        canvasSelection,
        onCameraPlacementChange,
      },
    });
  };

  const handleGroupDrag = (groupId, hoverIndex) => {
    const group = allGroups.find(group => group.id === groupId);
    const isIncluded = groupIndexes.find(groupIndex => groupIndex === groupId) != null;
    const isExcluded = groupIndexes.find(groupIndex => groupIndex === groupId) == null;

    if (isIncluded) {
      if (hoverIndex < includedGroups.length) {
        const excludedGroupIndex = excludedGroups.findIndex(group => group.id === groupId);
        if (excludedGroupIndex >= 0) {
          excludedGroups.splice(excludedGroupIndex, 1);
          setExcludedGroups(() => [...excludedGroups]);
        }

        const includedGroup = groups.find(includedGroup => includedGroup.index === group.id);
        includedGroups.splice(includedGroups.findIndex(group => group.id === groupId), 1);
        includedGroups.splice(hoverIndex, 0, { ...group, ...includedGroup });
        setIncludedGroups(() => [...includedGroups]);
      }
      else if (excludedGroups.length > 0) {
        const excludedGroupIndex = excludedGroups.findIndex(group => group.id === groupId);
        excludedGroupIndex >= 0 && excludedGroups.splice(excludedGroupIndex, 1);
        excludedGroups.splice(hoverIndex - includedGroups.length, 0, group);
        setExcludedGroups(() => [...excludedGroups]);
      }
    }
    else if (isExcluded) {
      if (hoverIndex >= includedGroups.length) {
        const includedGroupIndex = includedGroups.findIndex(group => group.id === groupId);
        if (includedGroupIndex >= 0) {
          includedGroups.splice(includedGroupIndex, 1);
          setIncludedGroups(() => [...includedGroups]);
        }

        excludedGroups.splice(excludedGroups.findIndex(group => group.id === groupId), 1);
        excludedGroups.splice(hoverIndex - includedGroups.length, 0, group);
        setExcludedGroups(() => [...excludedGroups]);
      }
      else if (includedGroups.length > 0) {
        const includedGroupIndex = includedGroups.findIndex(group => group.id === groupId);
        includedGroupIndex >= 0 && includedGroups.splice(includedGroupIndex, 1);
        includedGroups.splice(hoverIndex, 0, group);
        setIncludedGroups(() => [...includedGroups]);
      }
    }
  };

  const handleGroupDrop = (groupId, dropIndex, dragIndex) => {
    if (dropIndex === dragIndex) return;

    const group = allGroups.find(group => group.id === groupId);

    if (dropIndex < 0) {
      includedGroups.push(group);
      excludedGroups.splice(dragIndex, 1);
      setIncludedGroups(() => [...includedGroups]);
      setExcludedGroups(() => [...excludedGroups]);
    }
    else if (dropIndex >= groups.length && excludedGroups.length === 0) {
      excludedGroups.push(group);
      includedGroups.splice(dragIndex, 1);
      setIncludedGroups(() => [...includedGroups]);
      setExcludedGroups(() => [...excludedGroups]);
    }
    else if (dropIndex >= includedGroups.length) {
      includedGroups.splice(dragIndex, 1);
      setIncludedGroups(() => [...includedGroups]);
    }
    else {
      const excludedGroupIndex = excludedGroups.findIndex(group => group.id === groupId);
      if (excludedGroupIndex >= 0) {
        excludedGroups.splice(excludedGroupIndex, 1);
        setExcludedGroups(() => [...excludedGroups]);
      }
    }

    handleChange(groupId);
  };

  const handleGroupReset = () => {
    setIncludedGroups(getIncludedGroups());
    setExcludedGroups(getExcludedGroups());
  };

  const handleGroupAdd = (groupId) => {
    const group = allGroups.find(group => group.id === groupId);
    includedGroups.push(group);
    excludedGroups.splice(excludedGroups.findIndex(group => group.id === groupId), 1);
    setIncludedGroups(() => [...includedGroups]);
    setExcludedGroups(() => [...excludedGroups]);
    handleChange(groupId);
  };

  return (
    <>
      <p className={styles.header}>Grupper i omvisningen</p>
      <IncludedGroups
        groups={includedGroups}
        onGroupDrag={handleGroupDrag}
        onGroupDrop={handleGroupDrop}
        onGroupReset={handleGroupReset}
        onCameraPlacementChange={onCameraPlacementChange}
      />
      <p className={styles.header}>Tilgjengelige grupper</p>
      <ExcludedGroups
        groups={excludedGroups}
        startIndex={includedGroups.length}
        onGroupDrag={handleGroupDrag}
        onGroupDrop={handleGroupDrop}
        onGroupReset={handleGroupReset}
        onGroupAdd={handleGroupAdd}
      />
    </>
  );
};
