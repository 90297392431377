import * as Icon from '@mui/icons-material';
import { AccordionDetails, Button, Checkbox, Divider, FormControlLabel, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { SliderInput } from '../../../components/SliderInput';
import { ImageOrganizerDialog } from '../../ImageSphereEditors/ImageOrganizerDialog';

export const ImageSphereSettings = ({
  index,
  imageSphereItem,
  canvasHeight,
  canvasWidth,
  cellSize,
  onUpdate,
  onRemove,
  getSceneElements,
}) => {
  const [params, setParams] = useState();
  const [showImageGridDialog, setShowImageGridDialog] = useState(false);

  const update = (imageSphere) => {
    setParams(getParams(imageSphere));
    onUpdate && onUpdate(index, imageSphere);
  };

  const getScaleLimit = (localPosition, radius) => {
    const topEdge = localPosition.y + radius;
    const bottomEdge = localPosition.y - radius;
    const rightEdge = localPosition.x + radius;
    const leftEdge = localPosition.x - radius;

    const topLimit = canvasHeight / 2;
    const bottomLimit = canvasHeight / -2;
    const rightLimit = canvasWidth / 2;
    const leftLimit = canvasWidth / -2;

    const topScaleMax = radius + topLimit - topEdge;
    const bottomScaleMax = radius + bottomEdge - bottomLimit;
    const rightScaleMax = radius + rightLimit - rightEdge;
    const leftScaleMax = radius + leftEdge - leftLimit;

    return Math.min(topScaleMax, bottomScaleMax, rightScaleMax, leftScaleMax);
  };

  const getParams = (obj) => {
    imageSphereItem = obj ?? imageSphereItem;
    const { canvasRef, itemRef: imageSphereRef } = getSceneElements();

    if (!canvasRef || !imageSphereRef) return null;

    const localPosition = canvasRef.worldToLocal(imageSphereItem.position.clone());
    const scaleLimit = getScaleLimit(localPosition, imageSphereItem.radius);

    return {
      xPosition: {
        value: imageSphereItem.boundToGrid
          ? (localPosition.x - imageSphereItem.radius + (canvasWidth / 2) + cellSize) / cellSize
          : localPosition.x - imageSphereItem.radius + (canvasWidth / 2),
        min: imageSphereItem.boundToGrid ? 1 : 0,
        max: imageSphereItem.boundToGrid
          ? (canvasWidth / cellSize) - Math.ceil(imageSphereItem.radius * 2 / cellSize) + 1
          : canvasWidth - (imageSphereItem.radius * 2),
        step: imageSphereItem.boundToGrid ? 1 : 0.001,
        onChange: (value, last) => {
          const scaleOffset = (Math.ceil(imageSphereItem.radius * 2 / cellSize) - 1) * cellSize / 2;
          value = imageSphereItem.boundToGrid
            ? ((value - 1) * cellSize) - (((imageSphereItem.radius * 2) - cellSize) / 2) + scaleOffset
            : value;
          const newPosition = localPosition.clone().setX(value + imageSphereItem.radius - (canvasWidth / 2));
          imageSphereItem.position.copy(canvasRef.localToWorld(newPosition));
          imageSphereRef.position.copy(imageSphereItem.position);
          last && update(imageSphereItem);
        },
      },
      yPosition: {
        value: imageSphereItem.boundToGrid
          ? (localPosition.y - imageSphereItem.radius + (canvasHeight / 2) + cellSize) / cellSize
          : localPosition.y - imageSphereItem.radius + (canvasHeight / 2),
        min: imageSphereItem.boundToGrid ? 1 : 0,
        max: imageSphereItem.boundToGrid
          ? (canvasHeight / cellSize) - Math.ceil(imageSphereItem.radius * 2 / cellSize) + 1
          : canvasHeight - (imageSphereItem.radius * 2),
        step: imageSphereItem.boundToGrid ? 1 : 0.001,
        onChange: (value, last) => {
          const scaleOffset = (Math.ceil(imageSphereItem.radius * 2 / cellSize) - 1) * cellSize / 2;
          value = imageSphereItem.boundToGrid
            ? ((value - 1) * cellSize) - (((imageSphereItem.radius * 2) - cellSize) / 2) + scaleOffset
            : value;
          const newPosition = localPosition.clone().setY(value + imageSphereItem.radius - (canvasHeight / 2));
          imageSphereItem.position.copy(canvasRef.localToWorld(newPosition));
          imageSphereRef.position.copy(imageSphereItem.position);
          last && update(imageSphereItem);
        },
      },
      zPosition: {
        value: localPosition.z - imageSphereItem.radius,
        min: 0,
        max: 5,
        step: 0.001,
        onChange: (value, last) => {
          const newPosition = localPosition.clone().setZ(value + imageSphereItem.radius);
          imageSphereItem.position.copy(canvasRef.localToWorld(newPosition));
          imageSphereRef.position.copy(imageSphereItem.position);
          last && update(imageSphereItem);
        },
      },
      widthSegments: {
        value: imageSphereItem.segments.width,
        min: 1,
        max: 10,
        step: 1,
        onChange: (value, last) => {
          if (last) {
            imageSphereItem.segments.width = value;
            update(imageSphereItem);
          }
        },
      },
      heightSegments: {
        value: imageSphereItem.segments.height,
        min: 1,
        max: 5,
        step: 1,
        onChange: (value, last) => {
          if (last) {
            imageSphereItem.segments.height = value;
            update(imageSphereItem);
          }
        },
      },
      radius: {
        value: imageSphereItem.radius * 2,
        min: 0.1,
        max: scaleLimit * 2,
        step: 0.001,
        onChange: (value, last) => {
          if (last) {
            imageSphereItem.radius = value / 2;

            if (imageSphereItem.radius > localPosition.z) {
              const newPosition = localPosition.clone().setZ(imageSphereItem.radius);
              imageSphereItem.position.copy(canvasRef.localToWorld(newPosition));
            }

            update(imageSphereItem);
          }
        },
      },
      spacing: {
        value: imageSphereItem.spacing,
        min: 0,
        max: 1,
        step: 0.001,
        onChange: (value, last) => {
          if (last) {
            imageSphereItem.spacing = value;
            update(imageSphereItem);
          }
        },
      },
      thetaOffset: {
        value: imageSphereItem.thetaOffset,
        min: 0,
        max: 2,
        step: 0.001,
        onChange: (value, last) => {
          if (last) {
            imageSphereItem.thetaOffset = value;
            update(imageSphereItem);
          }
        }
      },
      rotationSpeed: {
        value: imageSphereItem.rotationSpeed,
        min: 0,
        max: 1,
        step: 0.001,
        onChange: (value, last) => {
          if (last) {
            imageSphereItem.rotationSpeed = value;
            update(imageSphereItem);
          }
        },
      },
      opacity: {
        value: 1 - imageSphereItem.opacity,
        min: 0,
        max: 1,
        step: 0.001,
        onChange: (value, last) => {
          if (last) {
            imageSphereItem.opacity = 1 - value;
            update(imageSphereItem);
          }
        },
      },
      staggeredLayout: {
        value: imageSphereItem.staggeredLayout,
        onChange: (event) => {
          imageSphereItem.staggeredLayout = event.target.checked;
          update(imageSphereItem);
        },
      },
      inverted: {
        value: imageSphereItem.inverted,
        onChange: (event) => {
          imageSphereItem.inverted = event.target.checked;
          update(imageSphereItem);
        },
      },
      boundToGrid: {
        value: imageSphereItem.boundToGrid,
        onChange: (event) => {
          imageSphereItem.boundToGrid = event.target.checked;
          update(imageSphereItem);
        },
      },
    };
  }

  useEffect(() => {
    setParams(getParams());
  }, [imageSphereItem, imageSphereItem.boundToGrid, imageSphereItem.inverted, imageSphereItem.opacity,
    imageSphereItem.position.x, imageSphereItem.position.y, imageSphereItem.position.z, imageSphereItem.radius,
    imageSphereItem.rotationSpeed, imageSphereItem.segments.height, imageSphereItem.segments.width,
    imageSphereItem.spacing, imageSphereItem.staggeredLayout, imageSphereItem.thetaOffset]);

  return (
    <>
      {params && (
        <>
          <AccordionDetails>
            <div>
              <Typography>Posisjon</Typography>
              <SliderInput title={'X'} {...params.xPosition} />
              <SliderInput title={'Y'} {...params.yPosition} />
              <SliderInput title={'Z'} {...params.zPosition} />
            </div>
            <div>
              <Typography>Segmenter</Typography>
              <SliderInput title={'X'} marks {...params.widthSegments} />
              <SliderInput title={'Y'} marks {...params.heightSegments} />
            </div>
            <div>
              <Typography>Skalering</Typography>
              <SliderInput {...params.radius} />
            </div>
            <div>
              <Typography>Mellomrom</Typography>
              <SliderInput {...params.spacing} />
            </div>
            <div>
              <Typography>Polar forskyvning</Typography>
              <SliderInput {...params.thetaOffset} />
            </div>
            <div>
              <Typography>Rotasjonshastighet</Typography>
              <SliderInput {...params.rotationSpeed} />
            </div>
            <div>
              <Typography>Gjennomsiktighet</Typography>
              <SliderInput {...params.opacity} />
            </div>
            <div>
              <FormControlLabel
                label="Forskjøvet layout"
                control={
                  <Checkbox
                    checked={params.staggeredLayout.value}
                    onChange={params.staggeredLayout.onChange}
                  />
                }
              />
              <FormControlLabel
                label="Speilvendt bilde"
                control={
                  <Checkbox
                    checked={params.inverted.value}
                    onChange={params.inverted.onChange}
                  />
                }
              />
              <FormControlLabel
                label="Følg grid"
                control={
                  <Checkbox
                    checked={params.boundToGrid.value}
                    onChange={params.boundToGrid.onChange}
                  />
                }
              />
            </div>
          </AccordionDetails>
          <Divider light />
          <AccordionDetails>
            <Button
              variant="outlined"
              size="small"
              startIcon={<Icon.DeleteOutlined fontSize="small" />}
              onClick={() => onRemove(index)}
            >
              Slett bildekule
            </Button>
            {/* <Button
              variant="outlined"
              size="small"
              startIcon={<Icon.GridOn fontSize="small" />}
              onClick={() => setShowImageGridDialog(true)}
            >
              Bilder
            </Button> */}
          </AccordionDetails>
          {showImageGridDialog &&
            <ImageOrganizerDialog
              images={imageSphereItem.images}
              segments={imageSphereItem.segments}
              onClose={() => setShowImageGridDialog(false)}
            />
          }
        </>
      )}
    </>
  );
};
