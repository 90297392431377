export const demo8 = {
    roomModelDmsId: '0136NRjVRo3B',
    startPosition: { x: 10.8, y: 3.2, z: -3 },
    directionOfView: { x: 0.001, y: 0, z: 0 },
    dome: {
        skyBoxDmsId: '0136NvASPyuq',
    },
    lights: [
        {
            type: 'ambient',
            intensity: 0.1,
        },
        {
            type: 'hemisphere',
            skyColor: '#DCDCDC',
            groundColor: '#A9A9A9',
            intensity: 0.3,
        },
        {
            type: 'spot',
            intensity: 1.1,
            position: { x: 10.8, y: 5.3, z: -3 },
            shadowBias: -0.015,
        },
        {
            type: 'spot',
            intensity: 1.1,
            position: { x: 20, y: 5.3, z: -3 },
            shadowBias: -0.015,
        },
        {
            type: 'spot',
            intensity: 0.8,
            position: { x: 27.3, y: 4, z: -3 },
            shadowBias: -0.015,
        },
        {
            type: 'spot',
            intensity: 0.8,
            position: { x: 2.8, y: 4, z: -3 },
            shadowBias: -0.015,
        },
        {
            type: 'spot',
            intensity: 0.8,
            position: { x: 3.3, y: 2.4, z: 5 },
            shadowBias: -0.015,
        },
        {
            type: 'spot',
            intensity: 0.8,
            position: { x: 10.8, y: 0.8, z: 5 },
            shadowBias: -0.015,
        },
        {
            type: 'spot',
            intensity: 0.8,
            position: { x: 20, y: 0.8, z: 5 },
            shadowBias: -0.015,
        },
        {
            type: 'spot',
            intensity: 0.8,
            position: { x: 27.3, y: 2.4, z: 5 },
            shadowBias: -0.015,
        },
    ],
    images: [
        {
            dmsId: '0231vTnzCTrG',
            scale: 2.2,
            rotation: { x: 0, y: Math.PI, z: 0 },
            position: { x: 10.8, y: 3, z: 1.34 },
            viewerData: {
                model: {
                    type: 'ImageSingle',
                    dmsId: '0231vTnzCTrG',
                },
                metaData: {
                    title: 'Nygårdsgaten ved Norges Bank',
                    year: 1953,
                    artist: 'Andreassen, Erling',
                    url: 'https://digitaltmuseum.no/021047677070',
                },
            },
        },
        {
            dmsId: '0331vTo1dRoD',
            scale: 1.5,
            rotation: { x: 0, y: Math.PI / -2, z: 0 },
            position: { x: 13.33, y: 3, z: 0 },
            viewerData: {
                model: {
                    type: 'ImageSingle',
                    dmsId: '0331vTo1dRoD',
                },
                metaData: {
                    title: 'Tobiasstredet',
                    year: 1975,
                    artist: 'Andreassen, Erling',
                    url: 'https://digitaltmuseum.no/021047677116',
                },
            },
        },
        {
            dmsId: '0231vTo1fS9t',
            scale: 2.2,
            rotation: { x: 0, y: 0, z: 0 },
            position: { x: 10.9, y: 3, z: -7.34 },
            viewerData: {
                model: {
                    type: 'ImageSingle',
                    dmsId: '0231vTo1fS9t',
                },
                metaData: {
                    title: 'Olje på finerplate',
                    year: 1944,
                    artist: 'Andreassen, Erling',
                    url: 'https://digitaltmuseum.no/021047677402',
                },
            },
        },
        {
            dmsId: '0231vTo1dRLe',
            scale: 1.5,
            rotation: { x: 0, y: Math.PI / 2, z: 0 },
            position: { x: 8.17, y: 3, z: -6.05 },
            viewerData: {
                model: {
                    type: 'ImageSingle',
                    dmsId: '0231vTo1dRLe',
                },
                metaData: {
                    title: 'Sundbytranga fra Glemmengata',
                    year: 1973,
                    artist: 'Andreassen, Erling',
                    url: 'https://digitaltmuseum.no/021047677103',
                },
            },
        },
        {
            dmsId: '0234ASsLzYk3',
            scale: 1,
            rotation: { x: 0, y: Math.PI / -2, z: 0 },
            position: { x: 22.52, y: 3, z: -6.05 },
            viewerData: {
                model: {
                    type: 'ImageSingle',
                    dmsId: '0234ASsLzYk3',
                },
            },
        },
        {
            dmsId: '0334Avr9HPbB',
            scale: 1,
            rotation: { x: 0, y: 0, z: 0 },
            position: { x: 20.8, y: 3, z: -7.34 },
            viewerData: {
                model: {
                    type: 'ImageSingle',
                    dmsId: '0334Avr9HPbB',
                },
            },
        },
        {
            dmsId: '0234ASsAZc8d',
            scale: 1,
            rotation: { x: 0, y: 0, z: 0 },
            position: { x: 18.8, y: 3, z: -7.34 },
            viewerData: {
                model: {
                    type: 'ImageSingle',
                    dmsId: '0234ASsAZc8d',
                },
            },
        },
        {
            dmsId: '0234BSdh8XWH',
            scale: 1.258,
            rotation: { x: 0, y: Math.PI / 2, z: 0 },
            position: { x: 17.35, y: 3, z: 0.05 },
            viewerData: {
                model: {
                    type: 'ImageSingle',
                    dmsId: '0234BSdh8XWH',
                },
            },
        },
        {
            dmsId: '0136Mv4b5nXs',
            scale: 1,
            rotation: { x: 0, y: Math.PI, z: 0 },
            position: { x: 18.9, y: 3, z: 1.34 },
            viewerData: {
                model: {
                    type: 'ImageSingle',
                    dmsId: '0136Mv4b5nXs',
                },
            },
        },
        {
            dmsId: '0136Muwy7ffL',
            scale: 1,
            rotation: { x: 0, y: Math.PI, z: 0 },
            position: { x: 20.9, y: 3, z: 1.34 },
            viewerData: {
                model: {
                    type: 'ImageSingle',
                    dmsId: '0136Muwy7ffL',
                },
            },
        },
    ],
    quizzes: [
        {
            position: { x: 15.3, y: 2, z: -2.2 },
            scale: { x: 2.5, y: 2.5, z: 2.5 },
            content: {
                title: 'Test Quiz',
                questions: [
                    {
                        id: 1,
                        question:
                            'Hvor mange biler kan sees på maleriet med tittelen "Nygårdsgaten ved Norges Bank"?',
                        a: '1',
                        b: '2',
                        c: '3',
                        d: '4',
                        answer: 'b',
                    },
                    {
                        id: 2,
                        question:
                            'Hvilket år ble maleriet med tittelen "Tobiasstredet" skapt?',
                        a: '1944',
                        b: '1953',
                        c: '1973',
                        d: '1975',
                        answer: 'd',
                    },
                    {
                        id: 3,
                        question:
                            'Hvor mange kraner kan sees på maleriet med tittelen "Olje på finerplate"?',
                        a: '1',
                        b: '2',
                        c: '3',
                        d: '4',
                        answer: 'd',
                    },
                ],
            },
        },
    ],
}