import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles({
  bar: {
    height: 4,
    width: '100%',
    overflow: 'hidden',
    position: 'relative',
    borderRadius: 8,
    backgroundColor: '#fff',
    '& > div': {
      top: 0,
      left: 0,
      bottom: 0,
      width: '100%',
      position: 'absolute',
      backgroundColor: '#008000',
      transformOrigin: 'left',
      transition: 'transform .4s linear',
    },
  },
  text: {
    display: 'block',
    position: 'relative',
    textAlign: 'center',
    fontVariantNumeric: 'tabular-nums',
    color: '#f0f0f0',
    whiteSpace: 'nowrap',
    fontSize: 18,
  },
});

export const ProgressBar = ({ progress }) => {
  const styles = useStyles();

  return (
    <>
      <div
        className={styles.bar}
        role="progressbar"
        aria-valuemin="0"
        aria-valuemax="100"
        aria-valuenow={progress}
      >
        <div style={{ transform: `translateX(-${100 - progress}%)` }} />
      </div>
      <span className={styles.text}>
        {`Loading ${progress.toFixed(2)}%`}
      </span>
    </>
  );
};
