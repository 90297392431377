import * as Icon from '@mui/icons-material';
import { AccordionDetails, Button, Checkbox, FormControlLabel, TextField, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { computeBoundingBoxSize } from '../../../helpers';
import { SliderInput } from '../../../components/SliderInput';
import { Groups } from '../../../components/TourGroupPicker/Groups';

export const TourSettings = ({
  index,
  tourItem,
  canvasHeight,
  canvasWidth,
  cellSize,
  groups,
  onUpdate,
  onRemove,
  getSceneElements,
}) => {
  const [params, setParams] = useState();

  const update = (tour) => {
    setParams(getParams(tour));
    onUpdate && onUpdate(index, tour);
  };

  const getParams = (obj) => {
    tourItem = obj ?? tourItem;
    const { canvasRef, itemRef: tourRef } = getSceneElements();

    if (!canvasRef || !tourRef) return null;

    const localPosition = canvasRef.worldToLocal(tourItem.position.clone());
    const size = computeBoundingBoxSize(tourRef);

    return {
      xPosition: {
        value: tourItem.boundToGrid
          ? (localPosition.x - (size.x / 2) + (canvasWidth / 2) + cellSize) / cellSize
          : localPosition.x - (size.x / 2) + (canvasWidth / 2),
        min: tourItem.boundToGrid ? 1 : 0,
        max: tourItem.boundToGrid
          ? (canvasWidth / cellSize) - Math.ceil(size.x / cellSize) + 1
          : canvasWidth - size.x,
        step: tourItem.boundToGrid ? 1 : 0.001,
        onChange: (value, last) => {
          const scaleOffset = (Math.ceil(size.x / cellSize) - 1) * cellSize / 2;
          value = tourItem.boundToGrid
            ? ((value - 1) * cellSize) - ((size.x - cellSize) / 2) + scaleOffset
            : value;
          const newPosition = localPosition.clone().setX(value - (canvasWidth / 2) + (size.x / 2));
          newPosition.copy(canvasRef.localToWorld(newPosition));

          if (last && !tourItem.position.equals(newPosition)) {
            tourItem.position.copy(newPosition);
            update(tourItem);
          }
          else {
            tourRef.parent.position.copy(newPosition);
          }
        },
      },
      yPosition: {
        value: tourItem.boundToGrid
          ? (localPosition.y - (size.y / 2) + (canvasHeight / 2) + cellSize) / cellSize
          : localPosition.y - (size.y / 2) + (canvasHeight / 2),
        min: tourItem.boundToGrid ? 1 : 0,
        max: tourItem.boundToGrid
          ? (canvasHeight / cellSize) - Math.ceil(size.y / cellSize) + 1
          : canvasHeight - size.y,
        step: tourItem.boundToGrid ? 1 : 0.001,
        onChange: (value, last) => {
          const scaleOffset = (Math.ceil(size.y / cellSize) - 1) * cellSize / 2;
          value = tourItem.boundToGrid
            ? ((value - 1) * cellSize) - ((size.y - cellSize) / 2) + scaleOffset
            : value;
          const newPosition = localPosition.clone().setY(value - (canvasHeight / 2) + (size.y / 2));
          newPosition.copy(canvasRef.localToWorld(newPosition));

          if (last && !tourItem.position.equals(newPosition)) {
            tourItem.position.copy(newPosition);
            update(tourItem);
          }
          else {
            tourRef.parent.position.copy(newPosition);
          }
        },
      },
      title: {
        value: tourItem.title ?? '',
        onChange: (event) => {
          if (tourItem.title !== event.target.value) {
            tourItem.title = event.target.value;
            update(tourItem);
          }
        },
      },
      introduction: {
        value: tourItem.introduction ?? '',
        onChange: (event) => {
          if (tourItem.introduction !== event.target.value) {
            tourItem.introduction = event.target.value;
            update(tourItem);
          }
        },
      },
      groups: {
        value: tourItem.groups,
        onChange: (groups) => {
          if (JSON.stringify(tourItem.groups) !== JSON.stringify(groups)) {
            tourItem.groups = groups;
            update(tourItem);
          }
        },
        onCameraPlacementChange: (index, position, rotation) => {
          const group = tourItem.groups[index];
          group.position = position;
          group.rotation = rotation;
          update(tourItem);
        },
      },
      boundToGrid: {
        value: tourItem.boundToGrid,
        onChange: (event) => {
          tourItem.boundToGrid = event.target.checked;
          update(tourItem);
        },
      },
    };
  };

  useEffect(() => {
    setParams(getParams());
  }, [tourItem, tourItem.position.x, tourItem.position.y, tourItem.title,
    tourItem.introduction, tourItem.groups, tourItem.boundToGrid]);

  return (
    <>
      {params && (
        <>
          <AccordionDetails>
            <div>
              <Typography>Posisjon</Typography>
              <SliderInput title={'X'} {...params.xPosition} />
              <SliderInput title={'Y'} {...params.yPosition} />
            </div>
            <TextField
              variant='outlined'
              label='Tittel'
              size='small'
              defaultValue={params.title.value}
              onBlur={params.title.onChange}
            />
            <TextField
              variant='outlined'
              label='Innledning'
              size='small'
              helperText='Maks 240 tegn'
              multiline
              rows={5}
              defaultValue={params.introduction.value}
              onBlur={params.introduction.onChange}
              inputProps={{ maxLength: 240 }}
            />
            <div>
              <FormControlLabel
                label="Følg grid"
                control={
                  <Checkbox
                    checked={params.boundToGrid.value}
                    onChange={params.boundToGrid.onChange}
                  />
                }
              />
            </div>
          </AccordionDetails>
          <Groups
            groups={params.groups.value}
            allGroups={groups}
            onChange={params.groups.onChange}
            onCameraPlacementChange={params.groups.onCameraPlacementChange}
          />
          <AccordionDetails>
            <Button
              variant="outlined"
              size="small"
              startIcon={<Icon.DeleteOutlined fontSize="small" />}
              onClick={() => onRemove(index)}
            >
              Slett omvisning
            </Button>
          </AccordionDetails>
        </>
      )}
    </>
  );
};
