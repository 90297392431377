import * as Icons from '@mui/icons-material';
import { useEffect, useState } from 'react';
import { createUseStyles } from 'react-jss';
import { store } from '../store';
import { KeyboardInput } from './KeyboardInput';

const useStyles = createUseStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
    position: 'absolute',
    bottom: 25,
    left: 25,
    gap: 5,
    '& > div': {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      gap: 5,
    },
  },
});

export const KeyboardInputs = () => {
  const styles = useStyles();
  const [ moveForward, setMoveForward ] = useState(false);
  const [ moveLeft, setMoveLeft ] = useState(false);
  const [ moveBackward, setMoveBackward ] = useState(false);
  const [ moveRight, setMoveRight ] = useState(false);
  const setKeyboardInputs = store(state => state.setKeyboardInputs);
  const keyboardInputs = {};

  const handleKeyDown = (event) => {
    updateInputControls(event.code, true);
  };

  const handleKeyUp = (event) => {
    updateInputControls(event.code, false);
  };

  const handleButtonDown = (keyCode) => {
    const updated = updateInputControls(keyCode, true);
    updated && setKeyboardInputs(keyboardInputs);
  };

  const handleButtonUp = (keyCode) => {
    const updated = updateInputControls(keyCode, false);
    updated && setKeyboardInputs(keyboardInputs);
  };

  const updateInputControls = (keyCode, pressed) => {
    let updated = false;

    switch (keyCode) {
      case 'ArrowUp':
      case 'KeyW':
        if (moveForward !== pressed) {
          setMoveForward(pressed);
          keyboardInputs.moveForward = pressed;
          updated = true;
        }
        break;
      case 'ArrowLeft':
      case 'KeyA':
        if (moveLeft !== pressed) {
          setMoveLeft(pressed);
          keyboardInputs.moveLeft = pressed;
          updated = true;
        }
        break;
      case 'ArrowDown':
      case 'KeyS':
        if (moveBackward !== pressed) {
          setMoveBackward(pressed);
          keyboardInputs.moveBackward = pressed;
          updated = true;
        }
        break;
      case 'ArrowRight':
      case 'KeyD':
        if (moveRight !== pressed) {
          setMoveRight(pressed);
          keyboardInputs.moveRight = pressed;
          updated = true;
        }
        break;
      default:
        break;
    }

    return updated;
  };

  useEffect(() => {
    document.addEventListener('keydown', handleKeyDown);
    document.addEventListener('keyup', handleKeyUp);

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
      document.removeEventListener('keyup', handleKeyUp);
    };
  });

  return (
    <div className={styles.container}>
      <div>
        <KeyboardInput
          icon={<Icons.KeyboardArrowUp />}
          keyCode={'KeyW'}
          pressed={moveForward}
          onButtonDown={handleButtonDown}
          onButtonUp={handleButtonUp}
        />
      </div>
      <div>
        <KeyboardInput
          icon={<Icons.KeyboardArrowLeft />}
          keyCode={'KeyA'}
          pressed={moveLeft}
          onButtonDown={handleButtonDown}
          onButtonUp={handleButtonUp}
        />
        <KeyboardInput
          icon={<Icons.KeyboardArrowDown />}
          keyCode={'KeyS'}
          pressed={moveBackward}
          onButtonDown={handleButtonDown}
          onButtonUp={handleButtonUp}
        />
        <KeyboardInput
          icon={<Icons.KeyboardArrowRight />}
          keyCode={'KeyD'}
          pressed={moveRight}
          onButtonDown={handleButtonDown}
          onButtonUp={handleButtonUp}
        />
      </div>
    </div>
  );
};
