import { CameraControls } from '../Controls/CameraControls';
import { Lights } from '../Lights/Lights';
import { Dome } from '../Dome/Dome';
import { InteractableObjects } from './InteractableObjects';
import { Canvases } from '../Canvases/Canvases';
import { useEffect } from 'react';
import { SET_CANVAS_SELECTION, SET_SELECTED_TOUR, useThreeDDispatch, useThreeDProps, useThreeDState } from '../App/ThreeDContext';

export const Scene = () => {
  const { adminMode, canvasSelection, selectedTour } = useThreeDProps();
  const { interactiveMode } = useThreeDState();
  const dispatch = useThreeDDispatch();

  useEffect(() => {
    if (!adminMode) {
      canvasSelection && dispatch({ type: SET_CANVAS_SELECTION, canvasSelection: null });
      selectedTour && dispatch({ type: SET_SELECTED_TOUR, selectedTour: null });
    }
  }, [adminMode]);

  return (
    <group>
      <Dome />
      <CameraControls />
      <InteractableObjects />
      <Lights />
      {adminMode && interactiveMode &&
        <Canvases />
      }
    </group>
  );
};
