import { cloneElement, useRef } from 'react';
import { useDrag, useDrop } from 'react-dnd';
import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles({
  row: {
    borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
    backgroundColor: '#FAFAFA',
    '&:first-child': {
      borderTop: '1px solid rgba(0, 0, 0, 0.12)',
    },
    '& .MuiTypography-root': {
      fontSize: 14,
    },
  },
});

export const DragItem = ({
  id,
  index,
  onGroupDrag,
  onGroupDrop,
  onGroupReset,
  children,
}) => {
  const styles = useStyles();
  const ref = useRef(null);

  const [{ isDragging }, drag, preview] = useDrag({
    type: 'group',
    item: () => (
      { id, index }
    ),
    end: (item, monitor) => {
      const didDrop = monitor.didDrop();

      if (didDrop) {
        onGroupDrop(item.id, item.index, index);
      }
      else {
        onGroupReset();
      }
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  const [{ handlerId }, drop] = useDrop({
    accept: 'group',
    collect: (monitor) => (
      { handlerId: monitor.getHandlerId() }
    ),
    hover: (item, monitor) => {
      if (!ref.current || item.index === index) {
        return;
      }

      const hoverBoundingRect = ref.current?.getBoundingClientRect();
      const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
      const clientOffset = monitor.getClientOffset();
      const hoverClientY = clientOffset.y - hoverBoundingRect.top;

      if ((item.index < index && hoverClientY < hoverMiddleY) || (item.index > index && hoverClientY > hoverMiddleY)) {
        return;
      }

      onGroupDrag(item.id, index);
      item.index = index;
    },
  });

  const opacity = isDragging ? 0 : 1;
  drag(drop(ref));

  return (
    <div
      ref={ref}
      className={styles.row}
      style={{ opacity }}
      data-handler-id={handlerId}
    >
      {cloneElement(children, {
        isDragging: isDragging,
        preview: preview,
      })}
    </div>
  );
};
