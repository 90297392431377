import * as Icon from '@mui/icons-material';
import { CloseButton } from '../../components/CloseButton';
import { MobileDrawer } from '../../components/MobileDrawer';
import { createUseStyles } from 'react-jss';
import { isMobileDevice } from '../../helpers';
import { useThreeDProps } from '../App/ThreeDContext';

const useStyles = createUseStyles({
  desktop: {
    width: 275,
    maxHeight: '100%',
    position: 'absolute',
    top: 10,
    left: 10,
    background: '#FEFEFE',
    cursor: 'default',
    padding: '36px 12px 24px 12px',
    boxSizing: 'border-box',
    zIndex: 1,
    borderRadius: 8,
    boxShadow: `0px 4px 4px rgba(0, 0, 0, 0.25),
                0px 6px 6px -3px rgba(0, 0, 0, 0.2),
                0px 10px 14px 1px rgba(0, 0, 0, 0.14),
                0px 4px 114px 3px rgba(0, 0, 0, 0.12)`,
  },
  mobile: {
    padding: '4px 16px 8px 16px',
    height: '100%',
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    overflowY: 'auto',
    gap: 8,
  },
  flag: {
    color: '#6DCDB3',
  },
  tour: {
    fontSize: 12,
  },
  title: {
    fontSize: 18,
    fontWeight: 'bold',
  },
  groups: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    gap: 8,
    padding: '12px 0',
  },
  group: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    backgroundColor: '#F2F3F3',
    borderRadius: 999,
    padding: '12px 20px 12px 20px',
    fontSize: 14,
    cursor: 'pointer',
    '& > svg': {
      width: 22,
      height: 22,
    },
    '&:hover': {
      color: '#ffffff',
      backgroundColor: '#2E2B36',
    },
  },
  selectedGroup: {
    color: '#ffffff',
    backgroundColor: '#2E2B36',
  },
  cancel: {
    fontSize: 12,
    fontWeight: 'bold',
    textDecoration: 'underline',
    cursor: 'pointer',
  }
});

export const TourActiveDialog = ({
  title,
  groups,
  selectedIndex,
  onSelect,
  onClose,
}) => {
  const { data } = useThreeDProps();
  const { groups: allGroups } = data;
  const styles = useStyles();
  const isMobile = isMobileDevice();
  const groupIndexes = groups.map(group => group.index);
  const includedGroups = allGroups
    .filter(group => groupIndexes.includes(group.id))
    .sort((a, b) => groupIndexes.indexOf(a.id) - groupIndexes.indexOf(b.id));

  const Tour = () => {
    return (
      <>
        <div className={styles.tour}>Omvisning</div>
        <div className={styles.title}>{title}</div>
        <div className={styles.groups}>
          {includedGroups.map((group, index) => (
            <div
              key={index}
              className={`${styles.group} ${index === selectedIndex ? styles.selectedGroup : ''}`}
              onClick={() => onSelect(index)}
            >
              {`${index + 1}. ${group.name}`}
              {index === selectedIndex ? <Icon.LocationOn /> : <Icon.ArrowForward /> }
            </div>
          ))}
        </div>
        {!isMobile &&
          <div
            className={styles.cancel}
            onClick={onClose}
          >
            Avslutt omvisningen
          </div>
        }
      </>
    );
  };

  return (
    <>
      {!isMobile &&
        <div className={`${styles.desktop} ${styles.container}`}>
          <CloseButton
            size={20}
            margin={10}
            onClick={onClose}
          />
          <Icon.Tour className={styles.flag} />
          <Tour />
        </div>
      }
      {isMobile &&
        <MobileDrawer swipeAreaWidth={85} onClose={onClose}>
          <div className={`${styles.mobile} ${styles.container}`}>
            <Tour />
          </div>
        </MobileDrawer>
      }
    </>
  );
};
