export const demo27 ={
    adminMode: true,
    roomModelDmsId: '013AkPFuLLW2',
    startPosition: { x: -4.5, y: 1.75, z: -5 },
    directionOfView: { x: 0.004, y: 0, z: 0.001 },
    lights: [
        {
            type: 'ambient',
            intensity: 1,
        },
    ],
    canvases: [
        {
            type: 'Wall',
            position: { x: 4.995, y: 3, z: 0 },
            rotation: { x: 0, y: Math.PI / -2, z: 0 },
            dimensions: {
                cellSize: 0.5,
                columns: 40,
                rows: 12,
            },
        },
        {
            type: 'Wall',
            position: { x: -4.995, y: 3, z: 0 },
            rotation: { x: 0, y: Math.PI / 2, z: 0 },
            dimensions: {
                cellSize: 0.5,
                columns: 40,
                rows: 12,
            },
        },
        {
            type: 'Wall',
            position: { x: 0, y: 3, z: -9.9945 },
            rotation: { x: 0, y: 0, z: 0 },
            dimensions: {
                cellSize: 0.5,
                columns: 20,
                rows: 12,
            },
        },
        {
            type: 'Wall',
            position: { x: 0, y: 3, z: 9.9945 },
            rotation: { x: 0, y: Math.PI, z: 0 },
            dimensions: {
                cellSize: 0.5,
                columns: 20,
                rows: 12,
            },
        },
        {
            type: 'Wall',
            position: { x: 1.793, y: 1.65, z: -1.45 },
            rotation: { x: 0, y: Math.PI / -2, z: 0 },
            dimensions: {
                cellSize: 0.5,
                columns: 11,
                rows: 7,
            },
        },
        {
            type: 'Wall',
            position: { x: -1.793, y: 1.65, z: 1.45 },
            rotation: { x: 0, y: Math.PI / 2, z: 0 },
            dimensions: {
                cellSize: 0.5,
                columns: 11,
                rows: 7,
            },
        },
        {
            type: 'Wall',
            position: { x: -2.001, y: 1.65, z: 1.35 },
            rotation: { x: 0, y: Math.PI / -2, z: 0 },
            dimensions: {
                cellSize: 0.475,
                columns: 12,
                rows: 7,
            },
        },
        {
            type: 'Wall',
            position: { x: 2.001, y: 1.65, z: -1.35 },
            rotation: { x: 0, y: Math.PI / 2, z: 0 },
            dimensions: {
                cellSize: 0.475,
                columns: 12,
                rows: 7,
            },
        },
        {
            type: 'Wall',
            position: { x: -0.254, y: 1.59, z: 6.7965 },
            rotation: { x: 0, y: Math.PI, z: 0 },
            dimensions: {
                cellSize: 0.516,
                columns: 6,
                rows: 7,
            },
        },
        {
            type: 'Wall',
            position: { x: 0.254, y: 1.59, z: -6.7965 },
            rotation: { x: 0, y: 0, z: 0 },
            dimensions: {
                cellSize: 0.516,
                columns: 6,
                rows: 7,
            },
        },
        {
            type: 'Wall',
            position: { x: 0.347, y: 1.65, z: -7.001 },
            rotation: { x: 0, y: Math.PI, z: 0 },
            dimensions: {
                cellSize: 0.549,
                columns: 6,
                rows: 6,
            },
        },
        {
            type: 'Wall',
            position: { x: -0.353, y: 1.65, z: 7.001 },
            rotation: { x: 0, y: 0, z: 0 },
            dimensions: {
                cellSize: 0.549,
                columns: 6,
                rows: 6,
            },
        },
        {
            type: 'Wall',
            position: { x: 2.001, y: 1.6, z: -6.4 },
            rotation: { x: 0, y: Math.PI / 2, z: 0 },
            dimensions: {
                cellSize: 0.6,
                columns: 2,
                rows: 6,
            },
        },
        {
            type: 'Wall',
            position: { x: -2.001, y: 1.6, z: 6.4 },
            rotation: { x: 0, y: Math.PI / -2, z: 0 },
            dimensions: {
                cellSize: 0.6,
                columns: 2,
                rows: 6,
            },
        },
        {
            type: 'Wall',
            position: { x: 1.793, y: 1.65, z: -6.3 },
            rotation: { x: 0, y: Math.PI / -2, z: 0 },
            dimensions: {
                cellSize: 0.5,
                columns: 2,
                rows: 7,
            },
        },
        {
            type: 'Wall',
            position: { x: -1.793, y: 1.65, z: 6.3 },
            rotation: { x: 0, y: Math.PI / 2, z: 0 },
            dimensions: {
                cellSize: 0.5,
                columns: 2,
                rows: 7,
            },
        },
    ],
}