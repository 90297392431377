import * as Icon from "@mui/icons-material";
import {SelectInput} from "../../components/SelectInput";
import {useFontSizeOptions} from "../font/useFontSizeOptions";

export const TextFontSize = ({fontSize, onChange}) => {
    const fontSizeOptions = useFontSizeOptions()

    return (
        <SelectInput
            value={fontSize}
            options={fontSizeOptions}
            icon={<Icon.TextFields fontSize='small'/>}
            maxHeight={215}
            onChange={onChange}
        />
    )
}