import { TourEditor } from './TourEditor';
import { getCanvasItemName } from '../../helpers';
import { CanvasItemTypes } from '../../helpers/enums';

export const TourEditors = ({
  tours,
  canvasName,
  canvasIndex,
  canvasWidth,
  canvasHeight,
  cellSize,
  onUpdate,
  onRemove,
}) => {
  const handleUpdate = (index, tour) => {
    onUpdate && onUpdate(index, tour, CanvasItemTypes.TOUR);
  };

  const handleRemove = (index) => {
    onRemove && onRemove(index, CanvasItemTypes.TOUR);
  };

  return (
    <group>
      {tours.map((tour, index) => (
        <TourEditor
          key={index}
          tourName={getCanvasItemName(CanvasItemTypes.TOUR, canvasIndex, index)}
          tourItem={tour}
          canvasName={canvasName}
          canvasIndex={canvasIndex}
          canvasWidth={canvasWidth}
          canvasHeight={canvasHeight}
          cellSize={cellSize}
          index={index}
          onUpdate={handleUpdate}
          onRemove={handleRemove}
        />
      ))}
    </group>
  );
};
