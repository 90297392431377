import { createUseStyles } from 'react-jss';
import * as Icon from 'react-feather';

const useStyles = createUseStyles({
  closeButton: {
    position: 'absolute',
    top: (props) => props.margin,
    right: (props) => props.margin,
    width: (props) => props.size,
    height: (props) => props.size,
    color: (props) => (props.color ?? 'rgba(0, 0, 0, 0.54)'),
    padding: 5,
    cursor: 'pointer',
    transition: 'background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
    borderRadius: '50%',
    boxSizing: 'content-box',
    zIndex: '999999999 !important',
    '&:hover': {
      background: 'rgb(128, 128, 128, 0.1)',
    },
  },
});

export const CloseButton = ({
  color,
  iconName,
  size = 30,
  margin = 20,
  onClick,
}) => {
  const styles = useStyles({ color, size, margin });
  const CloseIcon = Icon[iconName] ?? Icon.X;

  return (
    <CloseIcon
      className={styles.closeButton}
      onClick={onClick}
    />
  );
}