export const demo17 = {
    roomModelDmsId: '013AkP2pHMdh',
    startPosition: { x: -18, y: 1.75, z: 0 },
    directionOfView: { x: 0.001, y: 0, z: 0 },
    lights: [
        {
            type: 'ambient',
            intensity: 1,
        },
    ],
}