import * as Icon from '@mui/icons-material';
import { AccordionDetails, Box, Button, FormControlLabel, MenuItem,
  Radio, RadioGroup, Select, Typography } from '@mui/material';
import { createUseStyles } from 'react-jss';
import { SET_GROUP_MANAGER_DIALOG_OPEN, useThreeDDispatch } from '../3droom/App/ThreeDContext';

const useStyles = createUseStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
    gap: '6px !important',
  },
  group: {
    display: 'flex',
    flexDirection: 'row',
    gap: 8,
  },
  groupName: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    gap: 4,
    '& svg': {
      padding: '0 3px',
    },
  },
  groupIndex: {
    display: 'flex',
    flexDirection: 'column',
    gap: 4,
  },
  menuItem: {
    '&.MuiMenuItem-root': {
      fontSize: 14,
    },
    '& .MuiRadio-root': {
      padding: 5,
      '&.Mui-checked': {
        color: 'rgba(0, 0, 0, 0.6)',
      },
    },
    '& .MuiSvgIcon-root': {
      fontSize: 20,
      padding: '0 3px',
    },
    '& .MuiTypography-root': {
      fontSize: 14,
    },
  },
});

export const GroupSegment = ({
  id,
  index,
  groups,
  onGroupChange,
  onGroupIndexChange,
}) => {
  const dispatch = useThreeDDispatch();
  const styles = useStyles();
  const selectedGroup = groups.find(group => group.id === id);

  const handleGroupChange = (event) => {
    onGroupChange && onGroupChange(event.target.value);
  };

  const handleGroupIndexChange = (event) => {
    onGroupIndexChange && onGroupIndexChange(event.target.value);
  };

  return (
    <AccordionDetails className={styles.container}>
      <div className={styles.group}>
        <div className={styles.groupName}>
          <Typography>Gruppe</Typography>
          <Select
            variant="outlined"
            size="small"
            value={selectedGroup?.id ?? ''}
            displayEmpty={true}
            onChange={handleGroupChange}
          >
            <MenuItem
              value=""
              className={styles.menuItem}
            >
              <em>Ingen gruppe</em>
            </MenuItem>
            {groups.map((group) => (
              <MenuItem
                key={group.id}
                value={group.id}
                className={styles.menuItem}
              >
                <FormControlLabel
                  value={group.id}
                  control={<Icon.Square sx={{color: group.color}} />}
                  label={group.name}
                />
              </MenuItem>
            ))}
          </Select>
        </div>
        <div className={styles.groupIndex}>
          <Typography>Rekkefølge</Typography>
          <RadioGroup value={index ?? null}>
            <Select
              variant="outlined"
              size="small"
              value={index ?? ''}
              displayEmpty={true}
              disabled={!selectedGroup}
              renderValue={(value) => !isNaN(parseInt(value)) ? value + 1 : ''}
              onChange={handleGroupIndexChange}
            >
              {selectedGroup &&
                Array.from(Array(selectedGroup.itemCount).keys()).map((index) => (
                  <MenuItem
                    key={index}
                    value={index}
                    className={styles.menuItem}
                  >
                    <FormControlLabel
                      value={index}
                      control={<Radio />}
                      label={index + 1}
                    />
                  </MenuItem>
                ))
              }
            </Select>
          </RadioGroup>
        </div>
      </div>
      <Box>
        <Button
          variant="outlined"
          size="small"
          startIcon={<Icon.EditOutlined fontSize="small" />}
          onClick={() => {
            dispatch({ type: SET_GROUP_MANAGER_DIALOG_OPEN, groupManagerDialogOpen: true });
          }}
          fullWidth
        >
          {groups.length > 0 ? 'REDIGER GRUPPER' : 'OPPRETT GRUPPE'}
        </Button>
      </Box>
    </AccordionDetails>
  );
};
