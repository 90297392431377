import * as Icon from '@mui/icons-material';
import { Button, IconButton, TextField } from '@mui/material';
import { useState } from 'react';
import { createUseStyles } from 'react-jss';
import { Dialog } from '../../components/Dialog';
import { CloseButton } from '../../components/CloseButton';
import { ColorInput } from '../../components/ColorInput';
import { useThreeDProps } from '../App/ThreeDContext';

const useStyles = createUseStyles({
  dialog: {
    width: 750,
    display: 'flex',
    flexDirection: 'column',
    overflow: 'visible',
    padding: 28,
    rowGap: 24,
  },
  title: {
    fontSize: 24,
  },
  description: {
    fontSize: 14,
    lineHeight: '24px',
    letterSpacing: 0.15,
  },
  list: {
    display: 'flex',
    flexDirection: 'column',
    '& > div': {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      gap: 8,
      padding: '14px 0',
      '& > svg': {
        padding: 8,
        color: 'rgba(0, 0, 0, 0.6)',
      },
      '&:first-child': {
        borderTop: '1px solid rgba(0, 0, 0, 0.12)',
      },
      '&:not(:last-child)': {
        borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
      },
    },
    '& .MuiButton-root': {
      width: 150,
      lineHeight: '32px',
      color: 'rgba(0, 0, 0, 0.6)',
      borderColor: 'rgba(140, 140, 140, 0.5)',
      '&:hover': {
        borderColor: 'rgba(0, 0, 0, 0.87)',
      },
    },
    '& .MuiInputLabel-root.Mui-focused:not(.Mui-error)': {
      color: 'rgba(0, 0, 0, 0.6) !important',
    },
    '& .Mui-focused:not(.Mui-error) fieldset': {
      borderColor: 'rgba(0, 0, 0, 0.87) !important',
    },
  },
  groupName: {
    flexGrow: 1,
  },
  color: {
    width: 160,
    '& .MuiInputBase-root': {
      padding: '0 8px',
    },
    '& .colorPickerContainer': {
      position: 'absolute',
      bottom: 50,
      right: 0,
      zIndex: 2,
    }
  },
  add: {
    '&.MuiButton-root': {
      fontSize: 14,
      width: 'auto',
      padding: '4px 8px 4px 12px',
      color: 'rgba(0, 0, 0, 0.6)',
      textTransform: 'none',
      '& .MuiSvgIcon-root': {
        fontSize: 24,
      },
    },
  },
});

export const GroupManagerDialog = ({
  onClose,
}) => {
  const { data, onChange } = useThreeDProps();
  const { groups, images, models, tours } = data;
  const styles = useStyles();
  const [newGroup, setNewGroup] = useState();
  const [validate, setValidate] = useState(false);

  const handleNameChange = (id, name) => {
    const group = groups.find(group => group.id === id);

    if (group.name !== name) {
      group.name = name;
      onChange(data);
    }
  };

  const handleColorChange = (id, color) => {
    const group = groups.find(group => group.id === id);

    if (group.color !== color) {
      group.color = color;
      onChange(data);
    }
  };

  const handleDeleteClick = (id) => {
    images && images.forEach(image => {
      image.group?.id === id && delete image.group;
      image.group?.id > id && image.group.id--;
    });
    models && models.forEach(model => {
      model.group?.id === id && delete model.group;
      model.group?.id > id && model.group.id--;
    });
    tours && tours.forEach(tour => {
      tour.groups = tour.groups.filter(group => group.index !== id);
    });
    groups.splice(groups.findIndex(group => group.id === id), 1);
    onChange(data);
  };

  const handleStartAdd = () => {
    setNewGroup({
      name: null,
      color: null,
      id: groups.length,
      itemCount: 0,
    });
    setValidate(false);
  };

  const handleCancelAdd = () => {
    setNewGroup(null);
    setValidate(false);
  };

  const handleNewNameChange = (event) => {
    if (event.target.value !== newGroup.name) {
      const group = {...newGroup};
      group.name = event.target.value;
      setNewGroup(group);
    }
  };

  const handleNewColorChange = (color) => {
    const group = {...newGroup};
    group.color = color;
    setNewGroup(group);
  };

  const handleAddClick = () => {
    if (newGroup.name?.length > 0 && newGroup.color) {
      groups.push(newGroup);
      onChange(data);
      setNewGroup(null);
      setValidate(false);
    }
    else {
      setValidate(true);
    }
  };

  return (
    <Dialog className={styles.dialog}>
      <CloseButton onClick={onClose} />
      <div className={styles.title}>Redigere grupper</div>
      <div className={styles.description}>En gruppe med objekter gir besøkende mulighet til å bla gjennom alle objektene i gruppen i full skjerm. Nummer og farge på gruppene er ikke synlig i utstillingen, men er et hjelpemiddel for å skille grupper fra hverandre når du redigerer utstillingen.</div>
      <div className={styles.list}>
        {groups.map(group => (
          <div key={group.id}>
            <Icon.DragHandle />
            <TextField
              label={`Tittel for gruppe #${group.id + 1}`}
              defaultValue={group.name}
              variant="outlined"
              autoComplete="off"
              size="small"
              className={styles.groupName}
              onBlur={(event) => handleNameChange(group.id, event.target.value)}
            />
            <ColorInput
              title={'Farge i editoren'}
              color={group.color}
              className={styles.color}
              onChange={(color) => handleColorChange(group.id, color)}
            />
            <Button
              variant="outlined"
              startIcon={<Icon.DeleteOutline />}
              size="small"
              onClick={() => handleDeleteClick(group.id)}
            >
              SLETT GRUPPE
            </Button>
          </div>
        ))}
        <div>
          {!newGroup &&
            <Button
              variant="text"
              startIcon={<Icon.Add />}
              className={styles.add}
              onClick={handleStartAdd}
            >
              Lag ny gruppe
            </Button>
          }
          {newGroup &&
            <>
              <IconButton onClick={handleCancelAdd}>
                <Icon.Clear />
              </IconButton>
              <TextField
                label={`Tittel for gruppe #${newGroup.id + 1}`}
                defaultValue={newGroup.name}
                variant="outlined"
                autoComplete="off"
                autoFocus
                size="small"
                className={styles.groupName}
                error={validate && (newGroup.name?.length === 0)}
                onBlur={handleNewNameChange}
              />
              <ColorInput
                title={'Farge i editoren'}
                color={newGroup.color}
                className={styles.color}
                error={validate && !newGroup.color}
                onChange={handleNewColorChange}
              />
              <Button
                variant="outlined"
                startIcon={<Icon.Add />}
                size="small"
                onClick={handleAddClick}
              >
                LEGG TIL
              </Button>
            </>
          }
        </div>
      </div>
    </Dialog>
  );
};
