import { useEffect, useMemo, useRef, useState } from 'react';
import { useThree } from '@react-three/fiber';
import { useCanvasItemEditor } from '../Canvases/useCanvasItemEditor';
import { getSceneElementByName, areVectorsEqual, areVectorsEqualOnOneOrMoreAxis,
  setCursor, isCanvasItem } from '../../helpers';
import { CanvasItemTypes, CursorTypes } from '../../helpers/enums';
import { Buttons } from './Buttons';
import { TextItem } from '../Texts/TextItem';
import { SET_CANVAS_SELECTION, SET_OUTLINE_CANVAS, SET_ROTATION_ENABLED,
  useThreeDDispatch, useThreeDState } from '../App/ThreeDContext';

export const TextEditor = ({
  textName,
  textItem,
  canvasName,
  canvasIndex,
  canvasWidth,
  canvasHeight,
  cellSize,
  index,
  onUpdate,
  onRemove,
}) => {
  const { canvasSelection, outlineCanvas } = useThreeDState();
  const dispatch = useThreeDDispatch();
  const { scene } = useThree();
  const [dragPosition, setDragPosition] = useState(textItem.position);
  const [pointerDown, setPointerDown] = useState(false);
  const [positionOffset, setPositionOffset] = useState();
  const [dragMode, setDragMode] = useState(false);
  const canvas = getSceneElementByName(scene.children, canvasName);
  const text = getSceneElementByName(scene.children, textName);
  const canvasItemEditor = useCanvasItemEditor({
    canvas,
    canvasItem: text,
    canvasSize: { height: canvasHeight, width: canvasWidth },
    cellSize,
  });
  const point = useRef();

  const isSelected = useMemo(() => {
    return canvasSelection?.canvasIndex === canvasIndex
    && canvasSelection?.itemType === CanvasItemTypes.TEXT
    && canvasSelection?.itemIndex === index
  }, [canvasSelection, canvasIndex, index]);

  useEffect(() => {
    if (isSelected) {
      updateOutlineCanvas();
      !canvasSelection.getSceneElements && updateCanvasSelection();
    }
  });

  useEffect(() => {
    setDragPosition(textItem.position);
  }, [textItem.position, textItem.position.x, textItem.position.y, textItem.position.z]);

  const updateOutlineCanvas = () => {
    if (outlineCanvas !== textName) {
      dispatch({ type: SET_OUTLINE_CANVAS, outlineCanvas: textName });
    }
  };

  const updateCanvasSelection = () => {
    dispatch({
      type: SET_CANVAS_SELECTION,
      canvasSelection: {
        canvasIndex: canvasIndex,
        itemIndex: index,
        itemType: CanvasItemTypes.TEXT,
        onUpdate: onUpdate,
        onRemove: onRemove,
        getSceneElements: () => ({
          canvasRef: canvas ?? getSceneElementByName(scene.children, canvasName),
          itemRef: getSceneElementByName(scene.children, textName),
          rescaleButtonsRef: getSceneElementByName(scene.children, `${textName}-buttons`),
        }),
      },
    });
  };

  const handleClick = (event) => {
    if (!isCanvasItem(event)) return null;
    const intersection = event.intersections.reduce((acc, obj) => (obj.distance < acc.distance ? obj : acc));

    if (event.button === 0 && !dragMode && intersection.object === event.object && point.current?.x === event.screenX && point.current?.y === event.screenY) {
      updateOutlineCanvas();
      !isSelected && updateCanvasSelection();
    }
  };

  const handlePointerDown = (event) => {
    event.stopPropagation();
    const intersection = event.intersections.reduce((acc, obj) => (obj.distance < acc.distance ? obj : acc));

    if (intersection.object === event.object && event.button === 0 && isSelected) {
      const localPosition = text.worldToLocal(event.point.clone());
      setPositionOffset({ x: localPosition.x, y: localPosition.y });
      dispatch({ type: SET_ROTATION_ENABLED, rotationEnabled: false });
      setPointerDown(true);
      setCursor(CursorTypes.MOVE);
    }

    point.current = { x: parseInt(event.screenX), y: parseInt(event.screenY) };
  };

  const handlePointerUp = (event) => {
    event.stopPropagation();

    if (event.button === 0 && isSelected) {
      setCursor(CursorTypes.GRAB);
      dispatch({ type: SET_ROTATION_ENABLED, rotationEnabled: true });
      setPositionOffset(null);
      setPointerDown(false);
      setDragMode(false);

      if (dragMode) {
        const textSize = { width: text.scale.x, height: text.scale.y };
        canvasItemEditor.applyPositionOffset2D(event.point, positionOffset);
        textItem.boundToGrid && canvasItemEditor.snapPositionToGrid(event.point, textSize);
        canvasItemEditor.applyPositionLimits(event.point, textSize);

        if (!areVectorsEqual(textItem.position, event.point)) {
          textItem.position.copy(event.point);
          onUpdate(index, textItem);
        }
      }

      updateOutlineCanvas();
    }
    else {
      pointerDown && setPointerDown(false);
      dragMode && setDragMode(false);
    }
  };

  const handlePointerMove = (event) => {
    if (event.buttons === 1) {
      if (pointerDown && dragMode && areVectorsEqualOnOneOrMoreAxis(textItem.position, event.point)) {
        const textSize = { width: text.scale.x, height: text.scale.y };
        canvasItemEditor.applyPositionOffset2D(event.point, positionOffset);
        textItem.boundToGrid && canvasItemEditor.snapPositionToGrid(event.point, textSize);
        canvasItemEditor.applyPositionLimits(event.point, textSize);
        setDragPosition(event.point);
      }

      pointerDown && !dragMode && setDragMode(true);
    }
  };

  const handleRescale = (point, direction, last) => {
    const localPoint = text.worldToLocal(point.clone());

    if ((direction === 'centerLeft' && localPoint.x > 0) ||
      (direction === 'centerRight' && localPoint.x < 0))
    {
      localPoint.setX(0);
      localPoint.setY(0);
      point = text.localToWorld(localPoint.clone());
    };

    const localPosition = canvas.worldToLocal(textItem.position.clone());
    const width = textItem.boundToGrid ? Math.round(textItem.maxWidth / cellSize) * cellSize : textItem.maxWidth;
    const positionDiff = localPosition.clone().sub(canvas.worldToLocal(point.clone()));
    let widthDiff = Math.abs(positionDiff.x) - (textItem.maxWidth / 2);
    textItem.boundToGrid && (widthDiff = Math.round(widthDiff / cellSize) * cellSize);

    if ((widthDiff !== 0 && width + widthDiff >= cellSize) || last) {
      let value = null;
      let limit = null;

      switch (direction) {
        case 'centerLeft':
          value = localPosition.x - (widthDiff / 2);
          limit = (canvasWidth - (width + widthDiff)) / -2;

          if (value < limit) {
            value = (canvasWidth - width) / -2;
            widthDiff = localPosition.x - value;
            value = value + (widthDiff / 2);
          }

          localPosition.setX(value);
          break;
        case 'centerRight':
          value = localPosition.x + (widthDiff / 2);
          limit = (canvasWidth - (width + widthDiff)) / 2;
          
          if (value > limit) {
            value = (canvasWidth - width) / 2;
            widthDiff = value - localPosition.x;
            value = value - (widthDiff / 2);
          }

          localPosition.setX(value);
          break;
        default:
          break;
      }

      const adjustedPosition = canvas.localToWorld(localPosition);
      setDragPosition(adjustedPosition);
      textItem.position.copy(adjustedPosition);
      textItem.maxWidth += widthDiff;
      last && onUpdate(index, textItem);
    }
  };

  return (
    <group>
      {isSelected && !dragMode && (
        <Buttons
          position={dragPosition}
          rotation={textItem.rotation}
          maxWidth={textItem.maxWidth}
          textName={textName}
          onRescale={handleRescale}
        />
      )}
      <group
        onClick={handleClick}
        onPointerDown={handlePointerDown}
        onPointerUp={handlePointerUp}
        onPointerMove={handlePointerMove}
      >
        <TextItem
          {...textItem}
          name={textName}
          position={dragPosition}
          maxWidth={textItem.maxWidth}
          dragMode={isSelected && dragMode}
          editMode={true}
        />
      </group>
    </group>
  );
};
