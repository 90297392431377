import { Suspense } from 'react';
import { useThree } from '@react-three/fiber';
import { getObjectMeshes, getSceneElementByName } from '../helpers';
import { EffectComposer, Outline, SMAA } from '@react-three/postprocessing';
import { BlendFunction } from 'postprocessing';
import { useThreeDState } from './App/ThreeDContext';
import { store } from './store';

export const Effects = () => {
  const { outlineCanvas } = useThreeDState();
  const outlineItem = store(state => state.outlineItem);
  const { scene } = useThree();
  const outlineItemObj = outlineItem
    ? getObjectMeshes(getSceneElementByName(scene.children, outlineItem))
    : null;
  const outlineCanvasObj = outlineCanvas
    ? getObjectMeshes(getSceneElementByName(scene.children, outlineCanvas))
    : null;

  return (
    <Suspense fallback={null}>
      <EffectComposer
        autoClear={false}
        multisampling={0}
      >
        <SMAA preset={2} />
        <Outline
          selection={outlineItemObj ?? []}
          edgeStrength={6}
          visibleEdgeColor={0xFFDD1A}
          hiddenEdgeColor={0xFFDD1A}
          pulseSpeed={0.0}
          blendFunction={BlendFunction.ALPHA}
          xRay={false}
        />
        <Outline
          selection={outlineCanvasObj ?? []}
          edgeStrength={4}
          visibleEdgeColor={0x4484F1}
          hiddenEdgeColor={0x4484F1}
          pulseSpeed={0.0}
          blendFunction={BlendFunction.ALPHA}
          xRay={false}
        />
      </EffectComposer>
    </Suspense>
  );
};
