import { create } from 'zustand';

const initialState = {
  cameraPosition: null,
  cameraDirection: null,
  outlineItem: null,
  keyboardInputs: null,
};

export const store = create(set => ({
  ...initialState,
  setCameraPosition: cameraPosition => set(() => ({ cameraPosition })),
  setCameraDirection: cameraDirection => set(() => ({ cameraDirection })),
  setOutlineItem: outlineItem => set(() => ({ outlineItem })),
  setKeyboardInputs: keyboardInputs => set(() => ({ keyboardInputs })),
  resetState: () => set(() => ({ ...initialState })),
}));
