export const demo10 = {
    roomModelDmsId: '0138WvJoNVHX',
    startPosition: { x: 26.5, y: 5.5, z: -48 },
    directionOfView: { x: -0.0004, y: 0, z: 0.001 },
    dome: {
        skyShader: {
            distance: 10000,
            elevation: 1,
            azimuth: -20,
            turbidity: 0.5,
            rayleigh: 4,
            mieCoefficient: 0.01,
            mieDirectionalG: 0.999,
            exposure: 0.5,
        },
    },
    lights: [
        {
            type: 'ambient',
            intensity: 0.5,
        },
    ],
    models: [
        {
            dmsId: '0136NvAPTLy8',
            position: { x: -10.04, y: 5.69, z: -25.54 },
            rotation: { x: 0, y: 0.3, z: 0 },
            scale: { x: 0.02, y: 0.02, z: 0.02 },
            viewerData: {
                model: {
                    type: 'Object',
                    dmsId: '0136NvAPTLy8',
                },
                license: {
                    creatorName: 'Opus Poly',
                    creatorUrl: 'https://sketchfab.com/OpusPoly',
                    licenseName: 'CC BY 4.0',
                    licenseUrl: 'https://creativecommons.org/licenses/by/4.0/',
                    sourceName: 'Gislinge Viking Boat',
                    sourceUrl:
                        'https://sketchfab.com/3d-models/gislinge-viking-boat-01098ad7973647a9b558f41d2ebc5193',
                },
            },
        },
        {
            dmsId: '0138WvJrNUWY',
            position: { x: 24.8, y: 4.8, z: 20.7 },
            rotation: { x: 0, y: -2, z: 0 },
            scale: { x: 1.8, y: 1.8, z: 1.8 },
            viewerData: {
                model: {
                    type: 'Object',
                    dmsId: '0138WvJrNUWY',
                },
                license: {
                    creatorName: 'gatostas',
                    creatorUrl: 'https://sketchfab.com/gatostas',
                    licenseName: 'CC BY 4.0',
                    licenseUrl: 'https://creativecommons.org/licenses/by/4.0/',
                    sourceName: 'Tractor',
                    sourceUrl:
                        'https://sketchfab.com/3d-models/tractor-cdb44565a727480998c6fa80369cc4bb',
                },
            },
        },
        {
            dmsId: '0138WvJrNUWZ',
            position: { x: 32, y: 3.65, z: 0.7 },
            rotation: { x: -0.1, y: -0.4, z: 0 },
            scale: { x: 0.55, y: 0.55, z: 0.55 },
            animation: 'metarig|Eat',
            viewerData: {
                model: {
                    type: 'Object',
                    dmsId: '0138WvJrNUWZ',
                },
                license: {
                    creatorName: 'Devcels',
                    creatorUrl: 'https://sketchfab.com/devcels',
                    licenseName: 'CC BY 4.0',
                    licenseUrl: 'https://creativecommons.org/licenses/by/4.0/',
                    sourceName: 'Sheep',
                    sourceUrl:
                        'https://sketchfab.com/3d-models/sheep-32c1cc70eb82491ea484dae9d0b46c35',
                },
            },
        },
        {
            dmsId: '0138WvJuKnBr',
            position: { x: 23.67, y: 4.63, z: 9.68 },
            rotation: { x: 0, y: -2.2, z: 0 },
            scale: { x: 0.01, y: 0.01, z: 0.01 },
            animation: 'Take 001',
            viewerData: {
                model: {
                    type: 'Object',
                    dmsId: '0138WvJuKnBr',
                },
                license: {
                    creatorName: 'Renderpeople',
                    creatorUrl: 'https://sketchfab.com/renderpeople',
                    licenseName: 'CC BY 4.0',
                    licenseUrl: 'https://creativecommons.org/licenses/by/4.0/',
                    sourceName: 'Sophia Animated 003 - Animated 3D Woman',
                    sourceUrl:
                        'https://sketchfab.com/3d-models/sophia-animated-003-animated-3d-woman-dc448c3be0e74f96a55fb475a13433cf',
                },
            },
        },
        {
            dmsId: '0138WvJuKnBq',
            position: { x: 26, y: 4.72, z: 21.5 },
            rotation: { x: 0, y: 2.1, z: 0 },
            scale: { x: 0.01, y: 0.01, z: 0.01 },
            animation: 'Take 001',
            viewerData: {
                model: {
                    type: 'Object',
                    dmsId: '0138WvJuKnBq',
                },
                license: {
                    creatorName: 'Zombiealix',
                    creatorUrl: 'https://sketchfab.com/Zombiealix',
                    licenseName: 'CC BY 4.0',
                    licenseUrl: 'https://creativecommons.org/licenses/by/4.0/',
                    sourceName: 'Felix Richter Idle Animation',
                    sourceUrl:
                        'https://sketchfab.com/3d-models/felix-richter-idle-animation-e383540a2b2f4c2d9b76b91b52ee70c3',
                },
            },
        },
    ],
    images: [
        {
            dmsId: '01RVncew7h',
            scale: 3,
            rotation: { x: 0, y: -1.05, z: 0 },
            position: { x: 35.11, y: 6, z: 21.8 },
            viewerData: {
                model: {
                    type: 'ImageSingle',
                    dmsId: '01RVncew7h',
                },
            },
        },
        {
            dmsId: '03SVtZtkHu',
            scale: 3,
            rotation: { x: 0, y: -1.05, z: 0 },
            position: { x: 32.1, y: 6, z: 16.6 },
            viewerData: {
                model: {
                    type: 'ImageSingle',
                    dmsId: '03SVtZtkHu',
                },
            },
        },
        {
            dmsId: '04SVzxXyqx',
            scale: 3,
            rotation: { x: 0, y: -1.05, z: 0 },
            position: { x: 29.08, y: 6, z: 11.4 },
            viewerData: {
                model: {
                    type: 'ImageSingle',
                    dmsId: '04SVzxXyqx',
                },
            },
        },
    ],
    quizzes: [
        {
            position: { x: 0, y: 3.5, z: 10.2 },
            scale: { x: 3.5, y: 3.5, z: 3.5 },
            repeatable: false,
            content: {
                title: 'Test Quiz',
                questions: [
                    {
                        id: 1,
                        question: 'Hvilken farge er det på låven?',
                        a: 'Grønn',
                        b: 'Rød',
                        c: 'Blå',
                        d: 'Gul',
                        answer: 'b',
                    },
                ],
            },
            animations: {
                onCompletion: 'Path_Anim',
                dmsId: '0138WvJoNpTj',
            },
        },
    ],
}