import * as THREE from 'three';
import { useEffect, useMemo, useState } from 'react';
import { useThree } from '@react-three/fiber';
import { Sphere } from '@react-three/drei';
import { AudioController } from '../Audio/AudioController';
import { CanvasItemTypes } from '../../helpers/enums';
import { useCanvasItemEditor } from '../Canvases/useCanvasItemEditor';
import { getIntersectsFromCamera, getSceneElementByName } from '../../helpers';
import { SET_CANVAS_SELECTION, SET_OUTLINE_CANVAS, SET_ROTATION_ENABLED,
  useThreeDDispatch, useThreeDState } from '../App/ThreeDContext';

export const AudioEditor = ({
  audioName,
  audioItem,
  canvasName,
  canvasIndex,
  canvasWidth,
  canvasHeight,
  cellSize,
  index,
  onUpdate,
  onRemove,
}) => {
  const { canvasSelection, outlineCanvas } = useThreeDState();
  const dispatch = useThreeDDispatch();
  const { scene, camera, size } = useThree();
  const [dragPosition, setDragPosition] = useState(audioItem.position);
  const canvas = getSceneElementByName(scene.children, canvasName);
  const audio = getSceneElementByName(scene.children, audioName);
  const canvasItemEditor = useCanvasItemEditor({
    canvas,
    canvasItem: audio,
    canvasSize: { height: canvasHeight, width: canvasWidth },
    cellSize,
  });

  const isSelected = useMemo(() => {
    return canvasSelection?.canvasIndex === canvasIndex
      && canvasSelection?.itemType === CanvasItemTypes.AUDIO
      && canvasSelection?.itemIndex === index;
  }, [canvasSelection, canvasIndex, index]);

  useEffect(() => {
    if (isSelected) {
      updateOutlineCanvas();
      !canvasSelection.getSceneElements && updateCanvasSelection();
    }
  });

  useEffect(() => {
    setDragPosition(audioItem.position);
  }, [audioItem.position, audioItem.position.x, audioItem.position.y, audioItem.position.z]);

  const updateOutlineCanvas = () => {
    if (outlineCanvas !== audioName) {
      dispatch({ type: SET_OUTLINE_CANVAS, outlineCanvas: audioName });
    }
  };

  const updateCanvasSelection = () => {
    dispatch({
      type: SET_CANVAS_SELECTION,
      canvasSelection: {
        canvasIndex: canvasIndex,
        itemIndex: index,
        itemType: CanvasItemTypes.AUDIO,
        onUpdate: onUpdate,
        onRemove: onRemove,
        getSceneElements: () => {
          return {
            canvasRef: canvas ?? getSceneElementByName(scene.children, canvasName),
            itemRef: getSceneElementByName(scene.children, audioName),
            audibleSphereRef: getSceneElementByName(scene.children, `${audioName}-audibleSphere`),
          };
        },
      },
    });
  };

  const handleClick = () => {
    updateOutlineCanvas();
    !isSelected && updateCanvasSelection();
  };

  const handleMove = (point) => {
    const intersection = getDragIntersection(point);

    if (intersection) {
      const position = getDragPosition(intersection.point.clone());
      setDragPosition(position);
      dispatch({ type: SET_ROTATION_ENABLED, rotationEnabled: false });
    }
  };

  const handleMoved = (point) => {
    const intersection = getDragIntersection(point);

    if (intersection) {
      const position = getDragPosition(intersection.point.clone());
      audioItem.position.copy(position);
      onUpdate(index, audioItem);
    }

    dispatch({ type: SET_ROTATION_ENABLED, rotationEnabled: true });
  };

  const getDragPosition = (point) => {
    point = canvas.worldToLocal(point);
    point.setZ(0.001);
    point = canvas.localToWorld(point);
    const size = { width: 0.215 * audioItem.scale, height: 0.215 * audioItem.scale };
    audioItem.boundToGrid && canvasItemEditor.snapPositionToGrid(point, size);
    canvasItemEditor.applyPositionLimits(point, size);
    return point;
  };

  const getDragIntersection = (point) => {
    const coords = {
      x: ((point.x - size.left) / (size.width / 2)) - 1,
      y: ((point.y - size.top) / (size.height / -2)) + 1
    };

    const intersections = getIntersectsFromCamera(camera, scene, coords);
    return intersections.find(i => i.object.name === canvasName);
  };

  return (
    <>
      <AudioController
        name={audioName}
        position={dragPosition}
        rotation={audioItem.rotation}
        enabled={false}
        selected={isSelected}
        positioning={audioItem.positioning}
        scale={audioItem.scale}
        onClick={handleClick}
        onMove={handleMove}
        onMoved={handleMoved}
      />
      {isSelected &&
        <Sphere
          name={`${audioName}-audibleSphere`}
          args={[audioItem.maxDistance, 40, 40]}
          position={dragPosition}
          userData={{ traversable: true, nonInteractable: true }}
        >
          <meshBasicMaterial
            color={0x4484F1}
            side={THREE.DoubleSide}
            depthWrite={false}
            opacity={0.25}
            transparent
          />
        </Sphere>
      }
    </>
  );
};
