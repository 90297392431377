import { SpotLight } from './Spotlight';

export const SpotLights = ({ lights }) =>
  lights.map(({ intensity, position, shadowBias }, index) => (
    <SpotLight
      intensity={intensity}
      position={position}
      shadowBias={shadowBias}
      key={index}
    />
  ));
