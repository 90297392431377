import { useEffect } from 'react';
import { useAnimations } from '@react-three/drei';
import { getDmsFileUrl } from '@ekultur/dms-url-generator';
import { enableLightEmissivity, enableShadows, useGLTF } from '../../helpers';
import { SET_SCENE_ANIMATIONS, useThreeDDispatch, useThreeDProps, useThreeDState } from '../App/ThreeDContext';

export const RoomModel = () => {
  const { data } = useThreeDProps();
  const { sceneAnimations } = useThreeDState();
  const { roomModelDmsId, roomUrl } = data;
  const dispatch = useThreeDDispatch();
  const { scene, materials, animations, nodes } = useGLTF(roomModelDmsId ? getDmsFileUrl(roomModelDmsId, '01') : roomUrl);
  const { ref, actions } = useAnimations(animations);

  nodes.NavMesh && (nodes.NavMesh.visible = false);

  nodes.Spot_1_Orientation && (nodes.Spot_1_Orientation.intensity = 1);
  nodes.Spot_2_Orientation && (nodes.Spot_2_Orientation.intensity = 1);
  nodes.Spot_3_Orientation && (nodes.Spot_3_Orientation.intensity = 0.5);
  nodes.Spot_4_Orientation && (nodes.Spot_4_Orientation.intensity = 0.5);
  nodes.Spot_5_Orientation && (nodes.Spot_5_Orientation.intensity = 0.5);
  nodes.Spot_6_Orientation && (nodes.Spot_6_Orientation.intensity = 0.5);
  nodes.Spot_7_Orientation && (nodes.Spot_7_Orientation.intensity = 1);
  nodes.Spot_8_Orientation && (nodes.Spot_8_Orientation.intensity = 1);
  nodes.Spot_9_Orientation && (nodes.Spot_9_Orientation.intensity = 0.5);
  nodes.Spot_10_Orientation && (nodes.Spot_10_Orientation.intensity = 0.5);

  nodes.Floor_Concrete_01001 && (nodes.Floor_Concrete_01001.visible = false);
  nodes.Floor_Concrete_02001 && (nodes.Floor_Concrete_02001.visible = false);
  nodes.Floor_Concrete_03001 && (nodes.Floor_Concrete_03001.visible = false);
  nodes.Floor_Terrazzo_01_001 && (nodes.Floor_Terrazzo_01_001.visible = false);
  nodes.Floor_Terrazzo_02001 && (nodes.Floor_Terrazzo_02001.visible = false);
  nodes.Floor_Terrazzo_03001 && (nodes.Floor_Terrazzo_03001.visible = false);
  nodes.Floor_Ash_Herring_001001 && (nodes.Floor_Ash_Herring_001001.visible = false);
  nodes.Floor_Ash_Herring_002001 && (nodes.Floor_Ash_Herring_002001.visible = false);
  nodes.Floor_Ash_Herring_003001 && (nodes.Floor_Ash_Herring_003001.visible = false);

  nodes.Floor_Concrete && (nodes.Floor_Concrete.visible = false);
  nodes.Floor_Ash_Herring && (nodes.Floor_Ash_Herring.visible = false);
  nodes.Floor_Terrazzo && (nodes.Floor_Terrazzo.visible = false);

  nodes.Floor_Concrete001 && (nodes.Floor_Concrete001.visible = false);
  nodes.Floor_Ash_Herring001 && (nodes.Floor_Ash_Herring001.visible = false);
  nodes.Floor_Terrazzo001 && (nodes.Floor_Terrazzo001.visible = false);

  useEffect(() => {
    enableShadows(scene);
    enableLightEmissivity(materials);
    !sceneAnimations && dispatch({ type: SET_SCENE_ANIMATIONS, sceneAnimations: actions });
  }, [
    scene,
    materials,
    actions,
  ]);

  return (
    <primitive
      ref={ref}
      object={scene}
    />
  );
};
