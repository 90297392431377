import * as THREE from 'three';
import * as DREI from '@react-three/drei';
import { useEffect, useMemo, useRef } from 'react';
import { useFrame, useThree } from '@react-three/fiber';
import { useSpring } from '@react-spring/three';
import { getAudioUrl } from '@ekultur/dms-url-generator';
import { distance } from '../../helpers';
import { Stopwatch } from '../../components/Stopwatch';
import { SET_HAS_AUDIO, useThreeDDispatch, useThreeDState } from '../App/ThreeDContext';

export const PositionalAudio = ({
  url,
  dmsId,
  refDistance = 1,
  rolloffFactor = 1,
  maxDistance = 10,
  volume = 1,
  loop = true,
  position,
  paused = false,
  onProgressChange,
  onEnded,
}) => {
  const { audioMute, hasAudio } = useThreeDState();
  const dispatch = useThreeDDispatch();
  const { camera } = useThree();
  const sound = useRef();
  const isPaused = useRef(paused);
  const stopwatch = useMemo(() => new Stopwatch(), []);

  useEffect(() => {
    isPaused.current = paused;
  }, [paused]);

  const { 1: springApi } = useSpring(() => ({
    volume: -0.01,
    config: {
      duration: 1000,
    },
    onStart: (res) => {
      if (res.value.volume < volume) {
        sound.current.play();
        stopwatch.start();
      }
    },
    onChange: (res) => {
      sound.current.setVolume(audioMute ? 0 : res.value.volume);
    },
    onRest: (res) => {
      if (res.value.volume === 0) {
        sound.current.pause();
        stopwatch.stop();
      }
    }
  }));

  const inProximity = () => {
    const worldPosition = new THREE.Vector3();
    sound.current.getWorldPosition(worldPosition);
    return distance(worldPosition, camera.position) <= maxDistance;
  };

  useFrame(() => {
    if (!sound.current || isPaused.current) return;

    const isInProximity = inProximity();

    if (sound.current.isPlaying && !isInProximity) {
      springApi.start({ volume: 0 });
    } else if (!sound.current.isPlaying && isInProximity) {
      springApi.start({ volume: volume });
    }

    if (isInProximity && onProgressChange) {
      const percent = stopwatch.elapsedTime() / sound.current.buffer.duration * 100;
      onProgressChange(percent);
    }
  });

  useEffect(() => {
    if (isPaused.current && sound.current.isPlaying) {
      sound.current.pause();
      stopwatch.stop();
    }
    else if (!isPaused.current && !sound.current.isPlaying && inProximity()) {
      sound.current.setVolume(audioMute ? 0 : volume);
      sound.current.play();
      stopwatch.start();
    }
  }, [isPaused.current]);

  useEffect(() => {
    const listener = new THREE.AudioListener();
    sound.current.listener = listener;
    camera.add(listener);

    return () => {
      camera.remove(listener);
    }
  }, [sound, camera]);

  useEffect(() => {
    !hasAudio && dispatch({ type: SET_HAS_AUDIO, hasAudio: true });
    sound.current.setVolume(audioMute ? 0 : volume);
    sound.current.setRefDistance(refDistance);
    sound.current.setRolloffFactor(rolloffFactor);
    sound.current.setMaxDistance(maxDistance);
    sound.current.setDistanceModel('linear');
    sound.current.setLoop(loop);
  }, [
    loop,
    refDistance,
    rolloffFactor,
    volume,
    maxDistance,
    audioMute,
  ]);

  const handleEnded = () => {
    if (!loop) {
      sound.current.stop();
      stopwatch.reset();
      isPaused.current = true;
    }

    onEnded && onEnded(loop);
  };

  return (
    <DREI.PositionalAudio
      ref={sound} 
      url={dmsId ? getAudioUrl(dmsId, '01') : url}
      position={position}
      onEnded={handleEnded}
      listener={null}
    />
  );
};
