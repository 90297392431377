import { useState } from 'react';
import { ExhibitionInfo } from './ExhibitionInfo';
import { Feedback } from './Feedback';
import { AboutTabs } from '../../../helpers/enums';
import { CloseButton } from '../../../components/CloseButton';
import { Dialog } from '../../../components/Dialog';
import { createUseStyles } from 'react-jss';
import { useThreeDProps } from '../../App/ThreeDContext';

const useStyles = createUseStyles({
  dialog: {
    width: 750,
    height: 450,
    display: 'flex',
    flexDirection: 'row',
    overflow: 'hidden',
  },
  image: {
    flex: '0 0 33%',
    width: '33%',
    height: 'auto',
    objectFit: 'cover',
    '@media (max-width: 700px)': {
      display: 'none',
    },
  },
  tabs: {
    flex: '1 0',
    margin: '30px 40px',
    display: 'flex',
    flexDirection: 'column',
  },
  tabList: {
    display: 'flex',
    flexDirection: 'row',
    flex: '0 0 40px',
    fontSize: 14,
  },
  tabContent: {
    flex: '1 0',
    paddingTop: 10,
    overflowY: 'auto',
  },
  tab: {
    height: 'fit-content',
    color: 'rgba(12, 11, 15, 0.6)',
    padding: '0 12px 10px 12px',
    borderBottom: '1px solid rgba(12, 11, 15, 0.3)',
    cursor: 'pointer',
    userSelect: 'none',
    '&:first-of-type': {
      paddingLeft: 0,
    },
    '&:last-of-type': {
      paddingRight: 0,
    },
  },
  active: {
    color: 'rgb(12, 11, 15)',
    fontWeight: 'bold',
    borderBottom: '2px solid rgb(12, 11, 15)',
  },
});

export const AboutDialog = ({ onClose }) => {
  const { data } = useThreeDProps();
  const { about } = data;
  const styles = useStyles();
  const [activeTab, setActiveTab] = useState(AboutTabs.EXHIBITION_INFO);

  return (
    <>
      {about && (
        <Dialog className={styles.dialog}>
          <CloseButton onClick={onClose} />
          <img
            className={styles.image}
            src={about.imageUrl}
            alt=""
          />
          <div className={styles.tabs}>
            <div className={styles.tabList}>
              <div
                className={`${styles.tab} ${activeTab === AboutTabs.EXHIBITION_INFO ? styles.active : ''}`}
                onClick={() => setActiveTab(AboutTabs.EXHIBITION_INFO)}
              >
                Om utstillingen
              </div>
              <div
                className={`${styles.tab} ${activeTab === AboutTabs.FEEDBACK ? styles.active : ''}`}
                onClick={() => setActiveTab(AboutTabs.FEEDBACK)}
              >
                Tilbakemelding
              </div>
            </div>
            <div className={styles.tabContent}>
              {(activeTab === AboutTabs.EXHIBITION_INFO) && (
                <ExhibitionInfo {...about} />
              )}
              {(activeTab === AboutTabs.FEEDBACK) && (
                <Feedback />
              )}
            </div>
          </div>
        </Dialog>
      )}
    </>
  );
}