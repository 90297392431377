export const demo6 = {
    roomModelDmsId: '0136NRHBut4Q',
    startPosition: { x: 4, y: 4.11, z: -7.2 },
    directionOfView: { x: -0.001, y: 0, z: 0 },
    dome: {
        skyBoxDmsId: '0136MvjisAqp',
    },
    lights: [
        {
            type: 'ambient',
            intensity: 0.1,
        },
        {
            type: 'hemisphere',
            skyColor: '#DCDCDC',
            groundColor: '#0F0F0F',
            intensity: 0.3,
        },
        {
            type: 'rectArea',
            intensity: 2,
            position: { x: -9.75, y: 6.22, z: 6.6 },
            rotation: { x: Math.PI / -2, y: 0, z: 0 },
            width: 3.5,
            height: 14.7,
        },
        {
            type: 'rectArea',
            intensity: 0.6,
            position: { x: -9.75, y: 6.2, z: 21.05 },
            rotation: { x: Math.PI / -2, y: 0, z: 0 },
            width: 6.2,
            height: 3.3,
        },
        {
            type: 'rectArea',
            intensity: 0.6,
            position: { x: -9.75, y: 6.2, z: -7.85 },
            rotation: { x: Math.PI / -2, y: 0, z: 0 },
            width: 6.2,
            height: 3.3,
        },
    ],
    models: [
        {
            dmsId: '0136NvAPTLy8',
            position: { x: -9.9, y: 3.01, z: 7 },
            rotation: { x: 0, y: Math.PI, z: 0 },
            scale: { x: 0.012, y: 0.012, z: 0.012 },
            viewerData: {
                model: {
                    type: 'Object',
                    dmsId: '0136NvAPTLy8',
                },
                license: {
                    creatorName: 'Opus Poly',
                    creatorUrl: 'https://sketchfab.com/OpusPoly',
                    licenseName: 'CC BY 4.0',
                    licenseUrl: 'https://creativecommons.org/licenses/by/4.0/',
                    sourceName: 'Gislinge Viking Boat',
                    sourceUrl:
                        'https://sketchfab.com/3d-models/gislinge-viking-boat-01098ad7973647a9b558f41d2ebc5193',
                },
            },
        },
    ],
    images: [
        {
            dmsId: '03349wCLhapQ',
            scale: 2.2,
            rotation: { x: 0, y: Math.PI / 2, z: 0 },
            position: { x: -14.83, y: 2.1, z: 11.3 },
        },
        {
            dmsId: '03348T7Wdw7H',
            scale: 2,
            rotation: { x: 0, y: Math.PI / 2, z: 0 },
            position: { x: -14.83, y: 2.1, z: 8.3 },
        },
        {
            dmsId: '0331xTYpX3z8',
            scale: 2,
            rotation: { x: 0, y: Math.PI / 2, z: 0 },
            position: { x: -4.67, y: 2.1, z: 11.3 },
        },
        {
            dmsId: '0331xUE8fgJX',
            scale: 2,
            rotation: { x: 0, y: Math.PI / 2, z: 0 },
            position: { x: -4.67, y: 2.1, z: 8.3 },
        },
    ],
    quizzes: [
        {
            position: { x: -9.75, y: 2.5, z: -6.2 },
            scale: { x: 3, y: 3, z: 3 },
            content: {
                title: 'Test Quiz',
                questions: [
                    {
                        id: 1,
                        question: 'Hva er hovedstaden i Norge?',
                        a: 'Bergen',
                        b: 'Trondheim',
                        c: 'Stavanger',
                        d: 'Oslo',
                        answer: 'd',
                    },
                    {
                        id: 2,
                        question: 'Når forsvant Roald Amundsen i isødet?',
                        a: '1926',
                        b: '1927',
                        c: '1928',
                        d: '1929',
                        answer: 'c',
                    },
                    {
                        id: 3,
                        question: 'Hvilke farger er det i det Danske flagget?',
                        a: 'Rødt og hvitt',
                        b: 'Rødt, blått og hvitt',
                        c: 'Rødt, blått og grønt',
                        d: 'Blått og hvitt',
                        answer: 'a',
                    },
                ],
            },
        },
    ],
}