import * as THREE from 'three';
import { useState } from 'react';
import { useThree } from '@react-three/fiber';
import { useSpring } from 'react-spring';
import { easePolyInOut } from 'd3-ease';
import { detectCollisionBetween, getPositionAboveFloor } from '../../helpers';
import { store } from '../store';

export const usePointToPointTraversal = () => {
  const setCameraPosition = store(state => state.setCameraPosition);
  const { camera, scene } = useThree();
  const { 1: springApi } = useSpring(() => ({ }));
  const [ isTraversing, setIsTraversing ] = useState(false);

  const handleTraversalChange = (nextPosition, controls) => {
    const onCollisionCourse = detectCollisionBetween(camera, scene, camera.position, nextPosition);

    if (!onCollisionCourse) {
      const currentDirectionOfView = camera.position.clone().sub(controls.target);
      camera.position.copy(nextPosition);
      controls.target.copy(nextPosition).sub(currentDirectionOfView);
    }
    else {
      setCameraPosition(camera.position);
    }
  };

  const start = (destinationPosition, controls) => {
    springApi.start({
      from: {
        x: camera.position.x,
        y: camera.position.y,
        z: camera.position.z,
      },
      to: {
        x: destinationPosition.x,
        y: destinationPosition.y,
        z: destinationPosition.z,
      },
      config: {
        duration: 600 * (destinationPosition ? Math.sqrt(camera.position.distanceTo(destinationPosition)) : 1),
        easing: easePolyInOut,
      },
      onStart: () => {
        setIsTraversing(true);
      },
      onChange: (res) => {
        let nextPosition = new THREE.Vector3(res.value.x, res.value.y, res.value.z);
        nextPosition = getPositionAboveFloor(camera, scene, nextPosition) ??
          new THREE.Vector3(nextPosition.x, camera.position.y, nextPosition.z);

        handleTraversalChange(nextPosition, controls);
      },
      onRest: () => {
        setIsTraversing(false);
      },
    });
  };

  return {
    start,
    isTraversing: () => { return isTraversing },
  };
};
