import * as THREE from 'three';
import * as Icon from '@mui/icons-material';
import { Button } from '@mui/material';
import { useEffect, useState } from 'react';
import { createUseStyles } from 'react-jss';
import { CloseButton } from '../../components/CloseButton';
import { SET_CAMERA_PLACEMENT_MODE, SET_CANVAS_SELECTION,
  useThreeDDispatch, useThreeDState } from '../App/ThreeDContext';

const useStyles = createUseStyles({
  container: {
    width: 275,
    display: 'flex',
    flexDirection: 'column',
    maxHeight: '100%',
    position: 'absolute',
    boxSizing: 'border-box',
    top: 10,
    left: 10,
    padding: 25,
    gap: 20,
    fontSize: 14,
    background: '#FEFEFE',
    overflowY: 'auto',
    cursor: 'default',
    zIndex: 2,
    borderRadius: 8,
    boxShadow: `0px 4px 4px rgba(0, 0, 0, 0.25),
                0px 6px 6px -3px rgba(0, 0, 0, 0.2),
                0px 10px 14px 1px rgba(0, 0, 0, 0.14),
                0px 4px 114px 3px rgba(0, 0, 0, 0.12)`,
    '& p': {
      margin: 0,
      lineHeight: '150%',
    },
    '& .MuiButton-root': {
      fontSize: 14,
    },
  },
  header: {
    fontSize: 16,
    fontWeight: 'bold',
    paddingBottom: 4,
  },
  buttons: {
    display: 'flex',
    flexDirection: 'column',
    gap: 16,
  },
  cancel: {
    display: 'flex',
    alignSelf: 'center',
    fontWeight: 'bold',
    textTransform: 'uppercase',
    color: '#4484F1',
    cursor: 'pointer',
  },
  frameIndicator: {
    position: 'absolute',
    zIndex: 1,
  },
  crosshair: {
    top: 'calc(50% - 15px)',
    left: 'calc(50% - 15px)',
    width: 30,
    height: 30,
    border: '3px solid #000',
    borderRadius: '50%',
    boxShadow: 'inset 0 3px 0 #fff, inset 3px 0 0 #fff, inset 0 -3px 0 #fff, inset -3px 0 0 #fff',
  },
  topLeftCorner: {
    borderTop: '3px solid #000',
    borderLeft: '3px solid #000',
    boxShadow: 'inset 0 3px 0 #fff, inset 3px 0 0 #fff',
  },
  topRightCorner: {
    borderTop: '3px solid #000',
    borderRight: '3px solid #000',
    boxShadow: 'inset 0 3px 0 #fff, inset -3px 0 0 #fff',
  },
  bottomLeftCorner: {
    borderBottom: '3px solid #000',
    borderLeft: '3px solid #000',
    boxShadow: 'inset 0 -3px 0 #fff, inset 3px 0 0 #fff',
  },
  bottomRightCorner: {
    borderBottom: '3px solid #000',
    borderRight: '3px solid #000',
    boxShadow: 'inset 0 -3px 0 #fff, inset -3px 0 0 #fff',
  },
  landscape: {
    width: 80,
    height: 80,
    '&.topLeft': {
      top: (props) => `calc(100vw / ${props.aspect} * 0.05)`,
      left: (props) => `calc((100vw - (100vw / ${props.aspect} * 0.95 * ${props.targetLandscapeAspect})) / 2)`,
    },
    '&.topRight': {
      top: (props) => `calc(100vw / ${props.aspect} * 0.05)`,
      right: (props) => `calc((100vw - (100vw / ${props.aspect} * 0.95 * ${props.targetLandscapeAspect})) / 2)`,
    },
    '&.bottomLeft': {
      bottom: (props) => `calc(100vw / ${props.aspect} * 0.05)`,
      left: (props) => `calc((100vw - (100vw / ${props.aspect} * 0.95 * ${props.targetLandscapeAspect})) / 2)`,
    },
    '&.bottomRight': {
      bottom: (props) => `calc(100vw / ${props.aspect} * 0.05)`,
      right: (props) => `calc((100vw - (100vw / ${props.aspect} * 0.95 * ${props.targetLandscapeAspect})) / 2)`,
    },
  },
  portrait: {
    width: 40,
    height: 40,
    '&.topLeft': {
      top: (props) => `calc(100vw / ${props.aspect} * 0.05)`,
      left: (props) => `calc((100vw - (100vw / ${props.aspect} * 0.95 * ${props.targetPortraitAspect})) / 2)`,
    },
    '&.topRight': {
      top: (props) => `calc(100vw / ${props.aspect} * 0.05)`,
      right: (props) => `calc((100vw - (100vw / ${props.aspect} * 0.95 * ${props.targetPortraitAspect})) / 2)`,
    },
    '&.bottomLeft': {
      bottom: (props) => `calc(100vw / ${props.aspect} * 0.05)`,
      left: (props) => `calc((100vw - (100vw / ${props.aspect} * 0.95 * ${props.targetPortraitAspect})) / 2)`,
    },
    '&.bottomRight': {
      bottom: (props) => `calc(100vw / ${props.aspect} * 0.05)`,
      right: (props) => `calc((100vw - (100vw / ${props.aspect} * 0.95 * ${props.targetPortraitAspect})) / 2)`,
    },
  },
});

export const CameraPlacementDialog = ({
  index,
  canvasSelection,
  onCameraPlacementChange,
}) => {
  const { cameraRef } = useThreeDState();
  const dispatch = useThreeDDispatch();
  const [aspect, setAspect] = useState(cameraRef.current.aspect);
  const styles = useStyles({
    aspect: aspect,
    targetLandscapeAspect: 16 / 9,
    targetPortraitAspect: 9 / 16,
  });

  useEffect(() => {
    const handleWindowResize = () => {
      setAspect(cameraRef.current.aspect);
    };

    window.addEventListener('resize', handleWindowResize);

    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  });

  const handleSave = () => {
    dispatch({ type: SET_CANVAS_SELECTION, canvasSelection: canvasSelection });
    dispatch({ type: SET_CAMERA_PLACEMENT_MODE, cameraPlacementMode: null });

    const rotation = new THREE.Euler().setFromVector3(new THREE.Vector3(0, 0, 1).applyEuler(cameraRef.current.rotation).negate());
    onCameraPlacementChange(index, cameraRef.current.position, rotation);
  };

  const handleClose = () => {
    dispatch({ type: SET_CANVAS_SELECTION, canvasSelection: canvasSelection });
    dispatch({ type: SET_CAMERA_PLACEMENT_MODE, cameraPlacementMode: null });
  };

  return (
    <>
      <CloseButton onClick={handleClose} color={'#4484F1'} />
      <div className={styles.container}>
        <Icon.VideoCameraFrontOutlined />
        <div>
          <p className={styles.header}>Velg en posisjon og kameravinkel</p>
          <p>Plasser deg på et sted og se i en retning som du ønsker at besøkende skal oppleve når de kommer til denne gruppen i omvisningen. Vi kan ikke garantere hva som får plass innenfor skjermen på alle enheter, men besøkende kan se seg rundt fra posisjonen du velger.</p>
        </div>
        <div className={styles.buttons}>
          <Button
            variant='contained'
            onClick={handleSave}
          >
            Lagre kamera
          </Button>
          <div
            className={styles.cancel}
            onClick={handleClose}
          >
            Avbryt
          </div>
        </div>
      </div>
      <div className={`${styles.frameIndicator} ${styles.crosshair}`} />
      <div className={`${styles.frameIndicator} ${styles.landscape} ${styles.topLeftCorner} topLeft`} />
      <div className={`${styles.frameIndicator} ${styles.landscape} ${styles.topRightCorner} topRight`} />
      <div className={`${styles.frameIndicator} ${styles.landscape} ${styles.bottomLeftCorner} bottomLeft`} />
      <div className={`${styles.frameIndicator} ${styles.landscape} ${styles.bottomRightCorner} bottomRight`} />
      <div className={`${styles.frameIndicator} ${styles.portrait} ${styles.topLeftCorner} topLeft`} />
      <div className={`${styles.frameIndicator} ${styles.portrait} ${styles.topRightCorner} topRight`} />
      <div className={`${styles.frameIndicator} ${styles.portrait} ${styles.bottomLeftCorner} bottomLeft`} />
      <div className={`${styles.frameIndicator} ${styles.portrait} ${styles.bottomRightCorner} bottomRight`} />
    </>
  );
};
