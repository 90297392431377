import * as Icon from '@mui/icons-material';
import { CloseButton } from '../../components/CloseButton';
import { Dialog } from '../../components/Dialog';
import { Button } from '../../components/Button';
import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles({
  dialog: {
    width: 450,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: 40,
    gap: 14,
  },
  flag: {
    color: '#6DCDB3',
  },
  tour: {
    fontSize: 12,
  },
  title: {
    fontSize: 24,
    fontWeight: 'bold',
  },
  introduction: {
    fontSize: 14,
    textAlign: 'center',
  },
  buttonStart: {
    fontSize: 14,
    fontWeight: 'bold',
    color: '#0C0B0F',
    backgroundColor: '#5EE5CC !important',
    '&:hover': {
      backgroundColor: '#26dcba !important',
    },
  },
  buttonCancle: {
    fontSize: 12,
    fontWeight: 'bold',
    textDecoration: 'underline',
    cursor: 'pointer',
  },
});
  
export const TourPreviewDialog = ({
  title,
  introduction,
  onStart,
  onClose,
}) => {
  const styles = useStyles();

  return (
    <Dialog className={styles.dialog}>
      <CloseButton onClick={onClose} />
      <Icon.Tour className={styles.flag} />
      <div className={styles.tour}>Omvisning</div>
      <div className={styles.title}>{title}</div>
      <div className={styles.introduction}>{introduction}</div>
      <Button
        label={'Start omvisningen'}
        Icon={<Icon.ArrowForward />}
        iconRight={true}
        className={styles.buttonStart}
        onClick={onStart}
      />
      <div
        className={styles.buttonCancle}
        onClick={onClose}
      >Avbryt</div>
    </Dialog>
  );
};
