import { BackSide } from 'three';
import { Sphere, useTexture } from '@react-three/drei';
import { getDmsUrl } from '@ekultur/dms-url-generator';

export const SkyBox = ({ sceneryUrl, dmsId }) => {
  const scenery = useTexture(dmsId ? `${getDmsUrl(dmsId, '01')}?dimension=max` : sceneryUrl);

  return (
    <Sphere args={[250, 60, 60]}>
      <meshBasicMaterial attach="material" map={scenery} side={BackSide} />
    </Sphere>
  );
};
