import { TextField } from '../../../components/TextField';
import { createUseStyles } from 'react-jss';
import * as Icon from 'react-feather';

const useStyles = createUseStyles({
  description: {
    fontSize: 14,
    marginBottom: 20,
  },
  flexColumn: {
    display: 'flex',
    flexDirection: 'column',
    rowGap: 10,
  },
  flexRow: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  button: {
    display: 'flex',
    flexDirection: 'row',
    height: 36,
    width: 'fit-content',
    padding: '0 15px',
    alignItems: 'center',
    cursor: 'pointer',
    border: '2px solid #0C0B0F',
    borderRadius: 999,
    marginTop: 10,
    userSelect: 'none',
    '&:hover': {
      background: '#ECEBEF',
    },
    '& > label': {
      fontSize: 14,
      lineHeight: '16px',
      paddingRight: 5,
    }
  },
});

export const Feedback = () => {
  const styles = useStyles();

  return (
    <>
      <div className={styles.description}>Har du behov for å gi tilbakemelding til museet om feil eller tilleggsopplysninger om materialet i den virtuelle opplevelsen?</div>
      <form className={styles.flexColumn}>
        <div className={styles.flexRow}>
          <TextField
            id="name"
            label="Ditt navn"
            placeholder="Fornavn Etternavn"
          />
          <TextField
            id="email"
            label="Din e-post"
            placeholder="Din e-postadresse"
          />
        </div>
        <TextField
          id="message"
          label="Din melding"
          placeholder="Skriv en beskjed her"
          multiline
          rows={5}
        />
        <div className={styles.button}>
          <label>Send</label>
          <Icon.ArrowRight />
        </div>
      </form>
    </>
  );
}