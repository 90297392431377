export const demo19 = {
    roomModelDmsId: '013AkPFuLLW2',
    startPosition: { x: -4.5, y: 1.75, z: -5 },
    directionOfView: { x: 0.004, y: 0, z: 0.001 },
    lights: [
        {
            type: 'ambient',
            intensity: 1,
        },
    ],
}