import { Checkbox, FormControlLabel, FormGroup } from '@mui/material';
import { createUseStyles } from 'react-jss';
import { SET_SHOW_GROUPS, useThreeDDispatch, useThreeDState } from '../App/ThreeDContext';

const useStyles = createUseStyles({
  container: {
    position: 'absolute',
    bottom: 50,
    left: 50,
    background: '#212121',
    height: 44,
    borderRadius: 60,
    zIndex: 1,
    cursor: 'default',
    paddingLeft: 18,
    paddingRight: 18,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
  },
  toggle: {
    cursor: 'pointer',
    color: '#FFFFFF',
    '& svg': {
      fontSize: 20,
      color: '#FFFFFF',
    },
    '&.MuiFormControlLabel-root': {
      marginRight: 0,
    },
    '& .MuiFormControlLabel-label': {
      fontSize: 12,
    },
  },
});

export const SettingsToggle = () => {
  const { cameraPlacementMode, showGroups } = useThreeDState();
  const dispatch = useThreeDDispatch();
  const styles = useStyles();

  const handleClick = (event) => {
    dispatch({ type: SET_SHOW_GROUPS, showGroups: event.target.checked });
  };

  return (
    <>
      {!cameraPlacementMode &&
        <FormGroup className={styles.container}>
          <FormControlLabel
            label='Vis grupper'
            control={<Checkbox checked={showGroups} />}
            className={styles.toggle}
            onChange={handleClick}
          />
        </FormGroup>
      }
    </>
  );
};
