export const demo28 = {
    canvases: [
        {
            dimensions: {
                cellSize: 0.5,
                columns: 80,
                rows: 12,
            },
            position: { x: 9.985, y: 3, z: 0 },
            rotation: { x: 0, y: -1.570796326794, z: 0 },
            type: 'Wall',
        },
        {
            dimensions: {
                cellSize: 0.5,
                columns: 80,
                rows: 12,
            },
            position: { x: -9.985, y: 3, z: 0 },
            rotation: { x: 0, y: 1.570796326794, z: 0 },
            type: 'Wall',
        },
        {
            dimensions: {
                cellSize: 0.5,
                columns: 40,
                rows: 12,
            },
            position: { x: 0, y: 3, z: -19.98 },
            rotation: { x: 0, y: 0, z: 0 },
            type: 'Wall',
        },
        {
            dimensions: {
                cellSize: 0.5,
                columns: 40,
                rows: 12,
            },
            position: { x: 0, y: 3, z: 19.98 },
            rotation: { x: 0, y: 3.141592653589, z: 0 },
            type: 'Wall',
        },
        {
            dimensions: {
                cellSize: 0.5,
                columns: 30,
                rows: 32,
            },
            position: { x: 0, y: 0.001, z: -9.5 },
            rotation: { x: -1.570796326794, y: 0, z: 0 },
            type: 'Floor',
        },
        {
            dimensions: {
                cellSize: 0.5,
                columns: 30,
                rows: 32,
            },
            position: { x: 0, y: 0.001, z: 9.5 },
            rotation: { x: -1.570796326794, y: 0, z: 0 },
            type: 'Floor',
        }
    ],
    directionOfView: { x: 0.001, y: 0, z: 0 },
    images: [
        {
            addCanvasDepth: true,
            boundToGrid: false,
            canvasIndex: 0,
            dmsId: '013AmNTdmoPe',
            position: { x: 9.983999999985123, y: 3.3266825854313073, z: -16.355805789223663 },
            rotation: { x: 0, y: -1.570796326794, z: 0 },
            scale: 4.586,
            viewerData: {
                model: {
                    dmsId: '013AmNTdmoPe',
                    type: 'ImageSingle',
                },
            },
        },
        {
            addCanvasDepth: true,
            boundToGrid: false,
            canvasIndex: 0,
            dmsId: '013AmNTdmoTz',
            position: { x: 9.983999999997136, y: 3.1920000008593794, z: -3.2499999999999996 },
            rotation: { x: 0, y: -1.570796326794, z: 0 },
            scale: 4.5000000017187585,
            viewerData: {
                model: {
                    dmsId: '013AmNTdmoTz',
                    type: 'ImageSingle',
                },
            },
        },
        {
            addCanvasDepth: true,
            boundToGrid: true,
            canvasIndex: 0,
            dmsId: '013AmNTdmoTu',
            position: { x: 9.98400000001458, y: 3.25, z: 16.250624999999996 },
            rotation: { x: 0, y: -1.570796326794, z: 0 },
            scale: 4.5,
            viewerData: {
                model: {
                    dmsId: '013AmNTdmoTu',
                    type: 'ImageSingle',
                },
            },
        },
        {
            addCanvasDepth: true,
            boundToGrid: true,
            canvasIndex: 0,
            dmsId: '013AmNTdmoYL',
            position: { x: 9.984000000002947, y: 3.25, z: 3.250000000000001 },
            rotation: { x: 0, y: -1.570796326794, z: 0 },
            scale: 4.5,
            viewerData: {
                model: {
                    dmsId: '013AmNTdmoYL',
                    type: 'ImageSingle',
                },
            },
        },
        {
            addCanvasDepth: true,
            boundToGrid: true,
            canvasIndex: 0,
            dmsId: '013AmNTdmoTw',
            position: { x: 9.98400000000877, y: 3.25, z: 9.750000000000002 },
            rotation: { x: 0, y: -1.570796326794, z: 0 },
            scale: 4.5,
            viewerData: {
                model: {
                    dmsId: '013AmNTdmoTw',
                    type: 'ImageSingle',
                },
            },
        },
        {
            addCanvasDepth: true,
            boundToGrid: true,
            canvasIndex: 3,
            dmsId: '013AmNTdmoU1',
            position: { x: 6.250000000000001, y: 3.25, z: 19.979000000004984 },
            rotation: { x: 0, y: 3.141592653589, z: 0 },
            scale: 4.5,
            viewerData: {
                model: {
                    dmsId: '013AmNTdmoU1',
                    type: 'ImageSingle',
                },
            },
        },
        {
            addCanvasDepth: true,
            boundToGrid: false,
            canvasIndex: 3,
            dmsId: '013AmNTdmoYK',
            position: { x: -0.024645592530793244, y: 3.2051168520110687, z: 19.978999999999974 },
            rotation: { x: 0, y: 3.141592653589, z: 0 },
            scale: 4.549011767574186,
            viewerData: {
                model: {
                    dmsId: '013AmNTdmoYK',
                    type: 'ImageSingle',
                },
            },
        },
        {
            addCanvasDepth: true,
            boundToGrid: false,
            canvasIndex: 3,
            dmsId: '013AmNTdmoTx',
            position: { x: -6.289733049505501, y: 3.2389432067805504, z: 19.978999999995043 },
            rotation: { x: 0, y: 3.141592653589, z: 0 },
            scale: 4.417714728711972,
            viewerData: {
                model: {
                    dmsId: '013AmNTdmoTx',
                    type: 'ImageSingle',
                },
            },
        },
        {
            addCanvasDepth: true,
            boundToGrid: true,
            canvasIndex: 1,
            dmsId: '019EBskge7wnB',
            position: { x: -9.984000000014127, y: 3.25, z: 15.750000000000002 },
            rotation: { x: 0, y: 1.570796326794, z: 0 },
            scale: 4.5,
            viewerData: {
                model: {
                    dmsId: '019EBskge7wnB',
                    type: 'ImageSingle',
                },
            },
        },
        {
            addCanvasDepth: true,
            boundToGrid: true,
            canvasIndex: 1,
            dmsId: '019EBskge7wn9',
            position: { x: -9.98400000000831, y: 3.25, z: 9.250000000000002 },
            rotation: { x: 0, y: 1.570796326794, z: 0 },
            scale: 4.5,
            viewerData: {
                model: {
                    dmsId: '019EBskge7wn9',
                    type: 'ImageSingle',
                },
            },
        },
        {
            addCanvasDepth: true,
            boundToGrid: true,
            canvasIndex: 1,
            dmsId: '019EBskge7whp',
            position: { x: -9.98400000000248, y: 3.25, z: 2.750000000000001 },
            rotation: { x: 0, y: 1.570796326794, z: 0 },
            scale: 4.5,
            viewerData: {
                model: {
                    dmsId: '019EBskge7whp',
                    type: 'ImageSingle',
                },
            },
        },
        {
            addCanvasDepth: true,
            boundToGrid: true,
            canvasIndex: 1,
            dmsId: '019EBskge7whr',
            position: { x: -9.983999999996646, y: 3.25, z: -3.749999999999999 },
            rotation: { x: 0, y: 1.570796326794, z: 0 },
            scale: 4.5,
            viewerData: {
                model: {
                    dmsId: '019EBskge7whr',
                    type: 'ImageSingle',
                },
            },
        },
        {
            addCanvasDepth: true,
            boundToGrid: true,
            canvasIndex: 0,
            dmsId: '019EBskge7wnC',
            position: { x: 9.983999999991255, y: 3.25, z: -9.749999999999998 },
            rotation: { x: 0, y: -1.570796326794, z: 0 },
            scale: 4.5,
            viewerData: {
                model: {
                    dmsId: '019EBskge7wnC',
                    type: 'ImageSingle',
                },
            },
        },
        {
            addCanvasDepth: true,
            boundToGrid: false,
            canvasIndex: 1,
            dmsId: '019EBskge7wnA',
            position: { x: -9.983999999990836, y: 3.244793700801594, z: -10.223750719703746 },
            rotation: { x: 0, y: 1.570796326794, z: 0 },
            scale: 4.456078284171909,
            viewerData: {
                model: {
                    dmsId: '019EBskge7wnA',
                    type: 'ImageSingle',
                },
            },
        },
        {
            addCanvasDepth: true,
            boundToGrid: false,
            canvasIndex: 1,
            dmsId: '019EBskge7wnG',
            position: { x: -9.983999999985246, y: 3.252136095352164, z: -16.496983443067226 },
            rotation: { x: 0, y: 1.570796326794, z: 0 },
            scale: 4.45394478117935,
            viewerData: {
                model: {
                    dmsId: '019EBskge7wnG',
                    type: 'ImageSingle',
                },
            },
        },
        {
            addCanvasDepth: true,
            boundToGrid: false,
            canvasIndex: 2,
            dmsId: '019EBskge8HZk',
            position: { x: -3.4459999999999997, y: 4.0085979106469996, z: -19.979000000000035 },
            rotation: { x: 0, y: 0, z: 0 },
            scale: 0.1,
            viewerData: {
                model: {
                    dmsId: '019EBskge8HZk',
                    type: 'ImageSingle',
                },
            },
        },
        {
            addCanvasDepth: false,
            boundToGrid: false,
            canvasIndex: 2,
            dmsId: '019EBukYy1PxC',
            position: { x: -4.549893067162703, y: 2.917839520132335, z: -19.979 },
            rotation: { x: 0, y: 0, z: 0 },
            scale: 10.845352188535326,
            viewerData: {
                model: {
                    dmsId: '019EBukYy1PxC',
                    type: 'ImageSingle',
                },
            },
        },
        {
            addCanvasDepth: false,
            boundToGrid: false,
            canvasIndex: 2,
            dmsId: '019EBvjXZQt8Y',
            position: { x: 5.302777777777369, y: 3, z: -19.979 },
            rotation: { x: 0, y: 0, z: 0 },
            scale: 9.394444444445195,
            viewerData: {
                model: {
                    dmsId: '019EBvjXZQt8Y',
                    type: 'ImageSingle',
                },
            },
        }
    ],
    lights: [
        {
            intensity: 1,
            type: 'ambient',
        },
    ],
    roomModelDmsId: '013Aktb88DRA',
    startPosition: { x: -9, y: 1.75, z: 0 },
    texts: [
        {
            boundToGrid: true,
            canvasIndex: 0,
            color: '#171717',
            maxWidth: 0.5,
            paragraphs: [
                {
                    fontSize: 0.2,
                },
            ],
            position: { x: 9.984000000006949, y: 2.75, z: 7.750000000000001 },
            rotation: { x: 0, y: -1.570796326794, z: 0 },
            textAlign: 'left',
        },
        {
            boundToGrid: false,
            canvasIndex: 3,
            color: '#ffffff',
            maxWidth: 4.140216710380963,
            paragraphs: [
                {
                    fontFamily: 'https://dms-cf-01.dimu.org/file/019EBtkSxnGyG',
                    fontSize: 0.175,
                    lineHeight: 1.3,
                    text: 'På banen brukes uniformer, driftsreglement',
                    textAlign: 'left',
                },
            ],
            position: { x: 6.292310746223268, y: 0.6171685115294094, z: 19.979000000004998 },
            rotation: { x: 0, y: 3.141592653589, z: 0  },
            textAlign: 'left',
        },
        {
            boundToGrid: false,
            canvasIndex: 3,
            color: '#ffffff',
            maxWidth: 3.1235251426919746,
            paragraphs: [
                {
                    fontFamily: 'https://dms-cf-01.dimu.org/file/019EBtkSxnGyG',
                    fontSize: 0.175,
                    text: 'og teknikker fra tiden banen gikk,\n',
                },
            ],
            position: { x: -0.32767986261592547, y: 0.5413786869012625, z: 19.978999999999758 },
            rotation: { x: 0, y: 3.141592653589, z: 0 },
            textAlign: 'left',
        },
        {
            boundToGrid: true,
            canvasIndex: 3,
            color: '#ffffff',
            maxWidth: 3,
            paragraphs: [
                {
                    fontFamily: 'https://dms-cf-01.dimu.org/file/019EBtkSxnGyG',
                    fontSize: 0.175,
                    text: 'i tillegg til rullende materiell.'
                }
            ],
            position: { x: -6.499999999999999, y: 0.75, z: 19.978999999994848 },
            rotation: { x: 0, y: 3.141592653589, z: 0 },
            textAlign: 'left',
        }
    ],
}