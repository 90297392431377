import './index.css';
import reportWebVitals from './reportWebVitals';
import { createRoot } from 'react-dom/client';
import { Demo } from './demo/Demo';
import { App } from './3droom/App/App';
import { JssProvider } from 'react-jss';

const roots  = {};

window.kit3d = {
  renderDemo: args => {
    const container = document.getElementById(args.containerId);

    if (!roots[args.containerId]) {
      roots[args.containerId] = createRoot(container);
    }

    roots[args.containerId].render(
      <JssProvider classNamePrefix="kit3d-demo-">
        <Demo {...args} />
      </JssProvider>
    );
  },
  render3dRoom: args => {
    const container = document.getElementById(args.containerId);

    if (!roots[args.containerId]) {
      roots[args.containerId] = createRoot(container);
    }

    roots[args.containerId].render(
      <JssProvider classNamePrefix="kit3d-3dRoom-">
        <App {...args} />
      </JssProvider>
    );
  },
  unmount: containerId => {
    if (roots[containerId]) {
      const container = roots[containerId];
      container.unmount();
      roots[containerId] = null;
    }
  },
};

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
