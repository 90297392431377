import * as Icon from '@mui/icons-material';
import { AccordionDetails, Button, Checkbox, Divider, FormControlLabel, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { computeBoundingBoxSize } from '../../../helpers';
import { SliderInput } from '../../../components/SliderInput';

export const QuizSettings = ({
  index,
  quizItem,
  canvasHeight,
  canvasWidth,
  cellSize,
  onUpdate,
  onRemove,
  getSceneElements,
}) => {
  const [params, setParams] = useState();

  const update = (quiz) => {
    setParams(getParams(quiz));
    onUpdate && onUpdate(index, quiz);
  };

  const getScaleLimit = (localPosition, size) => {
    const topEdge = localPosition.y + (size.z / 2);
    const bottomEdge = localPosition.y - (size.z / 2);
    const rightEdge = localPosition.x + (size.x / 2);
    const leftEdge = localPosition.x - (size.x / 2);

    const topLimit = canvasHeight / 2;
    const bottomLimit = canvasHeight / -2;
    const rightLimit = canvasWidth / 2;
    const leftLimit = canvasWidth / -2;

    const topScaleMax = quizItem.scale.y * (((topLimit - topEdge) * 2) + size.z) / size.z;
    const bottomScaleMax = quizItem.scale.y * (((bottomEdge - bottomLimit) * 2) + size.z) / size.z;
    const rightScaleMax = quizItem.scale.x * (((rightLimit - rightEdge) * 2) + size.x) / size.x;
    const leftScaleMax = quizItem.scale.x * (((leftEdge - leftLimit) * 2) + size.x) / size.x;

    return Math.min(topScaleMax, bottomScaleMax, rightScaleMax, leftScaleMax);
  };

  const getParams = (obj) => {
    quizItem = obj ?? quizItem;
    const { canvasRef, itemRef: quizRef } = getSceneElements();

    if (!canvasRef || !quizRef) return null;

    const localPosition = canvasRef.worldToLocal(quizItem.position.clone());
    const size = computeBoundingBoxSize(quizRef);
    const scaleLimit = getScaleLimit(localPosition, size);

    return {
      xPosition: {
        value: quizItem.boundToGrid
          ? (localPosition.x - (size.x / 2) + (canvasWidth / 2) + cellSize) / cellSize
          : localPosition.x - (size.x / 2) + (canvasWidth / 2),
        min: quizItem.boundToGrid ? 1 : 0,
        max: quizItem.boundToGrid
          ? (canvasWidth / cellSize) - Math.ceil(size.x / cellSize) + 1
          : canvasWidth - size.x,
        step: quizItem.boundToGrid ? 1 : 0.001,
        onChange: (value, last) => {
          const scaleOffset = (Math.ceil(size.x / cellSize) - 1) * cellSize / 2;
          value = quizItem.boundToGrid
            ? ((value - 1) * cellSize) - ((size.x - cellSize) / 2) + scaleOffset
            : value;
          const newPosition = localPosition.clone().setX(value - (canvasWidth / 2) + (size.x / 2));
          quizItem.position.copy(canvasRef.localToWorld(newPosition));
          quizRef.parent.parent.position.copy(quizItem.position);
          last && update(quizItem);
        },
      },
      yPosition: {
        value: quizItem.boundToGrid
          ? (localPosition.y - (size.y / 2) + (canvasHeight / 2) + cellSize) / cellSize
          : localPosition.y - (size.y / 2) + (canvasHeight / 2),
        min: quizItem.boundToGrid ? 1 : 0,
        max: quizItem.boundToGrid
          ? (canvasHeight / cellSize) - Math.ceil(size.y / cellSize) + 1
          : canvasHeight - size.y,
        step: quizItem.boundToGrid ? 1 : 0.001,
        onChange: (value, last) => {
          const scaleOffset = (Math.ceil(size.y / cellSize) - 1) * cellSize / 2;
          value = quizItem.boundToGrid
            ? ((value - 1) * cellSize) - ((size.y - cellSize) / 2) + scaleOffset
            : value;
          const newPosition = localPosition.clone().setY(value - (canvasHeight / 2) + (size.y / 2));
          quizItem.position.copy(canvasRef.localToWorld(newPosition));
          quizRef.parent.parent.position.copy(quizItem.position);
          last && update(quizItem);
        },
      },
      zPosition: {
        value: localPosition.z - (size.z / 2),
        min: 0,
        max: 5,
        step: 0.001,
        onChange: (value, last) => {
          const newPosition = localPosition.clone().setZ(value + (size.z / 2));
          quizItem.position.copy(canvasRef.localToWorld(newPosition));
          quizRef.parent.parent.position.copy(quizItem.position);
          last && update(quizItem);
        },
      },
      scale: {
        value: quizItem.scale.x,
        min: 1,
        max: Math.min(scaleLimit, 5),
        step: 0.001,
        onChange: (value, last) => {
          if (last) {
            const scaleDiff = (value - quizItem.scale.x) / quizItem.scale.x;
            const heightOffset = size.y * scaleDiff / 2;

            if ((size.y / 2) + heightOffset > localPosition.z) {
              const newPosition = localPosition.clone().setZ((size.y / 2) + heightOffset);
              quizItem.position.copy(canvasRef.localToWorld(newPosition));
              quizRef.position.copy(quizItem.position);
            }

            quizItem.scale.set(value, value, value);
            update(quizItem);
          }
        },
      },
      boundToGrid: {
        value: quizItem.boundToGrid,
        onChange: (event) => {
          quizItem.boundToGrid = event.target.checked;
          update(quizItem);
        },
      },
    };
  }

  useEffect(() => {
    setParams(getParams());
  }, [quizItem, quizItem.boundToGrid, quizItem.position.x, quizItem.position.y,
    quizItem.position.z, quizItem.scale.x, quizItem.scale.y]);

  return (
    <>
      {params && (
        <>
          <AccordionDetails>
            <div>
              <Typography>Posisjon</Typography>
              <SliderInput
                title={'X'}
                min={params.xPosition.min}
                max={params.xPosition.max}
                step={params.xPosition.step}
                value={params.xPosition.value}
                onChange={params.xPosition.onChange}
              />
              <SliderInput
                title={'Y'}
                min={params.yPosition.min}
                max={params.yPosition.max}
                step={params.yPosition.step}
                value={params.yPosition.value}
                onChange={params.yPosition.onChange}
              />
              <SliderInput
                title={'Z'}
                min={params.zPosition.min}
                max={params.zPosition.max}
                step={params.zPosition.step}
                value={params.zPosition.value}
                onChange={params.zPosition.onChange}
              />
            </div>
            <div>
              <Typography>Skalering</Typography>
              <SliderInput
                min={params.scale.min}
                max={params.scale.max}
                step={params.scale.step}
                value={params.scale.value}
                onChange={params.scale.onChange}
              />
            </div>
            <div>
              <FormControlLabel
                label="Følg grid"
                control={
                  <Checkbox
                    checked={params.boundToGrid.value}
                    onChange={params.boundToGrid.onChange}
                  />
                }
              />
            </div>
          </AccordionDetails>
          <Divider light />
          <AccordionDetails>
            <Button
              variant="outlined"
              size="small"
              startIcon={<Icon.DeleteOutlined fontSize="small" />}
              onClick={() => onRemove(index)}
            >
              Slett quiz
            </Button>
          </AccordionDetails>
        </>
      )}
    </>
  );
};
