import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles({
  name: {
    fontSize: 38,
    margin: 0,
    lineHeight: '120%',
  },
  description: {
    fontSize: 14,
    lineHeight: '150%',
  },
  link: {
    fontSize: 14,
    fontWeight: 'bold',
    textDecoration: 'none',
    color: '#000000',
    textDecorationLine: 'underline',
  },
});

export const ExhibitionInfo = ({ exhibition }) => {
  const styles = useStyles();

  return (
    <>
      <h3 className={styles.name}>{exhibition?.name}</h3>
      <p className={styles.description}>{exhibition?.description}</p>
      {exhibition?.url && 
        <a
          className={styles.link}
          href={exhibition.url}
          target="_blank"
          rel="noreferrer"
        >
          Les mer på utstillingssiden
        </a>
      }
    </>
  );
}