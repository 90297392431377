import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import { createUseStyles } from 'react-jss';
import * as Icon from 'react-feather';
import { SET_DRAWER_OPEN, useThreeDDispatch, useThreeDState } from '../3droom/App/ThreeDContext';

const useStyles = createUseStyles({
  drawer: {
    '&.MuiDrawer-root': {
      visibility: 'hidden',
      '& > .MuiPaper-root': {
        height: (props) => `calc(50% - ${props.swipeAreaWidth}px)`,
        backgroundColor: 'transparent',
        overflow: 'visible',
        touchAction: 'pan-y',
        pointerEvents: 'unset !important',
      },
    },
  },
  container: {
    position: 'absolute',
    top: (props) => `-${props.swipeAreaWidth}px`,
    bottom: 0,
    right: 0,
    left: 0,
    paddingTop: 20,
    paddingBottom: 20,
    borderTopLeftRadius: 8,
    borderTopRightRadius: 8,
    backgroundColor: '#FEFEFE',
    boxShadow: '0px -2px 14px 1px rgba(0, 0, 0, 0.14), 0px 4px 114px 3px rgba(0, 0, 0, 0.12)',
    marginLeft: 10,
    marginRight: 10,
    visibility: 'visible',
    pointerEvents: (props) => props.drawerOpen ? 'unset': 'none',
  },
  puller: {
    width: 30,
    height: 6,
    backgroundColor: '#D9D9D9',
    borderRadius: 3,
    position: 'absolute',
    top: 8,
    left: 'calc(50% - 15px)',
  },
  toggle: {
    position: 'absolute',
    top: (props) => `-${props.swipeAreaWidth - 10}px`,
    right: 18,
    visibility: 'visible',
    zIndex: 1,
  },
});

export const MobileDrawer = ({
  swipeAreaWidth = 20,
  onClose,
  children,
}) => {
  const { drawerOpen } = useThreeDState();
  const dispatch = useThreeDDispatch();
  const styles = useStyles({ swipeAreaWidth, drawerOpen });

  const toggleDrawer = (isOpen) => () => {
    dispatch({ type: SET_DRAWER_OPEN, drawerOpen: isOpen });
  };

  return (
    <SwipeableDrawer
      anchor="bottom"
      open={drawerOpen}
      onClose={toggleDrawer(false)}
      onOpen={toggleDrawer(true)}
      disableSwipeToOpen={false}
      swipeAreaWidth={swipeAreaWidth}
      hideBackdrop={true}
      className={styles.drawer}
      ModalProps={{
        keepMounted: true,
      }}
    >
      <div className={styles.toggle} onClick={onClose}>
        <Icon.X />
      </div>
      <div className={styles.container}>
        <div className={styles.puller} />
        {children}
      </div>
    </SwipeableDrawer>
  );
};
