export const demo12 = {
    roomModelDmsId: '013AhtPopuKW',
    startPosition: { x: -70, y: 6.57, z: 26 },
    directionOfView: { x: 0.0001, y: 0.00001, z: -0.00005 },
    dome: {
        skyShader: {
            distance: 10000,
            elevation: 35,
            azimuth: -140,
            turbidity: 1,
            rayleigh: 0.1,
            mieCoefficient: 0.005,
            mieDirectionalG: 0.99,
            exposure: 1,
        },
    },
    lights: [
        {
            type: 'ambient',
            intensity: 0.6,
        },
    ],
    models: [
        {
            dmsId: '0138WvJrNUWb',
            position: { x: -25.8, y: 21.43, z: -31.28 },
            rotation: { x: 0, y: 1.05, z: 0 },
            scale: { x: 9, y: 9, z: 9 },
            viewerData: {
                model: {
                    type: 'Object',
                    dmsId: '0138WvJrNUWb',
                },
            },
        },
        {
            dmsId: '0138WvJymkBb',
            position: { x: 40.55, y: 7.67, z: 20.16 },
            rotation: { x: 0, y: -2.1, z: 0 },
            scale: { x: 7, y: 7, z: 7 },
            viewerData: {
                model: {
                    type: 'Object',
                    dmsId: '0138WvJymkBb',
                },
            },
        },
    ],
}