import * as Icon from '@mui/icons-material';
import { Group } from './Group';
import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles({
  row: {
    display: 'flex',
    flexDirection: 'row',
    flexGrow: 1,
    justifyContent: 'space-between',
    padding: 8,
    cursor: 'move',
  },
  addIcon: {
    color: 'rgba(0, 0, 0, 0.54)',
    cursor: 'pointer',
  },
});

export const ExcludedGroup = ({
  id,
  name,
  color,
  onGroupAdd,
}) => {
  const styles = useStyles();

  return (
    <div className={styles.row}>
      <Group
        name={name}
        color={color}
      />
      <Icon.Add
        fontSize="small"
        className={styles.addIcon}
        onClick={() => onGroupAdd(id)}
      />
    </div>
  );
};
