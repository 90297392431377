export const demo11 = {
    roomModelDmsId: '0138WvJymk2p',
    startPosition: { x: 26.5, y: 5.5, z: -48 },
    directionOfView: { x: -0.0004, y: 0, z: 0.001 },
    dome: {
        skyShader: {
            distance: 10000,
            elevation: 35,
            azimuth: -50,
            turbidity: 1,
            rayleigh: 0.1,
            mieCoefficient: 0.005,
            mieDirectionalG: 0.99,
            exposure: 0.8,
        },
    },
    lights: [
        {
            type: 'ambient',
            intensity: 0.5,
        },
    ],
    models: [
        {
            dmsId: '0138WvJrNUWb',
            position: { x: -36.62, y: 17.66, z: -23 },
            rotation: { x: 0, y: 1.95, z: 0 },
            scale: { x: 7, y: 7, z: 7 },
            viewerData: {
                model: {
                    type: 'Object',
                    dmsId: '0138WvJrNUWb',
                },
            },
        },
        {
            dmsId: '0138WvJymkBb',
            position: { x: 34.47, y: 8.36, z: 20.28 },
            rotation: { x: -0.06, y: -2, z: -0.05 },
            scale: { x: 6, y: 6, z: 6 },
            viewerData: {
                model: {
                    type: 'Object',
                    dmsId: '0138WvJymkBb',
                },
            },
        },
    ],
}