import * as THREE from 'three';
import { Suspense, useEffect, useMemo, useState } from 'react';
import { useFrame } from '@react-three/fiber';
import { useDrag } from '@use-gesture/react';
import { getDmsFileUrl } from '@ekultur/dms-url-generator';
import { useAnimations } from '@react-three/drei';
import { PositionalAudio } from '../Audio/PositionalAudio';
import { Pedestal } from '../Pedestal/Pedestal';
import { CanvasItemTypes, ControlModes } from '../../helpers/enums';
import { DragPlane } from '../../components/DragPlane';
import { useThreeDState } from '../App/ThreeDContext';
import {
  enableShadows,
  playAnimation,
  preloadMesh,
  centerModel,
  useGLTF,
  copyScene,
  setUserData,
} from '../../helpers';

export const Model = ({
  modelUrl,
  dmsId,
  position,
  rotation,
  scale,
  rotate = false,
  rotationAxis,
  animation,
  audio,
  name,
  pedestal,
  editMode = false,
  dragPositionOffset,
  onClick,
  onPointerMove,
  viewerData,
}) => {
  const { userInteracted, controlMode } = useThreeDState();
  const { scene, animations } = useGLTF(dmsId ? getDmsFileUrl(dmsId, '01') : modelUrl);
  const { ref, actions } = useAnimations(animations);
  const [ visible, setVisible ] = useState(false);
  const copiedScene = useMemo(() => {
    const copy = copyScene(scene);
    enableShadows(copy);
    setUserData(copy, {
      itemName: name,
      itemType: CanvasItemTypes.MODEL3D,
      interactable: !!viewerData,
    });
    return copy;
  }, [scene]);

  useEffect(() => {
    animation && playAnimation(actions, animation);
    preloadMesh(ref.current);
  });

  useEffect(() => {
    centerModel(ref.current);
    setVisible(true);
  }, [ref]);

  useFrame(() => {
    if (rotate && rotationAxis && ref.current) {
      ref.current.rotation[rotationAxis] += 0.01;
    }
  });

  const handleClick = ({ event, tap }) => {
    if (tap) {
      const pointerLockElement = document.pointerLockElement || document.mozPointerLockElement || document.webkitPointerLockElement;

      if (!(controlMode === ControlModes.POINTER_LOCK_CONTROL && !pointerLockElement)) {
        const intersection = event.intersections.reduce((acc, obj) => (obj.distance < acc.distance ? obj : acc));

        if (onClick && intersection.object === event.object) {
          onClick();
        }
      }
    }
  };

  const useGestures = () => {
    const drag = useDrag(handleClick, { filterTaps: true });
    return !editMode ? drag() : {};
  };

  return (
    <group
      position={[position.x, position.y, position.z]}
      visible={visible}
    >
      <group
        rotation={[rotation.x, rotation.y, rotation.z]}
        scale={[scale.x, scale.y, scale.z]}
      >
        <primitive
          ref={ref}
          name={name}
          object={copiedScene}
          {...useGestures()}
        />
      </group>
      {userInteracted && audio &&
        <PositionalAudio {...audio} />
      }
      {pedestal &&
        <Suspense fallback={null}>
          <Pedestal
            name={name}
            {...pedestal}
            rotation={pedestal.lockedRotation ? new THREE.Euler(0, rotation.y, 0) : pedestal.rotation}
          />
        </Suspense>
      }
      {dragPositionOffset &&
        <DragPlane
          position={position}
          dragPositionOffset={dragPositionOffset.clone().multiply(scale)}
          onPointerMove={onPointerMove}
        />
      }
    </group>
  );
};
