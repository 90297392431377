export const demo26 = {
    roomModelDmsId: '013AkPUwR1rX',
    startPosition: { x: 0, y: 1.75, z: 18 },
    directionOfView: { x: 0, y: 0, z: -0.001 },
    lights: [
        {
            type: 'ambient',
            intensity: 1,
        },
    ],
    models: [
        {
            dmsId: '013Aksh1CT98',
            position: { x: 5.5, y: 1, z: -18.4 },
            rotation: { x: 0, y: Math.PI / -4, z: 0 },
            scale: { x: 0.01, y: 0.01, z: 0.01 },
            canvasIndex: 0,
            boundToGrid: true,
            pedestal: {
                scale: { x: 1.65, y: 0.75, z: 0.5 },
                position: { x: 0, y: -0.62, z: 0 },
                rotation: { x: 0, y: Math.PI / -4, z: 0, },
                lockedRotation: true,
            },
            viewerData: {
                model: {
                    type: 'Object',
                    dmsId: '013Aksh1CT98',
                },
            },
        },
        {
            dmsId: '013Aksh1CT99',
            position: { x: -20.3, y: 2.93, z: -13 },
            rotation: { x: Math.PI / -2, y: 0, z: 0 },
            scale: { x: 0.008, y: 0.008, z: 0.008 },
            canvasIndex: 0,
            boundToGrid: true,
            viewerData: {
                model: {
                    type: 'Object',
                    dmsId: '013Aksh1CT99',
                },
            },
        },
    ],
    images: [
        {
            dmsId: '013Aksh1CTSp',
            scale: 1.5,
            position: { x: -0.5, y: 1, z: -5.58 },
            rotation: { x: 0, y: 0, z: 0 },
            addCanvasDepth: false,
        },
        {
            dmsId: '013Aksh1CTHy',
            scale: 5,
            position: { x: -2.8, y: 3, z: 19.99 },
            rotation: { x: 0, y: Math.PI, z: 0 },
            addCanvasDepth: false,
        },
        {
            dmsId: '013Aksh1CTHq',
            scale: 7.1,
            position: { x: -1.465, y: 2, z: -11.48 },
            rotation: { x: 0, y: -0.7766, z: 0 },
            addCanvasDepth: false,
        },
        {
            dmsId: '013Aksh1CTHt',
            scale: 2.5,
            position: { x: 7, y: 1.8, z: -1 },
            rotation: { x: 0, y: Math.PI / -2, z: 0 },
            viewerData: {
                model: {
                    type: 'ImageSingle',
                    dmsId: '013Aksh1CTHt',
                },
            },
        },
        {
            dmsId: '013Aksh1CTHu',
            scale: 2.5,
            position: { x: 7, y: 1.8, z: 2.6 },
            rotation: { x: 0, y: Math.PI / -2, z: 0 },
            viewerData: {
                model: {
                    type: 'ImageSingle',
                    dmsId: '013Aksh1CTHu',
                },
            },
        },
        {
            dmsId: '013Aksh1CTHv',
            scale: 3.05,
            position: { x: 7, y: 1.8, z: 6.5 },
            rotation: { x: 0, y: Math.PI / -2, z: 0 },
            viewerData: {
                model: {
                    type: 'ImageSingle',
                    dmsId: '013Aksh1CTHv',
                },
            },
        },
        {
            dmsId: '013Aksh1CTHw',
            scale: 4.6,
            position: { x: 7, y: 1.8, z: 11.5 },
            rotation: { x: 0, y: Math.PI / -2, z: 0 },
            viewerData: {
                model: {
                    type: 'ImageSingle',
                    dmsId: '013Aksh1CTHw',
                },
            },
        },
        {
            dmsId: '013Aksh1CTHx',
            scale: 2.5,
            position: { x: 7, y: 1.8, z: 16.1 },
            rotation: { x: 0, y: Math.PI / -2, z: 0 },
            viewerData: {
                model: {
                    type: 'ImageSingle',
                    dmsId: '013Aksh1CTHx',
                },
            },
        },
        {
            dmsId: '013Aksh1CTHs',
            scale: 5,
            position: { x: -7, y: 2.8, z: 11 },
            rotation: { x: 0, y: Math.PI / 2, z: 0 },
            viewerData: {
                model: {
                    type: 'ImageSingle',
                    dmsId: '013Aksh1CTHs',
                },
            },
        },
        {
            dmsId: '013Aksh1CTSf',
            scale: 2,
            position: { x: -7, y: 3.8, z: 6.6 },
            rotation: { x: 0, y: Math.PI / 2, z: 0 },
            viewerData: {
                model: {
                    type: 'ImageSingle',
                    dmsId: '013Aksh1CTSf',
                },
            },
        },
        {
            dmsId: '013Aksh1CTNQ',
            scale: 2,
            position: { x: -7, y: 3.8, z: 4.1 },
            rotation: { x: 0, y: Math.PI / 2, z: 0 },
            viewerData: {
                model: {
                    type: 'ImageSingle',
                    dmsId: '013Aksh1CTNQ',
                },
            },
        },
        {
            dmsId: '013Aksh1CTNP',
            scale: 1.533,
            position: { x: -7, y: 3.8, z: 2 },
            rotation: { x: 0, y: Math.PI / 2, z: 0 },
            viewerData: {
                model: {
                    type: 'ImageSingle',
                    dmsId: '013Aksh1CTNP',
                },
            },
        },
        {
            dmsId: '013Aksh1CTSg',
            scale: 2,
            position: { x: -7, y: 3.8, z: -0.1 },
            rotation: { x: 0, y: Math.PI / 2, z: 0 },
            viewerData: {
                model: {
                    type: 'ImageSingle',
                    dmsId: '013Aksh1CTSg',
                },
            },
        },
        {
            dmsId: '013Aksh1CTSh',
            scale: 2,
            position: { x: -7, y: 1.8, z: 6.6 },
            rotation: { x: 0, y: Math.PI / 2, z: 0 },
            viewerData: {
                model: {
                    type: 'ImageSingle',
                    dmsId: '013Aksh1CTSh',
                },
            },
        },
        {
            dmsId: '013Aksh1CTSi',
            scale: 1.533,
            position: { x: -7, y: 1.8, z: 4.6 },
            rotation: { x: 0, y: Math.PI / 2, z: 0 },
            viewerData: {
                model: {
                    type: 'ImageSingle',
                    dmsId: '013Aksh1CTSi',
                },
            },
        },
        {
            dmsId: '013Aksh1CTSj',
            scale: 2.25,
            position: { x: -7, y: 1.8, z: 2.5 },
            rotation: { x: 0, y: Math.PI / 2, z: 0 },
            viewerData: {
                model: {
                    type: 'ImageSingle',
                    dmsId: '013Aksh1CTSj',
                },
            },
        },
        {
            dmsId: '013Aksh1CTSk',
            scale: 2,
            position: { x: -7, y: 1.8, z: -0.1 },
            rotation: { x: 0, y: Math.PI / 2, z: 0 },
            viewerData: {
                model: {
                    type: 'ImageSingle',
                    dmsId: '013Aksh1CTSk',
                },
            },
        },
    ],
    texts: [
        {
            position: { x: 1.5, y: 3, z: -5.585 },
            rotation: { x: 0, y: 0, z: 0 },
            maxWidth: 5.4,
            paragraphs: [
                {
                    text: 'Rekonstruksjon av middelalderbåten Vaterland 1. En liten båt med lang historie.',
                    fontSize: 0.4,
                },
                {
                    text: '9. februar 2011 ble det oppdaget et båtfunn i forbindelse med gravearbeid i Schweigaardsgate 8, utenfor bussterminalen ved Vaterland i Oslo sentrum. Funnet viste rester etter et lite klinkbygd fartøy datert til tidlig 1500-tall (1502-1505). Båtfunnet fikk navnet Vaterland 1.',
                    fontSize: 0.18,
                    margin: {
                        top: 0.3,
                    },
                },
            ],
        },
        {
            position: { x: 6.99, y: 3.75, z: 8.5 },
            rotation: { x: 0, y: Math.PI / -2, z: 0 },
            maxWidth: 5,
            paragraphs: [
                {
                    text: 'Funnene',
                    fontSize: 0.5,
                },
            ],
        },
        {
            position: { x: -6.99, y: 2.9, z: 16.5 },
            rotation: { x: 0, y: Math.PI / 2, z: 0 },
            maxWidth: 4,
            paragraphs: [
                {
                    text: 'Funnsituasjon',
                    fontSize: 0.5,
                },
                {
                    text: 'I dag ligger området Vaterland 1 (Id. 142002) ble funnet i langt inn på land. For 500 år siden var imidlertid vannstanden 1,7 meter høyere enn dagens nivå. Kart fra 1684 viser at Vaterland 1 må ha blitt deponert ved utløpet av Akerselva.',
                    fontSize: 0.15,
                    margin: {
                        top: 0.2,
                    },
                },
                {
                    text: 'Den arkeologiske feltundersøkelsen ble gjennomført i samarbeid med Byantikvaren, Oslo kommune, som først oppdaget kulturminnet (Carrasco og Vangstad 2021). Selve vraket hadde en utstrekning på 2 x 5 meter og besto av tre bordganger på hver side, lagt på en 3,8 meter lang t-kjøl.',
                    fontSize: 0.15,
                    margin: {
                        top: 0.1,
                    },
                },
                {
                    text: 'Akterenden av skroget var borte, og verken for- eller akterstevn var bevart. Det ble registrert sju band i tilknytning til båtfunnet. Av disse var kun fem bunnstokker in situ, plassert i fremre halvdel av båten. Bunnstokkene besto av annenhver i eik og annenhver i furu. De resterende delene besto hovedsakelig av eik, med unntak av en del i nåletre tolket som list eller esing.',
                    fontSize: 0.15,
                    margin: {
                        top: 0.1,
                    },
                },
            ],
        },
        {
            position: { x: 2.8, y: 3, z: 19.99 },
            rotation: { x: 0, y: Math.PI, z: 0 },
            maxWidth: 3.5,
            paragraphs: [
                {
                    text: 'Bunnstokk',
                    fontSize: 0.5,
                },
                {
                    text: 'Det er fire bordgangsflater på den ene siden og fem på den andre siden av et vågriss. Det er mulig at det har vært en ekstra bordgangsflate på hver side. De tre trenaglehullene på hver side av senter av bunnstokken har alle spor som indikerer startmerker for borring på innside.',
                    fontSize: 0.15,
                    margin: {
                        top: 0.2,
                    },
                },
                {
                    text: 'Trenaglehullet i senter og de to ytterste på hver side har ingen merker, noe som tyder på tilstedeværelsen av et overliggende kjølsvin. Jernnagler finnes bare på utsiden av bunnstokken. Bunnstokken er ikke festet til kjølen.',
                    fontSize: 0.15,
                    margin: {
                        top: 0.1,
                    },
                },
            ],
        },
    ],
    videos: [
        {
            dmsId: '013Aksh1CTHz',
            width: 7.5,
            height: 4,
            position: { x: -1.28, y: 2, z: -11.66 },
            rotation: { x: 0, y: 2.365, z: 0 },
            autoPlay: true,
        },
    ],
}