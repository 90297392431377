import { Billboard } from '@react-three/drei';
import { animated, useSpring, easings } from '@react-spring/three';
import { useDrag } from '@use-gesture/react';
import { useEffect, useRef } from 'react';
import { DragPlane } from '../../components/DragPlane';
import { CanvasItemTypes, ControlModes } from '../../helpers/enums';
import { setUserData } from '../../helpers';
import { FloorMarker } from './FloorMarker';
import { Icon } from './Icon/Icon';
import { TextContent } from './TextContent';
import { useThreeDState } from '../App/ThreeDContext';

export const Tour = ({
  name,
  title,
  position,
  isHighlighted = false,
  editMode = false,
  dragPositionOffset,
  onClick,
  onPointerMove,
}) => {
  const { controlMode } = useThreeDState();
  const ref = useRef();

  const { scale, movement } = useSpring({
    loop: true,
    to: [
      { scale: 0.85, movement: [0, 0.1, 0] },
      { scale: 1, movement: [0, 0, 0] },
    ],
    from: { scale: 1, movement: [0, 0, 0] },
    config: {
      duration: 2500,
      easing: easings.easeInOutCubic,
    },
    cancel: editMode,
  });

  useEffect(() => {
    setUserData(ref.current, {
      itemName: name,
      itemType: CanvasItemTypes.TOUR,
      interactable: true,
    });
  }, [ref, name]);

  const handleClick = ({ event, tap }) => {
    if (tap) {
      const pointerLockElement = document.pointerLockElement || document.mozPointerLockElement || document.webkitPointerLockElement;

      if (!(controlMode === ControlModes.POINTER_LOCK_CONTROL && !pointerLockElement)) {
        const intersection = event.intersections.reduce((acc, obj) => (obj.distance < acc.distance ? obj : acc));

        if (onClick && intersection.object === event.object) {
          onClick();
        }
      }
    }
  };

  const useGestures = () => {
    const drag = useDrag(handleClick, { filterTaps: true });
    return !editMode ? drag() : {};
  };

  return (
    <group
      ref={ref}
      position={position}
      userData={{ traversable: true }}
      {...useGestures()}
    >
      <group name={name}>
        <animated.group position={movement}>
          <Billboard>
            <Icon isHighlighted={isHighlighted} />
          </Billboard>
        </animated.group>
        <animated.group scale={scale}>
          <FloorMarker isHighlighted={isHighlighted} />
        </animated.group>
      </group>
      <animated.group position={movement}>
        <Billboard>
          <TextContent title={title} />
        </Billboard>
      </animated.group>
      {dragPositionOffset &&
        <DragPlane
          position={position}
          dragPositionOffset={dragPositionOffset.clone()}
          onPointerMove={onPointerMove}
        />
      }
    </group>
  );
};
