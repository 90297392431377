import {useEffect, useState} from "react";

const lineHeights = [
    1,
    1.1,
    1.2,
    1.3,
    1.4,
    1.5
];

export const useLineHeightOptions = () => {
    const [lineHeightOptions, setLineHeightOptions] = useState([])

    useEffect(
        () => {
            setLineHeightOptions(
                lineHeights.map(option => ({
                    value: option,
                    text: option.toLocaleString(),
                }))
            )
        }, []
    )

    return lineHeightOptions
}