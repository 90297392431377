import { SVGLoader } from 'three/examples/jsm/loaders/SVGLoader';

export const Outline = ({ isHighlighted }) => {
  const { paths } = new SVGLoader().parse(
    `<path
      d="
        M190 95C190 152.287 122.291 238.965 101.197 264.578C97.9646 268.503 92.054 268.493 88.837 264.555C67.782 238.784 0 151.436 0 95C0 42.5329 42.5329 0 95 0C147.467 0 190 42.5329 190 95Z
        M187.5 95C187.5 108.87 183.391 124.691 176.772 141.129C170.163 157.541 161.113 174.419 151.375 190.364C131.898 222.253 109.78 250.223 99.2672 262.988C97.0367 265.697 92.9916 265.689 90.773 262.973C80.2782 250.128 58.1495 221.948 38.6575 189.976C28.9116 173.989 19.8533 157.101 13.2376 140.741C6.61062 124.353 2.5 108.653 2.5 95C2.5 43.9137 43.9137 2.5 95 2.5C146.086 2.5 187.5 43.9137 187.5 95Z
      "
      fill-rule="evenodd"
    />`
  );
  const shape = SVGLoader.createShapes(paths[0]);

  return (
    <mesh scale={[1/268, 1/268, 1/268]}>
      <extrudeGeometry args={[shape, { depth: 0 }]} />
      <meshStandardMaterial
        color={0x000000}
        opacity={isHighlighted ? 0.4 : 0.2}
        transparent
      />
    </mesh>
  );
};
