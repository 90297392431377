const vertexShader = `
  uniform vec2 uRepeat;

  varying vec2 vUv1;
  varying vec2 vUv2;

  void main()
  {
    vUv1 = uv * uRepeat;
    vUv2 = uv;
    gl_Position = projectionMatrix * viewMatrix * modelMatrix * vec4(position, 1.0);
  }
`;

const fragmentShader = `
  uniform sampler2D uTexture1;
  uniform sampler2D uTexture2;

  varying vec2 vUv1;
  varying vec2 vUv2;

  void main() {
    vec4 t1 = texture2D(uTexture1, vUv1);
    vec4 t2 = texture2D(uTexture2, vUv2);
    float alpha = t2.r * (1.0/3.0) + t2.g * (1.0/3.0) + t2.b * (1.0/3.0);
    gl_FragColor = vec4(t1.r, t1.g, t1.b, alpha);
  }
`;

export { vertexShader, fragmentShader };
