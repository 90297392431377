export const useAddCanvasIdIfMissing = data => {
    if(data.canvases) {
        return {
            ...data,
            canvases: data.canvases.map((canvas, index) => ({
                ...canvas,
                id: canvas.id ? canvas.id : index + 1
            }))
        }
    } else {
        return data
    }

}