import * as THREE from 'three';
import { Suspense, useEffect, useState } from 'react';
import { useThree } from '@react-three/fiber';
import { CursorTypes } from '../../helpers/enums';
import { RescaleButton } from '../../components/RescaleButton';

export const Buttons = ({
  position,
  rotation,
  maxWidth,
  textName,
  onRescale,
}) => {
  const { scene, camera } = useThree();
  const [buttonPositions, setButtonPositions] = useState();
  const distance = Math.max(camera.position.distanceTo(position), 1.5);

  useEffect(() => {
    setButtonPositions(
      {
        centerLeft: new THREE.Vector3(
          maxWidth / -2,
          0,
          0.001,
        ),
        centerRight: new THREE.Vector3(
          maxWidth / 2,
          0,
          0.001,
        ),
      }
    );
  }, [position, position.x, position.y, position.z, maxWidth, textName, scene.children]);

  return (
    <group
      name={`${textName}-buttons`}
      position={[position.x, position.y, position.z]}
      rotation={[rotation.x, rotation.y, rotation.z]}
      visible={!!buttonPositions}
    >
      <Suspense fallback={null}>
        <RescaleButton
          position={buttonPositions?.centerLeft}
          distance={distance}
          cursor={CursorTypes.EW_RESIZE}
          onMove={(point, last) => onRescale(point, 'centerLeft', last)}
        />
        <RescaleButton
          position={buttonPositions?.centerRight}
          distance={distance}
          cursor={CursorTypes.EW_RESIZE}
          onMove={(point, last) => onRescale(point, 'centerRight', last)}
        />
      </Suspense>
    </group>
  );
};
