export const demo21 = {
    adminMode: true,
    roomModelDmsId: '019EBvjyi3Yut',
    startPosition: { x: 0, y: 1.75, z: 18 },
    directionOfView: { x: 0, y: 0, z: -0.001 },
    lights: [
        {
            type: 'ambient',
            intensity: 0.75,
        },
    ],
    images: [
        {
            dmsId: '013AkPUwQMRT',
            scale: 1.5,
            position: { x: 6.989, y: 1.8, z: -3.3 },
            rotation: { x: 0, y: Math.PI / -2, z: 0 },
            canvasIndex: 4,
            addCanvasDepth: true,
            boundToGrid: false,
            group: {
                id: 0,
                index: 0,
            },
            viewerData: {
                model: {
                    type: 'ImageSingle',
                    dmsId: '013AkPUwQMRT',
                },
            },
        },
        {
            dmsId: '013AkPUwQMRV',
            scale: 1.5,
            position: { x: 6.989, y: 1.8, z: -1 },
            rotation: { x: 0, y: Math.PI / -2, z: 0 },
            canvasIndex: 4,
            addCanvasDepth: true,
            boundToGrid: false,
            group: {
                id: 0,
                index: 1,
            },
            viewerData: {
                model: {
                    type: 'ImageDouble',
                    dmsId: '013AkPUwQMRV',
                    backsideDmsId: '013AkPUwQMRT',
                },
            },
        },
        {
            dmsId: '013AkPUwQMRW',
            scale: 1.5,
            position: { x: 6.989, y: 1.8, z: 1.3 },
            rotation: { x: 0, y: Math.PI / -2, z: 0 },
            canvasIndex: 4,
            addCanvasDepth: true,
            boundToGrid: false,
            group: {
                id: 0,
                index: 2,
            },
        },
        {
            dmsId: '013AkPUwQMRX',
            scale: 1.5,
            position: { x: 6.989, y: 1.8, z: 3.6 },
            rotation: { x: 0, y: Math.PI / -2, z: 0 },
            canvasIndex: 4,
            addCanvasDepth: true,
            boundToGrid: false,
            group: {
                id: 0,
                index: 3,
            },
        },
        {
            dmsId: '013AkPUwQMRY',
            scale: 1.5,
            position: { x: 6.989, y: 1.8, z: 5.8 },
            rotation: { x: 0, y: Math.PI / -2, z: 0 },
            canvasIndex: 4,
            addCanvasDepth: true,
            boundToGrid: false,
            group: {
                id: 0,
                index: 4,
            },
        },
        {
            dmsId: '013AkPUwQMRZ',
            scale: 3.276,
            position: { x: 6.989, y: 2.86, z: 8.5 },
            rotation: { x: 0, y: Math.PI / -2, z: 0 },
            canvasIndex: 4,
            addCanvasDepth: true,
            boundToGrid: false,
            group: {
                id: 1,
                index: 0,
            },
        },
        {
            dmsId: '013AkPUwQMRa',
            scale: 1.5,
            position: { x: 6.989, y: 1.8, z: 11.2 },
            rotation: { x: 0, y: Math.PI / -2, z: 0 },
            canvasIndex: 4,
            addCanvasDepth: true,
            boundToGrid: false,
            group: {
                id: 1,
                index: 1,
            },
        },
        {
            dmsId: '013AkPUwQgDd',
            scale: 1.5,
            position: { x: 6.989, y: 1.8, z: 13.4 },
            rotation: { x: 0, y: Math.PI / -2, z: 0 },
            canvasIndex: 4,
            addCanvasDepth: true,
            boundToGrid: false,
            group: {
                id: 1,
                index: 2,
            },
        },
        {
            dmsId: '013AkPUwQgDe',
            scale: 1.5,
            position: { x: 6.989, y: 1.8, z: 15.7 },
            rotation: { x: 0, y: Math.PI / -2, z: 0 },
            canvasIndex: 4,
            addCanvasDepth: true,
            boundToGrid: false,
            group: {
                id: 1,
                index: 3,
            },
        },
        {
            dmsId: '013AkPUwQgDf',
            scale: 1.5,
            position: { x: 6.989, y: 1.8, z: 18 },
            rotation: { x: 0, y: Math.PI / -2, z: 0 },
            canvasIndex: 4,
            addCanvasDepth: true,
            boundToGrid: false,
            group: {
                id: 1,
                index: 4,
            },
        },
    ],
    videos: [
        {
            dmsId: '0136NvW9VTDQ',
            width: 4.5,
            height: 2.53,
            position: { x: 6.989, y: 4, z: 3.7 },
            rotation: { x: 0, y: Math.PI / -2, z: 0 },
            volume: 1,
            autoPlay: false,
            loop: true,
            boundToGrid: true,
            canvasIndex: 4,
        },
    ],
    texts: [
        {
            position: { x: 6.989, y: 4, z: -2 },
            rotation: { x: 0, y: Math.PI / -2, z: 0 },
            maxWidth: 4.2,
            paragraphs: [
                {
                    text: 'Fest og venner',
                    fontFamily: 'https://fonts.gstatic.com/s/dmsans/v11/rP2Hp2ywxg089UriCZOIGA.ttf',
                    fontSize: 0.6,
                },
            ],
            audio: {
                dmsId: '0138Ytponu6z',
                refDistance: 1.5,
                rolloffFactor: 3,
                volume: 2.5,
                loop: false,
                maxDistance: 10,
            },
            canvasIndex: 4,
            boundToGrid: false,
        },
        {
            position: { x: 6.989, y: 3.2, z: -2.07 },
            rotation: { x: 0, y: Math.PI / -2, z: 0 },
            maxWidth: 4,
            paragraphs: [
                {
                    text: 'Fritid – Hva hadde du gjort på fritiden hvis du hverken hadde mobil eller internett? Kanskje kan du få noen ideer av å se hva Niels Juel og hans venner og fylte tiden sin med.',
                    fontFamily: 'https://fonts.gstatic.com/s/dmsans/v11/rP2Hp2ywxg089UriCZOIGA.ttf',
                    fontSize: 0.15,
                },
            ],
            canvasIndex: 4,
            boundToGrid: false,
        },
    ],
    models: [
        {
            dmsId: '0138WvJrNUWY',
            position: { x: -0.5, y: 0.7131565381344377, z: 5.25 },
            rotation: { x: 0, y: 0, z: 0 },
            scale: { x: 1.2492894182859475, y: 1.2492894182859475, z: 1.2492894182859475 },
            boundToGrid: true,
            canvasIndex: 15,
            group: {
                id: 2,
                index: 0,
            },
            viewerData: {
                model: {
                    type: 'Object',
                    dmsId: '0138WvJrNUWY',
                },
            },
        },
    ],
    audios: [
        {
            dmsId: '013AktN34DVw',
            positioning: true,
            position: { x: 6.989, y: 2, z: 19.45 },
            rotation: { x: 0, y: -1.5707963267948966, z: 0 },
            volume: 1.4,
            maxDistance: 100,
            scale: 2.5,
            interactive: true,
            loop: false,
            boundToGrid: true,
            canvasIndex: 4,
        },
    ],
    canvases: [
        {
            type: 'Wall',
            position: { x: 1.5, y: 3, z: -5.58 },
            rotation: { x: 0, y: 0, z: 0 },
            dimensions: {
                cellSize: 0.5,
                columns: 22,
                rows: 12,
            },
        },
        {
            type: 'Wall',
            position: { x: 0, y: 3, z: 19.985 },
            rotation: { x: 0, y: Math.PI, z: 0 },
            dimensions: {
                cellSize: 0.5,
                columns: 28,
                rows: 12,
            },
        },
        {
            type: 'Wall',
            position: { x: -7.408, y: 3, z: -14.495 },
            rotation: { x: 0, y: Math.PI / -2, z: 0 },
            dimensions: {
                cellSize: 0.5,
                columns: 22,
                rows: 12,
            },
        },
        {
            type: 'Wall',
            position: { x: -32.99, y: 3, z: -13 },
            rotation: { x: 0, y: Math.PI / 2, z: 0 },
            dimensions: {
                cellSize: 0.5,
                columns: 28,
                rows: 12,
            },
        },
        {
            type: 'Wall',
            position: { x: 6.99, y: 3, z: 7.2 },
            rotation: { x: 0, y: Math.PI / -2, z: 0 },
            dimensions: {
                cellSize: 0.5,
                columns: 51,
                rows: 12,
            },
        },
        {
            type: 'Wall',
            position: { x: -6.995, y: 3, z: 8.51 },
            rotation: { x: 0, y: Math.PI / 2, z: 0 },
            dimensions: {
                cellSize: 0.5,
                columns: 46,
                rows: 12,
            },
        },
        {
            type: 'Wall',
            position: { x: -20.2, y: 3, z: -19.97 },
            rotation: { x: 0, y: 0, z: 0 },
            dimensions: {
                cellSize: 0.5,
                columns: 51,
                rows: 12,
            },
        },
        {
            type: 'Wall',
            position: { x: -21.5, y: 3, z: -6.003 },
            rotation: { x: 0, y: Math.PI, z: 0 },
            dimensions: {
                cellSize: 0.5,
                columns: 46,
                rows: 12,
            },
        },
        {
            type: 'Wall',
            position: { x: 6.987, y: 3, z: -12.995 },
            rotation: { x: 0, y: Math.PI / -2, z: 0 },
            dimensions: {
                cellSize: 0.5,
                columns: 28,
                rows: 12,
            },
        },
        {
            type: 'Wall',
            position: { x: -0.002, y: 3, z: -19.98 },
            rotation: { x: 0, y: 0, z: 0 },
            dimensions: {
                cellSize: 0.5,
                columns: 28,
                rows: 12,
            },
        },
        {
            type: 'Wall',
            position: { x: 1.5, y: 3, z: -6 },
            rotation: { x: 0, y: Math.PI, z: 0 },
            dimensions: {
                cellSize: 0.5,
                columns: 22,
                rows: 12,
            },
        },
        {
            type: 'Wall',
            position: { x: -6.99, y: 3, z: -14.495 },
            rotation: { x: 0, y: Math.PI / 2, z: 0 },
            dimensions: {
                cellSize: 0.5,
                columns: 22,
                rows: 12,
            },
        },
        {
            type: 'Wall',
            position: { x: -1.29, y: 2, z: -11.67 },
            rotation: { x: 0, y: 2.365, z: 0 },
            dimensions: {
                cellSize: 0.5,
                columns: 14,
                rows: 8,
            },
        },
        {
            type: 'Wall',
            position: { x: -1.47, y: 2, z: -11.48 },
            rotation: { x: 0, y: -0.7766, z: 0 },
            dimensions: {
                cellSize: 0.5,
                columns: 14,
                rows: 8,
            },
        },
        {
            type: 'Wall',
            position: { x: -8.49, y: 3, z: -4.5 },
            rotation: { x: 0, y: 2.356, z: 0 },
            dimensions: {
                cellSize: 0.5,
                columns: 8,
                rows: 12,
            },
        },
        {
            type: 'Floor',
            position: { x: 0, y: 0.01, z: 8 },
            rotation: { x: Math.PI / -2, y: 0, z: 0 },
            dimensions: {
                cellSize: 0.5,
                columns: 15,
                rows: 34,
            },
        },
    ],
    tours: [
        {
            position: { x: -1, y: 0, z: 12.25 },
            title: 'Kort tekst',
            introduction: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.',
            groups: [
                {
                    index: 0,
                    position: { x: 0.9693792212863802, y: 1.7500000000000002, z: -2.8633044413887863 },
                    rotation: { x: 0.8440015316100633, y: 0.12488715671444281, z: 0.5215981333628884 },
                },
                {
                    index: 1,
                    position: { x: 6.099124820004383, y: 1.7499999547057148, z: -19.28082010100426 },
                    rotation: { x: -0.699787756274391, y: 0.06688430935758125, z: 0.7112127567262929 },
                },
                {
                    index: 2,
                    position: { x: -31.777896837510315, y: 1.7497955990945822, z: -12.719086717117785 },
                    rotation: { x: 0.9990671593871343, y: 0.043169985472239, z: -0.0010786048623334012 },
                },
            ],
            canvasIndex: 15,
            boundToGrid: false,
        },
        {
            position: { x: 1, y: 0, z: 12.25 },
            title: 'Unødvendig lang tekst som bør nedskaleres',
            introduction: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.',
            groups: [ ],
            canvasIndex: 15,
            boundToGrid: false,
        },
    ],
    groups: [
        { name: 'Gruppe 1', color: '#EB7A28' },
        { name: 'Gruppe 2', color: '#AF31EB' },
        { name: 'Gruppe 3', color: '#1B8751' },
    ],
}