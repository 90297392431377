import { useMemo } from 'react';
import { exitPointerLock, getExhibitionItems } from '../../helpers';
import { CanvasItemTypes } from '../../helpers/enums';
import { SET_MODELVIEWER_DATA, useThreeDDispatch, useThreeDProps, useThreeDState } from '../App/ThreeDContext';
import { Model } from './Model';

export const Models = () => {
  const { data, adminMode } = useThreeDProps();
  const { interactiveMode } = useThreeDState();
  const { models } = data;
  const dispatch = useThreeDDispatch();
  const filteredModels = useMemo(() =>
    getExhibitionItems(models, adminMode, interactiveMode),
    [models, adminMode, interactiveMode]
  );

  const handleModelClick = (model, index) => {
    if (!interactiveMode) return;
    exitPointerLock();
    model.viewerData && dispatch({
      type: SET_MODELVIEWER_DATA,
      modelViewerData: {
        index: index,
        type: CanvasItemTypes.IMAGE,
        viewerData: model.viewerData,
      },
    });
  };

  return filteredModels.map((model, index) => (
    <Model
      {...model}
      name={`Model-${index}`}
      key={index}
      onClick={() => handleModelClick(model, index)}
    />
  ));
};
