import { ImageSphereEditor } from './ImageSphereEditor';
import { getCanvasItemName } from '../../helpers';
import { CanvasItemTypes } from '../../helpers/enums';

export const ImageSphereEditors = ({
  imageSpheres,
  canvasName,
  canvasIndex,
  canvasWidth,
  canvasHeight,
  cellSize,
  onUpdate,
  onRemove,
}) => {
  const handleUpdate = (index, imageSphere) => {
    onUpdate && onUpdate(index, imageSphere, CanvasItemTypes.IMAGE_SPHERE);
  };

  const handleRemove = (index) => {
    onRemove && onRemove(index, CanvasItemTypes.IMAGE_SPHERE);
  };

  return (
    <group>
      {imageSpheres.map((imageSphere, index) => (
        <ImageSphereEditor
          imageSphereName={getCanvasItemName(CanvasItemTypes.IMAGE_SPHERE, canvasIndex, index)}
          imageSphereItem={imageSphere}
          canvasName={canvasName}
          canvasIndex={canvasIndex}
          canvasWidth={canvasWidth}
          canvasHeight={canvasHeight}
          cellSize={cellSize}
          index={index}
          key={index}
          onUpdate={handleUpdate}
          onRemove={handleRemove}
        />
      ))}
    </group>
  );
};
