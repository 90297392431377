import { VideoEditor } from './VideoEditor';
import { getCanvasItemName } from '../../helpers';
import { CanvasItemTypes } from '../../helpers/enums';

export const VideoEditors = ({
  videos,
  canvasName,
  canvasIndex,
  canvasWidth,
  canvasHeight,
  cellSize,
  onUpdate,
  onRemove,
}) => {
  const handleUpdate = (index, video) => {
    onUpdate && onUpdate(index, video, CanvasItemTypes.VIDEO);
  };

  const handleRemove = (index) => {
    onRemove && onRemove(index, CanvasItemTypes.VIDEO);
  };

  return (
    <group>
      {videos.map((video, index) => (
        <VideoEditor
          key={`${index}${video.dmsId}`}
          videoName={getCanvasItemName(CanvasItemTypes.VIDEO, canvasIndex, index)}
          videoItem={video}
          canvasName={canvasName}
          canvasIndex={canvasIndex}
          canvasWidth={canvasWidth}
          canvasHeight={canvasHeight}
          cellSize={cellSize}
          index={index}
          onUpdate={handleUpdate}
          onRemove={handleRemove}
        />
      ))}
    </group>
  );
};
