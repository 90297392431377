import { useThreeDProps } from '../App/ThreeDContext';
import { SkyBox } from './SkyBox';
import { SkyShader } from './SkyShader';

export const Dome = () => {
  const { data } = useThreeDProps();
  let { room, dome } = data;
  dome = dome ?? room?.dome;
  const { skyBox, skyBoxDmsId, skyShader } = dome ?? {};

  return (
    <>
      {(skyBox || skyBoxDmsId) && <SkyBox sceneryUrl={skyBox} dmsId={skyBoxDmsId} />}
      {skyShader && <SkyShader {...skyShader} />}
    </>
  );
};
