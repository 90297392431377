import { InputAdornment, MenuItem, TextField } from '@mui/material';
import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles({
  menuItem: {
    '&.MuiMenuItem-root': {
      fontSize: 14,
    }
  },
});

export const SelectInput = ({
  value,
  options = [],
  icon,
  maxHeight,
  onChange,
}) => {
  const styles = useStyles();

  return (
    <>
      {options.length > 0 &&
        <TextField
          variant='outlined'
          value={value}
          onChange={onChange}
          select
          InputProps={icon && {
            startAdornment: (
              <InputAdornment position='start'>
                {icon}
              </InputAdornment>
            ),
          }}
          SelectProps={maxHeight && {
            MenuProps: {
              PaperProps: {
                style: {
                  maxHeight: maxHeight,
                },
              },
            },
          }}
        >
          {options.map((option) =>
            <MenuItem
              key={option.value}
              value={option.value}
              className={styles.menuItem}
            >
              {option.text}
            </MenuItem>
          )}
        </TextField>
      }
    </>
  );
};
