import * as THREE from 'three';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useThree } from '@react-three/fiber';
import { useCanvasItemEditor } from '../Canvases/useCanvasItemEditor';
import { computeBoundingBoxSize, getSceneElementByName, isCanvasItem, setCursor } from '../../helpers';
import { GroupIndexMarkerBox } from '../../components/GroupIndexMarkerBox';
import { CanvasItemTypes, CursorTypes } from '../../helpers/enums';
import { Model } from '../Models/Model';
import { SET_CANVAS_SELECTION, SET_OUTLINE_CANVAS, SET_ROTATION_ENABLED,
  useThreeDDispatch, useThreeDState } from '../App/ThreeDContext';

export const ModelEditor = ({
  modelName,
  modelItem,
  canvasName,
  canvasIndex,
  canvasWidth,
  canvasHeight,
  cellSize,
  index,
  groups,
  onUpdate,
  onRemove,
  onGroupUpdate,
}) => {
  const { showGroups, canvasSelection, outlineCanvas } = useThreeDState();
  const dispatch = useThreeDDispatch();
  const { scene } = useThree();
  const [dragPosition, setDragPosition] = useState(modelItem.position);
  const [pointerDown, setPointerDown] = useState(false);
  const [positionOffset, setPositionOffset] = useState();
  const [dragMode, setDragMode] = useState(false);
  const canvas = getSceneElementByName(scene.children, canvasName);
  const model = getSceneElementByName(scene.children, modelName);
  const canvasItemEditor = useCanvasItemEditor({
    canvas,
    canvasItem: model,
    canvasSize: { height: canvasHeight, width: canvasWidth },
    cellSize,
  });
  const point = useRef();

  const isSelected = useMemo(() => {
    return canvasSelection?.canvasIndex === canvasIndex
      && canvasSelection?.itemType === CanvasItemTypes.MODEL3D
      && canvasSelection?.itemIndex === index;
  }, [canvasSelection, canvasIndex, index]);

  useEffect(() => {
    if (isSelected) {
      updateOutlineCanvas();
      !canvasSelection.getSceneElements && updateCanvasSelection();
    }
  });

  useEffect(() => {
    isSelected && updateCanvasSelection();
  }, [groups.length, modelItem.group?.id, showGroups]);

  useEffect(() => {
    setDragPosition(modelItem.position);
  }, [modelItem.position, modelItem.position.x, modelItem.position.y, modelItem.position.z]);

  const updateOutlineCanvas = () => {
    if (outlineCanvas !== modelName) {
      dispatch({ type: SET_OUTLINE_CANVAS, outlineCanvas: modelName });
    }
  };

  const updateCanvasSelection = () => {
    dispatch({
      type: SET_CANVAS_SELECTION,
      canvasSelection: {
        canvasIndex: canvasIndex,
        itemIndex: index,
        itemType: CanvasItemTypes.MODEL3D,
        onUpdate: onUpdate,
        onRemove: onRemove,
        onGroupUpdate: onGroupUpdate,
        getSceneElements: () => ({
          canvasRef: canvas ?? getSceneElementByName(scene.children, canvasName),
          itemRef: getSceneElementByName(scene.children, modelName),
          pedestalRef: getSceneElementByName(scene.children, `${modelName}-pedestal`),
          gimRef: getSceneElementByName(scene.children, `${modelName}-gim`),
        }),
      },
    });
  };

  const handleClick = (event) => {
    if (!isCanvasItem(event)) return null;
    const intersection = event.intersections.reduce((acc, obj) => (obj.distance < acc.distance ? obj : acc));

    if (event.button === 0 && !dragMode && intersection.object === event.object && point.current?.x === event.screenX && point.current?.y === event.screenY) {
      updateOutlineCanvas();
      !isSelected && updateCanvasSelection();
    }
  };

  const handlePointerDown = (event) => {
    const intersection = event.intersections.reduce((acc, obj) => (obj.distance < acc.distance ? obj : acc));

    if (intersection.object === event.object && event.button === 0 && isSelected) {
      setPositionOffset(model.worldToLocal(event.point.clone()));
      dispatch({ type: SET_ROTATION_ENABLED, rotationEnabled: false });
      setPointerDown(true);
      setCursor(CursorTypes.MOVE);
    }

    point.current = { x: parseInt(event.screenX), y: parseInt(event.screenY) };
  };

  const handlePointerUp = (event) => {
    const intersection = event.intersections.reduce((acc, obj) => (obj.distance < acc.distance ? obj : acc));

    if (intersection.object === event.object && event.button === 0 && isSelected) {
      dispatch({ type: SET_ROTATION_ENABLED, rotationEnabled: true });
      setPositionOffset(null);
      setCursor(CursorTypes.GRAB);

      const point = new THREE.Vector3();
      let dragPlanes = event.intersections.filter(obj => obj.object.name.startsWith('dragPlane'));
      dragPlanes = dragPlanes.filter((obj, i) => dragPlanes.findIndex(e => e.object.name === obj.object.name) === i);

      if (dragPlanes.length > 0) {
        dragPlanes.forEach(dragPlane => point.add(dragPlane.point));
        point.divideScalar(dragPlanes.length);
        point.setY(modelItem.position.y);

        const size = computeBoundingBoxSize(model);
        const modelSize = { width: size.x, height: size.y };
        canvasItemEditor.applyPositionOffset3D(point, positionOffset);
        modelItem.boundToGrid && canvasItemEditor.snapPositionToGrid(point, modelSize);
        canvasItemEditor.applyPositionLimits(point, modelSize);

        if (!point.equals(modelItem.position)) {
          modelItem.position.copy(point);
          onUpdate(index, modelItem);
        }
      }
    }

    pointerDown && setPointerDown(false);
    dragMode && setDragMode(false);
  };

  const handlePointerMove = (event) => {
    if (event.buttons === 1) {
      if (pointerDown && dragMode) {
        const point = new THREE.Vector3();
        let dragPlanes = event.intersections.filter(obj => obj.object.name.startsWith('dragPlane'));
        dragPlanes = dragPlanes.filter((obj, i) => dragPlanes.findIndex(e => e.object.name === obj.object.name) === i);

        if (new Set(dragPlanes.map(dragPlane => dragPlane.distance)).size !== 1) {
          dragPlanes.forEach(dragPlane => point.add(dragPlane.point));
          point.divideScalar(dragPlanes.length);
          point.setY(modelItem.position.y);

          const size = computeBoundingBoxSize(model);
          const modelSize = { width: size.x, height: size.y };
          canvasItemEditor.applyPositionOffset3D(point, positionOffset);
          modelItem.boundToGrid && canvasItemEditor.snapPositionToGrid(point, modelSize);
          canvasItemEditor.applyPositionLimits(point, modelSize);
          setDragPosition(point);
        }
      }

      pointerDown && !dragMode && setDragMode(true);
    }
  };

  return (
    <>
      <group
        onClick={handleClick}
        onPointerDown={handlePointerDown}
        onPointerUp={handlePointerUp}
      >
        {/* <Suspense fallback={null}> */}
          <Model
            {...modelItem}
            name={modelName}
            position={dragPosition}
            editMode={true}
            dragPositionOffset={positionOffset}
            onPointerMove={handlePointerMove}
          />
        {/* </Suspense> */}
      </group>
      {showGroups && modelItem.group && model && (
        <GroupIndexMarkerBox
          name={modelName}
          text={modelItem.group.index + 1}
          color={groups.find(group => group.id === modelItem.group.id)?.color}
          object={model.parent}
          position={dragPosition}
          rotation={modelItem.rotation}
          scale={modelItem.scale}
        />
      )}
    </>
  );
};
