import * as THREE from 'three';
import { useEffect, useRef } from 'react';
import { Sky } from '@react-three/drei';
import { useThree } from '@react-three/fiber';
import { sphericalDegToRad } from '../../helpers';

export const SkyShader = ({
  distance,
  elevation,
  azimuth,
  turbidity,
  rayleigh,
  mieCoefficient,
  mieDirectionalG,
  exposure = 1,
}) => {
  const { gl } = useThree();
  const sunPosition = sphericalDegToRad(elevation, azimuth);
  const lightSourceRef = useRef();
  const lightSourcePosition = new THREE.Vector3().addVectors(new THREE.Vector3(), sunPosition.clone().normalize().multiplyScalar(distance));

  useEffect(() => {
    lightSourceRef.current.shadow.mapSize.width = 1024;
    lightSourceRef.current.shadow.mapSize.height = 1024;
    lightSourceRef.current.shadow.camera.near = distance - 150;
    lightSourceRef.current.shadow.camera.far = distance + 150;
    lightSourceRef.current.shadow.camera.top = 100;
    lightSourceRef.current.shadow.camera.right = 100;
    lightSourceRef.current.shadow.camera.bottom = -100;
    lightSourceRef.current.shadow.camera.left = -100;
    lightSourceRef.current.shadow.normalBias = 0.5;
    gl.toneMappingExposure = exposure;
  });

  return (
    <>
      <Sky
        sunPosition={sunPosition}
        distance={distance}
        turbidity={turbidity}
        rayleigh={rayleigh}
        mieCoefficient={mieCoefficient}
        mieDirectionalG={mieDirectionalG}
      />
      <directionalLight
        ref={lightSourceRef}
        position={lightSourcePosition}
        castShadow={true}
        intensity={exposure * 0.5}
      />
    </>
  );
};
