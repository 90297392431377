import { QuizEditor } from './QuizEditor';
import { getCanvasItemName } from '../../helpers';
import { CanvasItemTypes } from '../../helpers/enums';

export const QuizEditors = ({
  quizzes,
  canvasName,
  canvasIndex,
  canvasWidth,
  canvasHeight,
  cellSize,
  onUpdate,
  onRemove,
}) => {
  const handleUpdate = (index, quiz) => {
    onUpdate && onUpdate(index, quiz, CanvasItemTypes.QUIZ);
  };

  const handleRemove = (index) => {
    onRemove && onRemove(index, CanvasItemTypes.QUIZ);
  };

  return (
    <group>
      {quizzes.map((quiz, index) => (
        <QuizEditor
          key={index}
          quizName={getCanvasItemName(CanvasItemTypes.QUIZ, canvasIndex, index)}
          quizItem={quiz}
          canvasName={canvasName}
          canvasIndex={canvasIndex}
          canvasWidth={canvasWidth}
          canvasHeight={canvasHeight}
          cellSize={cellSize}
          index={index}
          onUpdate={handleUpdate}
          onRemove={handleRemove}
        />
      ))}
    </group>
  );
};
