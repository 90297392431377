import { useRef } from 'react';
import { createUseStyles } from 'react-jss';
import Modal from 'react-modal';
import cx from 'classnames';

const useStyles = createUseStyles({
  dialog: {
    width: (props) => (props.fullScreen ? '100%' : 'unset'),
    height: (props) => (props.fullScreen ? '100%' : 'unset'),
    position: 'relative',
    color: 'rgba(0, 0, 0, 0.87)',
    backgroundColor: 'rgb(255, 255, 255)',
    borderRadius: (props) => (props.fullScreen ? 0 : 30),
    boxSizing: 'border-box',
    margin: (props) => (props.fullScreen ? 0 : 32),
    padding: (props) => (props.fullScreen ? 32 : 0),
    overflow: 'auto',
    outline: 'none',
    boxShadow: '0px 11px 15px -7px rgb(0 0 0 / 20%), 0px 24px 38px 3px rgb(0 0 0 / 14%), 0px 9px 46px 8px rgb(0 0 0 / 12%)',
  },
  overlay: {
    position: 'absolute',
    inset: 0,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    cursor: 'default',
    zIndex: '99999999 !important',
    opacity: 0,
    transition: 'opacity 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
  },
});

export const Dialog = ({ open = true, fullScreen = false, className, children }) => {
  const styles = useStyles({ fullScreen });
  const modal = useRef();

  const handleAfterOpen = () => {
    modal.current.portal.overlay.style.opacity = 1;
  };

  return (
    <Modal
      ref={modal}
      isOpen={open}
      className={cx(styles.dialog, className)}
      overlayClassName={styles.overlay}
      onAfterOpen={handleAfterOpen}
      ariaHideApp={false}
      parentSelector={() => document.getElementById('dialogContainer') ?? document.body}
    >
      {children}
    </Modal>
  );
};
