import { getDmsFileUrl } from '@ekultur/dms-url-generator';

export const fontFamilyOptions = [
  {
    name: 'DM Sans',
    weight: [
      {
        value: '400',
        url: 'https://fonts.gstatic.com/s/dmsans/v11/rP2Hp2ywxg089UriOZQ.ttf',
      },
      {
        value: '500',
        url: 'https://fonts.gstatic.com/s/dmsans/v11/rP2Cp2ywxg089UriAWCrOB8.ttf',
      },
      {
        value: '700',
        url: 'https://fonts.gstatic.com/s/dmsans/v11/rP2Cp2ywxg089UriASitOB8.ttf',
      },
    ],
  },
  {
    name: 'Bebas Neue',
    weight: [
      {
        value: '400',
        url: 'https://fonts.gstatic.com/s/bebasneue/v9/JTUSjIg69CK48gW7PXooxW4.ttf',
      },
    ],
  },
  {
    name: 'Work Sans',
    weight: [
      {
        value: '100',
        url: 'https://fonts.gstatic.com/s/worksans/v18/QGY_z_wNahGAdqQ43RhVcIgYT2Xz5u32K0nWNig.ttf',
      },
      {
        value: '200',
        url: 'https://fonts.gstatic.com/s/worksans/v18/QGY_z_wNahGAdqQ43RhVcIgYT2Xz5u32K8nXNig.ttf',
      },
      {
        value: '300',
        url: 'https://fonts.gstatic.com/s/worksans/v18/QGY_z_wNahGAdqQ43RhVcIgYT2Xz5u32KxfXNig.ttf',
      },
      {
        value: '400',
        url: 'https://fonts.gstatic.com/s/worksans/v18/QGY_z_wNahGAdqQ43RhVcIgYT2Xz5u32K0nXNig.ttf',
      },
      {
        value: '500',
        url: 'https://fonts.gstatic.com/s/worksans/v18/QGY_z_wNahGAdqQ43RhVcIgYT2Xz5u32K3vXNig.ttf',
      },
      {
        value: '600',
        url: 'https://fonts.gstatic.com/s/worksans/v18/QGY_z_wNahGAdqQ43RhVcIgYT2Xz5u32K5fQNig.ttf',
      },
      {
        value: '700',
        url: 'https://fonts.gstatic.com/s/worksans/v18/QGY_z_wNahGAdqQ43RhVcIgYT2Xz5u32K67QNig.ttf',
      },
      {
        value: '800',
        url: 'https://fonts.gstatic.com/s/worksans/v18/QGY_z_wNahGAdqQ43RhVcIgYT2Xz5u32K8nQNig.ttf',
      },
      {
        value: '900',
        url: 'https://fonts.gstatic.com/s/worksans/v18/QGY_z_wNahGAdqQ43RhVcIgYT2Xz5u32K-DQNig.ttf',
      },
    ],
  },
  {
    name: 'Manrope',
    weight: [
      {
        value: '200',
        url: 'https://fonts.gstatic.com/s/manrope/v13/xn7_YHE41ni1AdIRqAuZuw1Bx9mbZk59FO_F.ttf',
      },
      {
        value: '300',
        url: 'https://fonts.gstatic.com/s/manrope/v13/xn7_YHE41ni1AdIRqAuZuw1Bx9mbZk6jFO_F.ttf',
      },
      {
        value: '400',
        url: 'https://fonts.gstatic.com/s/manrope/v13/xn7_YHE41ni1AdIRqAuZuw1Bx9mbZk79FO_F.ttf',
      },
      {
        value: '500',
        url: 'https://fonts.gstatic.com/s/manrope/v13/xn7_YHE41ni1AdIRqAuZuw1Bx9mbZk7PFO_F.ttf',
      },
      {
        value: '600',
        url: 'https://fonts.gstatic.com/s/manrope/v13/xn7_YHE41ni1AdIRqAuZuw1Bx9mbZk4jE-_F.ttf',
      },
      {
        value: '700',
        url: 'https://fonts.gstatic.com/s/manrope/v13/xn7_YHE41ni1AdIRqAuZuw1Bx9mbZk4aE-_F.ttf',
      },
      {
        value: '800',
        url: 'https://fonts.gstatic.com/s/manrope/v13/xn7_YHE41ni1AdIRqAuZuw1Bx9mbZk59E-_F.ttf',
      },
    ],
  },
  {
    name: 'Syne',
    weight: [
      {
        value: '400',
        url: 'https://fonts.gstatic.com/s/syne/v15/8vIS7w4qzmVxsWxjBZRjr0FKM_04uT6k.ttf',
      },
      {
        value: '500',
        url: 'https://fonts.gstatic.com/s/syne/v15/8vIS7w4qzmVxsWxjBZRjr0FKM_0KuT6k.ttf',
      },
      {
        value: '600',
        url: 'https://fonts.gstatic.com/s/syne/v15/8vIS7w4qzmVxsWxjBZRjr0FKM_3mvj6k.ttf',
      },
      {
        value: '700',
        url: 'https://fonts.gstatic.com/s/syne/v15/8vIS7w4qzmVxsWxjBZRjr0FKM_3fvj6k.ttf',
      },
      {
        value: '800',
        url: 'https://fonts.gstatic.com/s/syne/v15/8vIS7w4qzmVxsWxjBZRjr0FKM_24vj6k.ttf',
      },
    ],
  },
  {
    name: 'Alegreya Sans',
    weight: [
      {
        value: '100',
        url: 'https://fonts.gstatic.com/s/alegreyasans/v21/5aUt9_-1phKLFgshYDvh6Vwt5TltuA.ttf',
      },
      {
        value: '300',
        url: 'https://fonts.gstatic.com/s/alegreyasans/v21/5aUu9_-1phKLFgshYDvh6Vwt5fFPmE0.ttf',
      },
      {
        value: '400',
        url: 'https://fonts.gstatic.com/s/alegreyasans/v21/5aUz9_-1phKLFgshYDvh6Vwt3V0.ttf',
      },
      {
        value: '500',
        url: 'https://fonts.gstatic.com/s/alegreyasans/v21/5aUu9_-1phKLFgshYDvh6Vwt5alOmE0.ttf',
      },
      {
        value: '700',
        url: 'https://fonts.gstatic.com/s/alegreyasans/v21/5aUu9_-1phKLFgshYDvh6Vwt5eFImE0.ttf',
      },
      {
        value: '800',
        url: 'https://fonts.gstatic.com/s/alegreyasans/v21/5aUu9_-1phKLFgshYDvh6Vwt5f1LmE0.ttf',
      },
      {
        value: '900',
        url: 'https://fonts.gstatic.com/s/alegreyasans/v21/5aUu9_-1phKLFgshYDvh6Vwt5dlKmE0.ttf',
      },
    ],
  },
  {
    name: 'Poppins',
    weight: [
      {
        value: '100',
        url: 'https://fonts.gstatic.com/s/poppins/v20/pxiGyp8kv8JHgFVrLPTedw.ttf',
      },
      {
        value: '200',
        url: 'https://fonts.gstatic.com/s/poppins/v20/pxiByp8kv8JHgFVrLFj_V1s.ttf',
      },
      {
        value: '300',
        url: 'https://fonts.gstatic.com/s/poppins/v20/pxiByp8kv8JHgFVrLDz8V1s.ttf',
      },
      {
        value: '400',
        url: 'https://fonts.gstatic.com/s/poppins/v20/pxiEyp8kv8JHgFVrFJA.ttf',
      },
      {
        value: '500',
        url: 'https://fonts.gstatic.com/s/poppins/v20/pxiByp8kv8JHgFVrLGT9V1s.ttf',
      },
      {
        value: '600',
        url: 'https://fonts.gstatic.com/s/poppins/v20/pxiByp8kv8JHgFVrLEj6V1s.ttf',
      },
      {
        value: '700',
        url: 'https://fonts.gstatic.com/s/poppins/v20/pxiByp8kv8JHgFVrLCz7V1s.ttf',
      },
      {
        value: '800',
        url: 'https://fonts.gstatic.com/s/poppins/v20/pxiByp8kv8JHgFVrLDD4V1s.ttf',
      },
      {
        value: '900',
        url: 'https://fonts.gstatic.com/s/poppins/v20/pxiByp8kv8JHgFVrLBT5V1s.ttf',
      },
    ],
  },
  {
    name: 'Quicksand',
    weight: [
      {
        value: '300',
        url: 'https://fonts.gstatic.com/s/quicksand/v30/6xK-dSZaM9iE8KbpRA_LJ3z8mH9BOJvgkKEo18E.ttf',
      },
      {
        value: '400',
        url: 'https://fonts.gstatic.com/s/quicksand/v30/6xK-dSZaM9iE8KbpRA_LJ3z8mH9BOJvgkP8o18E.ttf',
      },
      {
        value: '500',
        url: 'https://fonts.gstatic.com/s/quicksand/v30/6xK-dSZaM9iE8KbpRA_LJ3z8mH9BOJvgkM0o18E.ttf',
      },
      {
        value: '600',
        url: 'https://fonts.gstatic.com/s/quicksand/v30/6xK-dSZaM9iE8KbpRA_LJ3z8mH9BOJvgkCEv18E.ttf',
      },
      {
        value: '700',
        url: 'https://fonts.gstatic.com/s/quicksand/v30/6xK-dSZaM9iE8KbpRA_LJ3z8mH9BOJvgkBgv18E.ttf',
      },
    ],
  },
  {
    name: 'Raleway',
    weight: [
      {
        value: '100',
        url: 'https://fonts.gstatic.com/s/raleway/v28/1Ptxg8zYS_SKggPN4iEgvnHyvveLxVvao4CP.ttf',
      },
      {
        value: '200',
        url: 'https://fonts.gstatic.com/s/raleway/v28/1Ptxg8zYS_SKggPN4iEgvnHyvveLxVtaooCP.ttf',
      },
      {
        value: '300',
        url: 'https://fonts.gstatic.com/s/raleway/v28/1Ptxg8zYS_SKggPN4iEgvnHyvveLxVuEooCP.ttf',
      },
      {
        value: '400',
        url: 'https://fonts.gstatic.com/s/raleway/v28/1Ptxg8zYS_SKggPN4iEgvnHyvveLxVvaooCP.ttf',
      },
      {
        value: '500',
        url: 'https://fonts.gstatic.com/s/raleway/v28/1Ptxg8zYS_SKggPN4iEgvnHyvveLxVvoooCP.ttf',
      },
      {
        value: '600',
        url: 'https://fonts.gstatic.com/s/raleway/v28/1Ptxg8zYS_SKggPN4iEgvnHyvveLxVsEpYCP.ttf',
      },
      {
        value: '700',
        url: 'https://fonts.gstatic.com/s/raleway/v28/1Ptxg8zYS_SKggPN4iEgvnHyvveLxVs9pYCP.ttf',
      },
      {
        value: '800',
        url: 'https://fonts.gstatic.com/s/raleway/v28/1Ptxg8zYS_SKggPN4iEgvnHyvveLxVtapYCP.ttf',
      },
      {
        value: '900',
        url: 'https://fonts.gstatic.com/s/raleway/v28/1Ptxg8zYS_SKggPN4iEgvnHyvveLxVtzpYCP.ttf',
      },
    ],
  },
  {
    name: 'Montserrat',
    weight: [
      {
        value: '100',
        url: 'https://fonts.gstatic.com/s/montserrat/v25/JTUHjIg1_i6t8kCHKm4532VJOt5-QNFgpCtr6Uw-.ttf',
      },
      {
        value: '200',
        url: 'https://fonts.gstatic.com/s/montserrat/v25/JTUHjIg1_i6t8kCHKm4532VJOt5-QNFgpCvr6Ew-.ttf',
      },
      {
        value: '300',
        url: 'https://fonts.gstatic.com/s/montserrat/v25/JTUHjIg1_i6t8kCHKm4532VJOt5-QNFgpCs16Ew-.ttf',
      },
      {
        value: '400',
        url: 'https://fonts.gstatic.com/s/montserrat/v25/JTUHjIg1_i6t8kCHKm4532VJOt5-QNFgpCtr6Ew-.ttf',
      },
      {
        value: '500',
        url: 'https://fonts.gstatic.com/s/montserrat/v25/JTUHjIg1_i6t8kCHKm4532VJOt5-QNFgpCtZ6Ew-.ttf',
      },
      {
        value: '600',
        url: 'https://fonts.gstatic.com/s/montserrat/v25/JTUHjIg1_i6t8kCHKm4532VJOt5-QNFgpCu170w-.ttf',
      },
      {
        value: '700',
        url: 'https://fonts.gstatic.com/s/montserrat/v25/JTUHjIg1_i6t8kCHKm4532VJOt5-QNFgpCuM70w-.ttf',
      },
      {
        value: '800',
        url: 'https://fonts.gstatic.com/s/montserrat/v25/JTUHjIg1_i6t8kCHKm4532VJOt5-QNFgpCvr70w-.ttf',
      },
      {
        value: '900',
        url: 'https://fonts.gstatic.com/s/montserrat/v25/JTUHjIg1_i6t8kCHKm4532VJOt5-QNFgpCvC70w-.ttf',
      },
    ],
  },
  {
    name: 'Oswald',
    weight: [
      {
        value: '200',
        url: 'https://fonts.gstatic.com/s/oswald/v49/TK3_WkUHHAIjg75cFRf3bXL8LICs13FvgUE.ttf',
      },
      {
        value: '300',
        url: 'https://fonts.gstatic.com/s/oswald/v49/TK3_WkUHHAIjg75cFRf3bXL8LICs169vgUE.ttf',
      },
      {
        value: '400',
        url: 'https://fonts.gstatic.com/s/oswald/v49/TK3_WkUHHAIjg75cFRf3bXL8LICs1_FvgUE.ttf',
      },
      {
        value: '500',
        url: 'https://fonts.gstatic.com/s/oswald/v49/TK3_WkUHHAIjg75cFRf3bXL8LICs18NvgUE.ttf',
      },
      {
        value: '600',
        url: 'https://fonts.gstatic.com/s/oswald/v49/TK3_WkUHHAIjg75cFRf3bXL8LICs1y9ogUE.ttf',
      },
      {
        value: '700',
        url: 'https://fonts.gstatic.com/s/oswald/v49/TK3_WkUHHAIjg75cFRf3bXL8LICs1xZogUE.ttf',
      },
    ],
  },
  {
    name: 'Lato',
    weight: [
      {
        value: '100',
        url: 'https://fonts.gstatic.com/s/lato/v23/S6u8w4BMUTPHh30wWw.ttf',
      },
      {
        value: '300',
        url: 'https://fonts.gstatic.com/s/lato/v23/S6u9w4BMUTPHh7USew8.ttf',
      },
      {
        value: '400',
        url: 'https://fonts.gstatic.com/s/lato/v23/S6uyw4BMUTPHvxk.ttf',
      },
      {
        value: '700',
        url: 'https://fonts.gstatic.com/s/lato/v23/S6u9w4BMUTPHh6UVew8.ttf',
      },
      {
        value: '900',
        url: 'https://fonts.gstatic.com/s/lato/v23/S6u9w4BMUTPHh50Xew8.ttf',
      },
    ],
  },
  {
    name: 'DM Serif Display',
    weight: [
      {
        value: '400',
        url: 'https://fonts.gstatic.com/s/dmserifdisplay/v10/-nFnOHM81r4j6k0gjAW3mujVU2B2K_c.ttf',
      },
    ],
  },
  {
    name: 'Eczar',
    weight: [
      {
        value: '400',
        url: 'https://fonts.gstatic.com/s/eczar/v17/BXR2vF3Pi-DLmxcpJB-qbNTyTMDXHd6WqQ.ttf',
      },
      {
        value: '500',
        url: 'https://fonts.gstatic.com/s/eczar/v17/BXR2vF3Pi-DLmxcpJB-qbNTyTMDXL96WqQ.ttf',
      },
      {
        value: '600',
        url: 'https://fonts.gstatic.com/s/eczar/v17/BXR2vF3Pi-DLmxcpJB-qbNTyTMDXw9mWqQ.ttf',
      },
      {
        value: '700',
        url: 'https://fonts.gstatic.com/s/eczar/v17/BXR2vF3Pi-DLmxcpJB-qbNTyTMDX-tmWqQ.ttf',
      },
      {
        value: '800',
        url: 'https://fonts.gstatic.com/s/eczar/v17/BXR2vF3Pi-DLmxcpJB-qbNTyTMDXndmWqQ.ttf',
      },
    ],
  },
  {
    name: 'Cardo',
    weight: [
      {
        value: '400',
        url: 'https://fonts.gstatic.com/s/cardo/v19/wlp_gwjKBV1pqiv_.ttf',
      },
      {
        value: '700',
        url: 'https://fonts.gstatic.com/s/cardo/v19/wlpygwjKBV1pqhND-aQR.ttf',
      },
    ],
  },
  {
    name: 'Lora',
    weight: [
      {
        value: '400',
        url: 'https://fonts.gstatic.com/s/lora/v26/0QI6MX1D_JOuGQbT0gvTJPa787weuyJG.ttf',
      },
      {
        value: '500',
        url: 'https://fonts.gstatic.com/s/lora/v26/0QI6MX1D_JOuGQbT0gvTJPa787wsuyJG.ttf',
      },
      {
        value: '600',
        url: 'https://fonts.gstatic.com/s/lora/v26/0QI6MX1D_JOuGQbT0gvTJPa787zAvCJG.ttf',
      },
      {
        value: '700',
        url: 'https://fonts.gstatic.com/s/lora/v26/0QI6MX1D_JOuGQbT0gvTJPa787z5vCJG.ttf',
      },
    ],
  },
  {
    name: 'Playfair Display',
    weight: [
      {
        value: '400',
        url: 'https://fonts.gstatic.com/s/playfairdisplay/v30/nuFvD-vYSZviVYUb_rj3ij__anPXJzDwcbmjWBN2PKdFvUDQ.ttf',
      },
      {
        value: '500',
        url: 'https://fonts.gstatic.com/s/playfairdisplay/v30/nuFvD-vYSZviVYUb_rj3ij__anPXJzDwcbmjWBN2PKd3vUDQ.ttf',
      },
      {
        value: '600',
        url: 'https://fonts.gstatic.com/s/playfairdisplay/v30/nuFvD-vYSZviVYUb_rj3ij__anPXJzDwcbmjWBN2PKebukDQ.ttf',
      },
      {
        value: '700',
        url: 'https://fonts.gstatic.com/s/playfairdisplay/v30/nuFvD-vYSZviVYUb_rj3ij__anPXJzDwcbmjWBN2PKeiukDQ.ttf',
      },
      {
        value: '800',
        url: 'https://fonts.gstatic.com/s/playfairdisplay/v30/nuFvD-vYSZviVYUb_rj3ij__anPXJzDwcbmjWBN2PKfFukDQ.ttf',
      },
      {
        value: '900',
        url: 'https://fonts.gstatic.com/s/playfairdisplay/v30/nuFvD-vYSZviVYUb_rj3ij__anPXJzDwcbmjWBN2PKfsukDQ.ttf',
      },
    ],
  },
  {
    name: 'Libre Baskerville',
    weight: [
      {
        value: '400',
        url: 'https://fonts.gstatic.com/s/librebaskerville/v14/kmKnZrc3Hgbbcjq75U4uslyuy4kn0pNe.ttf',
      },
      {
        value: '700',
        url: 'https://fonts.gstatic.com/s/librebaskerville/v14/kmKiZrc3Hgbbcjq75U4uslyuy4kn0qviTjYw.ttf',
      },
    ],
  },
  {
    name: 'Alegreya',
    weight: [
      {
        value: '400',
        url: 'https://fonts.gstatic.com/s/alegreya/v29/4UacrEBBsBhlBjvfkQjt71kZfyBzPgNG9hUI_A.ttf',
      },
      {
        value: '500',
        url: 'https://fonts.gstatic.com/s/alegreya/v29/4UacrEBBsBhlBjvfkQjt71kZfyBzPgNGxBUI_A.ttf',
      },
      {
        value: '600',
        url: 'https://fonts.gstatic.com/s/alegreya/v29/4UacrEBBsBhlBjvfkQjt71kZfyBzPgNGKBII_A.ttf',
      },
      {
        value: '700',
        url: 'https://fonts.gstatic.com/s/alegreya/v29/4UacrEBBsBhlBjvfkQjt71kZfyBzPgNGERII_A.ttf',
      },
      {
        value: '800',
        url: 'https://fonts.gstatic.com/s/alegreya/v29/4UacrEBBsBhlBjvfkQjt71kZfyBzPgNGdhII_A.ttf',
      },
      {
        value: '900',
        url: 'https://fonts.gstatic.com/s/alegreya/v29/4UacrEBBsBhlBjvfkQjt71kZfyBzPgNGXxII_A.ttf',
      },
    ],
  },
  {
    name: 'Permanent Marker',
    weight: [
      {
        value: '400',
        url: 'https://fonts.gstatic.com/s/permanentmarker/v16/Fh4uPib9Iyv2ucM6pGQMWimMp004Hao.ttf',
      },
    ],
  },
  {
    name: 'Caveat',
    weight: [
      {
        value: '400',
        url: 'https://fonts.gstatic.com/s/caveat/v17/WnznHAc5bAfYB2QRah7pcpNvOx-pjfJ9SII.ttf',
      },
      {
        value: '500',
        url: 'https://fonts.gstatic.com/s/caveat/v17/WnznHAc5bAfYB2QRah7pcpNvOx-pjcB9SII.ttf',
      },
      {
        value: '600',
        url: 'https://fonts.gstatic.com/s/caveat/v17/WnznHAc5bAfYB2QRah7pcpNvOx-pjSx6SII.ttf',
      },
      {
        value: '700',
        url: 'https://fonts.gstatic.com/s/caveat/v17/WnznHAc5bAfYB2QRah7pcpNvOx-pjRV6SII.ttf',
      },
    ],
  },
  {
    name: 'Indie Flower',
    weight: [
      {
        value: '400',
        url: 'https://fonts.gstatic.com/s/indieflower/v17/m8JVjfNVeKWVnh3QMuKkFcZlbg.ttf',
      },
    ],
  },
  {
    name: 'Space Mono',
    weight: [
      {
        value: '400',
        url: 'https://fonts.gstatic.com/s/spacemono/v12/i7dPIFZifjKcF5UAWdDRUEY.ttf',
      },
      {
        value: '700',
        url: 'https://fonts.gstatic.com/s/spacemono/v12/i7dMIFZifjKcF5UAWdDRaPpZYFI.ttf',
      },
    ],
  },
  {
    name: 'Roboto Mono',
    weight: [
      {
        value: '100',
        url: 'https://fonts.gstatic.com/s/robotomono/v22/L0xuDF4xlVMF-BfR8bXMIhJHg45mwgGEFl0_3vuPQw.ttf',
      },
      {
        value: '200',
        url: 'https://fonts.gstatic.com/s/robotomono/v22/L0xuDF4xlVMF-BfR8bXMIhJHg45mwgGEFl0_XvqPQw.ttf',
      },
      {
        value: '300',
        url: 'https://fonts.gstatic.com/s/robotomono/v22/L0xuDF4xlVMF-BfR8bXMIhJHg45mwgGEFl0_gPqPQw.ttf',
      },
      {
        value: '400',
        url: 'https://fonts.gstatic.com/s/robotomono/v22/L0xuDF4xlVMF-BfR8bXMIhJHg45mwgGEFl0_3vqPQw.ttf',
      },
      {
        value: '500',
        url: 'https://fonts.gstatic.com/s/robotomono/v22/L0xuDF4xlVMF-BfR8bXMIhJHg45mwgGEFl0_7PqPQw.ttf',
      },
      {
        value: '600',
        url: 'https://fonts.gstatic.com/s/robotomono/v22/L0xuDF4xlVMF-BfR8bXMIhJHg45mwgGEFl0_AP2PQw.ttf',
      },
      {
        value: '700',
        url: 'https://fonts.gstatic.com/s/robotomono/v22/L0xuDF4xlVMF-BfR8bXMIhJHg45mwgGEFl0_Of2PQw.ttf',
      },
    ],
  },
  {
    name: 'PT Mono',
    weight: [
      {
        value: '400',
        url: 'https://fonts.gstatic.com/s/ptmono/v13/9oRONYoBnWILk-9ArCg.ttf',
      },
    ],
  },
  {
    name: 'Marr Sans',
    weight: [
      {
        value: '100',
        url: getDmsFileUrl('019EBtkSxmw2v', '01'),
      },
      {
        value: '300',
        url: getDmsFileUrl('019EBtkSxmwQx', '01'),
      },
      {
        value: '400',
        url: getDmsFileUrl('019EBtkLaA3Qa', '01'),
      },
      {
        value: '500',
        url: getDmsFileUrl('019EBtkSxmweC', '01'),
      },
      {
        value: '600',
        url: getDmsFileUrl('019EBtkSxnGfd', '01'),
      },
      {
        value: '700',
        url: getDmsFileUrl('019EBtkSxnGyG', '01'),
      },
      {
        value: '900',
        url: getDmsFileUrl('019EBtkSxnH8C', '01'),
      },
    ],
  },
];

export const getFontWeightIndexByUrl = (url) => {
  return fontFamilyOptions.findIndex(obj => obj.weight.find(w => w.url === url));
};

export const getFontWeightName = (fontWeight) => {
  switch (fontWeight) {
    case '100':
      return 'Thin';
    case '200':
      return 'Extra Light';
    case '300':
      return 'Light';
    case '400':
      return 'Normal';
    case '500':
      return 'Medium';
    case '600':
      return 'Semi Bold';
    case '700':
      return 'Bold';
    case '800':
      return 'Extra Bold';
    case '900':
      return 'Black';
    default:
      return fontWeight;
  }
};
