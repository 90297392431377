import { Vector3 } from 'three';

export const TransformMode = Object.freeze({
  TRANSLATE: 'translate',
  SCALE: 'scale',
  ROTATE: 'rotate',
});

export const CursorTypes = Object.freeze({
  SHOE_PRINT: 'shoe-print',
  PLAY: 'play',
  PAUSE: 'pause',
  EYE: 'eye',
  GRAB: 'grab',
  GRABBING: 'grabbing',
  POINTER: 'pointer',
  DEFAULT: 'default',
  MOVE: 'move',
  NESW_RESIZE: 'nesw-resize',
  NWSE_RESIZE: 'nwse-resize',
  EW_RESIZE: 'ew-resize',
  NS_RESIZE: 'ns-resize',
});

export const SupportedFormats = Object.freeze({
  GLTF: 'gltf',
  GLB: 'glb',
  OBJ: 'obj',
});

export const VectorDirection = Object.freeze({
  UP: new Vector3(0, 1, 0),
  DOWN: new Vector3(0, -1, 0),
});

export const ModelTypes = Object.freeze({
  OBJECT: 'Object',
  IMAGE_SINGLE: 'ImageSingle',
  IMAGE_DOUBLE: 'ImageDouble',
});

export const AboutTabs = Object.freeze({
  EXHIBITION_INFO: 'ExhibitionInfo',
  FEEDBACK: 'Feedback',
});

export const ControlModes = Object.freeze({
  ORBIT_CONTROL: 'OrbitControl',
  POINTER_LOCK_CONTROL: 'PointerLockControl',
});

export const CanvasTypes = Object.freeze({
  WALL: 'Wall',
  FLOOR: 'Floor',
});

export const CanvasItemTypes = Object.freeze({
  IMAGE: 'Image',
  VIDEO: 'Video',
  TEXT: 'Text',
  AUDIO: 'Audio',
  MODEL3D: '3D-Model',
  IMAGE_SPHERE: 'ImageSphere',
  QUIZ: 'Quiz',
  TOUR: 'Tour',
});

export const TourMode = Object.freeze({
  PREVIEW: 'Preview',
  ACTIVE: 'Active',
});

export const CameraPlacementMode = Object.freeze({
  PREVIEW: 'Preview',
  EDIT: 'Edit',
});
