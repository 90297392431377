export const demo2 = {
    roomModelDmsId: '0136NRHBvDPe',
    startPosition: { x: -1.3, y: 1.95, z: -3.2 },
    directionOfView: { x: 0.001, y: -0.0002, z: 0 },
    dome: {
        skyBoxDmsId: '0136NvASQK5z',
    },
    lights: [
        {
            type: 'ambient',
            intensity: 0.6,
        },
        {
            type: 'spot',
            intensity: 0.4,
            position: { x: 7.4, y: 2.8, z: 3.2 },
            shadowBias: -0.00015,
        },
        {
            type: 'spot',
            intensity: 0.4,
            position: { x: 3.4, y: 2.8, z: 3.2 },
            shadowBias: -0.00015,
        },
    ],
}