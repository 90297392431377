import {ParagraphSettings} from "./ParagraphSettings";

export const Paragraphs = ({paragraphs, textItem, onUpdate}) => {
    if(paragraphs) {
        // TODO: paragraphs should get a key instead of relying on array index
        return paragraphs.map((paragraph, i) => (
            <ParagraphSettings
                key={i}
                textItem={textItem}
                paragraph={paragraph}
                paragraphIndex={i}
                onUpdate={onUpdate}
            />
        ))
    } else {
        return null
    }
}