import * as THREE from 'three';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useFrame } from '@react-three/fiber';
import { useDrag } from '@use-gesture/react';
import { getDmsFileUrl } from '@ekultur/dms-url-generator';
import { Box } from '@react-three/drei';
import { CanvasItemTypes, ControlModes } from '../../helpers/enums';
import { playAnimation, useGLTF, copyScene, centerModel, exitPointerLock } from '../../helpers';
import { Audio } from '../Audio/Audio';
import { DragPlane } from '../../components/DragPlane';
import { SET_ACTIVE_QUIZ, useThreeDDispatch, useThreeDState } from '../App/ThreeDContext';

export const Quiz = ({
  name,
  position,
  scale,
  content,
  animations,
  repeatable = true,
  editMode = false,
  dragPositionOffset,
  onPointerMove,
}) => {
  const { sceneAnimations, controlMode } = useThreeDState();
  const dispatch = useThreeDDispatch();
  const { scene } = useGLTF(getDmsFileUrl('0136NvAPT226', '01'));
  const copiedScene = useMemo(() => copyScene(scene), [scene]);
  const containerRef = useRef();
  const modelRef = useRef();
  const [ visible, setVisible ] = useState(true);

  useEffect(() => {
    centerModel(modelRef.current);
  }, [modelRef]);

  useEffect(() => {
    if (copiedScene) {
      copiedScene.traverse(child => {
        if (child instanceof THREE.Mesh) {
          child.castShadow = true;
          child.receiveShadow = true;
          if (child.name.startsWith('Question_icon')) {
            child.material = new THREE.MeshStandardMaterial({ color: '#ffffff' });
          }
        }
      });
    }
  }, [copiedScene]);

  const frequency = 0.5;
  const amplitude = 0.1;
  useFrame(state => {
    if (containerRef.current && !editMode) {
      containerRef.current.rotation.y += (Math.sin(state.clock.elapsedTime * frequency) * amplitude) / 2;
    }
  });

  const handleAllAnswersCorrect = () => {
    animations?.onCompletion && playAnimation(sceneAnimations, animations.onCompletion, false);

    animations?.onCompletion && playAnimation(sceneAnimations, 'Stairs_1.001Action', false);
    animations?.onCompletion && playAnimation(sceneAnimations, 'Stairs_1.002Action', false);
    animations?.onCompletion && playAnimation(sceneAnimations, 'Stairs_1.003Action', false);
    animations?.onCompletion && playAnimation(sceneAnimations, 'Stairs_1.004Action', false);
    animations?.onCompletion && playAnimation(sceneAnimations, 'Stairs_1.005Action', false);
    animations?.onCompletion && playAnimation(sceneAnimations, 'Stairs_1.006Action', false);

    !repeatable && setVisible(false);
  };

  const handleClick = ({ event, tap }) => {
    if (tap) {
      const pointerLockElement = document.pointerLockElement || document.mozPointerLockElement || document.webkitPointerLockElement;

      if (!(controlMode === ControlModes.POINTER_LOCK_CONTROL && !pointerLockElement)) {
        const intersection = event.intersections.reduce((acc, obj) => (obj.distance < acc.distance ? obj : acc));

        if (intersection.object === event.object) {
          exitPointerLock();
          dispatch({
            type: SET_ACTIVE_QUIZ,
            activeQuiz: { content, isAllAnswersCorrect: false, handleAllAnswersCorrect },
          });
        }
      }
    }
  };

  const useGestures = () => {
    const drag = useDrag(handleClick, { filterTaps: true });
    return !editMode ? drag() : {};
  };

  return (
    <group position={position}>
      <group ref={containerRef} scale={visible ? [1, 1, 1] : [0, 0, 0]}>
        <primitive
          ref={modelRef}
          name={name}
          object={copiedScene}
          scale={[scale.x, scale.y, scale.z]}
        />
        <Box
          args={[scale.x * 0.1, scale.y * 0.1, scale.z * 0.1]}
          userData={{
            itemName: name,
            itemType: CanvasItemTypes.QUIZ,
            interactable: true,
          }}
          {...useGestures()}
        >
          <meshBasicMaterial
            depthWrite={false}
            opacity={0}
            transparent
          />
        </Box>
      </group>
      {!visible && (animations?.audio || animations?.dmsId) &&
        <Audio url={animations.audio} dmsId={animations.dmsId} loop={false} />
      }
      {dragPositionOffset &&
        <DragPlane
          position={position}
          dragPositionOffset={dragPositionOffset.clone().multiply(scale)}
          onPointerMove={onPointerMove}
        />
      }
    </group>
  );
};
