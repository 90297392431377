import * as Icon from '@mui/icons-material';
import { getDmsUrl } from '@ekultur/dms-url-generator';
import { Dialog } from '../../components/Dialog';
import { createUseStyles } from 'react-jss';
import { Button } from '@mui/material';

const useStyles = createUseStyles({
  dialog: {
    padding: 30,
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-evenly',
    '&:not(:first-child)': {
      paddingTop: 10,
    },
  },
  column: {
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
    '&:not(:first-child)': {
      paddingLeft: 10,
    },
  },
  deleteButton: {
    position: 'absolute',
    top: -5,
    right: -5,
    border: '1px solid rgba(140, 140, 140, 0.5)',
    borderRadius: 20,
    color: '#D11A2A',
    backgroundColor: 'rgb(230, 230, 230)',
    '&:hover': {
      cursor: 'pointer',
      backgroundColor: 'rgb(200, 200, 200)',
    },
  },
  image: {
    objectFit: 'contain',
    border: '1px solid rgba(140, 140, 140, 0.5)',
  },
  buttonRow: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginTop: 15,
    '& .MuiButton-root': {
      color: 'rgba(0, 0, 0, 0.6)',
      borderColor: 'rgba(140, 140, 140, 0.5)',
      '&:hover': {
        background: 'rgba(63, 81, 181, 0.08)',
        borderColor: 'rgba(140, 140, 140, 0.5)',
      },
      '&:not(:first-child)': {
        marginLeft: 10,
      },
    },
  },
});

export const ImageOrganizerDialog = ({ images, segments, onChange, onClose }) => {
  const styles = useStyles();
  const rows = [...Array(segments.height).keys()];
  const columns = [...Array(segments.width).keys()];

  const handleDeleteImage = () => {

  };

  const handleAddImage = () => {

  };

  return (
    <Dialog className={styles.dialog}>
      {rows.map(rowIndex => (
        <div className={styles.row} key={rowIndex}>
          {columns.map(columnIndex => (
            <div className={styles.column} key={columnIndex}>
              <Icon.Remove
                className={styles.deleteButton}
                fontSize="small"
                onClick={handleDeleteImage}
              />
              <img
                className={styles.image}
                src={getDmsUrl(images[(rowIndex * segments.width) + columnIndex].dmsId, '01')}
                alt=""
                width={128}
                height={128}
              />
            </div>
          ))}
        </div>
      ))}
      <div className={styles.buttonRow}>
        <div>
          <Button
            variant="outlined"
            size="small"
            startIcon={<Icon.Add fontSize="small" />}
            onClick={handleAddImage}
          >
            Legg til
          </Button>
        </div>
        <div>
          <Button
            variant="outlined"
            size="small"
            startIcon={<Icon.Check fontSize="small" />}
            onClick={onChange}
          >
            Oppdater
          </Button>
          <Button
            variant="outlined"
            size="small"
            startIcon={<Icon.Clear fontSize="small" />}
            onClick={onClose}
          >
            Avbryt
          </Button>
        </div>
      </div>
    </Dialog>
  );
}
