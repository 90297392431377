import { useEffect } from 'react';
import { createUseStyles } from 'react-jss';
import { CameraPlacementMode, ControlModes, TourMode } from '../../helpers/enums';
import { HelpDialog } from './HelpDialog';
import { AboutDialog } from './About/AboutDialog';
import { TourPreviewDialog } from './TourPreviewDialog';
import { TourActiveDialog } from './TourActiveDialog';
import { QuizDialog } from './Quiz/QuizDialog';
import { GroupManagerDialog } from './GroupManagerDialog';
import { CanvasItemEditorDialog } from './CanvasItemEditor/CanvasItemEditorDialog';
import { TourActiveStepper } from './TourActiveStepper';
import { CameraPlacementDialog } from './CameraPlacementDialog';
import { ModelViewerDialog } from './ModelViewerDialog';
import { KeyboardInputs } from '../Controls/KeyboardInputs';
import { exitPointerLock, isMobileDevice } from '../../helpers';
import { SET_ABOUT_DIALOG_OPEN, SET_DRAWER_OPEN, SET_GROUP_MANAGER_DIALOG_OPEN,
  SET_HELP_DIALOG_OPEN, SET_MODELVIEWER_DATA, SET_POINTER_LOCKED, SET_SELECTED_TOUR,
  SET_USER_INTERACTED, useThreeDDispatch, useThreeDProps, useThreeDState } from '../App/ThreeDContext';

const useStyles = createUseStyles({
  dialogContainer: {
    '& .MuiDialog-root, .MuiBackdrop-root': {
      position: 'absolute !important',
    },
  },
});

export const DialogContainer = () => {
  const { adminMode } = useThreeDProps();
  const { cameraPlacementMode, userInteracted, modelViewerData, groupManagerDialogOpen, helpDialogOpen,
    aboutDialogOpen, canvasSelection, selectedTour, controlMode, activeQuiz } = useThreeDState();
  const dispatch = useThreeDDispatch();
  const isMobile = isMobileDevice();
  const styles = useStyles();
  const dialogOpen = !!modelViewerData || selectedTour
    || helpDialogOpen || aboutDialogOpen || groupManagerDialogOpen;

    useEffect(() => {
    dialogOpen && exitPointerLock();
  });

  const updateControlMode = () => {
    if (controlMode === ControlModes.POINTER_LOCK_CONTROL) {
      dispatch({ type: SET_POINTER_LOCKED, pointerLocked: true });
    }
  };

  return (
    <>
      <div
        id="dialogContainer"
        className={styles.dialogContainer}
      >
        {modelViewerData && (
          <ModelViewerDialog
            modelViewerData={modelViewerData}
            onGroupItemChange={(item) => {
              dispatch({ type: SET_MODELVIEWER_DATA, modelViewerData: item });
            }}
            onClose={() => {
              dispatch({ type: SET_MODELVIEWER_DATA, modelViewerData: null });
              updateControlMode();
            }}
          />
        )}
        {activeQuiz?.content && (
          <QuizDialog />
        )}
        {selectedTour && selectedTour.mode === TourMode.PREVIEW && (
          <TourPreviewDialog
            {...selectedTour.tour}
            onStart={() => {
              dispatch({
                type: SET_SELECTED_TOUR,
                selectedTour: {
                  ...selectedTour,
                  mode: TourMode.ACTIVE,
                  selectedIndex: 0,
                },
              });
              updateControlMode();
            }}
            onClose={() => {
              dispatch({ type: SET_SELECTED_TOUR, selectedTour: null });
              updateControlMode();
            }}
          />
        )}
        {selectedTour && selectedTour.mode === TourMode.ACTIVE && (
          <>
            <TourActiveDialog
              {...selectedTour.tour}
              selectedIndex={selectedTour.selectedIndex}
              onSelect={selectedIndex => {
                dispatch({ type: SET_SELECTED_TOUR, selectedTour: { ...selectedTour, selectedIndex }});
                dispatch({ type: SET_DRAWER_OPEN, drawerOpen: false });
              }}
              onClose={() => {
                dispatch({ type: SET_SELECTED_TOUR, selectedTour: null });
                dispatch({ type: SET_DRAWER_OPEN, drawerOpen: false });
                updateControlMode();
              }}
            />
            {!isMobile && (
              <TourActiveStepper
                selectedIndex={selectedTour.selectedIndex}
                itemCount={selectedTour.tour.groups.length}
                onClick={selectedIndex => {
                  dispatch({ type: SET_SELECTED_TOUR, selectedTour: { ...selectedTour, selectedIndex }});
                }}
              />
            )}
          </>
        )}
        {helpDialogOpen && (
          <HelpDialog
            onClose={() => {
              dispatch({ type: SET_HELP_DIALOG_OPEN, helpDialogOpen: false });
              dispatch({ type: SET_USER_INTERACTED, userInteracted: true });
              updateControlMode();
            }}
          />
        )}
        {aboutDialogOpen && (
          <AboutDialog
            onClose={() => {
              dispatch({ type: SET_ABOUT_DIALOG_OPEN, aboutDialogOpen: false });
              updateControlMode();
            }}
          />
        )}
        {adminMode && groupManagerDialogOpen && (
          <GroupManagerDialog
            onClose={() => {
              dispatch({ type: SET_GROUP_MANAGER_DIALOG_OPEN, groupManagerDialogOpen: false });
            }}
          />
        )}
        {adminMode && canvasSelection?.getSceneElements && (
          <CanvasItemEditorDialog {...canvasSelection} />
        )}
        {adminMode && cameraPlacementMode && cameraPlacementMode.mode === CameraPlacementMode.EDIT && (
          <CameraPlacementDialog {...cameraPlacementMode} />
        )}
      </div>
      {!adminMode && !dialogOpen && userInteracted && <KeyboardInputs />}
    </>
  );
};
