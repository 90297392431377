import {useEffect, useState} from "react";

const margins = [
    0,
    0.1,
    0.2,
    0.3,
    0.4,
    0.5
]

export const useMarginOptions = () => {
    const [marginOptions, setMarginOptions] = useState([])

    useEffect(
        () => {
            setMarginOptions(
                margins.map(option => ({
                    value: option,
                    text: option.toLocaleString(),
                }))
            )
        }, []
    )

    return marginOptions
}