import * as THREE from 'three';

export const DragPlane = ({
  position,
  dragPositionOffset,
  onPointerMove,
}) => {
  return (
    <group position={dragPositionOffset}>
      <mesh
        name="dragPlaneX"
        visible={false}
        rotation={[Math.PI / -2, 0, 0]}
        position={[0, 0.01 - position.y - dragPositionOffset.y, 0]}
        onPointerMove={onPointerMove}
      >
        <planeGeometry args={[100, 100]} />
        <meshBasicMaterial transparent opacity={0} side={THREE.DoubleSide} />
      </mesh>
      <mesh
        name="dragPlaneX"
        visible={false}
        rotation={[Math.PI / -2, 0, 0]}
        onPointerMove={onPointerMove}
      >
        <planeGeometry args={[100, 100]} />
        <meshBasicMaterial transparent opacity={0} side={THREE.DoubleSide} />
      </mesh>
      <mesh
        name="dragPlaneY"
        visible={false}
        rotation={[0, 0, 0]}
        onPointerMove={onPointerMove}
      >
        <planeGeometry args={[100, 100]} />
        <meshBasicMaterial transparent opacity={0} side={THREE.DoubleSide} />
      </mesh>
      <mesh
        name="dragPlaneZ"
        visible={false}
        rotation={[0, Math.PI / -2, 0]}
        onPointerMove={onPointerMove}
      >
        <planeGeometry args={[100, 100]} />
        <meshBasicMaterial transparent opacity={0} side={THREE.DoubleSide} />
      </mesh>
    </group>
  );
};
