import { useEffect, useRef } from 'react';
import { createUseStyles } from 'react-jss';
import * as Icon from 'react-feather';

const useStyles = createUseStyles({
  button: {
    width: props => props.radius * 2,
    height: props => props.radius * 2,
    border: props => `${props.radius * 0.1}px solid ${props.selected ? '#4484F1' : '#0C0B0F'}`,
    backgroundColor: '#F5F5F7',
    borderRadius: '50%',
    boxSizing: 'content-box',
    '&:hover': {
      cursor: 'pointer',
    },
  },
  progress: {
    fill: 'transparent',
    transform: 'rotate(-90deg)',
    '& circle':  {
      stroke: '#D1D5DB',
      strokeWidth: props => props.radius * 0.15,
    },
  },
  bar: {
    stroke: '#00C8A4 !important',
  },
  icon: {
    width: props => props.radius * 1.1,
    height: props => props.radius * 1.1,
    position: 'absolute',
    top: props => `calc(50% - ${props.radius * 1.1 / 2}px)`,
    left: props => `calc(50% - ${props.radius * 1.1 / 2}px)`,
    color: '#0C0B0F',
  },
});

export const PlaybackButton = ({
  started,
  paused,
  hover,
  progress,
  opacity,
  selected = false,
  scale = 1,
  onClick,
  onPointerEnter,
  onPointerLeave,
}) => {
  const bar = useRef();
  const radius = 40 * scale;
  const styles = useStyles({ radius, scale, selected });

  useEffect(() => {
    updateProgressBar(progress);
  }, [progress, scale]);

  const updateProgressBar = (val) => {
    const r = bar.current.r.baseVal.value;
    const c = Math.PI * (r * 2);
    const pct = ((100 - val) / 100) * c;
    bar.current.style.strokeDashoffset = pct;
  };

  return (
    <div
      className={styles.button}
      onClick={onClick}
      onPointerEnter={onPointerEnter}
      onPointerLeave={onPointerLeave}
      style={{ opacity: opacity }}
    >
      <svg
        height={radius * 2}
        width={radius * 2}
        className={styles.progress}
      >
        <circle
          cx={radius}
          cy={radius}
          r={radius * 0.85}
          strokeDasharray={radius * 0.85 * 2 * Math.PI}
          strokeDashoffset={0}
        />
        <circle
          ref={bar}
          cx={radius}
          cy={radius}
          r={radius * 0.85}
          strokeDasharray={radius * 0.85 * 2 * Math.PI}
          strokeDashoffset={radius * 0.85 * 2 * Math.PI}
          className={styles.bar}
        />
      </svg>
      {!hover && !started && <Icon.Volume2 className={styles.icon} />}
      {(hover || started) && paused && <Icon.Play className={styles.icon} />}
      {(hover || started) && !paused && <Icon.Pause className={styles.icon} />}
    </div>
  );
};