import { useRef } from 'react';
import { Stats } from '@react-three/drei';
import { createUseStyles } from 'react-jss';
import { BetaLogo } from '../../logo/BetaLogo';
import { useThreeDProps, useThreeDState } from '../App/ThreeDContext';
import { NewItemPicker } from './NewItemPicker';
import { SettingsToggle } from './SettingsToggle';

const useStyles = createUseStyles({
  stats: {
    position: 'absolute !important',
  },
});

export const Overlay = () => {
  const { data, adminMode } = useThreeDProps();
  const { userInteracted } = useThreeDState();
  const styles = useStyles();
  const parent = useRef();
  parent.current = document.getElementById('3DCanvasContainer');

  return (
    <>
      {window._env_.VIEW_STATS === 'true' && userInteracted && (
        <Stats parent={parent} className={styles.stats} />
      )}
      {adminMode && userInteracted && (
        <>
          {data.groups?.length > 0 && <SettingsToggle />}
          <NewItemPicker canvases={data.canvases} />
        </>
      )}
      <BetaLogo />
    </>
  );
};
