import * as THREE from 'three';
import { useThree } from '@react-three/fiber';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useCanvasItemEditor } from '../Canvases/useCanvasItemEditor';
import { CanvasItemTypes, CursorTypes } from '../../helpers/enums';
import { getSceneElementByName, isCanvasItem, setCursor } from '../../helpers';
import { ImageSphere } from '../ImageSpheres/ImageSphere';
import { SET_CANVAS_SELECTION, SET_OUTLINE_CANVAS, SET_ROTATION_ENABLED, useThreeDDispatch, useThreeDState } from '../App/ThreeDContext';

export const ImageSphereEditor = ({
  imageSphereName,
  imageSphereItem,
  canvasName,
  canvasIndex,
  canvasWidth,
  canvasHeight,
  cellSize,
  index,
  onUpdate,
  onRemove,
}) => {
  const { canvasSelection, outlineCanvas } = useThreeDState();
  const dispatch = useThreeDDispatch();
  const { scene } = useThree();
  const [dragPosition, setDragPosition] = useState(imageSphereItem.position);
  const [pointerDown, setPointerDown] = useState(false);
  const [positionOffset, setPositionOffset] = useState();
  const [dragMode, setDragMode] = useState(false);
  const canvas = getSceneElementByName(scene.children, canvasName);
  const imageSphere = getSceneElementByName(scene.children, imageSphereName);
  const canvasItemEditor = useCanvasItemEditor({
    canvas,
    canvasItem: imageSphere,
    canvasSize: { height: canvasHeight, width: canvasWidth },
    cellSize,
  });
  const point = useRef();

  const isSelected = useMemo(() => {
    return canvasSelection?.canvasIndex === canvasIndex
      && canvasSelection?.itemType === CanvasItemTypes.IMAGE_SPHERE
      && canvasSelection?.itemIndex === index;
  }, [canvasSelection, canvasIndex, index]);

  useEffect(() => {
    if (isSelected) {
      updateOutlineCanvas();
      !canvasSelection.getSceneElements && updateCanvasSelection();
    }
  });

  useEffect(() => {
    setDragPosition(imageSphereItem.position);
  }, [imageSphereItem.position, imageSphereItem.position.x, imageSphereItem.position.y, imageSphereItem.position.z]);

  const updateOutlineCanvas = () => {
    if (outlineCanvas !== imageSphereName) {
      dispatch({ type: SET_OUTLINE_CANVAS, outlineCanvas: imageSphereName });
    }
  };

  const updateCanvasSelection = () => {
    dispatch({
      type: SET_CANVAS_SELECTION,
      canvasSelection: {
        canvasIndex: canvasIndex,
        itemIndex: index,
        itemType: CanvasItemTypes.IMAGE_SPHERE,
        onUpdate: onUpdate,
        onRemove: onRemove,
        getSceneElements: () => ({
          canvasRef: canvas ?? getSceneElementByName(scene.children, canvasName),
          itemRef: getSceneElementByName(scene.children, imageSphereName),
        }),
      },
    });
  };

  const handleClick = (event) => {
    if (!isCanvasItem(event)) return null;
    const intersection = event.intersections.reduce((acc, obj) => (obj.distance < acc.distance ? obj : acc));

    if (event.button === 0 && !dragMode && intersection.object === event.object && point.current?.x === event.screenX && point.current?.y === event.screenY) {
      updateOutlineCanvas();
      !isSelected && updateCanvasSelection();
    }
  };

  const handlePointerDown = (event) => {
    event.stopPropagation();
    const intersection = event.intersections.reduce((acc, obj) => (obj.distance < acc.distance ? obj : acc));

    if (intersection.object === event.object && event.button === 0 && isSelected) {
      setPositionOffset(imageSphere.worldToLocal(event.point.clone()));
      dispatch({ type: SET_ROTATION_ENABLED, rotationEnabled: false });
      setPointerDown(true);
      setCursor(CursorTypes.MOVE);
    }

    point.current = { x: parseInt(event.screenX), y: parseInt(event.screenY) };
  };

  const handlePointerUp = (event) => {
    event.stopPropagation();
    const intersection = event.intersections.reduce((acc, obj) => (obj.distance < acc.distance ? obj : acc));

    if (intersection.object === event.object && event.button === 0 && isSelected) {
      dispatch({ type: SET_ROTATION_ENABLED, rotationEnabled: true });
      setPointerDown(false);
      setPositionOffset(null);
      setCursor(CursorTypes.GRAB);

      const point = new THREE.Vector3();
      let dragPlanes = event.intersections.filter(obj => obj.object.name.startsWith('dragPlane'));
      dragPlanes = dragPlanes.filter((obj, i) => dragPlanes.findIndex(e => e.object.name === obj.object.name) === i);

      if (dragPlanes.length > 0) {
        dragPlanes.forEach(dragPlane => point.add(dragPlane.point));
        point.divideScalar(dragPlanes.length);
        point.setY(imageSphereItem.position.y);

        const imageSphereSize = { radius: imageSphereItem.radius };
        canvasItemEditor.applyPositionOffset3D(point, positionOffset);
        imageSphereItem.boundToGrid && canvasItemEditor.snapPositionToGrid(point, imageSphereSize);
        canvasItemEditor.applyPositionLimits(point, imageSphereSize);

        if (!point.equals(imageSphereItem.position)) {
          imageSphereItem.position.copy(point);
          onUpdate(index, imageSphereItem);
        }
      }
    }

    pointerDown && setPointerDown(false);
    dragMode && setDragMode(false);
  };

  const handlePointerMove = (event) => {
    if (event.buttons === 1) {
      if (pointerDown && dragMode) {
        const point = new THREE.Vector3();
        let dragPlanes = event.intersections.filter(obj => obj.object.name.startsWith('dragPlane'));
        dragPlanes = dragPlanes.filter((obj, i) => dragPlanes.findIndex(e => e.object.name === obj.object.name) === i);

        if (new Set(dragPlanes.map(dragPlane => dragPlane.distance)).size !== 1) {
          dragPlanes.forEach(dragPlane => point.add(dragPlane.point));
          point.divideScalar(dragPlanes.length);
          point.setY(imageSphereItem.position.y);

          const imageSphereSize = { radius: imageSphereItem.radius };
          canvasItemEditor.applyPositionOffset3D(point, positionOffset);
          imageSphereItem.boundToGrid && canvasItemEditor.snapPositionToGrid(point, imageSphereSize);
          canvasItemEditor.applyPositionLimits(point, imageSphereSize);
          setDragPosition(point);
        }
      }

      pointerDown && !dragMode && setDragMode(true);
    }
  };

  return (
    <group
      onClick={handleClick}
      onPointerDown={handlePointerDown}
      onPointerUp={handlePointerUp}
    >
      <ImageSphere
        {...imageSphereItem}
        name={imageSphereName}
        position={dragPosition}
        editMode={true}
        dragPositionOffset={positionOffset}
        onPointerMove={handlePointerMove}
      />
    </group>
  );
};
