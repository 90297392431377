import { AudioEditor } from './AudioEditor';
import { getCanvasItemName } from '../../helpers';
import { CanvasItemTypes } from '../../helpers/enums';

export const AudioEditors = ({
  audios,
  canvasName,
  canvasIndex,
  canvasWidth,
  canvasHeight,
  cellSize,
  onUpdate,
  onRemove,
}) => {
  const handleUpdate = (index, audio) => {
    onUpdate && onUpdate(index, audio, CanvasItemTypes.AUDIO);
  };

  const handleRemove = (index) => {
    onRemove && onRemove(index, CanvasItemTypes.AUDIO);
  };

  return (
    <group>
      {audios.map((audio, index) => (
        <AudioEditor
          key={`${index}`}
          audioName={getCanvasItemName(CanvasItemTypes.AUDIO, canvasIndex, index)}
          audioItem={audio}
          canvasName={canvasName}
          canvasIndex={canvasIndex}
          canvasWidth={canvasWidth}
          canvasHeight={canvasHeight}
          cellSize={cellSize}
          index={index}
          onUpdate={handleUpdate}
          onRemove={handleRemove}
        />
      ))}
    </group>
  );
};
