import {useEffect, useState} from "react";

const fontSizes = [
    0.05,
    0.0625,
    0.075,
    0.0875,
    0.1,
    0.125,
    0.15,
    0.175,
    0.2,
    0.25,
    0.3,
    0.4,
    0.5,
    0.6,
    0.7,
    0.8,
    0.9,
    1
]

export const useFontSizeOptions = () => {
    const [fontSizeOptions, setFontSizeOptions] = useState([])

    useEffect(
        () => {
            const options = fontSizes.map(option => ({
                value: option,
                text: parseFloat((option * 10).toFixed(12)).toLocaleString(),
            }))
            setFontSizeOptions(options)
        }, []
    )

    return fontSizeOptions
}