import { Text } from '@react-three/drei';
import { useEffect, useRef, useState } from 'react';
import { computeBoundingBoxSize } from '../../helpers';

export const TextContent = ({
  title,
}) => {
  const ref = useRef();
  const [ fontSize, setFontSize ] = useState(0.1);
  const [ visible, setVisible ] = useState(false);

  const autoSize = () => {
    if (ref.current) {
      const size = computeBoundingBoxSize(ref.current);

      if (size.y > 0.35) {
        setFontSize(parseFloat((fontSize - 0.01).toFixed(2)));
      }
      else {
        setVisible(true);
      }
    }
  };

  useEffect(() => {
    autoSize();
  }, []);

  return (
    <>
      <Text
        text={'Omvisning'}
        fontSize={0.06}
        maxWidth={0.45}
        color={0x000000}
        position={[0, 0.76, 0.01]}
        textAlign={'center'}
        anchorX={'center'}
        renderOrder={6}
      >
        <meshStandardMaterial />
      </Text>
      <Text
        ref={ref}
        text={title}
        fontSize={fontSize}
        maxWidth={0.45}
        color={0x000000}
        position={[0, 0.66, 0.01]}
        textAlign={'center'}
        anchorX={'center'}
        anchorY={'top'}
        renderOrder={6}
        onSync={() => autoSize()}
        visible={visible}
      >
        <meshStandardMaterial />
      </Text>
    </>
  );
};
