export const demo18 = {
    roomModelDmsId: '019EBwEQi34nL',
    startPosition: { x: 0, y: 1.75, z: 18 },
    directionOfView: { x: 0, y: 0, z: -0.001 },
    lights: [
        {
            type: 'ambient',
            intensity: 1,
        },
    ],
    images: [
        // Kortvegger
        {
            dmsId: '013AkPV4ryJa',
            scale: 6.4025,
            position: { x: 3.839, y: 3, z: -5.585 },
            rotation: { x: 0, y: 0, z: 0 },
            addCanvasDepth: false,
        },
        {
            dmsId: '013AkPV1tzWb',
            scale: 6,
            position: { x: -1.68, y: 3, z: -5.585 },
            rotation: { x: 0, y: 0, z: 0 },
            addCanvasDepth: false,
        },
        {
            dmsId: '013AkPV1tzWf',
            scale: 8.7,
            position: { x: 2.7, y: 3, z: 19.98 },
            rotation: { x: 0, y: Math.PI, z: 0 },
            addCanvasDepth: false,
        },
        {
            dmsId: '013AkPV1tzWg',
            scale: 14,
            position: { x: -32.99, y: 3, z: -13 },
            rotation: { x: 0, y: Math.PI / 2, z: 0 },
            addCanvasDepth: false,
        },
        {
            dmsId: '013AkPV1tzfa',
            scale: 6.01,
            position: { x: -7.41, y: 3, z: -11.78 },
            rotation: { x: 0, y: Math.PI / -2, z: 0 },
            addCanvasDepth: false,
        },
        {
            dmsId: '013AkPV1tzjr',
            scale: 5.99,
            position: { x: -7.41, y: 3, z: -17.255 },
            rotation: { x: 0, y: Math.PI / -2, z: 0 },
            addCanvasDepth: false,
        },
        // Fritid
        {
            dmsId: '013AkPUwQMRT',
            scale: 1.5,
            position: { x: 7, y: 1.8, z: -3.3 },
            rotation: { x: 0, y: Math.PI / -2, z: 0 },
            group: {
                id: 0,
                index: 0,
            },
            viewerData: {
                model: {
                    type: 'ImageSingle',
                    dmsId: '013AkPUwQMRT',
                    info: {
                        title: 'Lorem Ipsum',
                        description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
                    },
                },
            },
        },
        {
            dmsId: '013AkPUwQMRV',
            scale: 1.5,
            position: { x: 7, y: 1.8, z: -1 },
            rotation: { x: 0, y: Math.PI / -2, z: 0 },
            group: {
                id: 0,
                index: 1,
            },
            viewerData: {
                model: {
                    type: 'ImageSingle',
                    dmsId: '013AkPUwQMRV',
                    info: {
                        title: 'Lorem Ipsum',
                        description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla molestie at felis non euismod. Nullam orci justo, lacinia pretium vestibulum id, semper vitae justo. Nam tristique vel erat et euismod. Donec quis sem nulla. Pellentesque mattis faucibus mauris, quis iaculis ante ultricies sit amet. Duis ut porttitor nibh. Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. Duis facilisis mi ut sem efficitur, nec facilisis turpis laoreet. Vestibulum quis massa cursus, pellentesque ipsum ut, faucibus urna. Phasellus ut facilisis nibh. Nullam porttitor tincidunt est, sed porta metus vestibulum nec. In eget varius neque. Vestibulum leo turpis, congue vel dui varius, suscipit faucibus mauris.',
                    },
                },
            },
        },
        {
            dmsId: '013AkPUwQMRW',
            scale: 1.5,
            position: { x: 7, y: 1.8, z: 1.3 },
            rotation: { x: 0, y: Math.PI / -2, z: 0 },
            group: {
                id: 0,
                index: 2,
            },
            viewerData: {
                model: {
                    type: 'ImageSingle',
                    dmsId: '013AkPUwQMRW',
                    info: {
                        title: 'Lorem Ipsum',
                        description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin lobortis gravida augue, a viverra arcu. Nunc et nisi sed ipsum ultrices pharetra vitae eget tellus. Etiam tristique eget quam varius molestie. Donec volutpat sapien erat, nec laoreet massa finibus sed. Duis cursus a ipsum id tempor. Proin condimentum libero vitae sollicitudin eleifend. Maecenas non enim ex. Cras posuere pellentesque mollis. Pellentesque at arcu commodo est hendrerit ullamcorper. Cras at ultricies nisi. Proin pharetra non felis fringilla rhoncus. Nulla in sollicitudin velit, non tincidunt sapien. Ut pellentesque orci sit amet elit varius vestibulum. Donec rhoncus ultrices tellus vel congue.',
                    },
                },
            },
        },
        {
            dmsId: '013AkPUwQMRX',
            scale: 1.5,
            position: { x: 7, y: 1.8, z: 3.6 },
            rotation: { x: 0, y: Math.PI / -2, z: 0 },
            group: {
                id: 0,
                index: 3,
            },
            viewerData: {
                model: {
                    type: 'ImageSingle',
                    dmsId: '013AkPUwQMRX',
                    info: {
                        title: 'Lorem Ipsum',
                        description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi vel sem sit amet felis viverra volutpat. Nam euismod dolor ac enim malesuada ultrices. Quisque congue bibendum diam volutpat sollicitudin. Cras iaculis ultrices risus vitae tristique. Morbi et orci at arcu auctor porta. Nam facilisis interdum nisi. In hac habitasse platea dictumst. Donec non ante non mauris hendrerit euismod. Etiam commodo lectus mi. Cras maximus sagittis turpis, nec volutpat purus. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia curae; Vestibulum venenatis feugiat lacus a aliquet. Nulla laoreet porta sodales.',
                    },
                },
            },
        },
        {
            dmsId: '013AkPUwQMRY',
            scale: 1.5,
            position: { x: 7, y: 1.8, z: 5.8 },
            rotation: { x: 0, y: Math.PI / -2, z: 0 },
            group: {
                id: 0,
                index: 4,
            },
            viewerData: {
                model: {
                    type: 'ImageSingle',
                    dmsId: '013AkPUwQMRY',
                    info: {
                        title: 'Lorem Ipsum',
                        description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean viverra porttitor leo, vel feugiat est efficitur non. Pellentesque suscipit leo venenatis enim commodo gravida. Mauris malesuada ante in enim ultrices, eu gravida eros pharetra. Nulla euismod venenatis lectus egestas elementum. Donec dictum eu mauris at fermentum. Sed nisi libero, sagittis in rutrum a, tempor nec lacus. Suspendisse potenti. In aliquet quam sit amet felis congue varius. Proin cursus tempus ante, ac venenatis ante venenatis non. Aliquam sodales blandit neque id finibus. Vestibulum nec eros pulvinar, interdum lorem et, convallis nibh. Duis nec efficitur tortor. Mauris eget turpis faucibus tellus ornare cursus. Morbi vel dolor magna. Vivamus ut elit in massa dignissim tincidunt nec a nulla. Ut rhoncus aliquam eros ut condimentum.',
                    },
                },
            },
        },
        {
            dmsId: '013AkPUwQMRZ',
            scale: 3.275,
            position: { x: 7, y: 2.86, z: 8.5 },
            rotation: { x: 0, y: Math.PI / -2, z: 0 },
            group: {
                id: 0,
                index: 5,
            },
            viewerData: {
                model: {
                    type: 'ImageSingle',
                    dmsId: '013AkPUwQMRZ',
                    info: {
                        title: 'Lorem Ipsum',
                        description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed mauris lorem, vestibulum nec congue ut, consectetur in est. Praesent euismod convallis dictum. Vestibulum vulputate ligula quis neque dignissim venenatis. Aliquam erat volutpat. Aenean non nulla eu nulla iaculis mattis. Aliquam erat volutpat. Nam malesuada purus ut turpis luctus accumsan. Nam massa dui, cursus sit amet enim eget, convallis dictum augue.',
                    },
                },
            },
        },
        {
            dmsId: '013AkPUwQMRa',
            scale: 1.5,
            position: { x: 7, y: 1.8, z: 11.2 },
            rotation: { x: 0, y: Math.PI / -2, z: 0 },
            group: {
                id: 0,
                index: 6,
            },
            viewerData: {
                model: {
                    type: 'ImageSingle',
                    dmsId: '013AkPUwQMRa',
                    info: {
                        title: 'Lorem Ipsum',
                        description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam eleifend eleifend sem. Aliquam erat volutpat. Mauris ultricies nunc velit, ac egestas leo mattis a. Nullam fringilla blandit mauris, at dictum justo rhoncus vitae. Aliquam non lectus at tellus lobortis tempor. Pellentesque massa tellus, imperdiet in rhoncus sit amet, vestibulum ut lorem. Morbi viverra arcu vitae lorem consequat, ut gravida ex pulvinar. Integer auctor est ultricies, congue enim vel, luctus nisl. Duis bibendum, neque quis finibus fermentum, purus ante convallis mauris, in tempor nisi est quis diam. Vestibulum pulvinar pulvinar massa id aliquam.',
                    },
                },
            },
        },
        {
            dmsId: '013AkPUwQgDd',
            scale: 1.5,
            position: { x: 7, y: 1.8, z: 13.4 },
            rotation: { x: 0, y: Math.PI / -2, z: 0 },
            group: {
                id: 0,
                index: 7,
            },
            viewerData: {
                model: {
                    type: 'ImageSingle',
                    dmsId: '013AkPUwQgDd',
                    info: {
                        title: 'Lorem Ipsum',
                        description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mauris quis luctus purus, et pharetra lacus. Proin non enim egestas, viverra leo eget, congue lectus. Nunc nec tincidunt velit. Fusce ultricies sapien odio, vel viverra elit fringilla quis. Morbi a nulla eu nisi vestibulum mattis sit amet id nisi. Sed tempor tincidunt sem malesuada vestibulum. Vivamus faucibus hendrerit ligula, in luctus mi semper et. Phasellus blandit euismod diam, eget consequat arcu luctus nec. Nulla facilisi. Praesent sed mi eget lectus ornare imperdiet.',
                    },
                },
            },
        },
        {
            dmsId: '013AkPUwQgDe',
            scale: 1.5,
            position: { x: 7, y: 1.8, z: 15.7 },
            rotation: { x: 0, y: Math.PI / -2, z: 0 },
            group: {
                id: 0,
                index: 8,
            },
            viewerData: {
                model: {
                    type: 'ImageSingle',
                    dmsId: '013AkPUwQgDe',
                    info: {
                        title: 'Lorem Ipsum',
                        description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus accumsan dui orci, vestibulum interdum purus euismod nec. Morbi ac faucibus dolor. Phasellus non venenatis lectus. Proin interdum at est id lacinia. In porta dolor et metus rhoncus ultricies. Quisque ante magna, tempus et lobortis blandit, dignissim et ligula. Nunc id pellentesque dui, non pellentesque sapien. Vestibulum vel lacus interdum, finibus nibh vitae, mollis tellus. Nunc vitae eros ac diam scelerisque semper iaculis sed purus. Curabitur condimentum nibh a elit accumsan, in facilisis metus mollis. Cras sed nisi eu turpis vestibulum facilisis. Praesent sed nisl nec mauris posuere ullamcorper. Ut sed urna turpis. Nulla sed nisi convallis, sagittis ipsum sit amet, tristique mauris.',
                    },
                },
            },
        },
        {
            dmsId: '013AkPUwQgDf',
            scale: 1.5,
            position: { x: 7, y: 1.8, z: 18 },
            rotation: { x: 0, y: Math.PI / -2, z: 0 },
            group: {
                id: 0,
                index: 9,
            },
            viewerData: {
                model: {
                    type: 'ImageSingle',
                    dmsId: '013AkPUwQgDf',
                    info: {
                        title: 'Lorem Ipsum',
                        description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed lorem neque, accumsan nec sapien id, consectetur vestibulum ligula. Integer tortor sem, blandit sed eleifend vitae, sodales sit amet nisi. Ut ac tortor fermentum, tempus metus quis, tincidunt mauris. Praesent nec posuere nibh. Suspendisse feugiat commodo nunc ac mattis. Nunc pulvinar erat eu leo consequat, eget tristique enim dictum. Nulla efficitur orci vel posuere viverra. Vestibulum varius hendrerit malesuada. Donec lectus justo, varius quis leo sit amet, mollis bibendum dolor. Duis tincidunt lectus quis nisl ullamcorper, a elementum tortor viverra. Nullam quis justo lacus. Donec eget vehicula mi.',
                    },
                },
            },
        },
        // Vennskap
        {
            dmsId: '013AkPUwQgDg',
            scale: 1.5,
            position: { x: -7, y: 1.8, z: 17.4 },
            rotation: { x: 0, y: Math.PI / 2, z: 0 },
            group: {
                id: 1,
                index: 0,
            },
            viewerData: {
                model: {
                    type: 'ImageSingle',
                    dmsId: '013AkPUwQgDg',
                    info: {
                        title: 'Lorem Ipsum',
                        description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam rutrum massa non neque vestibulum malesuada. In gravida euismod lacinia. Aenean fermentum sodales sapien et lobortis. Nam ac mauris ipsum. Cras eget consectetur est. Integer condimentum justo in dapibus elementum. Aenean dictum auctor ante in lobortis. Cras fermentum nunc quam, sit amet vestibulum turpis laoreet et. Proin sem est, euismod convallis risus in, volutpat ultricies ipsum. Quisque fermentum risus eget magna tincidunt ultricies. Vestibulum tincidunt, tortor quis convallis efficitur, est orci mattis justo, a consectetur risus orci at lacus. Nunc at euismod ex. Nullam volutpat et quam et ullamcorper. Suspendisse ornare a mi non consectetur. Etiam urna tortor, maximus eget magna quis, dapibus convallis sem. Vivamus aliquet mi magna, vel hendrerit libero porta ac.',
                    },
                },
            },
        },
        {
            dmsId: '013AkPUwQgDh',
            scale: 1.5,
            position: { x: -7, y: 1.8, z: 15 },
            rotation: { x: 0, y: Math.PI / 2, z: 0 },
            group: {
                id: 1,
                index: 1,
            },
            viewerData: {
                model: {
                    type: 'ImageSingle',
                    dmsId: '013AkPUwQgDh',
                    info: {
                        title: 'Lorem Ipsum',
                        description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam id facilisis massa, at facilisis diam. Vivamus pellentesque sed diam et ultricies. Interdum et malesuada fames ac ante ipsum primis in faucibus. Integer velit dolor, pellentesque vel aliquam at, tempus id tortor. Aliquam leo lorem, consectetur vitae mauris ut, porta mollis tortor. Nullam aliquet, risus a cursus lobortis, ligula arcu condimentum massa, et commodo ex felis nec elit. Nam vel purus a turpis varius gravida non sit amet nisl. Nulla fringilla justo a ornare lobortis. Integer nunc mauris, rutrum quis mauris at, malesuada tempor urna. Aenean sed ipsum a elit vestibulum mattis in at nisi. Aenean et sem dictum nulla aliquet sagittis vel nec nunc. Integer vulputate volutpat dapibus.',
                    },
                },
            },
        },
        {
            dmsId: '013AkPUwQgDi',
            scale: 1.5,
            position: { x: -7, y: 1.8, z: 12.6 },
            rotation: { x: 0, y: Math.PI / 2, z: 0 },
            group: {
                id: 1,
                index: 2,
            },
            viewerData: {
                model: {
                    type: 'ImageSingle',
                    dmsId: '013AkPUwQgDi',
                    info: {
                        title: 'Lorem Ipsum',
                        description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur consectetur urna et lorem fermentum, at ornare risus finibus. Ut ut magna sed eros euismod luctus. Aliquam at semper nisi, at luctus nisl. Curabitur semper risus ut dolor elementum vestibulum. Duis in ipsum sit amet nulla feugiat eleifend. Praesent sodales orci vel lacinia ultricies. Aliquam rhoncus, dolor a vehicula efficitur, metus erat maximus nunc, id volutpat purus diam sit amet metus. Etiam nulla lorem, mattis vel laoreet ac, accumsan ac purus. Vestibulum libero sapien, sodales et tincidunt nec, varius id quam. Vestibulum id luctus arcu, quis volutpat ipsum. Vestibulum sit amet rutrum tortor, at gravida nibh. Nam feugiat consectetur purus, vitae vulputate sapien. Morbi mattis nunc nec tempus consectetur. Sed accumsan pretium ligula fringilla tincidunt.',
                    },
                },
            },
        },
        {
            dmsId: '013AkPUwQgDj',
            scale: 2.5,
            position: { x: -7, y: 2.16, z: 9.7 },
            rotation: { x: 0, y: Math.PI / 2, z: 0 },
            group: {
                id: 1,
                index: 3,
            },
            viewerData: {
                model: {
                    type: 'ImageSingle',
                    dmsId: '013AkPUwQgDj',
                    info: {
                        title: 'Lorem Ipsum',
                        description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla finibus sem orci, eleifend tempor justo varius ut. Duis eleifend eu dolor quis tincidunt. Pellentesque at diam a neque interdum porta. Fusce quam nulla, consequat sit amet sapien id, ullamcorper pellentesque arcu. Sed ultrices aliquet gravida. Phasellus non dolor rutrum, molestie lorem et, bibendum leo. Vestibulum a eros hendrerit odio luctus suscipit. Nam efficitur aliquet lacinia. Phasellus magna nisi, tempor a efficitur ac, pretium quis dolor. Mauris at pulvinar sapien.',
                    },
                },
            },
        },
        {
            dmsId: '013AkPUwQgDk',
            scale: 1.5,
            position: { x: -7, y: 1.8, z: 6.8 },
            rotation: { x: 0, y: Math.PI / 2, z: 0 },
            group: {
                id: 1,
                index: 4,
            },
            viewerData: {
                model: {
                    type: 'ImageSingle',
                    dmsId: '013AkPUwQgDk',
                    info: {
                        title: 'Lorem Ipsum',
                        description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque vestibulum diam ut dictum vulputate. Fusce urna nibh, euismod elementum maximus non, eleifend vitae magna. Proin tincidunt tellus et aliquet rhoncus. Donec pulvinar vulputate bibendum. Maecenas laoreet urna id nunc faucibus, sed tristique est eleifend. Integer imperdiet erat a lacus imperdiet, ut feugiat turpis ullamcorper. Fusce vitae cursus ligula, ac porta arcu.',
                    },
                },
            },
        },
        {
            dmsId: '013AkPUwQgDm',
            scale: 1.22,
            position: { x: -7, y: 1.8, z: 4.4 },
            rotation: { x: 0, y: Math.PI / 2, z: 0 },
            group: {
                id: 1,
                index: 5,
            },
            viewerData: {
                model: {
                    type: 'ImageSingle',
                    dmsId: '013AkPUwQgDm',
                    info: {
                        title: 'Lorem Ipsum',
                        description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc vitae ultrices orci. Sed ante lacus, porttitor vel efficitur in, eleifend sed leo. Vivamus dapibus sed metus a rutrum. Vestibulum blandit turpis placerat dolor aliquet venenatis. Praesent ipsum odio, bibendum sed ullamcorper et, semper eget dui. Quisque risus quam, vehicula sit amet lorem quis, posuere porta ex. Donec porttitor purus sed leo posuere, vel mollis risus mattis.',
                    },
                },
            },
        },
        {
            dmsId: '013AkPUwQgDn',
            scale: 1.5,
            position: { x: -7, y: 1.8, z: 2 },
            rotation: { x: 0, y: Math.PI / 2, z: 0 },
            group: {
                id: 1,
                index: 6,
            },
            viewerData: {
                model: {
                    type: 'ImageSingle',
                    dmsId: '013AkPUwQgDn',
                    info: {
                        title: 'Lorem Ipsum',
                        description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque eget augue sodales, venenatis urna ac, tristique dolor. In eu diam viverra, auctor est sit amet, tincidunt neque. Duis nec tristique augue. Interdum et malesuada fames ac ante ipsum primis in faucibus. Donec quis tellus augue. Curabitur euismod nisi eu dui dapibus, eget porta elit semper. Donec malesuada lorem magna, id pretium ipsum viverra a. Sed risus nunc, ultrices et aliquet posuere, consequat ac mauris.',
                    },
                },
            },
        },
        {
            dmsId: '013AkPUwQgJ3',
            scale: 1.5,
            position: { x: -7, y: 1.8, z: -0.4 },
            rotation: { x: 0, y: Math.PI / 2, z: 0 },
            group: {
                id: 1,
                index: 7,
            },
            viewerData: {
                model: {
                    type: 'ImageSingle',
                    dmsId: '013AkPUwQgJ3',
                    info: {
                        title: 'Lorem Ipsum',
                        description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla dapibus molestie ultricies. Quisque id elit urna. Nunc ut varius leo, quis semper est. Donec lobortis augue sed fermentum efficitur. Morbi sit amet mauris sodales, ultricies velit eu, tempus tellus. Curabitur ullamcorper velit ut ultricies fringilla. Fusce et auctor turpis. Etiam aliquam eros vestibulum risus dignissim, id condimentum justo rutrum. Morbi quis lorem placerat leo porttitor ullamcorper ac eget felis. Mauris massa nibh, hendrerit in lacus molestie, tristique ultricies sem. Sed rutrum sapien sit amet luctus facilisis. Maecenas tristique augue eget tellus pretium, eu iaculis neque elementum. Phasellus nec neque eget ex commodo fermentum vitae at justo.',
                    },
                },
            },
        },
        //Mennesker
        {
            dmsId: '013AkPUwQgNW',
            scale: 1.5,
            position: { x: -11.9, y: 1.8, z: -6.005 },
            rotation: { x: 0, y: Math.PI, z: 0 },
            group: {
                id: 2,
                index: 0,
            },
            viewerData: {
                model: {
                    type: 'ImageSingle',
                    dmsId: '013AkPUwQgNW',
                },
            },
        },
        {
            dmsId: '013AkPUwQgNX',
            scale: 1.5,
            position: { x: -14.2, y: 1.8, z: -6.005 },
            rotation: { x: 0, y: Math.PI, z: 0 },
            group: {
                id: 2,
                index: 1,
            },
            viewerData: {
                model: {
                    type: 'ImageSingle',
                    dmsId: '013AkPUwQgNX',
                },
            },
        },
        {
            dmsId: '013AkPUwQgNY',
            scale: 1.5,
            position: { x: -16.5, y: 1.8, z: -6.005 },
            rotation: { x: 0, y: Math.PI, z: 0 },
            group: {
                id: 2,
                index: 2,
            },
            viewerData: {
                model: {
                    type: 'ImageSingle',
                    dmsId: '013AkPUwQgNY',
                },
            },
        },
        {
            dmsId: '013AkPUwQgNZ',
            scale: 1.5,
            position: { x: -18.8, y: 1.8, z: -6.005 },
            rotation: { x: 0, y: Math.PI, z: 0 },
            group: {
                id: 2,
                index: 3,
            },
            viewerData: {
                model: {
                    type: 'ImageSingle',
                    dmsId: '013AkPUwQgNZ',
                },
            },
        },
        {
            dmsId: '013AkPUwQgNa',
            scale: 3.275,
            position: { x: -21.6, y: 2.85, z: -6.005 },
            rotation: { x: 0, y: Math.PI, z: 0 },
            group: {
                id: 2,
                index: 4,
            },
            viewerData: {
                model: {
                    type: 'ImageSingle',
                    dmsId: '013AkPUwQgNa',
                },
            },
        },
        {
            dmsId: '013AkPUwQgNb',
            scale: 1.5,
            position: { x: -24.4, y: 1.8, z: -6.005 },
            rotation: { x: 0, y: Math.PI, z: 0 },
            group: {
                id: 2,
                index: 5,
            },
            viewerData: {
                model: {
                    type: 'ImageSingle',
                    dmsId: '013AkPUwQgNb',
                },
            },
        },
        {
            dmsId: '013AkPUwQgNc',
            scale: 1.5,
            position: { x: -26.7, y: 1.8, z: -6.005 },
            rotation: { x: 0, y: Math.PI, z: 0 },
            group: {
                id: 2,
                index: 6,
            },
            viewerData: {
                model: {
                    type: 'ImageSingle',
                    dmsId: '013AkPUwQgNc',
                },
            },
        },
        {
            dmsId: '013AkPUwQgSs',
            scale: 1.5,
            position: { x: -29, y: 1.8, z: -6.005 },
            rotation: { x: 0, y: Math.PI, z: 0 },
            group: {
                id: 2,
                index: 7,
            },
            viewerData: {
                model: {
                    type: 'ImageSingle',
                    dmsId: '013AkPUwQgSs',
                },
            },
        },
        {
            dmsId: '013AkPUwQgSt',
            scale: 1.5,
            position: { x: -31.3, y: 1.8, z: -6.005 },
            rotation: { x: 0, y: Math.PI, z: 0 },
            group: {
                id: 2,
                index: 8,
            },
            viewerData: {
                model: {
                    type: 'ImageSingle',
                    dmsId: '013AkPUwQgSt',
                },
            },
        },
        //Gårdsliv
        {
            dmsId: '013AkPUwQgT1',
            scale: 1.5,
            position: { x: -29.95, y: 1.8, z: -19.98 },
            rotation: { x: 0, y: 0, z: 0 },
            group: {
                id: 3,
                index: 0,
            },
            viewerData: {
                model: {
                    type: 'ImageSingle',
                    dmsId: '013AkPUwQgT1',
                },
            },
        },
        {
            dmsId: '013AkPUwQgT2',
            scale: 1.5,
            position: { x: -27.65, y: 1.8, z: -19.98 },
            rotation: { x: 0, y: 0, z: 0 },
            group: {
                id: 3,
                index: 1,
            },
            viewerData: {
                model: {
                    type: 'ImageSingle',
                    dmsId: '013AkPUwQgT2',
                },
            },
        },
        {
            dmsId: '013AkPUwQgXH',
            scale: 1.5,
            position: { x: -25.35, y: 1.8, z: -19.98 },
            rotation: { x: 0, y: 0, z: 0 },
            group: {
                id: 3,
                index: 2,
            },
            viewerData: {
                model: {
                    type: 'ImageSingle',
                    dmsId: '013AkPUwQgXH',
                },
            },
        },
        {
            dmsId: '013AkPUwQgXN',
            scale: 2.5,
            position: { x: -22.4, y: 2.19, z: -19.98 },
            rotation: { x: 0, y: 0, z: 0 },
            group: {
                id: 3,
                index: 3,
            },
            viewerData: {
                model: {
                    type: 'ImageSingle',
                    dmsId: '013AkPUwQgXN',
                },
            },
        },
        {
            dmsId: '013AkPUwQgXK',
            scale: 1.5,
            position: { x: -19.55, y: 1.8, z: -19.98 },
            rotation: { x: 0, y: 0, z: 0 },
            group: {
                id: 3,
                index: 4,
            },
            viewerData: {
                model: {
                    type: 'ImageSingle',
                    dmsId: '013AkPUwQgXK',
                },
            },
        },
        {
            dmsId: '013AkPUwQgXL',
            scale: 1.5,
            position: { x: -17.25, y: 1.8, z: -19.98 },
            rotation: { x: 0, y: 0, z: 0 },
            group: {
                id: 3,
                index: 5,
            },
            viewerData: {
                model: {
                    type: 'ImageSingle',
                    dmsId: '013AkPUwQgXL',
                },
            },
        },
        {
            dmsId: '013AkPUwQgXM',
            scale: 1.5,
            position: { x: -14.95, y: 1.8, z: -19.98 },
            rotation: { x: 0, y: 0, z: 0 },
            group: {
                id: 3,
                index: 6,
            },
            viewerData: {
                model: {
                    type: 'ImageSingle',
                    dmsId: '013AkPUwQgXM',
                },
            },
        },
        {
            dmsId: '013AkPUwQgXJ',
            scale: 1.5,
            position: { x: -12.65, y: 1.8, z: -19.98 },
            rotation: { x: 0, y: 0, z: 0 },
            group: {
                id: 3,
                index: 7,
            },
            viewerData: {
                model: {
                    type: 'ImageSingle',
                    dmsId: '013AkPUwQgXJ',
                },
            },
        },
        {
            dmsId: '013AkPUwQgXP',
            scale: 1.5,
            position: { x: -10.35, y: 1.8, z: -19.98 },
            rotation: { x: 0, y: 0, z: 0 },
            group: {
                id: 3,
                index: 8,
            },
            viewerData: {
                model: {
                    type: 'ImageSingle',
                    dmsId: '013AkPUwQgXP',
                },
            },
        },
        // Hobbyfotografens utstyr
        {
            dmsId: '013AkPV1tzjs',
            scale: 3,
            position: { x: -2.4, y: 2, z: -12.435 },
            rotation: { x: 0, y: -0.7766, z: 0 },
            viewerData: {
                model: {
                    type: 'ImageSingle',
                    dmsId: '013AkPV1tzjs',
                },
            },
        },
        {
            dmsId: '013AkPV1tzjt',
            scale: 3.1,
            position: { x: -0.85, y: 2, z: -19.988 },
            rotation: { x: 0, y: 0, z: 0 },
            viewerData: {
                model: {
                    type: 'ImageSingle',
                    dmsId: '013AkPV1tzjt',
                },
            },
        },
        {
            dmsId: '013AkPV1tzju',
            scale: 3.1,
            position: { x: 3.5, y: 2, z: -19.988 },
            rotation: { x: 0, y: 0, z: 0 },
            viewerData: {
                model: {
                    type: 'ImageSingle',
                    dmsId: '013AkPV1tzju',
                },
            },
        },
        {
            dmsId: '013AkPV1tzSK',
            scale: 2,
            position: { x: 6.988, y: 1.8, z: -17 },
            rotation: { x: 0, y: Math.PI / -2, z: 0 },
            viewerData: {
                model: {
                    type: 'ImageSingle',
                    dmsId: '013AkPV1tzSK',
                },
            },
        },
        {
            dmsId: '013AkPV1tfe7',
            scale: 2.05,
            position: { x: 6.988, y: 1.8, z: -14 },
            rotation: { x: 0, y: Math.PI / -2, z: 0 },
            viewerData: {
                model: {
                    type: 'ImageSingle',
                    dmsId: '013AkPV1tfe7',
                },
            },
        },
        {
            dmsId: '013AkPV1tfe8',
            scale: 1.85,
            position: { x: 6.988, y: 1.8, z: -11 },
            rotation: { x: 0, y: Math.PI / -2, z: 0 },
            viewerData: {
                model: {
                    type: 'ImageSingle',
                    dmsId: '013AkPV1tfe8',
                },
            },
        },
    ],
    texts: [
        // Kortvegger
        {
            position: { x: -4.2, y: 3, z: 19.99 },
            rotation: { x: 0, y: Math.PI, z: 0 },
            maxWidth: 3,
            paragraphs: [
                {
                    text: 'Niels Juel',
                    fontSize: 0.6,
                },
                {
                    text: 'Godseieren Niels Juel fra Hurdal var en av Norges første hobbyfotografer. Her står han øverst i bildet.',
                    fontSize: 0.15,
                    margin: {
                        top: 0.4,
                    },
                },
                {
                    text: 'Niels Juel tok hundrevis av personlige bilder av familien, venner og gjester i løpet av livet.',
                    fontSize: 0.15,
                    margin: {
                        top: 0.2,
                    },
                },
                {
                    text: 'Bildene hans gir et unikt innblikk i hverdagslivet og fest for mer enn 100 år siden.',
                    fontSize: 0.15,
                    margin: {
                        top: 0.2,
                    },
                },
            ],
        },
        // Fritid
        {
            position: { x: 6.99, y: 4, z: -2.15 },
            rotation: { x: 0, y: Math.PI / -2, z: 0 },
            maxWidth: 10,
            paragraphs: [
                {
                    text: 'Fest og venner',
                    fontSize: 0.6,
                    textAlign: 'center',
                },
            ],
        },
        {
            position: { x: 6.99, y: 3.2, z: -2.07 },
            rotation: { x: 0, y: Math.PI / -2, z: 0 },
            maxWidth: 4,
            paragraphs: [
                {
                    text: 'Fritid – Hva hadde du gjort på fritiden hvis du hverken hadde mobil eller internett? Kanskje kan du få noen ideer av å se hva Niels Juel og hans venner og fylte tiden sin med.',
                    fontSize: 0.15,
                },
            ],
        },
        {
            position: { x: 6.99, y: 1.465, z: -2.35 },
            rotation: { x: 0, y: Math.PI / -2, z: 0 },
            maxWidth: 0.3,
            paragraphs: [
                {
                    text: 'Skiløp i Hurdal! Her poserer utøver nr.2 før startskuddet går.',
                    fontSize: 0.03,
                },
            ],
        },
        {
            position: { x: 6.99, y: 1.376, z: -0.05 },
            rotation: { x: 0, y: Math.PI / -2, z: 0 },
            maxWidth: 0.3,
            paragraphs: [
                {
                    text: 'Dette bildet er ikke tatt på Viken gård, men i en stilig stue i Kristiania – datidens navn på Oslo. Der sitter denne ukjente kvinnen og fordyper seg i en bok.',
                    fontSize: 0.03,
                },
            ],
        },
        {
            position: { x: 6.99, y: 1.375, z: 2.25 },
            rotation: { x: 0, y: Math.PI / -2, z: 0 },
            maxWidth: 0.3,
            paragraphs: [
                {
                    text: 'Niels Juel, vennen Nils Strand og en ukjent kvinne nyter ettermiddagen ute på Hurdalssjøen. Nils Strand har tatt med seg trekkspillet sitt, så kveldens musikalske innslag er sikret!',
                    fontSize: 0.03,
                },
            ],
        },
        {
            position: { x: 6.99, y: 1.43, z: 4.55 },
            rotation: { x: 0, y: Math.PI / -2, z: 0 },
            maxWidth: 0.3,
            paragraphs: [
                {
                    text: 'Det sies at nordmenn er født med ski på beina, og denne kvinnen ser ikke ut til å være noe unntak.',
                    fontSize: 0.03,
                },
            ],
        },
        {
            position: { x: 6.99, y: 1.43, z: 6.75 },
            rotation: { x: 0, y: Math.PI / -2, z: 0 },
            maxWidth: 0.3,
            paragraphs: [
                {
                    text: 'Noen dager må man bare legge seg ned i gresset sammen med gode venner.',
                    fontSize: 0.03,
                },
            ],
        },
        {
            position: { x: 6.99, y: 1.4, z: 9.95 },
            rotation: { x: 0, y: Math.PI / -2, z: 0 },
            maxWidth: 0.3,
            paragraphs: [
                {
                    text: 'Karneval, fest, og utkledning var noe Niels Juel var veldig glad i. Her er han pent utkledd og klar for fest!',
                    fontSize: 0.03,
                },
            ],
        },
        {
            position: { x: 6.99, y: 1.41, z: 12.15 },
            rotation: { x: 0, y: Math.PI / -2, z: 0 },
            maxWidth: 0.3,
            paragraphs: [
                {
                    text: 'Det var ikke bare Niels Juel som likte karneval. Her står han pent utkledd sammen med en konge i bakgrunnen og tre kongelige damer.',
                    fontSize: 0.03,
                },
            ],
        },
        {
            position: { x: 6.99, y: 1.405, z: 14.35 },
            rotation: { x: 0, y: Math.PI / -2, z: 0 },
            maxWidth: 0.3,
            paragraphs: [
                {
                    text: 'Vi vet dessverre ikke hvem disse menneskene er, men vi vet at de har god humor og gode utkledninger!',
                    fontSize: 0.03,
                },
            ],
        },
        {
            position: { x: 6.99, y: 1.405, z: 16.65 },
            rotation: { x: 0, y: Math.PI / -2, z: 0 },
            maxWidth: 0.3,
            paragraphs: [
                {
                    text: 'På fritiden kan man også stille som modell for en hobbyfotograf. Denne kvinnen ser ut til å stille opp med glede.',
                    fontSize: 0.03,
                },
            ],
        },
        {
            position: { x: 6.99, y: 1.395, z: 18.95 },
            rotation: { x: 0, y: Math.PI / -2, z: 0 },
            maxWidth: 0.3,
            paragraphs: [
                {
                    text: 'Det ser ut som om Hurdals egen syklubb er samlet i en av skogene på Niels Juels tomter. De driver med både hekling, brodering og sying.',
                    fontSize: 0.03,
                },
            ],
        },
        // Vennskap
        {
            position: { x: -6.99, y: 4, z: 16.2 },
            rotation: { x: 0, y: Math.PI / 2, z: 0 },
            maxWidth: 4,
            paragraphs: [
                {
                    text: 'Nære og kjære',
                    fontSize: 0.6,
                },
            ],
        },
        {
            position: { x: -6.99, y: 3.2, z: 16.15 },
            rotation: { x: 0, y: Math.PI / 2, z: 0 },
            maxWidth: 4,
            paragraphs: [
                {
                    text: 'Våre kjære og nære, ja venner og relasjoner, det er jo for noen kanskje det viktigste man har i livet. Det stemte for Niels Juel, vi vet at familien hans var noe han satt utrolig stor pris på.',
                    fontSize: 0.15,
                },
            ],
        },
        {
            position: { x: -6.99, y: 1.55, z: 16.45 },
            rotation: { x: 0, y: Math.PI / 2, z: 0 },
            maxWidth: 0.3,
            paragraphs: [
                {
                    text: 'Niels Juel var glad i sykkelturer, og her deler han gleden med vennen Nils Strand.',
                    fontSize: 0.03,
                },
            ],
        },
        {
            position: { x: -6.99, y: 1.48, z: 14.05 },
            rotation: { x: 0, y: Math.PI / 2, z: 0 },
            maxWidth: 0.3,
            paragraphs: [
                {
                    text: 'Denne tjenestemannen og tjenestekvinnen er dessverre ukjent for oss, men de lette smilene og den gode tonen gir oss hint om samhold og vennskap mellom dem.',
                    fontSize: 0.03,
                },
            ],
        },
        {
            position: { x: -6.99, y: 1.5, z: 11.65 },
            rotation: { x: 0, y: Math.PI / 2, z: 0 },
            maxWidth: 0.3,
            paragraphs: [
                {
                    text: 'Niels Juel og Nils Strand er ofte avbildet sammen. Selv om de på dette bildet er ute i en steinrøys og arbeider, satt nok latteren løst mellom disse to.',
                    fontSize: 0.03,
                },
            ],
        },
        {
            position: { x: -6.99, y: 1.55, z: 8.25 },
            rotation: { x: 0, y: Math.PI / 2, z: 0 },
            maxWidth: 0.3,
            paragraphs: [
                {
                    text: 'Med piper i munnen og drikke på bordet går Niels Juel og Nils Strand en fredelig kveld i møte.',
                    fontSize: 0.03,
                },
            ],
        },
        {
            position: { x: -6.99, y: 1.47, z: 5.85 },
            rotation: { x: 0, y: Math.PI / 2, z: 0 },
            maxWidth: 0.3,
            paragraphs: [
                {
                    text: 'Dette er Ragna og Aksel Grundset. Dette brudeparet var venner av Niels Juel. Som hobbyfotograf tok han brudebilder av dem, og han tok også bilder av selve bryllupsfeiringen deres.',
                    fontSize: 0.03,
                },
            ],
        },
        {
            position: { x: -6.99, y: 1.53, z: 3.6 },
            rotation: { x: 0, y: Math.PI / 2, z: 0 },
            maxWidth: 0.3,
            paragraphs: [
                {
                    text: 'I Nordmarka sitter to ukjente kvinner, arm i arm. Niels Juel hadde mange venner i Kristiania som han besøkte ofte.',
                    fontSize: 0.03,
                },
            ],
        },
        {
            position: { x: -6.99, y: 1.5, z: 1.05 },
            rotation: { x: 0, y: Math.PI / 2, z: 0 },
            maxWidth: 0.3,
            paragraphs: [
                {
                    text: 'En eldre Niels Juel har tatt med seg Leif Fagernes som sitter på en kjelke, og Harald Bjørnstad stående i midten. Rundt Viken gård var det mange steder som passet godt for kjelketurer.',
                    fontSize: 0.03,
                },
            ],
        },
        {
            position: { x: -6.99, y: 1.43, z: -1.35 },
            rotation: { x: 0, y: Math.PI / 2, z: 0 },
            maxWidth: 0.3,
            paragraphs: [
                {
                    text: 'Det var ofte sommergjester på Viken gård, som Niels Juel tok imot og underholdt. Gjestene var ofte rike fruer fra hovedstaden som ville oppleve sommeren et landlig sted. Disse ukjente kvinnene med Niels stående mellom seg er trolig slike sommergjester.',
                    fontSize: 0.03,
                },
            ],
        },
        //Mennesker
        {
            position: { x: -13.85, y: 4, z: -6.005 },
            rotation: { x: 0, y: Math.PI, z: 0 },
            maxWidth: 10,
            paragraphs: [
                {
                    text: 'Personlige portretter',
                    fontSize: 0.6,
                    textAlign: 'center',
                },
            ],
        },
        {
            position: { x: -13.15, y: 3.2, z: -6.005 },
            rotation: { x: 0, y: Math.PI, z: 0 },
            maxWidth: 4,
            paragraphs: [
                {
                    text: 'Niels Juel fotograferte nære venner og familie. Men han tok også bilder av alle som jobbet på gården – og de som besøkte gården om sommeren. I tillegg finner vi mange bilder av arbeidsdyr og kjæledyr blant bildene han tok.',
                    fontSize: 0.15,
                },
            ],
        },
        {
            position: { x: -12.85, y: 1.55, z: -6.005 },
            rotation: { x: 0, y: Math.PI, z: 0 },
            maxWidth: 0.3,
            paragraphs: [
                {
                    text: 'Posten skal alltid frem. Dette sørget Postmester Laurits Kristiansen for i sin tid. Her poserer han foran posthuset på Bertilstua Knai i Hurdal.',
                    fontSize: 0.03,
                },
            ],
        },
        {
            position: { x: -15.15, y: 1.47, z: -6.005 },
            rotation: { x: 0, y: Math.PI, z: 0 },
            maxWidth: 0.3,
            paragraphs: [
                {
                    text: 'Foran Bertilstua Knai sitter en Postmester Kristian Nielsen. Han var postmester helt frem til sin død i 1912. Ikke nok med at han passet på at posten kom trygt frem – han var også gårdbruker og drev gården sin i tillegg. Det er mye jobb i en gård, så å ha begge disse jobbene var godt gjort!',
                    fontSize: 0.03,
                },
            ],
        },
        {
            position: { x: -17.475, y: 1.45, z: -6.005 },
            rotation: { x: 0, y: Math.PI, z: 0 },
            maxWidth: 0.35,
            paragraphs: [
                {
                    text: 'Dette er et ganske spesielt gruppebilde. Vinkelen er ganske unik, for disse damene er fotografert fra siden. Det ser dessuten ikke ut til alle er helt klare for å bli knipset bilde av. I tillegg kan det se ut som om selve kameraet er plassert på bordet foran dem, og ikke fra et stativ eller hender. Det er stor sannsynlighet for at disse er sommergjester fra Oslo.',
                    fontSize: 0.03,
                },
            ],
        },
        {
            position: { x: -19.75, y: 1.58, z: -6.005 },
            rotation: { x: 0, y: Math.PI, z: 0 },
            maxWidth: 0.3,
            paragraphs: [
                {
                    text: 'Vi vet dessverre ikke hvem disse to er, men det er alltid stas å bli fotografert. Damens milde smil hinter til at hun mener det samme.',
                    fontSize: 0.03,
                },
            ],
        },
        {
            position: { x: -23.05, y: 1.45, z: -6.005 },
            rotation: { x: 0, y: Math.PI, z: 0 },
            maxWidth: 0.3,
            paragraphs: [
                {
                    text: 'Dette er Petra Juel – kvinnen i huset på Viken gård. Det var hennes idé å ta inn så mange sommergjester for å tjene ekstra penger. Hun var også veldig glad i katter, og alle gårdskattene som bodde på gården var nok hennes fortjeneste.',
                    fontSize: 0.03,
                },
            ],
        },
        {
            position: { x: -25.35, y: 1.48, z: -6.005 },
            rotation: { x: 0, y: Math.PI, z: 0 },
            maxWidth: 0.3,
            paragraphs: [
                {
                    text: 'Dette er et bilde av Niels Juel og tre barn som ofte går igjen på bildene han tok, så disse er nok barna til venner av Niels. Alle fire holder i brusflasker, kanskje de skal feire noe?',
                    fontSize: 0.03,
                },
            ],
        },
        {
            position: { x: -27.65, y: 1.47, z: -6.005 },
            rotation: { x: 0, y: Math.PI, z: 0 },
            maxWidth: 0.3,
            paragraphs: [
                {
                    text: 'En ukjent jente poserer pent ved et hvitt gjerde på Viken Gård. Hvem hun er, vet vi dessverre ikke, men det finnes flere bilder av henne i samlingen til Niels Juel, så trolig var hun en venn av familien.',
                    fontSize: 0.03,
                },
            ],
        },
        {
            position: { x: -29.975, y: 1.45, z: -6.005 },
            rotation: { x: 0, y: Math.PI, z: 0 },
            maxWidth: 0.35,
            paragraphs: [
                {
                    text: 'To ukjente kvinner sitter i entreen til et storslått hus. En av kvinnene sitter med en avis på fanget – Aftenposten. Avisen ble grunnlagt i 1860 av Christian Schibsted. Lite visste disse avislesende kvinnene at Aftenposten fortsatt skulle være en stor avis i Norge nå, 100 år senere.',
                    fontSize: 0.03,
                },
            ],
        },
        {
            position: { x: -32.25, y: 1.5, z: -6.005 },
            rotation: { x: 0, y: Math.PI, z: 0 },
            maxWidth: 0.3,
            paragraphs: [
                {
                    text: 'Et ungt søskenpar nyter solskinnsdagen ute på gården. Hvem de er vet vi ikke sikkert, men stilfulle er de i hvert fall!',
                    fontSize: 0.03,
                },
            ],
        },
        //Gårdsliv
        {
            position: { x: -28.3, y: 4, z: -19.98 },
            rotation: { x: 0, y: 0, z: 0 },
            maxWidth: 10,
            paragraphs: [
                {
                    text: 'Arbeid og hverdag',
                    fontSize: 0.6,
                    textAlign: 'center',
                },
            ],
        },
        {
            position: { x: -28.2, y: 3.15, z: -19.98 },
            rotation: { x: 0, y: 0, z: 0 },
            maxWidth: 5,
            paragraphs: [
                {
                    text: 'Norske gårder og små landbruk på begynnelsen av 1900-tallet var levende steder hvor det samme familietreet kunne ha bodd i flere generasjoner. Petra Juel, Niels Juel sin mor, arvet Viken gården etter hennes far, Bestefar Larsen. Her fikk Niels tilhøre hele livet, sammen med hans far, mor, tanter, og senere kone og barn. I tillegg til kjernefamilien bodde det både tjenestepiker, arbeidere, og til tider forpaktere på gården.',
                    fontSize: 0.15,
                },
            ],
        },
        {
            position: { x: -28.975, y: 1.55, z: -19.98 },
            rotation: { x: 0, y: 0, z: 0 },
            maxWidth: 0.35,
            paragraphs: [
                {
                    text: 'Martin Nordby, Niels Juel, og Karl Halvorsen tar en kort pause fra årets skuronn. Sanking, tørking, og lagring av korn er hovedfokuset. Bak de tre herrene er kornet plassert på staurer, og her skal det tørke før det blir tatt inn i en låve for lagring. Dette er tungt, men viktig arbeid!',
                    fontSize: 0.03,
                },
            ],
        },
        {
            position: { x: -26.7, y: 1.58, z: -19.98 },
            rotation: { x: 0, y: 0, z: 0 },
            maxWidth: 0.3,
            paragraphs: [
                {
                    text: 'Både menn, kvinner, og hester måtte trå til for å høste alle potetene som trengtes for å brødfø alle på Viken Gård. På bildet kan vi se både potehakker, potevogner, og høygafler.',
                    fontSize: 0.03,
                },
            ],
        },
        {
            position: { x: -24.4, y: 1.55, z: -19.98 },
            rotation: { x: 0, y: 0, z: 0 },
            maxWidth: 0.3,
            paragraphs: [
                {
                    text: 'Også arbeidsdyra på gården trenger mat. Derfor gjør Karl Halvorsen, Niels Juel og Martin Nordby en viktig jobb når de slår høy. Høyet skal lagres i en silo eller en låve, slik at dyrene på gården kan spise godt de også.',
                    fontSize: 0.03,
                },
            ],
        },
        {
            position: { x: -20.925, y: 1.45, z: -19.98 },
            rotation: { x: 0, y: 0, z: 0 },
            maxWidth: 0.35,
            paragraphs: [
                {
                    text: 'Hesten på bildet er en kaldblods dølahest. Dølahestene kommer fra Gudbrandsdalen og er en norsk rase som ble brukt til mye. De kalles kaldblodshester fordi har evnen til å holde seg rolig i ulike situasjoner, men også fordi de er utholdende i et kaldt klima. Mange av de norske hesterasene er kaldblodshester nettopp på grunn av dette.',
                    fontSize: 0.03,
                },
            ],
        },
        {
            position: { x: -18.575, y: 1.45, z: -19.98 },
            rotation: { x: 0, y: 0, z: 0 },
            maxWidth: 0.35,
            paragraphs: [
                {
                    text: 'Potethøsting på Viken Gård krevde mange mennesker i arbeid. Her ser du åtte mennesker og to hester i sving. Hestene ble brukt til å kjøre opp jorden med riktige redskaper, slik at arbeiderne kunne plukke opp potetene fra jorda. Så la de potetene i tønner og bøtter og fraktet dem til nærmeste potetkjeller. De bulkete bøttene hinter om at de har blitt brukt mye.',
                    fontSize: 0.03,
                },
            ],
        },
        {
            position: { x: -16.3, y: 1.55, z: -19.98 },
            rotation: { x: 0, y: 0, z: 0 },
            maxWidth: 0.3,
            paragraphs: [
                {
                    text: 'Høyet ble hengt på staur for å tørke før det ble tatt inn i låven og lagret gjennom vinteren. Det var viktig å utnytte enhver solskinnsdag i gårdsarbeidet.',
                    fontSize: 0.03,
                },
            ],
        },
        {
            position: { x: -14, y: 1.55, z: -19.98 },
            rotation: { x: 0, y: 0, z: 0 },
            maxWidth: 0.3,
            paragraphs: [
                {
                    text: 'Ikke alle som bodde på bygda drev gård. Det ser vi tydelig i det sot-dekte fjeset og hendene til denne feieren. I hendene holder han en pipebørste.',
                    fontSize: 0.03,
                },
            ],
        },
        {
            position: { x: -11.7, y: 1.55, z: -19.98 },
            rotation: { x: 0, y: 0, z: 0 },
            maxWidth: 0.3,
            paragraphs: [
                {
                    text: 'På en stor stein sitter Niels Juel, og ved siden av ham står Nils Strand med en slegge i hendene. Han har kanskje brukt slegga til å knuse steinen vi ser i bakgrunnen.',
                    fontSize: 0.03,
                },
            ],
        },
        {
            position: { x: -9.4, y: 1.58, z: -19.98 },
            rotation: { x: 0, y: 0, z: 0 },
            maxWidth: 0.3,
            paragraphs: [
                {
                    text: 'Niels Juel og en av arbeiderne på gården poserer med redskapene sine. Mannen ved siden av Juel holder en rake.',
                    fontSize: 0.03,
                },
            ],
        },
        //Hobbyfotografens utstyr
        {
            position: { x: -0.13, y: 2, z: -10.17 },
            rotation: { x: 0, y: -0.7766, z: 0 },
            maxWidth: 2.45,
            paragraphs: [
                {
                    text: 'Hobbyfotografens utstyr',
                    fontSize: 0.3,
                },
                {
                    text: 'Det var dette kameraet Niels Juel tok bilder med. Kameraet heter Svenska Express og ble laget i et samarbeid mellom produsentene Hasselblad og Svensson.',
                    fontSize: 0.1,
                    margin: {
                        top: 0.3,
                    },
                },
                {
                    text: 'Det er på størrelse med en brødrister og ganske lett å bære. Niels Juel hadde kameraet med seg på reiser både i Norge og i utlandet. Men hvordan ble bildene til?',
                    fontSize: 0.1,
                    margin: {
                        top: 0.15,
                    },
                },
                {
                    text: 'Kom inn og ta en kikk bak kameraet!',
                    fontSize: 0.1,
                    margin: {
                        top: 0.15,
                    },
                },
            ],
        },
        {
            position: { x: 2.15, y: 2, z: -9.25 },
            rotation: { x: 0, y: Math.PI, z: 0 },
            maxWidth: 1,
            paragraphs: [
                {
                    text: 'I disse eskene lå glassplatene som ble brukt til å ta bilder med. Disse ble satt inn i kameraet, og i fotograferingen, festet motivet seg på en slik glassplate.',
                    fontSize: 0.1,
                },
            ],
        },
        {
            position: { x: 0.9, y: 0.8, z: -19.988 },
            rotation: { x: 0, y: 0, z: 0 },
            maxWidth: 0.3,
            paragraphs: [
                {
                    text: 'Niels Juels kamera, forfra. Hvor mange kan ha sett inn i denne linsen?',
                    fontSize: 0.03,
                },
            ],
        },
        {
            position: { x: 5.25, y: 0.8, z: -19.988 },
            rotation: { x: 0, y: 0, z: 0 },
            maxWidth: 0.3,
            paragraphs: [
                {
                    text: 'Inni er kameraet ganske tomt, men skulle fylles med glassplater – inntil 12 stykker.',
                    fontSize: 0.03,
                },
            ],
        },
        {
            position: { x: 6.988, y: 1.38, z: -15.8 },
            rotation: { x: 0, y: Math.PI / -2, z: 0 },
            maxWidth: 0.3,
            paragraphs: [
                {
                    text: 'Eske med glassplater til kameraet. I kameraet festet motivet seg til platen. Så fremkalte man motivet i kontaktprint-rammen.',
                    fontSize: 0.03,
                },
            ],
        },
        {
            position: { x: 6.988, y: 1.3, z: -12.745 },
            rotation: { x: 0, y: Math.PI / -2, z: 0 },
            maxWidth: 0.35,
            paragraphs: [
                {
                    text: 'Dette er en kontaktprint-ramme. Den ble brukt til å fremkalle bilder med. Glassplatenegativer fra kameraet ble lagt inn i rammen, presset mot et fotopapir. Når rammen ble holdt opp mot lyset, ble motivet kopiert til papiret – og bilde ble skapt!',
                    fontSize: 0.03,
                },
            ],
        },
        {
            position: { x: 6.988, y: 1.39, z: -9.87 },
            rotation: { x: 0, y: Math.PI / -2, z: 0 },
            maxWidth: 0.3,
            paragraphs: [
                {
                    text: 'Rullen ble antakelig brukt til å behandle bildet, eller for å gi bildet mer glans til fotopapiret.',
                    fontSize: 0.03,
                },
            ],
        },
    ],
    videos: [
        {
            dmsId: '013AkPV1tL1F',
            width: 7.5,
            height: 4,
            position: { x: -1.28, y: 2, z: -11.66 },
            rotation: { x: 0, y: 2.365, z: 0 },
            autoPlay: true,
        },
    ],
    audios: [
        {
            dmsId: '019EBtFo2gP1t',
            positioning: true,
            position: { x: -3.6, y: 1.9, z: -19.979 },
            rotation: { x: 0, y: 0, z: 0 },
            volume: 1,
            maxDistance: 15,
            scale: 1.75,
            interactive: true,
            loop: false,
            boundToGrid: true,
            canvasIndex: 9,
        },
        {
            dmsId: '019EBtFo2gP1v',
            positioning: true,
            position: { x: -6.994, y: 4, z: 18.6 },
            rotation: { x: 0, y: 1.5707963267948966, z: 0 },
            volume: 1,
            maxDistance: 20,
            scale: 1.75,
            interactive: true,
            loop: false,
            boundToGrid: true,
            canvasIndex: 5,
        },
        {
            dmsId: '019EBtFo2gP1w',
            positioning: true,
            position: { x: -31.2, y: 4, z: -19.969 },
            rotation: { x: 0, y: 0, z: 0 },
            volume: 1,
            maxDistance: 20,
            scale: 1.75,
            interactive: true,
            loop: false,
            boundToGrid: true,
            canvasIndex: 6,
        },
    ],
    tours: [
        {
            position: { x: 0, y: 0, z: 10 },
            title: 'Livet på gården',
            introduction: 'Denne omvisningen viser deg bildene som ble tatt på gården i Hurdal og i nærområdet rundt gården.',
            groups: [
                {
                    index: 0,
                    position: { x: -0.124, y: 1.75, z: 14.456 },
                    rotation: { x: -0.942, y: 0.113, z: -0.314 },
                },
                {
                    index: 1,
                    position: { x: 0.847, y: 1.75, z: -1.682 },
                    rotation: { x: 0.897, y: 0.103, z: 0.429 },
                },
                {
                    index: 2,
                    position: { x: -12.456, y: 1.75, z: -11.536 },
                    rotation: { x: -0.509, y: 0.121, z: 0.852 },
                },
                {
                    index: 3,
                    position: { x: -26.558, y: 1.75, z: -12.361 },
                    rotation: { x: 0.314, y: 0.083, z: -0.946 },
                },
                {
                    index: 4,
                    position: { x: 2.411, y: 1.75, z: -16.232 },
                    rotation: { x: -0.665, y: 0.042, z: 0.746 },
                },
            ],
            canvasIndex: 15,
            boundToGrid: false,
        },
    ],
    groups: [
        { name: 'Fest og venner', color: '#1B8751' },
        { name: 'Nære og kjære', color: '#EB3151' },
        { name: 'Personlige portretter', color: '#EB7A28' },
        { name: 'Arbeid og hverdag', color: '#AF31EB' },
        { name: 'Hobbyfotografens utstyr', color: '#FF0000' },
    ],
}