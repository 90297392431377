import * as THREE from 'three';
import { useEffect, useMemo, useRef } from 'react';
import { useThree } from '@react-three/fiber';
import { animated } from 'react-spring';
import { OrbitControls } from '@react-three/drei';
import { usePointToPointTraversal } from './usePointToPointTraversal';
import { usePathfindingTraversal } from './usePathfindingTraversal';
import { usePlayerControlInputs } from './usePlayerControlInputs';
import { exitPointerLock, getDirectionOfViewTarget } from '../../helpers';
import { TourMode } from '../../helpers/enums';
import { useThreeDProps, useThreeDState } from '../App/ThreeDContext';
import { store } from '../store';

const AnimatedOrbitControls = animated(OrbitControls);

export const OrbitMode = ({
  dialogOpen,
}) => {
  const { initialCameraPosition, initialCameraDirection } = useThreeDProps();
  const { cameraPlacementMode, userInteracted, rotationEnabled, invertedMouseButtons, selectedTour } = useThreeDState();
  const { camera, scene, gl } = useThree();
  const cameraPosition = store(state => state.cameraPosition);
  const cameraDirection = store(state => state.cameraDirection);
  const control = useRef();
  const pointToPointTraversal = usePointToPointTraversal();
  const pathfindingTraversal = usePathfindingTraversal();
  const playerControlInputs = usePlayerControlInputs({
    camera: camera,
    scene: scene,
    controls: control.current,
    disabled:
      pointToPointTraversal.isTraversing() ||
      pathfindingTraversal.isTraversing() ||
      !!selectedTour ||
      !userInteracted ||
      dialogOpen,
  });

  useEffect(() => {
    if (!selectedTour) {
      pointToPointTraversal.start(cameraPosition ?? initialCameraPosition, control.current);
    }
  }, [cameraPosition, initialCameraPosition, selectedTour, pointToPointTraversal]);

  useEffect(() => {
    if (selectedTour && selectedTour.mode === TourMode.ACTIVE) {
      const group = selectedTour.tour.groups[selectedTour.selectedIndex];
      pathfindingTraversal.start(
        group.position,
        group.rotation,
        control.current
      );
    } else if (!selectedTour && pathfindingTraversal.isTraversing()) {
      pathfindingTraversal.stop();
    }
  }, [selectedTour]);

  useEffect(() => {
    if (
      cameraPlacementMode &&
      cameraPlacementMode.position &&
      cameraPlacementMode.rotation
    ) {
      pathfindingTraversal.start(
        cameraPlacementMode.position,
        cameraPlacementMode.rotation,
        control.current,
        750
      );
    } else if (!cameraPlacementMode && pathfindingTraversal.isTraversing()) {
      pathfindingTraversal.stop();
    }
  }, [cameraPlacementMode]);

  useEffect(() => {
    exitPointerLock();
    const target = getDirectionOfViewTarget(camera.position, cameraDirection ?? initialCameraDirection);
    control.current.target.copy(target);
  }, [camera.position, cameraDirection, initialCameraDirection]);

  const getScaledRotateSpeed = aspect => {
    const aspectRangeMin = 0.5;
    const aspectRangeMax = 2.5;
    const rotationRangeMax = -0.3;
    const rotationRangeMin = -0.6;
    const rotationRange = rotationRangeMax - rotationRangeMin;
    const aspectRange = aspectRangeMax - aspectRangeMin;
    return ((aspect - aspectRangeMin) * rotationRange) / aspectRange + rotationRangeMin;
  };

  const rotateSpeed = useMemo(() => getScaledRotateSpeed(camera.aspect), [camera.aspect]);

  return (
    <>
      <AnimatedOrbitControls
        ref={control}
        args={[camera, gl.domElement]}
        enableZoom={false}
        enablePan={false}
        enableDamping={true}
        enableRotate={rotationEnabled}
        dampingFactor={0.075}
        rotateSpeed={rotateSpeed}
        mouseButtons={{
          LEFT: invertedMouseButtons ? THREE.MOUSE.PAN : THREE.MOUSE.ROTATE,
          MIDDLE: THREE.MOUSE.DOLLY,
          RIGHT: invertedMouseButtons ? THREE.MOUSE.ROTATE : THREE.MOUSE.PAN,
        }}
      />
      {playerControlInputs}
    </>
  );
};
