import { Accordion, AccordionSummary, Divider } from '@mui/material';
import { ExpandMore } from '@mui/icons-material';
import { useState } from 'react';
import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles({
  container: {
    width: 275,
    maxHeight: 'calc(100% - 20px)',
    position: 'absolute',
    top: 10,
    right: 10,
    background: '#FAFAFA',
    zIndex: 1,
    overflowY: 'auto',
    cursor: 'default',
    boxSizing: 'border-box',
    borderRadius: 8,
    boxShadow: `0px 3px 5px -1px rgba(0, 0, 0, 0.2),
                0px 5px 8px rgba(0, 0, 0, 0.14),
                0px 1px 14px rgba(0, 0, 0, 0.12)`,
    '& .MuiAccordion-root, .MuiPaper-root': {
      borderRadius: '0px !important',
      boxShadow: 'none',
    },
    '& input': {
      height: 28,
      padding: '0 5px 0 10px',
      margin: 0,
      fontSize: 12,
      '&.MuiInputBase-inputAdornedStart': {
        paddingLeft: 3,
      },
    },
    '& textarea': {
      padding: '10px 12px',
      lineHeight: '18px',
      fontSize: 12,
      height: '72px !important',
    },
    '& p': {
      fontSize: 12,
      color: 'rgba(0, 0, 0, 0.6)',
      '&.subtitle': {
        fontWeight: 700,
      },
    },
    '& .MuiTypography-root': {
      fontSize: 12,
      lineHeight: '16px',
    },
    '& .MuiAccordionDetails-root': {
      display: 'flex',
      flexDirection: 'column',
      gap: 12,
      padding: '8px 12px',
      '&.compact': {
        gap: 0,
      },
      '& >.MuiBox-root:not(:first-child)': {
        paddingTop: 4,
      },
      '& >.MuiBox-root:not(:last-child)': {
        paddingBottom: 4,
      },
    },
    '& .MuiTextField-root': {
      flexGrow: 1,
      '& .MuiInputLabel-root': {
        fontSize: 12,
        WebkitTransform: 'translate(12px, 6px) scale(1)',
        MozTransform: 'translate(12px, 6px) scale(1)',
        msTransform: 'translate(12px, 6px) scale(1)',
        transform: 'translate(12px, 6px) scale(1)',
        '&.Mui-focused, &.MuiFormLabel-filled': {
          color: 'rgba(0, 0, 0, 0.6)',
          WebkitTransform: 'translate(12px, -6px) scale(0.8)',
          MozTransform: 'translate(12px, -6px) scale(0.8)',
          msTransform: 'translate(12px, -6px) scale(0.8)',
          transform: 'translate(12px, -6px) scale(0.8)',
        },
      },
    },
    '& .MuiInputBase-root': {
      padding: 0,
      fontSize: 12,
      lineHeight: '16px',
      '&.Mui-focused': {
        '& .MuiOutlinedInput-notchedOutline': {
          borderColor: 'rgba(0, 0, 0, 0.87)',
        },
      },
    },
    '& .MuiSlider-root': {
      '& .MuiSlider-rail': {
        color: 'rgba(0, 0, 0, 0.4)',
      },
      '& .MuiSlider-track': {
        color: 'rgba(0, 0, 0, 0.4)',
      },
      '& .MuiSlider-thumb': {
        color: '#666666',
        '&:focus, &:hover, &.Mui-focusVisible': {
          boxShadow: '0px 0px 0px 5px rgb(0 0 0 / 16%)',
        },
        '&.Mui-active': {
          boxShadow: '0px 0px 0px 8px rgb(0 0 0 / 16%)',
        },
      },
      '&.Mui-disabled': {
        '& .MuiSlider-thumb': {
          color: '#cccccc',
        },
        '& .MuiSlider-rail': {
          color: 'rgba(0, 0, 0, 0.2)',
        },
        '& .MuiSlider-track': {
          color: 'rgba(0, 0, 0, 0.2)',
        },
      },
    },
    '& .MuiSelect-select': {
      display: 'flex',
      alignItems: 'center',
      paddingTop: 0,
      paddingBottom: 0,
      margin: '6px 0',
      minHeight: 'auto !important',
      '& span': {
        lineHeight: '16px',
      },
    },
    '& .MuiCheckbox-root': {
      paddingTop: 5,
      paddingBottom: 5,
      paddingRight: 5,
      '&.Mui-checked': {
        color: 'rgba(0, 0, 0, 0.6)',
      },
    },
    '& .MuiInputBase-adornedStart': {
      '& .MuiInputAdornment-root': {
        margin: '0 8px 0 8px',
      },
    },
    '& .MuiInputBase-adornedEnd': {
      '& .MuiInputAdornment-root': {
        margin: '0 8px 0 2px',
      },
    },
    '& .MuiSvgIcon-root': {
      width: 16,
      height: 16,
      top: 'unset',
    },
    '& .MuiBox-root': {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between',
      '& >:not(.colorPickerContainer):not(:first-child)': {
        marginLeft: 4,
        '&.MuiSlider-root': {
          marginLeft: 6,
        },
      },
      '& >:not(.colorPickerContainer):not(:last-child)': {
        marginRight: 4,
        '&.MuiSlider-root': {
          marginRight: 6,
        },
      },
    },
    '& .MuiButton-root': {
      fontSize: 13,
      color: 'rgba(0, 0, 0, 0.6)',
      borderColor: 'rgba(140, 140, 140, 0.5)',
      '&:hover': {
        background: 'rgba(63, 81, 181, 0.08)',
        borderColor: 'rgba(140, 140, 140, 0.5)',
      }
    },
    '& .colorPickerContainer': {
      position: 'absolute',
      top: 40,
      right: 0,
      zIndex: 1,
    },
    '& div.Mui-disabled': {
      backgroundColor: '#f2f2f2',
    },
    '& .MuiFormGroup-root': {
      '& .MuiRadio-root': {
        paddingTop: 3,
        paddingBottom: 3,
      },
    },
    '& div > .slider:not(:last-child)': {
      paddingBottom: 8,
    },
  },
  title: {
    fontSize: 14,
    fontWeight: 'bold !important',
    color: 'rgba(0, 0, 0, 0.87)',
    '&.MuiAccordionSummary-root': {
      padding: '0 10px',
      minHeight: 40,
    },
    '&.MuiAccordionSummary-root.Mui-expanded': {
      minHeight: 40,
    },
    '& .MuiAccordionSummary-content.Mui-expanded': {
      margin: 0,
    },
  },
});

export const DialogContainer = ({
  title,
  children,
}) => {
  const styles = useStyles();
  const [expanded, setExpanded] = useState(true);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  return (
    <div className={styles.container}>
      <Accordion expanded={expanded}>
        <AccordionSummary
          expandIcon={<ExpandMore />}
          onClick={handleExpandClick}
          className={styles.title}
        >
          {title}
        </AccordionSummary>
        <Divider light />
        {children}
      </Accordion>
    </div>
  );
};
