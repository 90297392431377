import * as THREE from 'three';
import { useRef } from 'react';
import { useThree, useFrame } from '@react-three/fiber';
import { NavigationIcon } from '../../components/NavigationIcon';
import { CursorTypes, ControlModes, CanvasItemTypes } from '../../helpers/enums';
import { RoomModel } from './RoomModel';
import { OutdoorRoom } from '../OutdoorRoom/OutdoorRoom';
import { Images } from '../Images/Images';
import { Models } from '../Models/Models';
import { Videos } from '../Video/Videos';
import { Audios } from '../Audio/Audios';
import { ImageSpheres } from '../ImageSpheres/ImageSpheres';
import { TextItems } from '../Texts/TextItems';
import { Quizzes } from '../Quizzes/Quizzes';
import { Tours } from '../Tours/Tours';
import { isNavigableSurface, isShadow, isHidden, setCursor, addPlayerHeightOffset,
  getSceneElementByName, isMobileDevice, isNonInteractable, isCanvasItem, isNavMesh } from '../../helpers';
import { useGesture } from '@use-gesture/react';
import { usePulseAnimation } from '../../components/usePulseAnimation';
import { SET_CANVAS_ADDITION_ITEM_TYPE, SET_CANVAS_SELECTION, SET_INVERTED_MOUSE_BUTTONS,
  SET_OUTLINE_CANVAS, useThreeDDispatch, useThreeDProps, useThreeDState } from '../App/ThreeDContext';
import { store } from '../store';

export const InteractableObjects = () => {
  const { data, adminMode } = useThreeDProps();
  const { roomModelDmsId, roomUrl, room } = data;
  const { interactiveMode, selectedTour, controlMode, canvasSelection, outlineCanvas, canvasAdditionItemType, invertedMouseButtons } = useThreeDState();
  const dispatch = useThreeDDispatch();
  const { scene: mainScene, camera, mouse } = useThree();
  const setCameraPosition = store(state => state.setCameraPosition);
  const navigationIcon = useRef();
  const outlineItem = useRef();
  const isPointerOver = useRef(true);
  const isDragging = useRef(false);
  const isMobile = isMobileDevice();
  const raycaster = new THREE.Raycaster();
  const navMesh = getSceneElementByName(mainScene.children, 'NavMesh');
  const pulseAnimation = usePulseAnimation({ scene: mainScene });
  const setOutlineItem = store(state => state.setOutlineItem);

  const changeOutlineItem = (modelName) => {
    setOutlineItem(modelName);
    outlineItem.current = modelName;
  };

  const isValidNavigation = (intersection) => {
    return isNavMesh(intersection.object) || (!navMesh && isNavigableSurface(intersection.object));
  };

  const getIntersection = (intersections) => {
    let intersection = null;

    if (intersections.length > 0) {
      intersections = intersections.filter(obj =>
        !isShadow(obj.object) &&
        !isNonInteractable(obj.object) &&
        (!isHidden(obj.object) || isNavMesh(obj.object))
      );

      if (intersections.length > 0) {
        intersection = intersections.reduce((acc, obj) => (
          isNavMesh(obj.object) && !acc.object.name.startsWith('Canvas') && (!acc.object.userData?.itemType) && Math.abs(obj.distance - acc.distance) < 0.1
            ? obj
            : (obj.distance < acc.distance
              ? obj
              : acc
            )
        ));
      }
    };

    return intersection;
  };

  const updateNavigationIcon = (intersection) => {
    if (navigationIcon?.current) {
      navigationIcon.current.visible = false;

      if (!isDragging.current && !selectedTour) {
        if (isValidNavigation(intersection)) {
          const scale = camera.position.distanceTo(intersection.point) * 0.25;
          navigationIcon.current.position.set(
            intersection.point.x,
            intersection.point.y + 0.001,
            intersection.point.z
          );
          navigationIcon.current.scale.set(scale, scale, scale);
          navigationIcon.current.visible = true;
        }
      }
    }
  };

  const updateCursor = (intersection) => {
    const itemType = intersection.object.userData?.itemType;
    const interactable = intersection.object.userData?.interactable ?? false;

    if (!intersection.object.userData.isResize) {
      let cursor = null;

      if (isDragging.current) {
        cursor = CursorTypes.GRABBING;
      }
      else if (isValidNavigation(intersection) && !selectedTour) {
        cursor = CursorTypes.SHOE_PRINT;
      }
      else {
        switch (itemType) {
          case CanvasItemTypes.MODEL3D:
          case CanvasItemTypes.IMAGE:
          case CanvasItemTypes.IMAGE_SPHERE:
          case CanvasItemTypes.TOUR:
            cursor = adminMode
              ? CursorTypes.POINTER
              : (interactable && interactiveMode
                ? CursorTypes.EYE
                : CursorTypes.GRAB);
            break;
          case CanvasItemTypes.QUIZ:
            cursor = interactiveMode
              ? CursorTypes.POINTER
              : CursorTypes.GRAB;
            break;
          case CanvasItemTypes.VIDEO:
          case CanvasItemTypes.TEXT:
            adminMode && (cursor = CursorTypes.POINTER);
            !interactiveMode && (cursor = CursorTypes.GRAB);
            break;
          default:
            cursor = CursorTypes.GRAB;
            break;
        }
      }

      cursor && setCursor(cursor);
    }
  };

  const updateOutlineItem = (intersection) => {
    const itemType = intersection.object.userData?.itemType;
    const interactable = intersection.object.userData?.interactable ?? false;
    const itemTypes = [CanvasItemTypes.MODEL3D, CanvasItemTypes.IMAGE, CanvasItemTypes.IMAGE_SPHERE, CanvasItemTypes.QUIZ, CanvasItemTypes.TOUR];

    if (!isDragging.current) {
      if (interactable && itemTypes.includes(itemType)) {
        let name = intersection.object.userData?.itemName;

        if (outlineItem.current !== name) {
          changeOutlineItem(name);
        }
      } else {
        outlineItem.current && changeOutlineItem(null);
      }
    }
  };

  useFrame(() => {
    if (isMobile) return;

    if (isPointerOver.current) {
      raycaster.setFromCamera(mouse, camera);
      const intersections = raycaster.intersectObjects(mainScene.children, true);
      const intersection = getIntersection(intersections);

      if (intersection) {
        updateNavigationIcon(intersection);
        updateCursor(intersection);
        !adminMode && interactiveMode && updateOutlineItem(intersection);
      }
    }
    else {
      navigationIcon.current && (navigationIcon.current.visible = false);
      outlineItem.current && changeOutlineItem(null);
    }
  });

  const handleClick = ({ event, tap, dragging }) => {
    if (!isCanvasItem(event)) return null;

    if (tap && controlMode === ControlModes.ORBIT_CONTROL) {
      const intersection = getIntersection(event.intersections);

      if (intersection && isValidNavigation(intersection) && !selectedTour) {
        pulseAnimation.add(intersection.point.clone().setY(intersection.point.y + 0.001));
        setCameraPosition(addPlayerHeightOffset(intersection.point));
      }

      if (intersection && !intersection.object.name?.startsWith('Canvas')) {
        canvasSelection !== null && dispatch({ type: SET_CANVAS_SELECTION, canvasSelection: null });
        outlineCanvas !== null && dispatch({ type: SET_OUTLINE_CANVAS, outlineCanvas: null });
        canvasAdditionItemType !== null && dispatch({ type: SET_CANVAS_ADDITION_ITEM_TYPE, canvasAdditionItemType: null });
        invertedMouseButtons && dispatch({ type: SET_INVERTED_MOUSE_BUTTONS, invertedMouseButtons: false });
      }
    }

    isDragging.current = dragging;
    requestAnimationFrame(() => isPointerOver.current = true);
  };

  const handleHover = ({ first, last }) => {
    first && (isPointerOver.current = true);
    last && (isPointerOver.current = false);
  };

  const useGestures = () => {
    return useGesture(
      {
        onDrag: handleClick,
        onHover: handleHover,
      },
      { drag: { filterTaps: true }},
    )();
  };

  return (
    <>
      <group {...useGestures()}>
        {(roomUrl || roomModelDmsId) && <RoomModel />}
        {room && <OutdoorRoom />}
        <Models />
        <Images />
        <Videos />
        <Audios />
        <ImageSpheres />
        <Quizzes />
        <TextItems />
        <Tours />
      </group>
      {controlMode === ControlModes.ORBIT_CONTROL &&
        <group ref={navigationIcon} visible={false}>
          <NavigationIcon />
        </group>
      }
    </>
  );
};
