import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles({
  textfield: {
    display: 'flex',
    flexDirection: 'column',
    '& > label': {
      fontSize: 12,
      marginBottom: 5,
    },
    '& > input, textarea': {
      fontSize: 14,
      background: 'rgba(12, 11, 15, 0.05)',
      border: '2px solid #0C0B0F',
      borderRadius: 5,
      boxShadow: 'inset -5px 5px 0px rgba(0, 0, 0, 0.05)',
      outline: 'none',
      padding: '12px 15px',
      resize: 'none',
      '&:focus::placeholder': {
        color: 'transparent',
      },
      '&::placeholder': {
        color: '#0C0B0F',
        opacity: 0.7,
      },
    }
  },
});

export const TextField = ({
  id,
  label,
  placeholder,
  autoComplete = false,
  multiline = false,
  rows = 1,
}) => {
  const styles = useStyles();

  return (
    <div className={styles.textfield}>
      {label && <label>{label}</label>}
      {!multiline &&
        <input
          id={id}
          type="text"
          placeholder={placeholder}
          autoComplete={autoComplete ? "on" : "off"}
        />
      }
      {multiline &&
        <textarea
          id={id}
          rows={rows}
          placeholder={placeholder}
          autoComplete={autoComplete ? "on" : "off"}
        />
      }
    </div>
  );
}
