import * as THREE from 'three';
import { useCallback, useEffect, useRef } from 'react';
import { useFrame, useThree } from '@react-three/fiber';
import { PointerLockControls, Cylinder } from '@react-three/drei';
import { detectGeometryCollision, getPositionAboveFloor } from '../../helpers';
import { SET_POINTER_LOCKED, useThreeDDispatch, useThreeDProps } from '../App/ThreeDContext';
import { store } from '../store';

export const PointerLockMode = () => {
  const { pointerLocked } = useThreeDProps();
  const dispatch = useThreeDDispatch();
  const { camera, scene, gl, raycaster } = useThree();
  const setCameraPosition = store(state => state.setCameraPosition);
  const setCameraDirection = store(state => state.setCameraDirection);
  const control = useRef();
  const player = useRef();
  const clock = new THREE.Clock();
  const velocity = new THREE.Vector3();
  const direction = new THREE.Vector3();
  let moveForward = false;
  let moveBackward = false;
  let moveLeft = false;
  let moveRight = false;
  let isRunning = false;

  const handlePointerLockChange = useCallback(() => {
    const pointerLockElement = document.pointerLockElement || document.mozPointerLockElement || document.webkitPointerLockElement;
    const direction = new THREE.Vector3();
    control.current.getDirection(direction);
    setCameraDirection(direction);
    setCameraPosition(camera.position);
    dispatch({ type: SET_POINTER_LOCKED, pointerLocked: !!pointerLockElement });
    !pointerLockElement && control.current.unlock() && control.current.disconnect();
  }, [camera]);

  const handleKeyDown = (event) => {
    updateInputControls(event.code, true);
  };

  const handleKeyUp = (event) => {
    updateInputControls(event.code, false);
  };

  const computeOffsets = (_, { size: { width, height } }) => {
    return ({
      offsetX: width / 2,
      offsetY: height / 2,
    });
  };

  useEffect(() => {
    document.addEventListener('pointerlockchange', handlePointerLockChange);
    document.addEventListener('mozpointerlockchange', handlePointerLockChange);
    document.addEventListener('webkitpointerlockchange', handlePointerLockChange);
    document.addEventListener('keydown', handleKeyDown);
    document.addEventListener('keyup', handleKeyUp);

    return () => {
      document.removeEventListener('pointerlockchange', handlePointerLockChange);
      document.removeEventListener('mozpointerlockchange', handlePointerLockChange);
      document.removeEventListener('webkitpointerlockchange', handlePointerLockChange);
      document.removeEventListener('keydown', handleKeyDown);
      document.removeEventListener('keyup', handleKeyUp);
    };
  });

  useEffect(() => {
    raycaster.computeOffsets = computeOffsets;
    const pointerLockElement = document.pointerLockElement || document.mozPointerLockElement || document.webkitPointerLockElement;
    !pointerLockElement && pointerLocked && control.current.lock();
  }, [handlePointerLockChange, pointerLocked, raycaster]);

  useFrame(() => {
    if (control.current) {
      const originalPosition = camera.position.clone();

      updatePlayer();

      if (detectGeometryCollision(scene, player.current)) {
        camera.position.copy(originalPosition);
        player.current.position.set(
          originalPosition.x,
          originalPosition.y - 0.4,
          originalPosition.z,
        );
      }
    }
  });

  const updatePlayer = () => {
    const speedFactor = (isRunning ? 100 : 50);
    const delta = clock.getDelta();

    velocity.x -= velocity.x * 10 * delta;
    velocity.z -= velocity.z * 10 * delta;

    direction.z = Number(moveForward) - Number(moveBackward);
    direction.x = Number(moveRight) - Number(moveLeft);
    direction.normalize();

    if ((moveForward || moveBackward) && control.current.isLocked) velocity.z -= direction.z * speedFactor * delta;
    if ((moveLeft || moveRight) && control.current.isLocked) velocity.x -= direction.x * speedFactor * delta;

    control.current.moveRight(- velocity.x * delta);
    control.current.moveForward(- velocity.z * delta);

    camera.position.copy(getPositionAboveFloor(camera, scene, camera.position) ?? camera.position);

    player.current.position.set(
      camera.position.x,
      camera.position.y - 0.4,
      camera.position.z,
    );
  };

  const updateInputControls = (keyCode, pressed) => {
    switch (keyCode) {
      case 'ArrowUp':
      case 'KeyW':
        moveForward = pressed;
        break;
      case 'ArrowLeft':
      case 'KeyA':
        moveLeft = pressed;
        break;
      case 'ArrowDown':
      case 'KeyS':
        moveBackward = pressed;
        break;
      case 'ArrowRight':
      case 'KeyD':
        moveRight = pressed;
        break;
      case 'ShiftLeft':
      case 'ShiftRight':
        isRunning = pressed;
        break;
      default:
        break;
    }
  };

  return (
    <>
      <PointerLockControls
        ref={control}
        args={[camera, gl.domElement]}
      />
      <Cylinder
        ref={player}
        args={[0.2, 0.2, 1.2, 5, 1]}
        visible={false}
      >
        <meshBasicMaterial attach="material" wireframe />
      </Cylinder>
    </>
  );
};
