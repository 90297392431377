import { DragBox } from './DragBox';
import { DragItem } from './DragItem';
import { ExcludedGroup } from './ExcludedGroup';

export const ExcludedGroups = ({
  groups,
  startIndex,
  onGroupDrag,
  onGroupDrop,
  onGroupReset,
  onGroupAdd,
}) => {
  return (
    <>
      <div>
        {groups.map((group, index) =>
          <DragItem
            key={group.id}
            id={group.id}
            index={index + startIndex}
            onGroupDrag={onGroupDrag}
            onGroupDrop={onGroupDrop}
            onGroupReset={onGroupReset}
          >
            <ExcludedGroup
              {...group}
              onGroupAdd={onGroupAdd}
            />
          </DragItem>
        )}
      </div>
      {groups.length === 0 &&
        <DragBox
          text={'Dra grupper hit for å fjerne fra omvisningen'}
          index={startIndex}
          onGroupDrag={onGroupDrag}
        />
      }
    </>
  );
};
