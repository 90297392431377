export const useCanvasItemEditor = ({
  canvas,
  canvasItem,
  canvasSize,
  cellSize,
}) => {
  const getScaleLimits = (position, scale, size) => {
    const topEdge = position.y + (size.height / 2);
    const bottomEdge = position.y - (size.height / 2);
    const rightEdge = position.x + (size.width / 2);
    const leftEdge = position.x - (size.width / 2);

    const topLimit = canvasSize.height / 2;
    const bottomLimit = canvasSize.height / -2;
    const rightLimit = canvasSize.width / 2;
    const leftLimit = canvasSize.width / -2;

    const topScaleMax = scale * (((topLimit - topEdge) * 2) + size.height) / size.height;
    const bottomScaleMax = scale * (((bottomEdge - bottomLimit) * 2) + size.height) / size.height;
    const rightScaleMax = scale * (((rightLimit - rightEdge) * 2) + size.width) / size.width;
    const leftScaleMax = scale * (((leftEdge - leftLimit) * 2) + size.width) / size.width;

    return {
      top: topScaleMax,
      bottom: bottomScaleMax,
      right: rightScaleMax,
      left: leftScaleMax,
    };
  };

  const getScaleLimit = (position, scale, size, direction) => {
    const scaleLimits = getScaleLimits(position, scale, size);

    switch (direction) {
      case 'topLeft':
        return Math.min(scaleLimits.top, scaleLimits.left);
      case 'topRight':
        return Math.min(scaleLimits.top, scaleLimits.right);
      case 'bottomLeft':
        return Math.min(scaleLimits.bottom, scaleLimits.left);
      case 'bottomRight':
        return Math.min(scaleLimits.bottom, scaleLimits.right);
      default:
        return Math.min(scaleLimits.top, scaleLimits.bottom, scaleLimits.right, scaleLimits.left);
    }
  };

  const applyPositionOffset2D = (point, positionOffset) => {
    if (positionOffset) {
      const localPosition = canvasItem.worldToLocal(point.clone());
      localPosition.setX(localPosition.x - positionOffset.x);
      localPosition.setY(localPosition.y - positionOffset.y);
      const adjustedPosition = canvasItem.localToWorld(localPosition);
      point.copy(adjustedPosition);
    }
  };

  const applyPositionOffset3D = (point, positionOffset) => {
    if (positionOffset) {
      const localPosition = canvasItem.worldToLocal(point.clone());
      localPosition.setX(localPosition.x - positionOffset.x);
      localPosition.setZ(localPosition.z - positionOffset.z);
      const adjustedPosition = canvasItem.localToWorld(localPosition);
      point.copy(adjustedPosition);
    }
  };

  const snapPositionToGrid = (point, size) => {
    const localPosition = canvas.worldToLocal(point.clone());

    const xPosition = Math.round((localPosition.x - (size.width / 2) + (canvasSize.width / 2) + cellSize) / cellSize);
    const yPosition = Math.round((localPosition.y - (size.height / 2) + (canvasSize.height / 2) + cellSize) / cellSize);
    const xOffset = (Math.ceil(parseFloat((size.width / cellSize).toFixed(12))) - 1) * cellSize / 2;
    const yOffset = (Math.ceil(parseFloat((size.height / cellSize).toFixed(12))) - 1) * cellSize / 2;

    localPosition.setX(((xPosition - 1) * cellSize) - ((size.width - cellSize) / 2) + xOffset - (canvasSize.width / 2) + (size.width / 2));
    localPosition.setY(((yPosition - 1) * cellSize) - ((size.height - cellSize) / 2) + yOffset - (canvasSize.height / 2) + (size.height / 2));

    const adjustedPosition = canvas.localToWorld(localPosition);
    point.copy(adjustedPosition);
  };

  const applyPositionLimits = (point, size) => {
    const localPosition = canvas.worldToLocal(point.clone());
    const height = size.height ? (size.height / 2) : size.radius;
    const width = size.width ? (size.width / 2) : size.radius;

    const topEdge = localPosition.y + height;
    const bottomEdge = localPosition.y - height;
    const rightEdge = localPosition.x + width;
    const leftEdge = localPosition.x - width;

    const topLimit = canvasSize.height / 2;
    const bottomLimit = canvasSize.height / -2;
    const rightLimit = canvasSize.width / 2;
    const leftLimit = canvasSize.width / -2;

    localPosition.setY(topEdge > topLimit ? topLimit - height : localPosition.y);
    localPosition.setY(bottomEdge < bottomLimit ? bottomLimit + height : localPosition.y);
    localPosition.setX(rightEdge > rightLimit ? rightLimit - width : localPosition.x);
    localPosition.setX(leftEdge < leftLimit ? leftLimit + width : localPosition.x);

    const adjustedPosition = canvas.localToWorld(localPosition);
    point.copy(adjustedPosition);
  };

  return {
    getScaleLimit,
    applyPositionOffset2D,
    applyPositionOffset3D,
    snapPositionToGrid,
    applyPositionLimits,
  };
};
