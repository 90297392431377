import { CanvasItemTypes } from '../../../helpers/enums';
import { DialogContainer } from './DialogContainer';
import { ImageSettings } from './ImageSettings';
import { TextSettings } from './TextSettings';
import { TourSettings } from './TourSettings';
import { VideoSettings } from './VideoSettings';
import { ModelSettings } from './ModelSettings';
import { ImageSphereSettings } from './ImageSphereSettings';
import { QuizSettings } from './QuizSettings';
import { AudioSettings } from './AudioSettings';
import { useThreeDProps } from '../../App/ThreeDContext';

export const CanvasItemEditorDialog = ({
  canvasIndex,
  itemType,
  itemIndex,
  onUpdate,
  onRemove,
  onGroupUpdate,
  getSceneElements,
}) => {
  const { data } = useThreeDProps();
  const { canvases, groups, images, texts, videos, models, imageSpheres, quizzes, tours, audios } = data;

  const getItemFromArray = (array) => {
    return array
      .filter(item => item.canvasIndex === canvasIndex)
      .find((item, index) => index === itemIndex);
  };

  const getSelectedItem = () => {
    const canvas = canvases[canvasIndex];
    const selectedItem = {
      canvasWidth: canvas.dimensions.columns * canvas.dimensions.cellSize,
      canvasHeight: canvas.dimensions.rows * canvas.dimensions.cellSize,
      canvasRotation: canvas.rotation,
      cellSize: parseFloat(canvas.dimensions.cellSize),
    };

    switch (itemType) {
      case CanvasItemTypes.IMAGE:
        return {
          ...selectedItem,
          imageItem: getItemFromArray(images),
          title: 'Bilde',
        };
      case CanvasItemTypes.TEXT:
        return {
          ...selectedItem,
          textItem: getItemFromArray(texts),
          title: 'Tekst',
        };
      case CanvasItemTypes.VIDEO:
        return {
          ...selectedItem,
          videoItem: getItemFromArray(videos),
          title: 'Video',
        };
      case CanvasItemTypes.MODEL3D:
        return {
          ...selectedItem,
          modelItem: getItemFromArray(models),
          title: '3D-modell',
        };
      case CanvasItemTypes.IMAGE_SPHERE:
        return {
          ...selectedItem,
          imageSphereItem: getItemFromArray(imageSpheres),
          title: 'Bildekule',
        };
      case CanvasItemTypes.QUIZ:
        return {
          ...selectedItem,
          quizItem: getItemFromArray(quizzes),
          title: 'Quiz',
        };
      case CanvasItemTypes.TOUR:
        return {
          ...selectedItem,
          tourItem: getItemFromArray(tours),
          title: 'Omvisning',
        };
      case CanvasItemTypes.AUDIO:
        return {
          ...selectedItem,
          audioItem: getItemFromArray(audios),
          title: 'Lydpunkt',
        };
      default:
        break;
    };
  };

  const selectedItem = getSelectedItem();

  return (
    <DialogContainer title={selectedItem.title}>
      {selectedItem && selectedItem.imageItem && itemType === CanvasItemTypes.IMAGE &&
        <ImageSettings
          {...selectedItem}
          index={itemIndex}
          groups={groups}
          onUpdate={onUpdate}
          onRemove={onRemove}
          onGroupUpdate={onGroupUpdate}
          getSceneElements={getSceneElements}
        />
      }
      {selectedItem && selectedItem.textItem && itemType === CanvasItemTypes.TEXT &&
        <TextSettings
          {...selectedItem}
          index={itemIndex}
          onUpdate={onUpdate}
          onRemove={onRemove}
          getSceneElements={getSceneElements}
        />
      }
      {selectedItem && selectedItem.videoItem && itemType === CanvasItemTypes.VIDEO &&
        <VideoSettings
          {...selectedItem}
          index={itemIndex}
          onUpdate={onUpdate}
          onRemove={onRemove}
          getSceneElements={getSceneElements}
        />
      }
      {selectedItem && selectedItem.modelItem && itemType === CanvasItemTypes.MODEL3D &&
        <ModelSettings
          {...selectedItem}
          index={itemIndex}
          groups={groups}
          onUpdate={onUpdate}
          onRemove={onRemove}
          onGroupUpdate={onGroupUpdate}
          getSceneElements={getSceneElements}
        />
      }
      {selectedItem && selectedItem.imageSphereItem && itemType === CanvasItemTypes.IMAGE_SPHERE &&
        <ImageSphereSettings
          {...selectedItem}
          index={itemIndex}
          onUpdate={onUpdate}
          onRemove={onRemove}
          getSceneElements={getSceneElements}
        />
      }
      {selectedItem && selectedItem.quizItem && itemType === CanvasItemTypes.QUIZ &&
        <QuizSettings
          {...selectedItem}
          index={itemIndex}
          onUpdate={onUpdate}
          onRemove={onRemove}
          getSceneElements={getSceneElements}
        />
      }
      {selectedItem && selectedItem.tourItem && itemType === CanvasItemTypes.TOUR &&
        <TourSettings
          {...selectedItem}
          index={itemIndex}
          groups={groups}
          onUpdate={onUpdate}
          onRemove={onRemove}
          getSceneElements={getSceneElements}
        />
      }
      {selectedItem && selectedItem.audioItem && itemType === CanvasItemTypes.AUDIO &&
        <AudioSettings
          {...selectedItem}
          index={itemIndex}
          onUpdate={onUpdate}
          onRemove={onRemove}
          getSceneElements={getSceneElements}
        />
      }
    </DialogContainer>
  );
};
