import { useRef, Suspense, useState, useEffect } from 'react';
import { Canvas, useFrame } from '@react-three/fiber';
import { getDmsFileUrl } from '@ekultur/dms-url-generator';
import { Color, Mesh, MeshStandardMaterial } from 'three';
import { useProgress } from '@react-three/drei';
import { ProgressBar } from '../components/ProgressBar';
import { createUseStyles } from 'react-jss';
import { useGLTF } from '../helpers';

const useStyles = createUseStyles({
  container: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    background: '#171717',
    zIndex: 1000,
    backgroundColor: '#171717',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    transition: 'opacity .5s linear',
    transitionDelay: '.5s',
    opacity: 1,
  },
  inner: {
    width: 320,
  },
  logo: {
    width: '50vw',
    height: '50vh',
  },
});

const Logo = ({ animation, ...rest }) => {
  const { scene } = useGLTF(getDmsFileUrl('0136NRPtLyTA', '01'));
  const ref = useRef();

  if (scene) {
    scene.traverse(child => {
      if (child instanceof Mesh) {
        child.castShadow = true;
        child.receiveShadow = true;
        if (
          child.name.startsWith('Logo_V') ||
          child.name.startsWith('Logo_M')
        ) {
          child.material = new MeshStandardMaterial({
            color: new Color(0xffffff),
          });
        }
      }
    });
  }

  let time = 0;

  useFrame((state, delta) => {
    if (ref.current) {
      if (animation === 'bouncing') {
        time += delta;
        ref.current.rotation.z = -Math.cos(time) * 2;
        ref.current.rotation.x = Math.cos(time) * 2;
        ref.current.position.y = -0.5 + Math.abs(Math.sin(time * 3));
        ref.current.position.x = Math.cos(time) * 2;
      } else {
        ref.current.rotation.y += 0.04;
      }
    }
  });

  return <primitive ref={ref} object={scene} {...rest} />;
};

export const LoadingScreen = () => {
  const styles = useStyles();
  const [ initializing, setInitializing ] = useState(true);
  const { active, progress } = useProgress();

  useEffect(() => {
    active && setInitializing(false);
  }, [active]);

  return (
    <>
      {(initializing || active) &&
        <div className={styles.container}>
          <div className={styles.logo}>
            <Canvas
              camera={{
                position: [0, 0, -1],
                far: 100,
                near: 0.01,
                fov: 85,
              }}
            >
              <hemisphereLight intensity={0.35} />
              <spotLight
                position={[5, 5, 5]}
                angle={0.3}
                penumbra={1}
                intensity={0.2}
                shadow-mapSize-width={256}
                shadow-mapSize-height={256}
              />
              <Suspense fallback={null}>
                <Logo scale={[3, 3, 3]} />
              </Suspense>
            </Canvas>
          </div>
          <div className={styles.inner}>
            <ProgressBar progress={progress} />
          </div>
        </div>
      }
    </>
  );
};
