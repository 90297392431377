export const demo5 = {
    roomModelDmsId: '0136NQiaBLmL',
    startPosition: { x: 18.3, y: 2.84, z: -10 },
    directionOfView: { x: 0, y: 0, z: -0.001 },
    dome: {
        skyBoxDmsId: '0136MvjisAqp',
    },
    lights: [
        {
            type: 'hemisphere',
            skyColor: '#DCDCDC',
            groundColor: '#A9A9A9',
            intensity: 0.3,
        },
        {
            type: 'spot',
            intensity: 0.8,
            position: { x: 2.3, y: 4, z: -6.4 },
            shadowBias: -0.015,
        },
        {
            type: 'spot',
            intensity: 0.8,
            position: { x: 2.3, y: 4, z: -14.4 },
            shadowBias: -0.015,
        },
        {
            type: 'spot',
            intensity: 0.8,
            position: { x: 10.3, y: 4, z: -6.4 },
            shadowBias: -0.015,
        },
        {
            type: 'spot',
            intensity: 0.8,
            position: { x: 14.3, y: 4, z: -14.4 },
            shadowBias: -0.015,
        },
    ],
    models: [
        {
            dmsId: '0136NRjSVVW7',
            position: { x: 9.58, y: 2.55, z: -7.99 },
            rotation: { x: 0, y: 1, z: 0 },
            scale: { x: 1.2, y: 1.2, z: 1.2 },
            viewerData: {
                model: {
                    type: 'Object',
                    dmsId: '0136NRjSVVW7',
                },
            },
        },
        {
            dmsId: '0136NRjSVVxT',
            position: { x: 14.9, y: 2.46, z: -14.37 },
            rotation: { x: 0, y: 0, z: 0 },
            scale: { x: 0.00015, y: 0.00015, z: 0.00015 },
            rotate: true,
            rotationAxis: 'y',
            viewerData: {
                model: {
                    type: 'Object',
                    dmsId: '0136NRjSVW7K',
                },
            },
        },
        {
            dmsId: '0136NRjSVqDC',
            position: { x: 9.59, y: 2.28, z: -5.67 },
            rotation: { x: 0, y: 2.5, z: 0 },
            scale: { x: 2, y: 2, z: 2 },
            viewerData: {
                model: {
                    type: 'Object',
                    dmsId: '0136NRjSVqDC',
                },
                annotations: [
                    {
                        position: [0, 0.3, 0],
                        title: 'Annotasjon 1',
                        content: {
                            paragraphs: [
                                'Denne Adeliepingvinen ble fanget under Belgica-ekspedisjonen 1897-99 langs den Antarktiske halvøy. Det ble fanget mange pingviner under ekspedisjonen – både til mat og forskning.',
                                'Amundsen lærte seg å skrike på en måte som tiltrakk pingvinene. Som en kuriositet forsøkte han også å sette en pingvin som trekkdyr foran en slede, uten stor suksess.',
                                'Den utstoppede pingvinen mangler svømmehud mellom tærne og den har mistet høyre vinge, muligens i forbindelse med utstoppingen.',
                            ],
                        },
                    },
                    {
                        position: [0, 0, 0],
                        title: 'Annotasjon 2',
                        content: {
                            paragraphs: [
                                'Denne Adeliepingvinen ble fanget under Belgica-ekspedisjonen 1897-99 langs den Antarktiske halvøy. Det ble fanget mange pingviner under ekspedisjonen – både til mat og forskning.',
                                'Amundsen lærte seg å skrike på en måte som tiltrakk pingvinene. Som en kuriositet forsøkte han også å sette en pingvin som trekkdyr foran en slede, uten stor suksess.',
                                'Den utstoppede pingvinen mangler svømmehud mellom tærne og den har mistet høyre vinge, muligens i forbindelse med utstoppingen.',
                            ],
                        },
                    },
                    {
                        position: [0, 0.13, 0.04],
                        title: 'Annotasjon 3',
                        content: {
                            paragraphs: [
                                'Denne Adeliepingvinen ble fanget under Belgica-ekspedisjonen 1897-99 langs den Antarktiske halvøy. Det ble fanget mange pingviner under ekspedisjonen – både til mat og forskning.',
                                'Amundsen lærte seg å skrike på en måte som tiltrakk pingvinene. Som en kuriositet forsøkte han også å sette en pingvin som trekkdyr foran en slede, uten stor suksess.',
                                'Den utstoppede pingvinen mangler svømmehud mellom tærne og den har mistet høyre vinge, muligens i forbindelse med utstoppingen.',
                            ],
                        },
                    },
                ],
            },
        },
    ],
    images: [
        {
            dmsId: '0136NQibezZk',
            scale: 2,
            rotation: { x: 0, y: Math.PI / 2, z: 0 },
            position: { x: 0.34, y: 2.1, z: -3.45 },
        },
        {
            dmsId: '0136NR4Fi83X',
            scale: 2.005,
            rotation: { x: 0, y: Math.PI / 2, z: 0 },
            position: { x: 0.34, y: 2.1, z: -7.2 },
        },
        {
            dmsId: '0136Mux3aJAX',
            scale: 2.094,
            rotation: { x: 0, y: Math.PI / 2, z: 0 },
            position: { x: 0.34, y: 2.1, z: -17.3 },
        },
        {
            dmsId: '0136Mux3ZHsa',
            scale: 2.094,
            rotation: { x: 0, y: Math.PI / 2, z: 0 },
            position: { x: 0.34, y: 2.1, z: -13.55 },
        },
    ],
    videos: [
        {
            dmsId: '0136NvW9VTDQ',
            width: 3.2,
            height: 1.8,
            position: { x: 18.2, y: 2.8, z: -15.445 },
            rotation: { x: 0, y: 0, z: 0 },
            autoPlay: true,
        },
    ],
}