import { ModelEditor } from './ModelEditor';
import { getCanvasItemName } from '../../helpers';
import { CanvasItemTypes } from '../../helpers/enums';

export const ModelEditors = ({
  models,
  groups,
  canvasName,
  canvasIndex,
  canvasWidth,
  canvasHeight,
  cellSize,
  onUpdate,
  onRemove,
}) => {
  const handleUpdate = (index, model) => {
    onUpdate && onUpdate(index, model, CanvasItemTypes.MODEL3D);
  };

  const handleRemove = (index) => {
    onRemove && onRemove(index, CanvasItemTypes.MODEL3D);
  };

  const handleGroupUpdate = (index, model, groupChange) => {
    onUpdate && onUpdate(index, model, CanvasItemTypes.MODEL3D, groupChange);
  };

  return (
    <group>
      {models.map((model, index) => (
        <ModelEditor
          modelName={getCanvasItemName(CanvasItemTypes.MODEL3D, canvasIndex, index)}
          modelItem={model}
          canvasName={canvasName}
          canvasIndex={canvasIndex}
          canvasWidth={canvasWidth}
          canvasHeight={canvasHeight}
          cellSize={cellSize}
          index={index}
          key={index}
          groups={groups}
          onUpdate={handleUpdate}
          onRemove={handleRemove}
          onGroupUpdate={handleGroupUpdate}
        />
      ))}
    </group>
  );
};
