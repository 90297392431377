import { TextEditor } from './TextEditor';
import { getCanvasItemName } from '../../helpers';
import { CanvasItemTypes } from '../../helpers/enums';

export const TextEditors = ({
  texts,
  canvasName,
  canvasIndex,
  canvasWidth,
  canvasHeight,
  cellSize,
  onUpdate,
  onRemove,
}) => {
  const handleUpdate = (index, text) => {
    onUpdate && onUpdate(index, text, CanvasItemTypes.TEXT);
  };

  const handleRemove = (index) => {
    onRemove && onRemove(index, CanvasItemTypes.TEXT);
  };

  return (
    <group>
      {texts.map((text, index) => (
        <TextEditor
          key={index}
          textName={getCanvasItemName(CanvasItemTypes.TEXT, canvasIndex, index)}
          textItem={text}
          canvasName={canvasName}
          canvasIndex={canvasIndex}
          canvasWidth={canvasWidth}
          canvasHeight={canvasHeight}
          cellSize={cellSize}
          index={index}
          onUpdate={handleUpdate}
          onRemove={handleRemove}
        />
      ))}
    </group>
  );
};
