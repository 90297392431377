export const demo16 = {
    roomModelDmsId: '019EBwErxb1aU',
        startPosition: { x: 0, y: 1.75, z: -18 },
    directionOfView: { x: 0, y: 0, z: 0.001 },
    lights: [
        {
            type: 'ambient',
            intensity: 1,
        },
    ],
        groups: [
        {
            id: 0,
            name: 'Gruppe 1',
            color: '#864646',
            itemCount: 1,
        },
        {
            id: 1,
            name: 'Gruppe 2',
            color: '#2d6c4a',
            itemCount: 1,
        },
    ],
}