import { Flex } from '@react-three/flex';
import { Paragraph } from './Paragraph';

export const Paragraphs = ({
  name,
  color,
  maxWidth,
  paragraphs,
  onReflow,
}) => {
  return (
    <Flex
      size={[0, 0, 0]}
      align={'center'}
      justify={'center'}
      centerAnchor
      onReflow={onReflow}
    >
      {paragraphs.map((paragraph, index) =>
        <Paragraph
          key={index}
          name={name}
          color={color}
          maxWidth={maxWidth}
          text={paragraph.text}
          fontFamily={paragraph.fontFamily}
          fontSize={paragraph.fontSize}
          textAlign={paragraph.textAlign}
          lineHeight={paragraph.lineHeight}
          margin={paragraph.margin}
        />
      )}
    </Flex>
  );
};
