import cx from 'classnames';
import { Button } from '../../../components/Button';
import { createUseStyles } from 'react-jss';
import * as Icon from 'react-feather';

const useStyles = createUseStyles({
  resultQuestion: {
    paddingBottom: 10,
    width: '50%',
  },
  resultQuestionDef: {
    paddingBottom: 20,
  },
  resultQuestionAlternatives: {
    display: 'flex',
    flexDirection: 'column',
    paddingBottom: 20,
  },
  resultQuestionAlternative: {
    display: 'flex',
    alignItems: 'center',
    borderRadius: '10px',
    padding: 10,
    '&.yourAnswer': {
      border: '4px solid #4485c1',
    },
  },
  resultAnswers: {
    display: 'flex',
    justifyContent: 'space-around',
  },
  score: {
    textAlign: 'center',
    fontSize: 100,
  },
  resetButton: {
    backgroundColor: 'rgb(97, 156, 88) !important',
    border: '2px solid transparent !important',
    color: '#fff !important',
    '&:hover': {
      backgroundColor: 'rgba(97, 156, 88, 0.7) !important',
      border: '2px solid transparent !important',
    },
    '&:focus': {
      border: '2px solid #fff !important',
    },
  },
});

export const Results = ({ questions, answers, resetClick, onAllAnswersCorrect }) => {
  const classes = useStyles();
  const score = questions
    .map(q => (answers.find(a => a.id === q.id)?.value === q.answer ? 1 : 0))
    .reduce((a, b) => a + b, 0);

  score === questions.length && onAllAnswersCorrect();

  return (
    <div className={classes.results}>
      <Button
        label="Ta Quizen igjen"
        className={classes.resetButton}
        onClick={resetClick}
      />
      <h1>Resultater - Poengsum: {score} </h1>
      {questions.map(q => {
        const yourAnswer = answers.find(a => a.id === q.id)?.value;
        return (
          <div key={q.id} className={classes.resultQuestion}>
            <div className={classes.resultQuestionDef}>{q.question}</div>
            <div className={classes.resultQuestionAlternatives}>
              {['a', 'b', 'c', 'd'].map(option => (
                <span
                  key={option}
                  className={cx(classes.resultQuestionAlternative, {
                    yourAnswer: yourAnswer === option,
                  })}
                >
                  {`${option.toUpperCase()}: ${q[option]}`}
                  {yourAnswer !== q.answer && yourAnswer === option && (
                    <Icon.X color="#c20012" />
                  )}
                  {q.answer === option && <Icon.Check color="#619c58" />}
                </span>
              ))}
            </div>
          </div>
        );
      })}
    </div>
  );
};
