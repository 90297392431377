import * as THREE from 'three';
import { Plane } from '@react-three/drei';
import { getDmsUrl, getDmsFileUrl } from '@ekultur/dms-url-generator';
import { useEffect, useRef, useState } from 'react';
import { ImageEditors } from '../ImageEditors/ImageEditors';
import { ImageSphereEditors } from '../ImageSphereEditors/ImageSphereEditors';
import { TextEditors } from '../TextEditors/TextEditors';
import { QuizEditors } from '../QuizEditors/QuizEditors';
import { VideoEditors } from '../VideoEditors/VideoEditors';
import { ModelEditors } from '../ModelEditors/ModelEditors';
import { TourEditors } from '../TourEditors/TourEditors';
import { AudioEditors } from '../AudioEditors/AudioEditors';
import { CanvasItemTypes, CanvasTypes } from '../../helpers/enums';
import { GetImageDimensions, GetModelDimensions, GetVideoDimensions, isCanvasItem } from '../../helpers';
import { fontFamilyOptions } from '../../fonts';
import { vertexShader, fragmentShader } from '../../shaders/checkerboard/checkerboard';
import { SET_CANVAS_ADDITION_ITEM_TYPE, SET_CANVAS_SELECTION, SET_INVERTED_MOUSE_BUTTONS,
  SET_OUTLINE_CANVAS, useThreeDDispatch, useThreeDProps, useThreeDState } from '../App/ThreeDContext';

export const Canvas = ({
  index,
  groups,
  images,
  texts,
  models,
  imageSpheres,
  quizzes,
  videos,
  tours,
  audios,
  position,
  rotation,
  dimensions,
  type,
  onItemAdd,
  onItemUpdate,
  onItemRemove,
}) => {
  const { canvasAdditionItemType, canvasSelection } = useThreeDState();
  const dispatch = useThreeDDispatch();
  const canvasRef = useRef();
  const marker = useRef();
  const [hover, setHover] = useState(false);
  const [cellPositionStart, setCellPositionStart] = useState();
  const { onMediaSelect } = useThreeDProps();
  const name = `Canvas-${index}`;
  const point = useRef();

  const handlePointerEnter = (event) => {
    if (event.buttons === 1) {
      toggleHover(true);
    }
  };

  const handlePointerLeave = () => {
    toggleHover(false);
    marker.current && marker.current.scale.set(1, 1, 1);
  };

  const handlePointerMove = (event) => {
    if (marker.current) {
      if (event.buttons === 0) {
        const cellPosition = getCellPosition(event);
        marker.current.position.copy(cellPosition);
        toggleHover(true);
      }
      else if (event.buttons === 1 && cellPositionStart) {
        const cellPosition = getCellPosition(event);
        const size = getMarkerSize(event);
        marker.current.scale.copy(size);
        marker.current.position.set(
          cellPositionStart.x + ((cellPosition.x - cellPositionStart.x) / 2),
          cellPositionStart.y + ((cellPosition.y - cellPositionStart.y) / 2),
          cellPositionStart.z + ((cellPosition.z - cellPositionStart.z) / 2),
        );
      }
    }
  };

  const handlePointerDown = (event) => {
    if (!isCanvasItem(event)) return null;
    const intersection = event.intersections.reduce((acc, obj) => (obj.distance < acc.distance ? obj : acc));

    if (event.button === 0 && intersection.object === event.object) {
      if (!!canvasAdditionItemType && canvasSelection?.canvasIndex === index) {
        setCellPositionStart(getCellPosition(event));
      }
      else {
        point.current = { x: event.screenX, y: event.screenY };
      }
    }
  };

  const handlePointerUp = (event) => {
    if (!isCanvasItem(event)) return null;

    if (event.intersections[0].object === event.object) {
      if (event.button === 0 && cellPositionStart) {
        const size = getMarkerSize(event);

        switch (canvasAdditionItemType) {
          case CanvasItemTypes.IMAGE:
            addImage(size);
            break;
          case CanvasItemTypes.TEXT:
            addText(size);
            break;
          case CanvasItemTypes.MODEL3D:
            add3DModel(size);
            break;
          case CanvasItemTypes.IMAGE_SPHERE:
            addImageSphere(size);
            break;
          case CanvasItemTypes.QUIZ:
            addQuiz();
            break;
          case CanvasItemTypes.VIDEO:
            addVideo(size);
            break;
          case CanvasItemTypes.TOUR:
            addTour();
            break;
          case CanvasItemTypes.AUDIO:
            addAudio();
            break;
          default:
            break;
        }

        toggleHover(false);
        dispatch({ type: SET_CANVAS_ADDITION_ITEM_TYPE, canvasAdditionItemType: null });
        dispatch({ type: SET_INVERTED_MOUSE_BUTTONS, invertedMouseButtons: false });
      }
      else if (point.current && point.current.x === event.screenX && point.current.y === event.screenY) {
        dispatch({
          type: SET_CANVAS_SELECTION,
          canvasSelection: { canvasIndex: index, itemIndex: null, itemType: null },
        });
        dispatch({ type: SET_OUTLINE_CANVAS, outlineCanvas: name });
        dispatch({ type: SET_CANVAS_ADDITION_ITEM_TYPE, canvasAdditionItemType: null });
        dispatch({ type: SET_INVERTED_MOUSE_BUTTONS, invertedMouseButtons: false });
      }
    }

    marker.current && marker.current.scale.set(1, 1, 1);
    setCellPositionStart(null);
  };

  const handleUpdate = (itemIndex, item, itemType, groupChange) => {
    onItemUpdate(index, itemIndex, item, itemType, groupChange);
  };

  const handleRemove = (itemIndex, itemType) => {
    onItemRemove(index, itemIndex, itemType);
    dispatch({
      type: SET_CANVAS_SELECTION,
      canvasSelection: { canvasIndex: index, itemIndex: null, itemType: null },
    });
    dispatch({ type: SET_OUTLINE_CANVAS, outlineCanvas: name });
  };

  const handleKeyDown = (event) => {
    if (canvasSelection?.canvasIndex === index) {
      switch (event.code) {
        case 'Backspace':
        case 'Delete':
          deleteSelectedItem();
          break;
        case 'ArrowUp':
          break;
        case 'ArrowDown':
          break;
        case 'ArrowRight':
          break;
        case 'ArrowLeft':
          break;
        default:
          break;
      }
    }
  };

  const toggleHover = (toggle) => {
    if (toggle && !!canvasAdditionItemType && !hover) {
      setHover(true);
    }
    else if (!toggle && hover) {
      setHover(false);
    }
  };

  const deleteSelectedItem = () => {
    if (document.activeElement.tagName === 'BODY' &&
      canvasSelection?.canvasIndex === index &&
      canvasSelection?.itemType !== null &&
      canvasSelection?.itemIndex !== null
    ) {
      onItemRemove(index, canvasSelection.itemIndex, canvasSelection.itemType);
      dispatch({
        type: SET_CANVAS_SELECTION,
        canvasSelection: { canvasIndex: index, itemIndex: null, itemType: null },
      });
      dispatch({ type: SET_OUTLINE_CANVAS, outlineCanvas: name });
    }
  };

  const getCellPosition = (event) => {
    const cellPosition = canvasRef.current.worldToLocal(event.point.clone());
    cellPosition.set(
      cellPosition.x / dimensions.cellSize,
      cellPosition.y / dimensions.cellSize,
      0,
    );
    cellPosition.add(new THREE.Vector3(
      0.5 * (dimensions.columns % 2),
      0.5 * (dimensions.rows % 2),
      0,
    ));
    cellPosition.floor();
    cellPosition.multiply(new THREE.Vector3(
      dimensions.cellSize,
      dimensions.cellSize,
      0,
    ));
    cellPosition.add(new THREE.Vector3(
      dimensions.cellSize / 2 * (1 - (dimensions.columns % 2)),
      dimensions.cellSize / 2 * (1 - (dimensions.rows % 2)),
      0,
    ));
    cellPosition.setZ(0.001);
    return canvasRef.current.localToWorld(cellPosition);
  };

  const getMarkerSize = (event) => {
    const cellPosition = getCellPosition(event);
    const localCellPosition = canvasRef.current.worldToLocal(cellPosition.clone());
    const localCellPositionStart = canvasRef.current.worldToLocal(cellPositionStart.clone());
    return new THREE.Vector3(
      1 + Math.abs((localCellPosition.x - localCellPositionStart.x) / dimensions.cellSize),
      1 + Math.abs((localCellPosition.y - localCellPositionStart.y) / dimensions.cellSize),
      1 + Math.abs((localCellPosition.z - localCellPositionStart.z) / dimensions.cellSize),
    );
  };

  const addImage = (size) => {
    if (onMediaSelect) {
      const position = marker.current.position.clone();
      onMediaSelect('image', async (image) => {
        const imageUrl = getDmsUrl(image.dmsId, '01');
        const imageDimensions = await GetImageDimensions(imageUrl);
        const width = imageDimensions.width / (size.x * dimensions.cellSize);
        const height = imageDimensions.height / (size.y * dimensions.cellSize);
        const scale = width > height ? size.x * dimensions.cellSize : size.y * dimensions.cellSize;

        const newImage = {
          dmsId: image.dmsId,
          position: position,
          rotation: rotation.clone(),
          scale: scale,
          addCanvasDepth: type === CanvasTypes.WALL,
          boundToGrid: false,
          canvasIndex: index,
          viewerData: {
            model: {
              type: 'ImageSingle',
              dmsId: image.dmsId,
            },
          },
        };

        onItemAdd(newImage, CanvasItemTypes.IMAGE);
        dispatch({
          type: SET_CANVAS_SELECTION,
          canvasSelection: {
            canvasIndex: index,
            itemIndex: images.length,
            itemType: CanvasItemTypes.IMAGE,
          },
        });
      }, 'single');
    } else {
      console.warn('onMediaSelect is undefined');
    }
  };

  const addText = (size) => {
    const text = {
      position: marker.current.position.clone(),
      rotation: rotation.clone(),
      maxWidth: size.x * dimensions.cellSize,
      color: '#171717',
      textAlign: 'left',
      paragraphs: [{
        fontSize: 0.2,
        fontFamily: fontFamilyOptions[0].weight.find(w => w.value === '400').url,
      }],
      boundToGrid: false,
      canvasIndex: index,
    };

    onItemAdd(text, CanvasItemTypes.TEXT);
    dispatch({
      type: SET_CANVAS_SELECTION,
      canvasSelection: {
        canvasIndex: index,
        itemIndex: texts.length,
        itemType: CanvasItemTypes.TEXT,
      },
    });
  };

  const add3DModel = async (size) => {
    if (onMediaSelect) {
      const position = marker.current.position.clone();
      onMediaSelect('model', async (model) => {
        const modelUrl = getDmsFileUrl(model.dmsId, '01');
        const modelDimensions = await GetModelDimensions(modelUrl);
        const scale = Math.min(size.x, size.y) * dimensions.cellSize / Math.max(modelDimensions.x, modelDimensions.y);
        position.setY(canvasRef.current.position.y + (modelDimensions.y / 2 * scale));

        const newModel = {
          dmsId: model.dmsId,
          position: position,
          rotation: new THREE.Euler(0, 0, 0),
          scale: new THREE.Vector3(scale, scale, scale),
          boundToGrid: false,
          canvasIndex: index,
          viewerData: {
            model: {
              type: 'Object',
              dmsId: model.dmsId,
            },
          },
        };

        onItemAdd(newModel, CanvasItemTypes.MODEL3D);
        dispatch({
          type: SET_CANVAS_SELECTION,
          canvasSelection: {
            canvasIndex: index,
            itemIndex: models.length,
            itemType: CanvasItemTypes.MODEL3D,
          },
        });
      }, 'single');
    } else {
      console.warn('onMediaSelect is undefined');
    }
  };

  const addImageSphere = (size) => {
    if (onMediaSelect) {
      const position = marker.current.position.clone();
      onMediaSelect('image', async (images) => {
        const radius = Math.min(size.x, size.y) * dimensions.cellSize / 2;
        position.setY(position.y + radius);
        const imageSphere = {
          position: position,
          radius: radius,
          segments: {
            height: 2,
            width: 5,
          },
          spacing: 0,
          thetaOffset: 0,
          rotationSpeed: 0.1,
          staggeredLayout: false,
          retainImageAspectRatio: true,
          opacity: 1,
          inverted: false,
          images: images.map(image => ({
            dmsId: image.dmsId,
            viewerData: {
              model: {
                type: 'ImageSingle',
                dmsId: image.dmsId,
              },
            },
          })),
          boundToGrid: false,
          canvasIndex: index,
        };

        onItemAdd(imageSphere, CanvasItemTypes.IMAGE_SPHERE);
        dispatch({
          type: SET_CANVAS_SELECTION,
          canvasSelection: {
            canvasIndex: index,
            itemIndex: imageSpheres.length,
            itemType: CanvasItemTypes.IMAGE_SPHERE,
          },
        });
      }, 'multiple');
    } else {
      console.warn('onMediaSelect is undefined');
    }
  };

  const addQuiz = () => {
    const position = marker.current.position.clone();
    position.setY(position.y + 0.5);
    const quiz = {
      position: position,
      scale: new THREE.Vector3(2.5, 2.5, 2.5),
      repeatable: true,
      boundToGrid: false,
      canvasIndex: index,
    };

    onItemAdd(quiz, CanvasItemTypes.QUIZ);
    dispatch({
      type: SET_CANVAS_SELECTION,
      canvasSelection: {
        canvasIndex: index,
        itemIndex: quizzes.length,
        itemType: CanvasItemTypes.QUIZ,
      },
    });
  };

  const addVideo = async (size) => {
    if (onMediaSelect) {
      const position = marker.current.position.clone();
      onMediaSelect('video', async (video) => {
        const videoDimensions = await GetVideoDimensions(video.dmsId);
        const width = size.x * dimensions.cellSize * Math.min((size.y / size.x) / (videoDimensions.height / videoDimensions.width), 1);
        const height = size.y * dimensions.cellSize * Math.min((size.x / size.y) / (videoDimensions.width / videoDimensions.height), 1);

        const newVideo = {
          dmsId: video.dmsId,
          width: width,
          height: height,
          position: position,
          rotation: rotation.clone(),
          volume: 1,
          autoPlay: false,
          loop: true,
          boundToGrid: false,
          canvasIndex: index,
        };

        onItemAdd(newVideo, CanvasItemTypes.VIDEO);
        dispatch({
          type: SET_CANVAS_SELECTION,
          canvasSelection: {
            canvasIndex: index,
            itemIndex: videos.length,
            itemType: CanvasItemTypes.VIDEO,
          },
        });
      }, 'single');
    } else {
      console.warn('onMediaSelect is undefined');
    }
  };

  const addTour = () => {
    const position = marker.current.position.clone();
    const tour = {
      position: position,
      boundToGrid: false,
      canvasIndex: index,
    };

    onItemAdd(tour, CanvasItemTypes.TOUR);
    dispatch({
      type: SET_CANVAS_SELECTION,
      canvasSelection: {
        canvasIndex: index,
        itemIndex: tours.length,
        itemType: CanvasItemTypes.TOUR,
      },
    });
  };

  const addAudio = () => {
    if (onMediaSelect) {
      const position = marker.current.position.clone();
      onMediaSelect('audio', async (audio) => {
        const newAudio = {
          dmsId: audio.dmsId,
          positioning: true,
          position: position,
          rotation: rotation.clone(),
          volume: 1,
          maxDistance: 15,
          scale: 1.5,
          interactive: true,
          loop: false,
          boundToGrid: false,
          canvasIndex: index,
        };

        onItemAdd(newAudio, CanvasItemTypes.AUDIO);
        dispatch({
          type: SET_CANVAS_SELECTION,
          canvasSelection: {
            canvasIndex: index,
            itemIndex: audios.length,
            itemType: CanvasItemTypes.AUDIO,
          },
        });
      }, 'single');
    } else {
      console.warn('onMediaSelect is undefined');
    }
  };

  useEffect(() => {
    if (marker.current) {
      const positionOffset = new THREE.Vector3(0, 0, 1).applyEuler(canvasRef.current.rotation).multiplyScalar(0.001);
      marker.current.rotation.copy(rotation);
      marker.current.position.copy(position.clone().add(positionOffset));
    }
  }, [position, rotation, canvasAdditionItemType]);

  useEffect(() => {
    document.addEventListener('keydown', handleKeyDown);

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  });

  return (
    <group>
      <Plane
        ref={canvasRef}
        name={name}
        args={[
          dimensions.columns * dimensions.cellSize,
          dimensions.rows * dimensions.cellSize,
          dimensions.columns,
          dimensions.rows,
        ]}
        position={position}
        rotation={rotation}
        renderOrder={1}
        onPointerEnter={handlePointerEnter}
        onPointerLeave={handlePointerLeave}
        onPointerMove={handlePointerMove}
        onPointerDown={handlePointerDown}
        onPointerUp={handlePointerUp}
      >
        <shaderMaterial
          vertexShader={vertexShader}
          fragmentShader={fragmentShader}
          uniforms={{
            uGridDimensions: { value: new THREE.Vector2(dimensions.columns, dimensions.rows) },
            uPrimaryColor: { value: new THREE.Color(0xAAAAAA) },
            uSecondaryColor: { value: new THREE.Color(0xFFFFFF) },
            uAlpha: { value: 0.2 },
          }}
          depthWrite={false}
          transparent
        />
      </Plane>
      {!!canvasAdditionItemType && canvasSelection?.canvasIndex === index &&
        <Plane
          ref={marker}
          name={'CanvasMarker'}
          args={[dimensions.cellSize, dimensions.cellSize]}
          visible={hover}
          renderOrder={2}
        >
          <meshBasicMaterial
            color={0x000000}
            opacity={0.3}
            transparent
          />
        </Plane>
      }
      <ImageEditors
        images={images}
        groups={groups}
        canvasName={name}
        canvasIndex={index}
        canvasWidth={dimensions.columns * dimensions.cellSize}
        canvasHeight={dimensions.rows * dimensions.cellSize}
        cellSize={parseFloat(dimensions.cellSize)}
        onUpdate={handleUpdate}
        onRemove={handleRemove}
      />
      <TextEditors
        texts={texts}
        canvasName={name}
        canvasIndex={index}
        canvasWidth={dimensions.columns * dimensions.cellSize}
        canvasHeight={dimensions.rows * dimensions.cellSize}
        cellSize={parseFloat(dimensions.cellSize)}
        onUpdate={handleUpdate}
        onRemove={handleRemove}
      />
      <ModelEditors
        models={models}
        groups={groups}
        canvasName={name}
        canvasIndex={index}
        canvasWidth={dimensions.columns * dimensions.cellSize}
        canvasHeight={dimensions.rows * dimensions.cellSize}
        cellSize={parseFloat(dimensions.cellSize)}
        onUpdate={handleUpdate}
        onRemove={handleRemove}
      />
      <ImageSphereEditors
        imageSpheres={imageSpheres}
        canvasName={name}
        canvasIndex={index}
        canvasWidth={dimensions.columns * dimensions.cellSize}
        canvasHeight={dimensions.rows * dimensions.cellSize}
        cellSize={parseFloat(dimensions.cellSize)}
        onUpdate={handleUpdate}
        onRemove={handleRemove}
      />
      <QuizEditors
        quizzes={quizzes}
        canvasName={name}
        canvasIndex={index}
        canvasWidth={dimensions.columns * dimensions.cellSize}
        canvasHeight={dimensions.rows * dimensions.cellSize}
        cellSize={parseFloat(dimensions.cellSize)}
        onUpdate={handleUpdate}
        onRemove={handleRemove}
      />
      <VideoEditors
        videos={videos}
        canvasName={name}
        canvasIndex={index}
        canvasWidth={dimensions.columns * dimensions.cellSize}
        canvasHeight={dimensions.rows * dimensions.cellSize}
        cellSize={parseFloat(dimensions.cellSize)}
        onUpdate={handleUpdate}
        onRemove={handleRemove}
      />
      <TourEditors
        tours={tours}
        canvasName={name}
        canvasIndex={index}
        canvasWidth={dimensions.columns * dimensions.cellSize}
        canvasHeight={dimensions.rows * dimensions.cellSize}
        cellSize={parseFloat(dimensions.cellSize)}
        onUpdate={handleUpdate}
        onRemove={handleRemove}
      />
      <AudioEditors
        audios={audios}
        canvasName={name}
        canvasIndex={index}
        canvasWidth={dimensions.columns * dimensions.cellSize}
        canvasHeight={dimensions.rows * dimensions.cellSize}
        cellSize={parseFloat(dimensions.cellSize)}
        onUpdate={handleUpdate}
        onRemove={handleRemove}
      />
    </group>
  );
}
