import * as THREE from 'three';
import { Suspense } from 'react';
import { PCFSoftShadowMap } from 'three';
import { Canvas } from '@react-three/fiber';
import { Scene } from './Scene/Scene';
import { Effects } from './Effects';
import { LoadingScreen } from '../components/LoadingScreen';
import { createUseStyles } from 'react-jss';
import { AppBar } from './AppBar';
import { DialogContainer } from './Dialogs/DialogContainer';
import { useThreeDState } from './App/ThreeDContext';
import { Overlay } from './Overlay/Overlay';
import { GPUAnalyzer } from '../components/GPUAnalyzer';

const useStyles = createUseStyles({
  page: {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
    zIndex: 0,
  },
  content: {
    width: '100%',
    height: 'calc(100% - 64px)',
    position: 'relative',
    zIndex: 1,
    '& > .tp-rotv': {
      position: 'absolute',
      top: 10,
      right: 10,
      zIndex: 1,
    },
  },
  canvas: {
    outline: 'none',
    '& *': {
      outline: 'none',
    },
    zIndex: 1,
  },
});

export const Room3d = () => {
  const { userInteracted } = useThreeDState();
  const styles = useStyles();

  return (
    <div className={styles.page}>
      <AppBar />
      <div id="3DCanvasContainer" className={styles.content}>
        <Canvas
          className={styles.canvas}
          gl={{ toneMapping: THREE.NoToneMapping }}
          shadows={{
            enabled: true,
            autoUpdate: false,
            needsUpdate: true,
            type: PCFSoftShadowMap,
          }}
          camera={{
            far: 350,
            near: 0.01,
            fov: 55,
          }}
        >
          <Suspense fallback={<color attach="background" args={['#171717']} />}>
            <Scene />
            <Effects />
          </Suspense>
        </Canvas>
        <DialogContainer />
        <Overlay />
        <Suspense>
          <GPUAnalyzer />
        </Suspense>
      </div>
      {!userInteracted && <LoadingScreen />}
    </div>
  );
};
