import { useThree } from '@react-three/fiber';
import { useEffect, useMemo, useRef, useState } from 'react';
import { CanvasItemTypes, CursorTypes } from '../../helpers/enums';
import { useCanvasItemEditor } from '../Canvases/useCanvasItemEditor';
import { getSceneElementByName, areVectorsEqual, areVectorsEqualOnOneOrMoreAxis,
  setCursor, isCanvasItem } from '../../helpers';
import { Buttons } from './Buttons';
import { Video } from '../Video/Video';
import { SET_CANVAS_SELECTION, SET_OUTLINE_CANVAS, SET_ROTATION_ENABLED,
  useThreeDDispatch, useThreeDState } from '../App/ThreeDContext';

export const VideoEditor = ({
  videoName,
  videoItem,
  canvasName,
  canvasIndex,
  canvasWidth,
  canvasHeight,
  cellSize,
  index,
  onUpdate,
  onRemove,
}) => {
  const { canvasSelection, outlineCanvas } = useThreeDState();
  const dispatch = useThreeDDispatch();
  const { scene } = useThree();
  const [dragPosition, setDragPosition] = useState(videoItem.position);
  const [dragWidth, setDragWidth] = useState(videoItem.width);
  const [dragHeight, setDragHeight] = useState(videoItem.height);
  const [pointerDown, setPointerDown] = useState(false);
  const [positionOffset, setPositionOffset] = useState();
  const [dragMode, setDragMode] = useState(false);
  const canvas = getSceneElementByName(scene.children, canvasName);
  const video = getSceneElementByName(scene.children, videoName);
  const canvasItemEditor = useCanvasItemEditor({
    canvas,
    canvasItem: video,
    canvasSize: { height: canvasHeight, width: canvasWidth },
    cellSize,
  });
  const point = useRef();

  const isSelected = useMemo(() => {
    return canvasSelection?.canvasIndex === canvasIndex
      && canvasSelection?.itemType === CanvasItemTypes.VIDEO
      && canvasSelection?.itemIndex === index;
  }, [canvasSelection, canvasIndex, index]);

  useEffect(() => {
    if (isSelected) {
      updateOutlineCanvas();
      !canvasSelection.getSceneElements && updateCanvasSelection();
    }
  });

  useEffect(() => {
    setDragPosition(videoItem.position);
    setDragWidth(videoItem.width);
    setDragHeight(videoItem.height);
  }, [videoItem.position, videoItem.position.x, videoItem.position.y, videoItem.position.z, videoItem.width, videoItem.height]);

  const updateOutlineCanvas = () => {
    if (outlineCanvas !== videoName) {
      dispatch({ type: SET_OUTLINE_CANVAS, outlineCanvas: videoName });
    }
  };

  const updateCanvasSelection = () => {
    dispatch({
      type: SET_CANVAS_SELECTION,
      canvasSelection: {
        canvasIndex: canvasIndex,
        itemIndex: index,
        itemType: CanvasItemTypes.VIDEO,
        onUpdate: onUpdate,
        onRemove: onRemove,
        getSceneElements: () => ({
          canvasRef: canvas ?? getSceneElementByName(scene.children, canvasName),
          itemRef: getSceneElementByName(scene.children, videoName),
          rescaleButtonsRef: getSceneElementByName(scene.children, `${videoName}-buttons`),
        }),
      },
    });
  };

  const handleClick = (event) => {
    if (!isCanvasItem(event)) return null;
    const intersection = event.intersections.reduce((acc, obj) => (obj.distance < acc.distance ? obj : acc));

    if (event.button === 0 && !dragMode && intersection.object === event.object && point.current?.x === event.screenX && point.current?.y === event.screenY) {
      updateOutlineCanvas();
      !isSelected && updateCanvasSelection();
    }
  };

  const handlePointerDown = (event) => {
    event.stopPropagation();
    const intersection = event.intersections.reduce((acc, obj) => (obj.distance < acc.distance ? obj : acc));

    if (intersection.object === event.object && event.button === 0 && isSelected) {
      const localPosition = video.worldToLocal(event.point.clone());
      setPositionOffset({ x: localPosition.x, y: localPosition.y });
      dispatch({ type: SET_ROTATION_ENABLED, rotationEnabled: false });
      setPointerDown(true);
      setCursor(CursorTypes.MOVE);
    }

    point.current = { x: parseInt(event.screenX), y: parseInt(event.screenY) };
  };

  const handlePointerUp = (event) => {
    event.stopPropagation();

    if (event.button === 0 && isSelected) {
      dispatch({ type: SET_ROTATION_ENABLED, rotationEnabled: true });
      setPointerDown(false);
      setPositionOffset(null);
      setCursor(CursorTypes.GRAB);

      if (dragMode) {
        const videoSize = { height: videoItem.height, width: videoItem.width };
        canvasItemEditor.applyPositionOffset2D(event.point, positionOffset);
        videoItem.boundToGrid && canvasItemEditor.snapPositionToGrid(event.point, videoSize);
        canvasItemEditor.applyPositionLimits(event.point, videoSize);

        if (!areVectorsEqual(videoItem.position, event.point)) {
          videoItem.position.copy(event.point);
          onUpdate(index, videoItem);
        }
      }

      updateOutlineCanvas();
    }

    pointerDown && setPointerDown(false);
    dragMode && setDragMode(false);
  };

  const handlePointerMove = (event) => {
    if (event.buttons === 1) {
      if (pointerDown && dragMode && areVectorsEqualOnOneOrMoreAxis(videoItem.position, event.point)) {
        const videoSize = { height: videoItem.height, width: videoItem.width };
        canvasItemEditor.applyPositionOffset2D(event.point, positionOffset);
        videoItem.boundToGrid && canvasItemEditor.snapPositionToGrid(event.point, videoSize);
        canvasItemEditor.applyPositionLimits(event.point, videoSize);
        setDragPosition(event.point);
      }

      pointerDown && !dragMode && setDragMode(true);
    }
  };

  const handleRescale = (point, direction, last) => {
    const minimumScale = 0.1;
    const localPoint = video.worldToLocal(point.clone());

    if ((direction === 'topLeft' && (localPoint.x > 0 || localPoint.y < 0)) ||
      (direction === 'topRight' && (localPoint.x < 0 || localPoint.y < 0)) ||
      (direction === 'bottomLeft' && (localPoint.x > 0 || localPoint.y > 0)) ||
      (direction === 'bottomRight' && (localPoint.x < 0 || localPoint.y > 0))
    ) {
      localPoint.setX(0);
      localPoint.setY(0);
    };

    const localPosition = video.worldToLocal(videoItem.position.clone());
    const positionDiff = localPosition.clone().sub(localPoint);
    const positionDiffX = Math.abs(positionDiff.x) - (videoItem.width / 2);
    const positionDiffY = Math.abs(positionDiff.y) - (videoItem.height / 2);
    const scaleDiff = Math.min(
      (videoItem.width + positionDiffX) / videoItem.width,
      (videoItem.height + positionDiffY) / videoItem.height
    );
    let width = Math.max(videoItem.width * scaleDiff, minimumScale * Math.min(videoItem.width / videoItem.height, 1));
    let height = Math.max(videoItem.height * scaleDiff, minimumScale * Math.min(videoItem.height / videoItem.width, 1));
    let widthDiff = width - videoItem.width;
    let heightDiff = height - videoItem.height;

    if (videoItem.boundToGrid) {
      const gridScale = Math.round(
        Math.max(
          Math.abs(widthDiff) / 2,
          Math.abs(heightDiff) / 2
        ) / cellSize
      ) * cellSize * (widthDiff > 0 || heightDiff > 0 ? 1 : -1);

      width = videoItem.width + (gridScale * Math.min(videoItem.width / videoItem.height, 1));
      height = videoItem.height + (gridScale * Math.min(videoItem.height / videoItem.width, 1));
      widthDiff = width - videoItem.width;
      heightDiff = height - videoItem.height;
    }

    const pos = canvas.worldToLocal(dragPosition.clone());
    const size = { width: dragWidth, height: dragHeight };
    const scaleLimit = canvasItemEditor.getScaleLimit(pos, Math.max(dragWidth, dragHeight), size, direction);
    const scale = Math.max(width, height);

    if (scale > scaleLimit) {
      width = videoItem.width > videoItem.height ? scaleLimit : scaleLimit * videoItem.width / videoItem.height;
      height = videoItem.height > videoItem.width ? scaleLimit : scaleLimit * videoItem.height / videoItem.width;
      widthDiff = width - videoItem.width;
      heightDiff = height - videoItem.height;
      width = width - (widthDiff / 2);
      height = height - (heightDiff / 2);
    }

    if ((widthDiff !== 0 || heightDiff !== 0) || last) {
      switch (direction) {
        case 'topLeft':
          localPosition.setX(localPosition.x - (widthDiff / 2));
          localPosition.setY(localPosition.y + (heightDiff / 2));
          break;
        case 'topRight':
          localPosition.setX(localPosition.x + (widthDiff / 2));
          localPosition.setY(localPosition.y + (heightDiff / 2));
          break;
        case 'bottomLeft':
          localPosition.setX(localPosition.x - (widthDiff / 2));
          localPosition.setY(localPosition.y - (heightDiff / 2));
          break;
        case 'bottomRight':
          localPosition.setX(localPosition.x + (widthDiff / 2));
          localPosition.setY(localPosition.y - (heightDiff / 2));
          break;
        default:
          break;
      }

      const adjustedPosition = video.localToWorld(localPosition);
      setDragPosition(adjustedPosition);
      setDragWidth(width);
      setDragHeight(height);
      videoItem.position.copy(adjustedPosition);
      videoItem.width = width;
      videoItem.height = height;
      last && onUpdate(index, videoItem);
    }
  };

  return (
    <>
      {isSelected && !dragMode && (
        <Buttons
          position={dragPosition}
          rotation={videoItem.rotation}
          width={dragWidth}
          height={dragHeight}
          videoName={videoName}
          onRescale={handleRescale}
        />
      )}
      <group
        onClick={handleClick}
        onPointerDown={handlePointerDown}
        onPointerUp={handlePointerUp}
        onPointerMove={handlePointerMove}
      >
        <Video
          {...videoItem}
          name={videoName}
          position={dragPosition}
          width={dragWidth}
          height={dragHeight}
          editMode={true}
        />
        {isSelected && dragMode &&
          <mesh
            visible={false}
            position={dragPosition}
            rotation={videoItem.rotation}
          >
            <planeGeometry args={[100, 100]} />
            <meshBasicMaterial transparent opacity={0} depthWrite={false} />
          </mesh>
        }
      </group>
    </>
  );
};
