export const demo1 = {
    roomModelDmsId: '0136NRHBvDPW',
    startPosition: { x: 5, y: 1.95, z: -5.5 },
    directionOfView: { x: 0, y: -0.0001, z: 0.001 },
    dome: {
        skyBoxDmsId: '0136NvASPyuh',
    },
    lights: [
        {
            type: 'ambient',
            intensity: 0.4,
        },
        {
            type: 'spot',
            intensity: 0.3,
            position: { x: 7, y: 2.8, z: -3.2 },
            shadowBias: -0.00015,
        },
        {
            type: 'spot',
            intensity: 0.3,
            position: { x: 3, y: 2.8, z: -3.2 },
            shadowBias: -0.00015,
        },
    ],
    models: [
        {
            dmsId: '0138WvJrNUWb',
            position: { x: 2.98, y: 1.47, z: -3.196 },
            rotation: { x: 0, y: Math.PI, z: 0 },
            scale: { x: 0.08, y: 0.08, z: 0.08 },
            viewerData: {
                model: {
                    type: 'Object',
                    dmsId: '0138WvJrNUWb',
                },
            },
        },
        {
            dmsId: '0138WvJuKnBp',
            position: { x: 7.02, y: 1.35, z: -3.2 },
            rotation: { x: 0, y: Math.PI, z: 0 },
            scale: { x: 0.07, y: 0.07, z: 0.07 },
            viewerData: {
                model: {
                    type: 'Object',
                    dmsId: '0138WvJuKnBp',
                },
            },
        },
    ],
}