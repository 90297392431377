import { useEffect, useState } from 'react';
import { Dialog } from '../../components/Dialog';
import { CloseButton } from '../../components/CloseButton';
import {
  ModelViewer,
  ModelviewerProvider,
  WithModelviewerMicroFrontend
} from '@ekultur/modelviewer-microfrontend';
import { createUseStyles } from 'react-jss';
import { CanvasItemTypes } from '../../helpers/enums';
import { useThreeDProps } from '../App/ThreeDContext';

const useStyles = createUseStyles({
  dialog: {
    display: 'flex',
    flexDirection: 'column',
    width: '100vw',
    height: '100vh',
    borderRadius: 0,
    margin: 0,
    marginTop: '-64px',
    background: `
    radial-gradient(
      50% 50% at 50% 50%,
      rgba(255, 255, 255, 0.2) 0%,
      rgba(255, 255, 255, 0) 100%
    ),
    linear-gradient(0deg, #222222, #222222),
    #232225`,
  },
  title: {
    padding: '10px 15px',
    fontSize: 18,
    fontWeight: 500,
    color: 'rgba(0, 0, 0, 0.87)',
    backgroundColor: '#ffffff',
    borderBottom: 'solid 1px #BDBDBD',
  },
});

export const ModelViewerDialog = ({
  modelViewerData,
  onGroupItemChange,
  onClose,
}) => {
  const { data, adminMode, onChange, onMediaSelect } = useThreeDProps();
  const { groups, images, imageSpheres, models } = data;
  const { viewerData, index, subIndex, type } = modelViewerData;
  const [ currentModelViewerData, setCurrentModelViewerData ] = useState();
  const styles = useStyles();

  useEffect(() =>{
    const group = getGroup(getItem());
    group && (viewerData.group = group);
    setCurrentModelViewerData(viewerData);
  }, [index, subIndex]);

  const getItem = () => {
    switch (type) {
      case CanvasItemTypes.IMAGE:
        if(images) {
          return images[index];
        } else {
          console.warn('Trying to fetch images when there are none!')
          return null
        }

      case CanvasItemTypes.IMAGE_SPHERE:
        return imageSpheres[index];
      case CanvasItemTypes.MODEL3D:
        return models[index];
      default:
        break;
    }
  };

  const getGroup = (item) => {
    if (item?.group) {
      const group = groups[item.group.id];
      return {
        index: item.group.index,
        length: group.itemCount,
        name: group.name,
      };
    }
    else if (type === CanvasItemTypes.IMAGE_SPHERE) {
      return {
        index: subIndex,
        length: item.images.length,
        name: '',
      };
    }
  };

  const getGroupItem = (id, itemIndex) => {
    let i;
    i = images?.findIndex(x => x.group?.id === id && x.group?.index === itemIndex);
    if (i && i > -1) {
      return { item: images[i], index: i, type: CanvasItemTypes.IMAGE };
    }
    i = models?.findIndex(x => x.group?.id === id && x.group?.index === itemIndex);
    if (i && i > -1) {
      return { item: models[i], index: i, type: CanvasItemTypes.MODEL3D };
    }
    if (type === CanvasItemTypes.IMAGE_SPHERE) {
      return { item: imageSpheres[index].images[itemIndex], index: index, subIndex: itemIndex, type };
    }
  };

  const handleClose = () => {
    const updatedItem = getItem();

    if (updatedItem) {
      delete currentModelViewerData.group;
      updatedItem.viewerData = currentModelViewerData;
      onChange && onChange(data);
    }

    onClose && onClose();
  };

  const handleChange = (data) => {
    setCurrentModelViewerData(data);
  };

  const handleGroupItemChange = (index) => {
    const currentItem = getItem();
    const newItem = getGroupItem(currentItem.group?.id, index);

    if (newItem) {
      onGroupItemChange({
        adminMode: adminMode,
        index: newItem.index,
        subIndex: newItem.subIndex,
        type: newItem.type,
        viewerData: newItem.item.viewerData,
      });
    }
  };

  return (
    <>
      {currentModelViewerData && (
        <Dialog
          open={true}
          className={styles.dialog}
        >
          {adminMode && (
            <>
              <div className={styles.title}>
                Redigere beskrivelser og annotasjoner
              </div>
              <CloseButton
                size={24}
                margin={4}
                onClick={handleClose}
              />
            </>
          )}
          <div style={{ flexGrow: 1, overflow: 'auto' }}>
            <ModelviewerProvider>
              <WithModelviewerMicroFrontend url={window._env_.MICROFRONTEND_MODELVIEWER_URL}>
                <ModelViewer
                  adminMode={adminMode}
                  onChange={handleChange}
                  onMediaSelect={onMediaSelect}
                  onGroupItemChange={handleGroupItemChange}
                  onClose={!adminMode ? onClose : null}
                  data={currentModelViewerData}
                />
              </WithModelviewerMicroFrontend>
            </ModelviewerProvider>
          </div>
        </Dialog>
      )}
    </>
  );
};
