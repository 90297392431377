import * as THREE from 'three';
import { Plane, useTexture } from '@react-three/drei';
import { getDmsUrl } from '@ekultur/dms-url-generator';
import { vertexShader, fragmentShader } from '../../shaders/textureMerger/textureMerger';
import { Dome } from '../Dome/Dome';
import { useThreeDProps } from '../App/ThreeDContext';

export const OutdoorRoom = () => {
  const { data, adminMode } = useThreeDProps();
  const { room } = data ?? {};
  let { floorTextureUrl, floorTextureDmsId, dimensions } = room ?? {};
  const floorTexture = useTexture(floorTextureDmsId ? getDmsUrl(floorTextureDmsId, '01') : floorTextureUrl, texture => texture.encoding = THREE.SRGBColorSpace);
  const alpha = useTexture(getDmsUrl('013AkP9JrCzB', '01'));
  floorTexture.wrapS = THREE.RepeatWrapping;
  floorTexture.wrapT = THREE.RepeatWrapping;
  floorTexture.repeat.set(dimensions.x / 2, dimensions.y / 2);

  return (
    <group>
      <Plane
        rotation={[Math.PI / -2, 0, 0]}
        args={[dimensions.x, dimensions.y]}
        receiveShadow={true}
        name={'Floor'}
      >
        <shaderMaterial
          vertexShader={vertexShader}
          fragmentShader={fragmentShader}
          uniforms={{
            uTexture1: { value: floorTexture },
            uTexture2: { value: alpha },
            uRepeat: { value: new THREE.Vector2(dimensions.x / 2, dimensions.y / 2) },
          }}
          transparent
        />
      </Plane>
      {!adminMode &&
        <Plane
          rotation={[Math.PI / -2, 0, 0]}
          position={[0, 0.002, 1]}
          args={[dimensions.x, dimensions.y]}
          receiveShadow={true}
          name={'Shadow'}
          renderOrder={4}
        >
          <shadowMaterial
            opacity={0.35}
            transparent
          />
        </Plane>
      }
      <Dome />
    </group>
  );
};
