import * as THREE from 'three';
import { useEffect, useRef, useState } from 'react';
import { useFrame } from '@react-three/fiber';
import { Cylinder } from '@react-three/drei';
import { detectGeometryCollision, getPositionAboveFloor } from '../../helpers';
import { store } from '../store';

export const usePlayerControlInputs = ({
  camera,
  scene,
  controls,
  disabled = false,
}) => {
  const keyboardInputs = store(state => state.keyboardInputs);
  const setCameraPosition = store(state => state.setCameraPosition);
  const player = useRef();
  const clock = new THREE.Clock();
  const direction = new THREE.Vector3();
  const vector = new THREE.Vector3();
  const [velocity, setVelocity] = useState(new THREE.Vector3());
  const [isMovingForward, setIsMovingForward] = useState(
    keyboardInputs?.moveForward ?? false
  );
  const [isMovingBackward, setIsMovingBackward] = useState(
    keyboardInputs?.moveBackward ?? false
  );
  const [isMovingLeft, setIsMovingLeft] = useState(
    keyboardInputs?.moveLeft ?? false
  );
  const [isMovingRight, setIsMovingRight] = useState(
    keyboardInputs?.moveRight ?? false
  );
  const [isRunning, setIsRunning] = useState(false);

  useFrame(() => {
    if (!disabled && player.current) {
      const originalPosition = camera.position.clone();
      const originalTarget = controls.target.clone();

      updatePlayer();

      if (detectGeometryCollision(scene, player.current)) {
        camera.position.copy(originalPosition);
        controls.target.copy(originalTarget);
        player.current.position.set(
          originalPosition.x,
          originalPosition.y - 0.4,
          originalPosition.z
        );
        setCameraPosition(camera.position);
      }
    }
  });

  useEffect(() => {
    setIsMovingForward(keyboardInputs?.moveForward ?? false);
    setIsMovingBackward(keyboardInputs?.moveBackward ?? false);
    setIsMovingLeft(keyboardInputs?.moveLeft ?? false);
    setIsMovingRight(keyboardInputs?.moveRight ?? false);
  }, [keyboardInputs]);

  useEffect(() => {
    if (disabled) return;

    document.addEventListener('keydown', handleKeyDown);
    document.addEventListener('keyup', handleKeyUp);

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
      document.removeEventListener('keyup', handleKeyUp);
    };
  });

  const handleKeyDown = event => {
    if (document.activeElement === document.body) {
      updateInputControls(event.code, true);
    }
  };

  const handleKeyUp = event => {
    if (document.activeElement === document.body) {
      updateInputControls(event.code, false);
    }
  };

  const updateInputControls = (keyCode, pressed) => {
    switch (keyCode) {
      case 'ArrowUp':
      case 'KeyW':
        setIsMovingForward(pressed);
        break;
      case 'ArrowLeft':
      case 'KeyA':
        setIsMovingLeft(pressed);
        break;
      case 'ArrowDown':
      case 'KeyS':
        setIsMovingBackward(pressed);
        break;
      case 'ArrowRight':
      case 'KeyD':
        setIsMovingRight(pressed);
        break;
      case 'ShiftLeft':
      case 'ShiftRight':
        setIsRunning(pressed);
        break;
      default:
        break;
    }
  };

  const updatePlayer = () => {
    const speedFactor = isRunning ? 100 : 50;
    const delta = clock.getDelta();

    velocity.x -= velocity.x * 10 * delta;
    velocity.z -= velocity.z * 10 * delta;

    direction.z = Number(isMovingForward) - Number(isMovingBackward);
    direction.x = Number(isMovingRight) - Number(isMovingLeft);
    direction.normalize();

    if (isMovingForward || isMovingBackward)
      velocity.z -= direction.z * speedFactor * delta;
    if (isMovingLeft || isMovingRight)
      velocity.x -= direction.x * speedFactor * delta;

    if (Math.abs(velocity.x) > 1e-2 || Math.abs(velocity.z) > 1e-2) {
      const currentDirectionOfView = camera.position.clone().sub(controls.target);

      moveRight(-velocity.x * delta);
      moveForward(-velocity.z * delta);

      camera.position.copy(getPositionAboveFloor(camera, scene, camera.position) ?? camera.position);
      controls.target.copy(camera.position).sub(currentDirectionOfView);

      player.current.position.set(
        camera.position.x,
        camera.position.y - 0.4,
        camera.position.z
      );

      setCameraPosition(camera.position);
    }

    setVelocity(velocity);
  };

  const moveForward = distance => {
    vector.setFromMatrixColumn(camera.matrix, 0);
    vector.crossVectors(camera.up, vector);
    camera.position.addScaledVector(vector, distance);
  };

  const moveRight = distance => {
    vector.setFromMatrixColumn(camera.matrix, 0);
    camera.position.addScaledVector(vector, distance);
  };

  return (
    <Cylinder ref={player} args={[0.2, 0.2, 1.2, 5, 1]} visible={false}>
      <meshBasicMaterial attach="material" wireframe />
    </Cylinder>
  );
};
