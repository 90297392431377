import * as THREE from 'three';
import { Suspense, useEffect, useMemo, useRef } from 'react';
import { useFrame } from '@react-three/fiber';
import { useSpring } from '@react-spring/three';
import { useHover } from '@use-gesture/react';
import { Image } from './Image';
import { getSpherePositions } from '../../helpers';
import { DragPlane } from '../../components/DragPlane';

export const ImageSphere = ({
  index,
  name,
  position,
  radius = 1,
  segments,
  spacing = 0,
  thetaOffset = 0,
  rotationSpeed = 0.1,
  staggeredLayout = true,
  retainImageAspectRatio = true,
  opacity,
  inverted = false,
  images,
  editMode = false,
  dragPositionOffset,
  onPointerMove,
}) => {
  const container = useRef();
  const positions = useMemo(() => getSpherePositions(segments), [segments]);
  const clock = useMemo(() => new THREE.Clock(), []);

  const [spring, springApi] = useSpring(() => ({
    rotationSpeed: 0,
    config: {
      duration: 500,
    },
  }));

  useEffect(() => {
    springApi.start({ rotationSpeed: rotationSpeed });
  }, []);

  useFrame(() => {
    if (container.current && !editMode) {
      const rotationSpeedValue = spring.rotationSpeed.get();
      container.current.rotation.y += clock.getDelta() * rotationSpeedValue;
    }
  });

  const getSegmentIndices = (index) => {
    const heightSegment = Math.ceil((index + 1) / segments.width) - 1;

    return {
      height: heightSegment,
      width: index - (heightSegment * segments.width),
    };
  };

  const handleHover = ({ first, last }) => {
    first && springApi.start({ rotationSpeed: 0 });
    last && springApi.start({ rotationSpeed: rotationSpeed });
  };

  const useGestures = () => {
    const hover = useHover(handleHover);
    return !editMode ? hover() : {};
  };

  return (
    <group
      ref={container}
      name={name}
      position={[position.x, position.y, position.z]}
      userData={{ traversable: true }}
      {...useGestures()}
    >
      <Suspense fallback={null}>
        {images?.slice(0, segments.height * segments.width).map((image, imageIndex) => (
          <Image
            name={`${name}-element-${imageIndex}`}
            imageIndex={imageIndex}
            imageSphereIndex={index}
            key={imageIndex}
            position={positions[imageIndex]}
            radius={radius}
            spacing={spacing}
            thetaOffset={thetaOffset}
            staggeredLayout={staggeredLayout}
            retainImageAspectRatio={retainImageAspectRatio}
            opacity={opacity}
            inverted={inverted}
            segments={segments}
            segmentIndices={getSegmentIndices(imageIndex)}
            editMode={editMode}
            {...image}
          />
        ))}
      </Suspense>
      {dragPositionOffset &&
        <DragPlane
          position={position}
          dragPositionOffset={dragPositionOffset}
          onPointerMove={onPointerMove}
        />
      }
    </group>
  );
}
