import { useMemo } from 'react';
import { useThreeDProps, useThreeDState } from '../App/ThreeDContext';
import { getExhibitionItems } from '../../helpers';
import { Video } from './Video';

export const Videos = () => {
  const { data, adminMode } = useThreeDProps();
  const { interactiveMode } = useThreeDState();
  const { videos } = data;
  const filteredVideos = useMemo(() =>
    getExhibitionItems(videos, adminMode, interactiveMode),
    [videos, adminMode, interactiveMode]
  );

  return filteredVideos.map((video, index) => (
    <Video
      key={index}
      {...video}
      name={`Video-${index}`}
      editMode={!interactiveMode}
    />
  ));
};
