import { useDrag } from '@use-gesture/react';
import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles({
  button: {
    position: 'relative',
    display: 'flex',
    width: 35,
    height: 35,
    justifyContent: 'center',
    cursor: 'pointer',
    backgroundColor: 'rgba(0, 0, 0, 0.125)',
    border: 'solid 3px rgba(255, 255, 255, 0.55)',
    borderRadius: 8,
    boxSizing: 'content-box',
    touchAction: 'none',
    zIndex: 2,
    '& > div': {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      width: 30,
      height: 30,
      color: 'rgba(0, 0, 0, 0.67)',
      backgroundColor: 'rgba(255, 255, 255, 0.55)',
      borderRadius: 6,
      transition: 'background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
      '&.pressed, &:hover': {
        backgroundColor: 'rgba(255, 255, 255, 0.7)',
      },
      '& > svg': {
        fontSize: 20,
      },
    },
  },
});

export const KeyboardInput = ({
  icon,
  keyCode,
  pressed,
  onButtonDown,
  onButtonUp,
}) => {
  const styles = useStyles();

  const handleClick = ({ down }) => {
    down && onButtonDown(keyCode);
    !down && onButtonUp(keyCode);
  };

  const useGestures = () => {
    return useDrag(handleClick)();
  };

  return (
    <div
      className={styles.button}
      {...useGestures()}
    >
      <div className={`${pressed ? 'pressed' : ''}`}>
        {icon}
      </div>
    </div>
  );
};
