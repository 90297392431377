import { useState } from 'react';
import { useParams } from 'react-router';
import { App } from '../3droom/App/App';
import {demo30} from "./30";
import {demo29} from "./29";
import {demo28} from "./28";
import {demo27} from "./27";
import {demo26} from "./26";
import {demo25} from "./25";
import {demo24} from "./24";
import {demo23} from "./23";
import {demo22} from "./22";
import {demo21} from "./21";
import {demo20} from "./20";
import {demo19} from "./19";
import {demo18} from "./18";
import {demo17} from "./17";
import {demo16} from "./16";
import {demo15} from "./15";
import {demo14} from "./14";
import {demo13} from "./13";
import {demo12} from "./12";
import {demo11} from "./11";
import {demo10} from "./10";
import {demo9} from "./09";
import {demo8} from "./08";
import {demo7} from "./07";
import {demo6} from "./06";
import {demo5} from "./05";
import {demo4} from "./04";
import {demo3} from "./03";
import {demo2} from "./02";
import {demo1} from "./01";
import {demo31} from "./31";
import {demo32} from "./32"

export const Demo3d = () => {
  const { id } = useParams();
  const [ data, setData ] = useState(scenes[id]);

  if (!data) return null;

  return <App
    data={data}
    adminMode={data.adminMode}
    onClose={() => console.log('close')}
    onChange={(json) => {
      console.log(json);
      setData(json);
    }}
    onMediaSelect={(type, callback, mode) => {
      switch (type) {
        case 'image':
          if (mode === 'multiple') {
            const images = [
              { dmsId: '013AhtPrnsxT', type: 'image', source: 'vm' },
              { dmsId: '013AhtPrnsxU', type: 'image', source: 'vm' },
              { dmsId: '013AhtPrnsxV', type: 'image', source: 'vm' },
              { dmsId: '013AhtPrnsxW', type: 'image', source: 'vm' },
              { dmsId: '013AhtPrnsxX', type: 'image', source: 'vm' },
              { dmsId: '013AhtPrnsxY', type: 'image', source: 'vm' },
              { dmsId: '013AhtPrnsxZ', type: 'image', source: 'vm' },
              { dmsId: '013AhtPrnsxa', type: 'image', source: 'vm' },
              { dmsId: '013AhtPrnsxb', type: 'image', source: 'vm' },
              { dmsId: '013AhtPrnsxc', type: 'image', source: 'vm' },
              { dmsId: '013AhtPrnt2s', type: 'image', source: 'vm' },
              { dmsId: '013AhtPrnt2t', type: 'image', source: 'vm' },
              { dmsId: '013AhtPrnt2u', type: 'image', source: 'vm' },
              { dmsId: '013AhtPrnt2v', type: 'image', source: 'vm' },
              { dmsId: '013AhtPrnt2w', type: 'image', source: 'vm' },
              { dmsId: '013AhtPrnt2x', type: 'image', source: 'vm' },
              { dmsId: '013AhtPrnt2y', type: 'image', source: 'vm' },
              { dmsId: '013AhtPrnt2z', type: 'image', source: 'vm' },
              { dmsId: '013AhtPrnt31', type: 'image', source: 'vm' },
              { dmsId: '013AhtPrnt32', type: 'image', source: 'vm' },
              { dmsId: '013AhtPrnt7H', type: 'image', source: 'vm' },
              { dmsId: '013AhtPrnt7J', type: 'image', source: 'vm' },
              { dmsId: '013AhtPrnt7K', type: 'image', source: 'vm' },
              { dmsId: '013AhtPrnt7L', type: 'image', source: 'vm' },
            ];
            callback(images);
          }
          else {
            const imageDms = [
              '013AhtPrnsxT',
              '013AhtPrnsxU',
              '013AhtPrnsxV',
              '013AhtPrnsxW',
              '013AhtPrnsxX',
              '013AhtPrnsxY',
              '013AhtPrnsxZ',
              '013AhtPrnsxa',
              '013AhtPrnsxb',
              '013AhtPrnsxc',
            ];
            callback({
              dmsId: imageDms[Math.floor(Math.random() * imageDms.length)],
              alt: 'Et tilfeldig bilde',
            });
          }
          break;
        case 'model':
          const modelDms = [
            // '0136NRjTvnvv',
            // '0136NRjSVVW7',
            // '0136NRjSVpz4',
            // '0136NRjSVqDC',
            '0138WvJrNUWY',
            // '013Akt8x1EL7',
          ];
          callback({
            dmsId: modelDms[Math.floor(Math.random() * modelDms.length)],
            alt: 'En tilfeldig modell',
          });
          break;
        case 'video':
          const videoDms = [
            '012uPXGEtNxY',
            '0136NvW9VTDQ',
          ];
          callback({
            dmsId: videoDms[Math.floor(Math.random() * videoDms.length)],
            alt: 'En tilfeldig video',
          });
          break;
        case 'audio':
          const audioDms = [
            '013AktUouFvW',
            '013AktUouFr7',
            '013AktUouFr3',
            '013AktN34DVw',
            '013AitqNDa5w',
          ];
          callback({
            dmsId: audioDms[Math.floor(Math.random() * audioDms.length)],
            alt: 'En tilfeldig lyd',
          });
          break;
        default:
          return null;
      }
    }}
  />
};

const scenes = {
  1: demo1,
  2: demo2,
  3: demo3,
  4: demo4,
  5: demo5,
  6: demo6,
  7: demo7,
  8: demo8,
  9: demo9,
  10: demo10,
  11: demo11,
  12: demo12,
  13: demo13,
  14: demo14,
  15: demo15,
  16: demo16,
  17: demo17,
  18: demo18,
  19: demo19,
  20: demo20,
  21: demo21,
  22: demo22,
  23: demo23,
  24: demo24,
  25: demo25,
  26: demo26,
  27: demo27,
  28: demo28,
  29: demo29,
  30: demo30,
  31: demo31,
  32: demo32
};
