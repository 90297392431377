import * as THREE from 'three';
import { SVGLoader } from 'three/examples/jsm/loaders/SVGLoader';

export const Flag = () => {
  const { paths } = new SVGLoader().parse(
    `<path d="M21.6673 2.66667H3.00065V0H0.333984V26.6667H3.00065V16H21.6673L19.0007 9.33333L21.6673 2.66667ZM13.6673 9.33333C13.6673 10.8 12.4673 12 11.0007 12C9.53398 12 8.33398 10.8 8.33398 9.33333C8.33398 7.86667 9.53398 6.66667 11.0007 6.66667C12.4673 6.66667 13.6673 7.86667 13.6673 9.33333Z" />`
  );
  const shape = SVGLoader.createShapes(paths[0]);

  return (
    <mesh
      scale={[1/300, 1/300, 1/300]}
      position={[0.335, 0.09, -0.01]}
      renderOrder={6}
    >
      <shapeGeometry args={[shape]} />
      <meshStandardMaterial
        color={0x6DCDB3}
        side={THREE.DoubleSide}
        transparent />
    </mesh>
  );
};
