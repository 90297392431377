import * as THREE from 'three';
import { Suspense, useEffect, useState } from 'react';
import { useThree } from '@react-three/fiber';
import { getSceneElementByName, getPlaneSize } from '../../helpers';
import { CursorTypes } from '../../helpers/enums';
import { RescaleButton } from '../../components/RescaleButton';

export const Buttons = ({
  position,
  rotation,
  scale,
  imageName,
  onRescale,
}) => {
  const { scene, camera } = useThree();
  const [cornerPositions, setCornerPositions] = useState();
  const distance = Math.max(camera.position.distanceTo(position), 1.5);

  useEffect(() => {
    const image = getSceneElementByName(scene.children, imageName);
    const imageSize = getPlaneSize(image);

    setCornerPositions(
      {
        topLeft: new THREE.Vector3(
          imageSize.width / -2,
          imageSize.height / 2,
          0.001,
        ),
        topRight: new THREE.Vector3(
          imageSize.width / 2,
          imageSize.height / 2,
          0.001,
        ),
        bottomLeft: new THREE.Vector3(
          imageSize.width / -2,
          imageSize.height / -2,
          0.001,
        ),
        bottomRight: new THREE.Vector3(
          imageSize.width / 2,
          imageSize.height / -2,
          0.001,
        ),
      }
    );
  }, [position, position.x, position.y, position.z, scale, imageName, scene.children]);

  return (
    <group
      name={`${imageName}-buttons`}
      position={[position.x, position.y, position.z]}
      rotation={[rotation.x, rotation.y, rotation.z]}
      visible={!!cornerPositions}
    >
      <Suspense fallback={null}>
        <RescaleButton
          position={cornerPositions?.topLeft}
          distance={distance}
          cursor={CursorTypes.NWSE_RESIZE}
          onMove={(point, last) => onRescale(point, 'topLeft', last)}
        />
        <RescaleButton
          position={cornerPositions?.topRight}
          distance={distance}
          cursor={CursorTypes.NESW_RESIZE}
          onMove={(point, last) => onRescale(point, 'topRight', last)}
        />
        <RescaleButton
          position={cornerPositions?.bottomLeft}
          distance={distance}
          cursor={CursorTypes.NESW_RESIZE}
          onMove={(point, last) => onRescale(point, 'bottomLeft', last)}
        />
        <RescaleButton
          position={cornerPositions?.bottomRight}
          distance={distance}
          cursor={CursorTypes.NWSE_RESIZE}
          onMove={(point, last) => onRescale(point, 'bottomRight', last)}
        />
      </Suspense>
    </group>
  );
};
