import cx from 'classnames';
import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles({
  question: {
    color: 'white',
    height: '100%',
  },
  questionText: {
    fontSize: 20,
    fontWeight: 'bold',
    margin: '0 0 20px 0',
  },
  questionOptions: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    minHeight: '80%',
    fontSize: 30,
  },
  questionOption: {
    flexBasis: 'calc(50% - 40px)',
    margin: '20px',
    justifyContent: 'center',
    flexDirection: 'column',
    cursor: 'pointer',
    '&:hover': {
      outline: '2px solid white',
    },
    '&.selected': {
      outline: '8px solid white',
    },
    '&.optionA': {
      backgroundColor: '#619c58',
      color: 'white',
    },
    '&.optionB': {
      backgroundColor: '#c20012',
      color: 'black',
    },
    '&.optionC': {
      backgroundColor: '#4485c1',
      color: 'white',
    },
    '&.optionD': {
      backgroundColor: '#d8842f',
      color: 'black',
    },
  },
  questionOptionContent: {
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
  },
});

export const Question = ({ question, answerClick, selectedAnswer }) => {
  const classes = useStyles();

  return (
    <div className={classes.question}>
      <div className={classes.questionText}>
        {question.id}. {question.question}
      </div>
      <div className={classes.questionOptions}>
        {['a', 'b', 'c', 'd'].map(option => (
          <div
            key={option}
            className={cx(
              classes.questionOption,
              `option${option.toUpperCase()}`,
              {
                selected: selectedAnswer === option,
              }
            )}
            onClick={() => answerClick(question.id, option)}
          >
            <div className={classes.questionOptionContent}>
              {question[option]}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};
