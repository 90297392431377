import {useState} from "react";
import {Box, TextField} from "@mui/material";

export const TextContent = ({text, onChange}) => {
    const [value, setValue] = useState(text)

    const valueChangeHandler = event => {
        setValue(event.target.value)
    }

    const blurHandler = () => {
        onChange(value)
    }

    return (
        <Box>
            <TextField
                variant='outlined'
                multiline
                rows={4}
                defaultValue={''}
                value={value}
                onChange={valueChangeHandler}
                onBlur={blurHandler}
            />
        </Box>
    )
}