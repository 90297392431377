import { AmbientLight } from './AmbientLights';
import { HemisphereLights } from './HemisphereLights';
import { SpotLights } from './Spotlights';
import { RectAreaLights } from './RectAreaLights';
import { useThreeDProps } from '../App/ThreeDContext';

export const Lights = () => {
  const { data } = useThreeDProps();
  const { lights } = data;

  return (
    <group>
      <AmbientLight lights={(lights ?? []).filter(l => l.type === 'ambient')} />
      <HemisphereLights lights={(lights ?? []).filter(l => l.type === 'hemisphere')} />
      <SpotLights lights={(lights ?? []).filter(l => l.type === 'spot')} />
      <RectAreaLights lights={(lights ?? []).filter(l => l.type === 'rectArea')} />
    </group>
  );
};
