import { useEffect, useRef } from 'react';

export const SpotLight = ({ intensity, position, shadowBias }) => {
  const light = useRef();
  const lightTarget = useRef();

  useEffect(() => {
    light.current.target = lightTarget.current;
  });

  return (
    <spotLight
      ref={light}
      castShadow
      intensity={intensity}
      position={position}
      penumbra={0.4}
      distance={10}
      decay={1.5}
      angle={1.4}
      shadow-bias={shadowBias}
      shadow-mapSize-height={1024}
      shadow-mapSize-width={1024}
    >
      <object3D ref={lightTarget} position={[0, -1, 0]} />
    </spotLight>
  );
};
