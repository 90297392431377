import { useEffect, useRef } from 'react';
import { centerModel } from '../../../helpers';
import { Flag } from './Flag';
import { Body } from './Body';
import { Outline } from './Outline';

export const Icon = ({ isHighlighted }) => {
  const ref = useRef();

  useEffect(() => {
    centerModel(ref.current);
  }, [ref]);

  return (
    <group position={[0, 0.5, 0]}>
      <group
        ref={ref}
        rotation={[0, Math.PI, Math.PI]}
      >
        <Body isHighlighted={isHighlighted} />
        <Outline isHighlighted={isHighlighted} />
        <Flag />
      </group>
    </group>
  );
};
