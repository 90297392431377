import * as THREE from 'three';
import { Text } from '@react-three/drei';

export const GroupIndexMarker = ({
  name,
  text,
  color,
  position,
  rotation,
  scale,
}) => {
  const linearColor = new THREE.Color().set(color).convertSRGBToLinear();

  return (
    <Text
      name={`${name}-gim`}
      text={text}
      position={position}
      rotation={rotation}
      scale={scale}
      fontSize={0.75}
      color={linearColor}
      strokeWidth={'2%'}
      strokeColor={0x000000}
      renderOrder={10}
      anchorY={'40%'}
    >
      <meshBasicMaterial />
    </Text>
  );
};
