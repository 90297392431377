import { useMemo } from 'react';
import { Tour } from './Tour';
import { TourMode } from '../../helpers/enums';
import { exitPointerLock, getExhibitionItems } from '../../helpers';
import { SET_SELECTED_TOUR, useThreeDDispatch, useThreeDProps, useThreeDState } from '../App/ThreeDContext';

export const Tours = () => {
  const { data, adminMode } = useThreeDProps();
  const { tours } = data;
  const { interactiveMode, selectedTour } = useThreeDState();
  const dispatch = useThreeDDispatch();
  const filteredTours = useMemo(() =>
    getExhibitionItems(tours, adminMode, interactiveMode),
    [tours, adminMode, interactiveMode]
  );

  if (selectedTour && selectedTour.mode === TourMode.ACTIVE) {
    return [];
  }

  const handleTourClick = (tour) => {
    if (!interactiveMode) return;
    exitPointerLock();
    dispatch({
      type: SET_SELECTED_TOUR,
      selectedTour: {
        tour: tour,
        mode: TourMode.PREVIEW,
      },
    });
  };

  return filteredTours.map((tour, index) => (
    <Tour
      {...tour}
      name={`Tour-${index}`}
      key={index}
      onClick={() => handleTourClick(tour)}
    />
  ));
};
