import * as THREE from 'three';
import { useEffect, useRef, useState } from 'react';
import { setCursor } from '../../helpers';
import { CursorTypes, ControlModes, CanvasItemTypes } from '../../helpers/enums';
import { getDmsStreamingUrl } from '@ekultur/dms-url-generator';
import { useThree } from '@react-three/fiber';
import { useGesture } from '@use-gesture/react';
import Hls from 'hls.js';
import { SET_HAS_AUDIO, useThreeDDispatch, useThreeDState } from '../App/ThreeDContext';

export const Video = ({
  url,
  dmsId,
  position,
  rotation,
  autoPlay,
  loop = true,
  name,
  width,
  height,
  volume = 1,
  editMode = false,
}) => {
  const { userInteracted, audioMute, hasAudio, controlMode } = useThreeDState();
  const dispatch = useThreeDDispatch();
  const { camera, scene } = useThree();
  const modelRef = useRef();
  const [ isPlaying, setIsPlaying ] = useState(autoPlay);
  const [ video, setVideo ] = useState(null);
  const [ audio, setAudio ] = useState(null);

  useEffect(() => {
    if (!video) {
      const vid = document.createElement('video');
      vid.crossOrigin = 'Anonymous';
      vid.currentTime = 0;
      vid.onended = () => setIsPlaying(false);
      !hasAudio && dispatch({ type: SET_HAS_AUDIO, hasAudio: true });

      if (dmsId) {
        const src=`https://ems.dimu.org/streaming/adaptive/${dmsId}/index.m3u8`

        if (Hls.isSupported()) {
          loadHls(src, vid);
        }
        else if (vid.canPlayType('application/vnd.apple.mpegurl')) {
          vid.src = src;
        }
      }
      else {
        vid.src = url;
      }

      setVideo(vid);
    }

    return () => {
      video && video.pause();
      video && video.remove();
    };
  }, [dmsId, url, video]);

  const loadHls = (src, vid) => {
    const hlsFragments = [];
    const hls = new Hls();
    hls.startLevel = -1;
    hls.config.maxMaxBufferLength = 1;
    hls.loadSource(src);
    hls.attachMedia(vid);

    hls.on(Hls.Events.FRAG_LOADED, (event, data) => {
      hlsFragments.push(data.frag);
    });

    hls.on(Hls.Events.LEVEL_SWITCHED, (event, data) => {
      const fragmentLevels = hlsFragments.map(fragment => fragment.level);
      const maxLevel = fragmentLevels.reduce((max, cur) => Math.max(max, cur), 0);

      hlsFragments.forEach((fragment, index) => {
        if (fragment.level < maxLevel) {
          hls.trigger(Hls.Events.BUFFER_FLUSHING, {startOffset: fragment.startPTS, endOffset: fragment.endPTS});
          hlsFragments.splice(index, 1);
        }
      });
    });
  };

  useEffect(() => {
    if (video && userInteracted && (!audio || video.src !== audio)) {
      const listener = new THREE.AudioListener();
      camera.add(listener);
      const positionalAudio = new THREE.PositionalAudio(listener);
      positionalAudio.setMediaElementSource(video);
      positionalAudio.position.copy(position);
      positionalAudio.rotation.copy(rotation);
      scene.add(positionalAudio);
      setAudio(video.src);
    }
  }, [userInteracted, position, rotation, scene, video, audio]);

  useEffect(() => {
    if (video) {
      video.loop = loop;
      video.volume = volume;
      !editMode && autoPlay && userInteracted && video.play();
    }
  }, [loop, volume, editMode, autoPlay, userInteracted, video]);

  useEffect(() => {
    video && (video.muted = audioMute);
  }, [audioMute, video]);

  const handleClick = ({ event, tap }) => {
    if (tap) {
      const pointerLockElement = document.pointerLockElement || document.mozPointerLockElement || document.webkitPointerLockElement;

      if (!(controlMode === ControlModes.POINTER_LOCK_CONTROL && !pointerLockElement)) {
        const intersection = event.intersections.reduce((acc, obj) => (obj.distance < acc.distance ? obj : acc));

        if (intersection.eventObject === event.object) {
          if (!isPlaying) {
            video.play();
            setIsPlaying(true);
            setCursor(CursorTypes.PAUSE);
          } else {
            video.pause();
            setIsPlaying(false);
            setCursor(CursorTypes.PLAY);
          }
        }
        else {
          setCursor(isPlaying
            ? CursorTypes.PAUSE
            : CursorTypes.PLAY);
        }
      }
    }
  };

  const handleHover = ({ first }) => {
    if (first) {
      setCursor(isPlaying
        ? CursorTypes.PAUSE
        : CursorTypes.PLAY);
    }
  };

  const useGestures = () => {
    const gestures = useGesture(
      {
        onDrag: handleClick,
        onHover: handleHover,
      },
      { drag: { filterTaps: true }}
    );
    return !editMode ? gestures() : {};
  };

  return (
    <group
      position={[position.x, position.y, position.z]}
      rotation={[rotation.x, rotation.y, rotation.z]}
    >
      <mesh
        ref={modelRef}
        name={name}
        renderOrder={3}
        userData={{
          itemName: name,
          itemType: CanvasItemTypes.VIDEO,
        }}
        {...useGestures()}
      >
        <planeGeometry args={[width, height]} />
        {video &&
          <meshStandardMaterial transparent>
            <videoTexture attach="map" args={[video]} />
            <videoTexture attach="emissiveMap" args={[video]} />
          </meshStandardMaterial>
        }
      </mesh>
    </group>
  );
};
