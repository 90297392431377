import * as Icon from '@mui/icons-material';
import {Box, IconButton, Typography} from '@mui/material';
import {SelectInput} from '../../components/SelectInput';
import {fontFamilyOptions, getFontWeightIndexByUrl, getFontWeightName} from '../../fonts';
import {TextContent} from "../text-settings/TextContent";
import {TextFontSize} from "../text-settings/TextFontSize";
import {useEffect} from "react";
import {TextLineHeight} from "../text-settings/TextLineHeight";
import {TextMargin} from "../text-settings/TextMargin";

export const ParagraphSettings = ({
                                      textItem,
                                      paragraph,
                                      paragraphIndex,
                                      onUpdate,
                                  }) => {
    const fontFamilyIndex = getFontWeightIndexByUrl(paragraph.fontFamily);

    useEffect(
        () => {
            return () => {
                console.debug('unmount')
            }
        }, []
    )

    const textAlignOptions = [
        {value: 'left', text: <Icon.FormatAlignLeft fontSize='small'/>},
        {value: 'center', text: <Icon.FormatAlignCenter fontSize='small'/>},
        {value: 'right', text: <Icon.FormatAlignRight fontSize='small'/>}
    ]

    const handleDelete = () => {
        textItem.paragraphs.splice(paragraphIndex, 1);
        onUpdate(textItem);
    };

    const handleMoveUp = () => {
        textItem.paragraphs.splice(paragraphIndex - 1, 0, textItem.paragraphs.splice(paragraphIndex, 1)[0]);
        onUpdate(textItem);
    };

    const handleMoveDown = () => {
        textItem.paragraphs.splice(paragraphIndex + 1, 0, textItem.paragraphs.splice(paragraphIndex, 1)[0]);
        onUpdate(textItem);
    };

    const handleFontSizeChange = (event) => {
        paragraph.fontSize = event.target.value;
        onUpdate(textItem);
    };

    const handleLineHeightChange = (event) => {
        paragraph.lineHeight = event.target.value;
        onUpdate(textItem);
    };

    const handleMarginChange = (event) => {
        paragraph.margin = paragraph.margin ?? {};
        paragraph.margin.top = event.target.value;
        paragraph.margin.bottom = event.target.value;
        onUpdate(textItem);
    };

    const handleFontFamilyChange = (event) => {
        const fontFamily = fontFamilyOptions.at(event.target.value);
        const fontWeight = fontFamily.weight.find(obj => obj.value === '400').url;

        if (paragraph.fontFamily !== fontWeight) {
            paragraph.fontFamily = fontWeight;
            onUpdate(textItem);
        }
    };

    const handleFontWeightChange = (event) => {
        if (paragraph.fontFamily !== event.target.value) {
            paragraph.fontFamily = event.target.value;
            onUpdate(textItem);
        }
    };

    const handleTextAlignChange = (event) => {
        if (paragraph.textAlign !== event.target.value) {
            paragraph.textAlign = event.target.value;
            onUpdate(textItem);
        }
    };

    const handleTextChange = newText => {
        if (paragraph.text !== newText) {
          paragraph.text = newText;
          onUpdate(textItem);
        }
    };

    return (
        <>
            <Box>
                <Box>
                    <Typography className='subtitle'>{`Paragraf ${paragraphIndex + 1}`}</Typography>
                    <IconButton
                        size='small'
                        disabled={textItem.paragraphs.length <= 1}
                        onClick={handleDelete}
                    >
                        <Icon.DeleteOutlined fontSize='inherit'/>
                    </IconButton>
                </Box>
                <Box>
                    <IconButton
                        size='small'
                        disabled={paragraphIndex === 0}
                        onClick={handleMoveUp}
                    >
                        <Icon.ArrowUpwardOutlined fontSize='small'/>
                    </IconButton>
                    <IconButton
                        size='small'
                        disabled={paragraphIndex + 1 === textItem.paragraphs.length}
                        onClick={handleMoveDown}
                    >
                        <Icon.ArrowDownwardOutlined fontSize='small'/>
                    </IconButton>
                </Box>
            </Box>
            <Box>
                <TextFontSize
                    fontSize={paragraph.fontSize}
                    onChange={handleFontSizeChange}
                />
                <TextLineHeight
                    lineHeight={paragraph.lineHeight}
                    onChange={handleLineHeightChange}
                />
                <TextMargin
                    value={paragraph.margin?.top ?? 0}
                    onChange={handleMarginChange}
                />
            </Box>
            <Box>
                <SelectInput
                    value={fontFamilyIndex}
                    options={fontFamilyOptions.map((obj, index) => ({value: index, text: obj.name}))}
                    maxHeight={215}
                    onChange={handleFontFamilyChange}
                />
            </Box>
            <Box>
                <SelectInput
                    value={paragraph.fontFamily}
                    options={fontFamilyOptions.at(fontFamilyIndex).weight.map(obj => ({
                        value: obj.url,
                        text: getFontWeightName(obj.value),
                    }))}
                    onChange={handleFontWeightChange}
                />
                <SelectInput
                    value={paragraph.textAlign ?? 'left'}
                    options={textAlignOptions}
                    onChange={handleTextAlignChange}
                />
            </Box>
            <TextContent
                text={paragraph.text}
                onChange={handleTextChange}
            />
        </>
    );
};
