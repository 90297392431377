import {useLineHeightOptions} from "../font/useLineHeightOptions";
import * as Icon from "@mui/icons-material";
import {SelectInput} from "../../components/SelectInput";

export const TextLineHeight = ({lineHeight, onChange}) => {
    const lineHeightOptions = useLineHeightOptions()

    return (
        <SelectInput
            value={lineHeight ?? 1}
            options={lineHeightOptions}
            icon={<Icon.FormatLineSpacing fontSize='small'/>}
            onChange={onChange}
        />
    )
}