import * as Icon from '@mui/icons-material';
import { Typography } from '@mui/material';
import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles({
  row: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: 8,
  },
  dragIcon: {
    color: 'rgba(0, 0, 0, 0.54)',
  },
});

export const Group = ({
  name,
  color,
}) => {
  const styles = useStyles();

  return (
    <div className={styles.row}>
      <Icon.DragIndicator fontSize="small" className={styles.dragIcon} />
      <Icon.Square sx={{color: color}} />
      <Typography>{name}</Typography>
    </div>
  );
};
