import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { DemoLinks } from './DemoLinks';
import { Demo3d } from './Demo3d';

export const Demo = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<DemoLinks />} />
        <Route path="room/:id" element={<Demo3d />} />
      </Routes>
    </BrowserRouter>
  );
};
