export const demo31 = {
    "audios": [
        {
            "boundToGrid": false,
            "canvasIndex": 5,
            "dmsId": "019EBuFnPRiCq",
            "interactive": true,
            "loop": false,
            "maxDistance": 15,
            "position": {
                "x": -6.9940000000000015,
                "y": 0.8405411829938045,
                "z": 16.829808548199715
            },
            "positioning": true,
            "rotation": {
                "_order": "XYZ",
                "_x": 0,
                "_y": 1.5707963267948966,
                "_z": 0,
                "isEuler": true
            },
            "scale": 1.3,
            "volume": 1
        },
        {
            "boundToGrid": false,
            "canvasIndex": 5,
            "dmsId": "019EBuFnPS3v6",
            "interactive": true,
            "loop": false,
            "maxDistance": 15,
            "position": {
                "x": -6.9940000000000015,
                "y": 3.322859305513733,
                "z": 13.699246847842206
            },
            "positioning": true,
            "rotation": {
                "_order": "XYZ",
                "_x": 0,
                "_y": 1.5707963267948966,
                "_z": 0,
                "isEuler": true
            },
            "scale": 1.5,
            "volume": 1
        },
        {
            "boundToGrid": false,
            "canvasIndex": 5,
            "dmsId": "019EBuFnQtgy6",
            "interactive": true,
            "loop": false,
            "maxDistance": 15,
            "position": {
                "x": -6.994,
                "y": 0.8054398441545532,
                "z": 8.693913792853234
            },
            "positioning": true,
            "rotation": {
                "_order": "XYZ",
                "_x": 0,
                "_y": 1.5707963267948966,
                "_z": 0,
                "isEuler": true
            },
            "scale": 1.5,
            "volume": 1
        },
        {
            "boundToGrid": false,
            "canvasIndex": 5,
            "dmsId": "019EBuFnQuhnp",
            "interactive": true,
            "loop": false,
            "maxDistance": 15,
            "position": {
                "x": -6.993999999999999,
                "y": 0.8073556240036805,
                "z": 6.195579409531481
            },
            "positioning": true,
            "rotation": {
                "_order": "XYZ",
                "_x": 0,
                "_y": 1.5707963267948966,
                "_z": 0,
                "isEuler": true
            },
            "scale": 1.5,
            "volume": 1
        },
        {
            "boundToGrid": false,
            "canvasIndex": 5,
            "dmsId": "019EBuFnSRhtP",
            "interactive": true,
            "loop": false,
            "maxDistance": 15,
            "position": {
                "x": -6.9940000000000015,
                "y": 0.8343767334595422,
                "z": 14.601358195937838
            },
            "positioning": true,
            "rotation": {
                "_order": "XYZ",
                "_x": 0,
                "_y": 1.5707963267948966,
                "_z": 0,
                "isEuler": true
            },
            "scale": 1.3,
            "volume": 1
        },
        {
            "boundToGrid": false,
            "canvasIndex": 5,
            "dmsId": "019EBujeWpUf8",
            "interactive": true,
            "loop": false,
            "maxDistance": 15,
            "position": {
                "x": -6.994000000000001,
                "y": 0.8237872573403013,
                "z": 11.995747608579169
            },
            "positioning": true,
            "rotation": {
                "_order": "XYZ",
                "_x": 0,
                "_y": 1.5707963267948966,
                "_z": 0,
                "isEuler": true
            },
            "scale": 1.3,
            "volume": 1
        },
        {
            "boundToGrid": false,
            "canvasIndex": 4,
            "dmsId": "019EBujeYJ85P",
            "interactive": true,
            "loop": false,
            "maxDistance": 15,
            "position": {
                "x": 6.988999999999999,
                "y": 1.0003440125151084,
                "z": 2.1571470456676574
            },
            "positioning": true,
            "rotation": {
                "_order": "XYZ",
                "_x": 0,
                "_y": -1.5707963267948966,
                "_z": 0,
                "isEuler": true
            },
            "scale": 1.5,
            "volume": 1
        },
        {
            "boundToGrid": false,
            "canvasIndex": 1,
            "dmsId": "019EBujebH7Ep",
            "interactive": true,
            "loop": false,
            "maxDistance": 15,
            "position": {
                "x": -1.9726321867648338,
                "y": 1.4356573578362335,
                "z": 19.983999999999998
            },
            "positioning": true,
            "rotation": {
                "_order": "XYZ",
                "_x": 0,
                "_y": 3.141592653589793,
                "_z": 0,
                "isEuler": true
            },
            "scale": 1.3,
            "volume": 1
        },
        {
            "boundToGrid": false,
            "canvasIndex": 5,
            "dmsId": "019EBujecmmFk",
            "interactive": true,
            "loop": false,
            "maxDistance": 15,
            "position": {
                "x": -6.993999999999999,
                "y": 0.8245348073253398,
                "z": 3.9014900743005434
            },
            "positioning": true,
            "rotation": {
                "_order": "XYZ",
                "_x": 0,
                "_y": 1.5707963267948966,
                "_z": 0,
                "isEuler": true
            },
            "scale": 1.5,
            "volume": 1
        },
        {
            "boundToGrid": false,
            "canvasIndex": 5,
            "dmsId": "019EBujeeGmD3",
            "interactive": true,
            "loop": false,
            "maxDistance": 15,
            "position": {
                "x": -6.993999999999999,
                "y": 0.8065830597649302,
                "z": 1.253678548060881
            },
            "positioning": true,
            "rotation": {
                "_order": "XYZ",
                "_x": 0,
                "_y": 1.5707963267948966,
                "_z": 0,
                "isEuler": true
            },
            "scale": 1.5,
            "volume": 1
        },
        {
            "boundToGrid": false,
            "canvasIndex": 4,
            "dmsId": "019EBukfV4aHv",
            "interactive": true,
            "loop": false,
            "maxDistance": 15,
            "position": {
                "x": 6.9890000000000025,
                "y": 1.0231799615010009,
                "z": 15.76471896655433
            },
            "positioning": true,
            "rotation": {
                "_order": "XYZ",
                "_x": 0,
                "_y": -1.5707963267948966,
                "_z": 0,
                "isEuler": true
            },
            "scale": 1.5,
            "volume": 1
        },
        {
            "boundToGrid": false,
            "canvasIndex": 4,
            "dmsId": "019EBukfZXtNT",
            "interactive": true,
            "loop": false,
            "maxDistance": 15,
            "position": {
                "x": 6.989,
                "y": 0.9923242123085014,
                "z": 6.942245133012522
            },
            "positioning": true,
            "rotation": {
                "_order": "XYZ",
                "_x": 0,
                "_y": -1.5707963267948966,
                "_z": 0,
                "isEuler": true
            },
            "scale": 1.5,
            "volume": 1
        },
        {
            "boundToGrid": false,
            "canvasIndex": 4,
            "dmsId": "019EBukfZYDvp",
            "interactive": true,
            "loop": false,
            "maxDistance": 15,
            "position": {
                "x": 6.989000000000001,
                "y": 2.81326840182356,
                "z": 10.74910900276031
            },
            "positioning": true,
            "rotation": {
                "_order": "XYZ",
                "_x": 0,
                "_y": -1.5707963267948966,
                "_z": 0,
                "isEuler": true
            },
            "scale": 1.5,
            "volume": 1
        },
        {
            "boundToGrid": false,
            "canvasIndex": 6,
            "dmsId": "019EBvEXiPHeG",
            "interactive": true,
            "loop": false,
            "maxDistance": 15,
            "position": {
                "x": -14.148940706717168,
                "y": 0.8039937463081763,
                "z": -19.968999999999998
            },
            "positioning": true,
            "rotation": {
                "_order": "XYZ",
                "_x": 0,
                "_y": 0,
                "_z": 0,
                "isEuler": true
            },
            "scale": 1.3,
            "volume": 1
        },
        {
            "boundToGrid": false,
            "canvasIndex": 7,
            "dmsId": "019EBvEXiPdCe",
            "interactive": true,
            "loop": false,
            "maxDistance": 15,
            "position": {
                "x": -14.802257322682454,
                "y": 0.8253742952508314,
                "z": -6.004
            },
            "positioning": true,
            "rotation": {
                "_order": "XYZ",
                "_x": 0,
                "_y": 3.141592653589793,
                "_z": 0,
                "isEuler": true
            },
            "scale": 1.3,
            "volume": 1
        },
        {
            "boundToGrid": false,
            "canvasIndex": 6,
            "dmsId": "019EBvEXiPxqS",
            "interactive": true,
            "loop": false,
            "maxDistance": 15,
            "position": {
                "x": -9.105681553923255,
                "y": 0.8170563004453619,
                "z": -19.968999999999998
            },
            "positioning": true,
            "rotation": {
                "_order": "XYZ",
                "_x": 0,
                "_y": 0,
                "_z": 0,
                "isEuler": true
            },
            "scale": 1.3,
            "volume": 1
        },
        {
            "boundToGrid": false,
            "canvasIndex": 9,
            "dmsId": "019EBvEXmLbAp",
            "interactive": true,
            "loop": false,
            "maxDistance": 15,
            "position": {
                "x": -4.1731552835582635,
                "y": 2.8367816950050515,
                "z": -19.979
            },
            "positioning": true,
            "rotation": {
                "_order": "XYZ",
                "_x": 0,
                "_y": 0,
                "_z": 0,
                "isEuler": true
            },
            "scale": 1.3,
            "volume": 1
        },
        {
            "boundToGrid": false,
            "canvasIndex": 8,
            "dmsId": "019EBvEXmMGMu",
            "interactive": true,
            "loop": false,
            "maxDistance": 15,
            "position": {
                "x": 6.986,
                "y": 0.8579746354946454,
                "z": -15.127259218034258
            },
            "positioning": true,
            "rotation": {
                "_order": "XYZ",
                "_x": 0,
                "_y": -1.5707963267948966,
                "_z": 0,
                "isEuler": true
            },
            "scale": 1.3,
            "volume": 1
        },
        {
            "boundToGrid": false,
            "canvasIndex": 11,
            "dmsId": "019EBvEXtmYFU",
            "interactive": true,
            "loop": false,
            "maxDistance": 15,
            "position": {
                "x": -6.989,
                "y": 1.3520926835980003,
                "z": -14.354477921564007
            },
            "positioning": true,
            "rotation": {
                "_order": "XYZ",
                "_x": 0,
                "_y": 1.5707963267948966,
                "_z": 0,
                "isEuler": true
            },
            "scale": 1.3,
            "volume": 1
        },
        {
            "boundToGrid": false,
            "canvasIndex": 6,
            "dmsId": "019EBvEeHQSUP",
            "interactive": true,
            "loop": false,
            "maxDistance": 15,
            "position": {
                "x": -27.127807689091213,
                "y": 0.8097399131341225,
                "z": -19.968999999999998
            },
            "positioning": true,
            "rotation": {
                "_order": "XYZ",
                "_x": 0,
                "_y": 0,
                "_z": 0,
                "isEuler": true
            },
            "scale": 1.3,
            "volume": 1
        },
        {
            "boundToGrid": false,
            "canvasIndex": 6,
            "dmsId": "019EBvEeJtRui",
            "interactive": true,
            "loop": false,
            "maxDistance": 15,
            "position": {
                "x": -19.106981120877435,
                "y": 0.8303000777587872,
                "z": -19.968999999999998
            },
            "positioning": true,
            "rotation": {
                "_order": "XYZ",
                "_x": 0,
                "_y": 0,
                "_z": 0,
                "isEuler": true
            },
            "scale": 1.3,
            "volume": 1
        },
        {
            "boundToGrid": false,
            "canvasIndex": 6,
            "dmsId": "019EBvEeJtm5q",
            "interactive": true,
            "loop": false,
            "maxDistance": 15,
            "position": {
                "x": -29.60661716041102,
                "y": 0.8183715922782167,
                "z": -19.968999999999998
            },
            "positioning": true,
            "rotation": {
                "_order": "XYZ",
                "_x": 0,
                "_y": 0,
                "_z": 0,
                "isEuler": true
            },
            "scale": 1.3,
            "volume": 1
        },
        {
            "boundToGrid": false,
            "canvasIndex": 6,
            "dmsId": "019EBvEeJtmhJ",
            "interactive": true,
            "loop": false,
            "maxDistance": 15,
            "position": {
                "x": -24.765890663756895,
                "y": 0.8176025177888842,
                "z": -19.968999999999998
            },
            "positioning": true,
            "rotation": {
                "_order": "XYZ",
                "_x": 0,
                "_y": 0,
                "_z": 0,
                "isEuler": true
            },
            "scale": 1.4,
            "volume": 1
        },
        {
            "boundToGrid": false,
            "canvasIndex": 11,
            "dmsId": "019EBvjQwTSDe",
            "interactive": true,
            "loop": false,
            "maxDistance": 15,
            "position": {
                "x": -6.988999999999999,
                "y": 1.3085176169705566,
                "z": -16.656332447688293
            },
            "positioning": true,
            "rotation": {
                "_order": "XYZ",
                "_x": 0,
                "_y": 1.5707963267948966,
                "_z": 0,
                "isEuler": true
            },
            "scale": 1.5,
            "volume": 1
        },
        {
            "boundToGrid": false,
            "canvasIndex": 11,
            "dmsId": "019EBvjQwTmPv",
            "interactive": true,
            "loop": false,
            "maxDistance": 15,
            "position": {
                "x": -6.988999999999999,
                "y": 1.3138373899757143,
                "z": -18.788657043829318
            },
            "positioning": true,
            "rotation": {
                "_order": "XYZ",
                "_x": 0,
                "_y": 1.5707963267948966,
                "_z": 0,
                "isEuler": true
            },
            "scale": 1.5,
            "volume": 1
        },
        {
            "boundToGrid": false,
            "canvasIndex": 6,
            "dmsId": "019EBvjR3PigA",
            "interactive": true,
            "loop": false,
            "maxDistance": 15,
            "position": {
                "x": -26.705356562609992,
                "y": 4.19251255460415,
                "z": -19.968999999999998
            },
            "positioning": true,
            "rotation": {
                "_order": "XYZ",
                "_x": 0,
                "_y": 0,
                "_z": 0,
                "isEuler": true
            },
            "scale": 1.5,
            "volume": 1
        },
        {
            "boundToGrid": false,
            "canvasIndex": 7,
            "dmsId": "019EBvjXQVxNh",
            "interactive": true,
            "loop": false,
            "maxDistance": 15,
            "position": {
                "x": -27.13305808193696,
                "y": 0.7956740561701814,
                "z": -6.0040000000000004
            },
            "positioning": true,
            "rotation": {
                "_order": "XYZ",
                "_x": 0,
                "_y": 3.141592653589793,
                "_z": 0,
                "isEuler": true
            },
            "scale": 1.5,
            "volume": 1
        }
    ],
    "canvases": [
        {
            "dimensions": {
                "cellSize": 0.5,
                "columns": 22,
                "rows": 12
            },
            "position": {
                "x": 1.5,
                "y": 3,
                "z": -5.58
            },
            "rotation": {
                "_order": "XYZ",
                "_x": 0,
                "_y": 0,
                "_z": 0,
                "isEuler": true
            },
            "type": "Wall"
        },
        {
            "dimensions": {
                "cellSize": 0.5,
                "columns": 28,
                "rows": 12
            },
            "position": {
                "x": 0,
                "y": 3,
                "z": 19.985
            },
            "rotation": {
                "_order": "XYZ",
                "_x": 0,
                "_y": 3.141592653589793,
                "_z": 0,
                "isEuler": true
            },
            "type": "Wall"
        },
        {
            "dimensions": {
                "cellSize": 0.5,
                "columns": 22,
                "rows": 12
            },
            "position": {
                "x": -7.408,
                "y": 3,
                "z": -14.495
            },
            "rotation": {
                "_order": "XYZ",
                "_x": 0,
                "_y": -1.5707963267948966,
                "_z": 0,
                "isEuler": true
            },
            "type": "Wall"
        },
        {
            "dimensions": {
                "cellSize": 0.5,
                "columns": 28,
                "rows": 12
            },
            "position": {
                "x": -32.99,
                "y": 3,
                "z": -13
            },
            "rotation": {
                "_order": "XYZ",
                "_x": 0,
                "_y": 1.5707963267948966,
                "_z": 0,
                "isEuler": true
            },
            "type": "Wall"
        },
        {
            "dimensions": {
                "cellSize": 0.5,
                "columns": 51,
                "rows": 12
            },
            "position": {
                "x": 6.99,
                "y": 3,
                "z": 7.2
            },
            "rotation": {
                "_order": "XYZ",
                "_x": 0,
                "_y": -1.5707963267948966,
                "_z": 0,
                "isEuler": true
            },
            "type": "Wall"
        },
        {
            "dimensions": {
                "cellSize": 0.5,
                "columns": 46,
                "rows": 12
            },
            "position": {
                "x": -6.995,
                "y": 3,
                "z": 8.51
            },
            "rotation": {
                "_order": "XYZ",
                "_x": 0,
                "_y": 1.5707963267948966,
                "_z": 0,
                "isEuler": true
            },
            "type": "Wall"
        },
        {
            "dimensions": {
                "cellSize": 0.5,
                "columns": 51,
                "rows": 12
            },
            "position": {
                "x": -20.2,
                "y": 3,
                "z": -19.97
            },
            "rotation": {
                "_order": "XYZ",
                "_x": 0,
                "_y": 0,
                "_z": 0,
                "isEuler": true
            },
            "type": "Wall"
        },
        {
            "dimensions": {
                "cellSize": 0.5,
                "columns": 46,
                "rows": 12
            },
            "position": {
                "x": -21.5,
                "y": 3,
                "z": -6.003
            },
            "rotation": {
                "_order": "XYZ",
                "_x": 0,
                "_y": 3.141592653589793,
                "_z": 0,
                "isEuler": true
            },
            "type": "Wall"
        },
        {
            "dimensions": {
                "cellSize": 0.5,
                "columns": 28,
                "rows": 12
            },
            "position": {
                "x": 6.987,
                "y": 3,
                "z": -12.995
            },
            "rotation": {
                "_order": "XYZ",
                "_x": 0,
                "_y": -1.5707963267948966,
                "_z": 0,
                "isEuler": true
            },
            "type": "Wall"
        },
        {
            "dimensions": {
                "cellSize": 0.5,
                "columns": 28,
                "rows": 12
            },
            "position": {
                "x": -0.002,
                "y": 3,
                "z": -19.98
            },
            "rotation": {
                "_order": "XYZ",
                "_x": 0,
                "_y": 0,
                "_z": 0,
                "isEuler": true
            },
            "type": "Wall"
        },
        {
            "dimensions": {
                "cellSize": 0.5,
                "columns": 22,
                "rows": 12
            },
            "position": {
                "x": 1.5,
                "y": 3,
                "z": -6
            },
            "rotation": {
                "_order": "XYZ",
                "_x": 0,
                "_y": 3.141592653589793,
                "_z": 0,
                "isEuler": true
            },
            "type": "Wall"
        },
        {
            "dimensions": {
                "cellSize": 0.5,
                "columns": 22,
                "rows": 12
            },
            "position": {
                "x": -6.99,
                "y": 3,
                "z": -14.495
            },
            "rotation": {
                "_order": "XYZ",
                "_x": 0,
                "_y": 1.5707963267948966,
                "_z": 0,
                "isEuler": true
            },
            "type": "Wall"
        },
        {
            "dimensions": {
                "cellSize": 0.5,
                "columns": 14,
                "rows": 8
            },
            "position": {
                "x": -1.29,
                "y": 2,
                "z": -11.67
            },
            "rotation": {
                "_order": "XYZ",
                "_x": 0,
                "_y": 2.365,
                "_z": 0,
                "isEuler": true
            },
            "type": "Wall"
        },
        {
            "dimensions": {
                "cellSize": 0.5,
                "columns": 14,
                "rows": 8
            },
            "position": {
                "x": -1.47,
                "y": 2,
                "z": -11.48
            },
            "rotation": {
                "_order": "XYZ",
                "_x": 0,
                "_y": -0.7766,
                "_z": 0,
                "isEuler": true
            },
            "type": "Wall"
        },
        {
            "dimensions": {
                "cellSize": 0.5,
                "columns": 8,
                "rows": 12
            },
            "position": {
                "x": -8.49,
                "y": 3,
                "z": -4.5
            },
            "rotation": {
                "_order": "XYZ",
                "_x": 0,
                "_y": 2.356,
                "_z": 0,
                "isEuler": true
            },
            "type": "Wall"
        },
        {
            "dimensions": {
                "cellSize": 0.5,
                "columns": 15,
                "rows": 34
            },
            "position": {
                "x": 0,
                "y": 0.01,
                "z": 8
            },
            "rotation": {
                "_order": "XYZ",
                "_x": -1.5707963267948966,
                "_y": 0,
                "_z": 0,
                "isEuler": true
            },
            "type": "Floor"
        }
    ],
    "directionOfView": {
        "_order": "XYZ",
        "_x": 0,
        "_y": 0,
        "_z": -0.001,
        "isEuler": true
    },
    "groups": [],
    "images": [
        {
            "addCanvasDepth": true,
            "boundToGrid": false,
            "canvasIndex": 4,
            "dmsId": "019EBtk7PASDu",
            "position": {
                "x": 6.989000000000003,
                "y": 1.8514282118868637,
                "z": -3.2036199431046604
            },
            "rotation": {
                "_order": "XYZ",
                "_x": 0,
                "_y": -1.5707963267948966,
                "_z": 0,
                "isEuler": true
            },
            "scale": 1.6800462256742987,
            "viewerData": {
                "model": {
                    "dmsId": "019EBtk7PASDu",
                    "scale": 2,
                    "type": "ImageSingle"
                }
            }
        },
        {
            "addCanvasDepth": true,
            "boundToGrid": false,
            "canvasIndex": 4,
            "dmsId": "019EBtk7PASDo",
            "position": {
                "x": 6.989000000000004,
                "y": 1.8491244293402407,
                "z": -0.918540055549001
            },
            "rotation": {
                "_order": "XYZ",
                "_x": 0,
                "_y": -1.5707963267948966,
                "_z": 0,
                "isEuler": true
            },
            "scale": 1.694762345384498,
            "viewerData": {
                "model": {
                    "dmsId": "019EBtk7PASDo",
                    "scale": 2,
                    "type": "ImageSingle"
                }
            }
        },
        {
            "addCanvasDepth": true,
            "boundToGrid": false,
            "canvasIndex": 4,
            "dmsId": "019EBtk7PAmFL",
            "position": {
                "x": 6.989000000000004,
                "y": 1.8463661319521036,
                "z": 3.7930944639478117
            },
            "rotation": {
                "_order": "XYZ",
                "_x": 0,
                "_y": -1.5707963267948966,
                "_z": 0,
                "isEuler": true
            },
            "scale": 1.677017722059502,
            "viewerData": {
                "model": {
                    "dmsId": "019EBtk7PAmFL",
                    "scale": 2,
                    "type": "ImageSingle"
                }
            }
        },
        {
            "addCanvasDepth": true,
            "boundToGrid": false,
            "canvasIndex": 4,
            "dmsId": "019EBtk7S6PyP",
            "position": {
                "x": 6.9890000000000025,
                "y": 1.8384371720981194,
                "z": 6.234714911539338
            },
            "rotation": {
                "_order": "XYZ",
                "_x": 0,
                "_y": -1.5707963267948966,
                "_z": 0,
                "isEuler": true
            },
            "scale": 1.7205239791087654,
            "viewerData": {
                "model": {
                    "dmsId": "019EBtk7S6PyP",
                    "scale": 2,
                    "type": "ImageSingle"
                }
            }
        },
        {
            "addCanvasDepth": true,
            "boundToGrid": false,
            "canvasIndex": 4,
            "dmsId": "019EBtk7PAmUX",
            "position": {
                "x": 6.9890000000000025,
                "y": 2.08604242422463,
                "z": 12.087433287782865
            },
            "rotation": {
                "_order": "XYZ",
                "_x": 0,
                "_y": -1.5707963267948966,
                "_z": 0,
                "isEuler": true
            },
            "scale": 2.3285253159929877,
            "viewerData": {
                "model": {
                    "dmsId": "019EBtk7PAmUX",
                    "scale": 2,
                    "type": "ImageSingle"
                }
            }
        },
        {
            "addCanvasDepth": true,
            "boundToGrid": false,
            "canvasIndex": 4,
            "dmsId": "019EBtk7PAmUU",
            "position": {
                "x": 6.98900000000003,
                "y": 1.848086293125712,
                "z": 17.59421008471488
            },
            "rotation": {
                "_order": "XYZ",
                "_x": 0,
                "_y": -1.5707963267948966,
                "_z": 0,
                "isEuler": true
            },
            "scale": 1.672057587222859,
            "viewerData": {
                "model": {
                    "dmsId": "019EBtk7PAmUU",
                    "scale": 2,
                    "type": "ImageSingle"
                }
            }
        },
        {
            "addCanvasDepth": true,
            "boundToGrid": false,
            "canvasIndex": 5,
            "dmsId": "019EBtk7PAmKn",
            "position": {
                "x": -6.993999999999993,
                "y": 1.977692226556629,
                "z": 9.758431405406983
            },
            "rotation": {
                "_order": "XYZ",
                "_x": 0,
                "_y": 1.5707963267948966,
                "_z": 0,
                "isEuler": true
            },
            "scale": 2.5,
            "viewerData": {
                "model": {
                    "dmsId": "019EBtk7PAmKn",
                    "scale": 2,
                    "type": "ImageSingle"
                }
            }
        },
        {
            "addCanvasDepth": true,
            "boundToGrid": false,
            "canvasIndex": 5,
            "dmsId": "019EBtk7QfS2K",
            "position": {
                "x": -6.994000000000002,
                "y": 1.6716051217535046,
                "z": 6.835455333295978
            },
            "rotation": {
                "_order": "XYZ",
                "_x": 0,
                "_y": 1.5707963267948966,
                "_z": 0,
                "isEuler": true
            },
            "scale": 1.661411558561951,
            "viewerData": {
                "model": {
                    "dmsId": "019EBtk7QfS2K",
                    "scale": 2,
                    "type": "ImageSingle"
                }
            }
        },
        {
            "addCanvasDepth": true,
            "boundToGrid": false,
            "canvasIndex": 7,
            "dmsId": "019EBtk7PASDs",
            "position": {
                "x": -11.63898341036587,
                "y": 1.7505522760219994,
                "z": -6.004000000000004
            },
            "rotation": {
                "_order": "XYZ",
                "_x": 0,
                "_y": 3.141592653589793,
                "_z": 0,
                "isEuler": true
            },
            "scale": 1.9215265814663605,
            "viewerData": {
                "model": {
                    "dmsId": "019EBtk7PASDs",
                    "scale": 2,
                    "type": "ImageSingle"
                }
            }
        },
        {
            "addCanvasDepth": true,
            "boundToGrid": false,
            "canvasIndex": 7,
            "dmsId": "019EBtk7PASJL",
            "position": {
                "x": -14.018630499874815,
                "y": 1.7483210639205466,
                "z": -6.003999999999995
            },
            "rotation": {
                "_order": "XYZ",
                "_x": 0,
                "_y": 3.141592653589793,
                "_z": 0,
                "isEuler": true
            },
            "scale": 1.9052829294895643,
            "viewerData": {
                "model": {
                    "dmsId": "019EBtk7PASJL",
                    "scale": 2,
                    "type": "ImageSingle"
                }
            }
        },
        {
            "addCanvasDepth": true,
            "boundToGrid": false,
            "canvasIndex": 7,
            "dmsId": "019EBtk7PAS9W",
            "position": {
                "x": -16.438823910424837,
                "y": 1.73898395289521,
                "z": -6.003999999999993
            },
            "rotation": {
                "_order": "XYZ",
                "_x": 0,
                "_y": 3.141592653589793,
                "_z": 0,
                "isEuler": true
            },
            "scale": 1.8734852800621555,
            "viewerData": {
                "model": {
                    "dmsId": "019EBtk7PAS9W",
                    "scale": 2,
                    "type": "ImageSingle"
                }
            }
        },
        {
            "addCanvasDepth": true,
            "boundToGrid": false,
            "canvasIndex": 7,
            "dmsId": "019EBtk7PAm6S",
            "position": {
                "x": -18.923829826224594,
                "y": 1.748988486080458,
                "z": -6.003999999999998
            },
            "rotation": {
                "_order": "XYZ",
                "_x": 0,
                "_y": 3.141592653589793,
                "_z": 0,
                "isEuler": true
            },
            "scale": 1.9148309376631392,
            "viewerData": {
                "model": {
                    "dmsId": "019EBtk7PAm6S",
                    "scale": 2,
                    "type": "ImageSingle"
                }
            }
        },
        {
            "addCanvasDepth": true,
            "boundToGrid": false,
            "canvasIndex": 7,
            "dmsId": "019EBtk7PAS51",
            "position": {
                "x": -21.546306200048427,
                "y": 2.431615218987579,
                "z": -6.003999999999997
            },
            "rotation": {
                "_order": "XYZ",
                "_x": 0,
                "_y": 3.141592653589793,
                "_z": 0,
                "isEuler": true
            },
            "scale": 2.842053096815798,
            "viewerData": {
                "model": {
                    "dmsId": "019EBtk7PAS51",
                    "scale": 2,
                    "type": "ImageSingle"
                }
            }
        },
        {
            "addCanvasDepth": true,
            "boundToGrid": false,
            "canvasIndex": 7,
            "dmsId": "019EBtk7PAmKe",
            "position": {
                "x": -24.038706469668796,
                "y": 1.73834595474479,
                "z": -6.003999999999991
            },
            "rotation": {
                "_order": "XYZ",
                "_x": 0,
                "_y": 3.141592653589793,
                "_z": 0,
                "isEuler": true
            },
            "scale": 1.89391146867278,
            "viewerData": {
                "model": {
                    "dmsId": "019EBtk7PAmKe",
                    "scale": 2,
                    "type": "ImageSingle"
                }
            }
        },
        {
            "addCanvasDepth": true,
            "boundToGrid": false,
            "canvasIndex": 7,
            "dmsId": "019EBtk7PASJH",
            "position": {
                "x": -26.387064039195515,
                "y": 1.715524789266807,
                "z": -6.003999999999978
            },
            "rotation": {
                "_order": "XYZ",
                "_x": 0,
                "_y": 3.141592653589793,
                "_z": 0,
                "isEuler": true
            },
            "scale": 1.7731191453316943,
            "viewerData": {
                "model": {
                    "dmsId": "019EBtk7PASJH",
                    "scale": 2,
                    "type": "ImageSingle"
                }
            }
        },
        {
            "addCanvasDepth": true,
            "boundToGrid": false,
            "canvasIndex": 7,
            "dmsId": "019EBtk7PASJD",
            "position": {
                "x": -28.80662327808675,
                "y": 1.7432649749201787,
                "z": -6.003999999999996
            },
            "rotation": {
                "_order": "XYZ",
                "_x": 0,
                "_y": 3.141592653589793,
                "_z": 0,
                "isEuler": true
            },
            "scale": 1.8994385985213253,
            "viewerData": {
                "model": {
                    "dmsId": "019EBtk7PASJD",
                    "scale": 2,
                    "type": "ImageSingle"
                }
            }
        },
        {
            "addCanvasDepth": true,
            "boundToGrid": false,
            "canvasIndex": 7,
            "dmsId": "019EBtk7S6Ptw",
            "position": {
                "x": -31.115330982737824,
                "y": 1.6917280724353536,
                "z": -6.003999999999998
            },
            "rotation": {
                "_order": "XYZ",
                "_x": 0,
                "_y": 3.141592653589793,
                "_z": 0,
                "isEuler": true
            },
            "scale": 1.7505350706060785,
            "viewerData": {
                "model": {
                    "dmsId": "019EBtk7S6Ptw",
                    "scale": 2,
                    "type": "ImageSingle"
                }
            }
        },
        {
            "addCanvasDepth": true,
            "boundToGrid": false,
            "canvasIndex": 6,
            "dmsId": "019EBtk7PASJF",
            "position": {
                "x": -22.792497245537497,
                "y": 2.0824546520650657,
                "z": -19.969
            },
            "rotation": {
                "_order": "XYZ",
                "_x": 0,
                "_y": 0,
                "_z": 0,
                "isEuler": true
            },
            "scale": 2.755408504850867,
            "viewerData": {
                "model": {
                    "dmsId": "019EBtk7PASJF",
                    "scale": 2,
                    "type": "ImageSingle"
                }
            }
        },
        {
            "addCanvasDepth": true,
            "boundToGrid": false,
            "canvasIndex": 6,
            "dmsId": "019EBtk7PAmUZ",
            "position": {
                "x": -14.929114376049545,
                "y": 1.746361263071214,
                "z": -19.969
            },
            "rotation": {
                "_order": "XYZ",
                "_x": 0,
                "_y": 0,
                "_z": 0,
                "isEuler": true
            },
            "scale": 1.9368962410148036,
            "viewerData": {
                "model": {
                    "dmsId": "019EBtk7PAmUZ",
                    "scale": 2,
                    "type": "ImageSingle"
                }
            }
        },
        {
            "addCanvasDepth": true,
            "boundToGrid": false,
            "canvasIndex": 6,
            "dmsId": "019EBtk7PAmAt",
            "position": {
                "x": -17.41515359371576,
                "y": 1.7465969703193922,
                "z": -19.969
            },
            "rotation": {
                "_order": "XYZ",
                "_x": 0,
                "_y": 0,
                "_z": 0,
                "isEuler": true
            },
            "scale": 1.938826024025452,
            "viewerData": {
                "model": {
                    "dmsId": "019EBtk7PAmAt",
                    "scale": 2,
                    "type": "ImageSingle"
                }
            }
        },
        {
            "addCanvasDepth": true,
            "boundToGrid": false,
            "canvasIndex": 6,
            "dmsId": "019EBtk7PASJK",
            "position": {
                "x": -9.911098553563816,
                "y": 1.7501287911441854,
                "z": -19.969
            },
            "rotation": {
                "_order": "XYZ",
                "_x": 0,
                "_y": 0,
                "_z": 0,
                "isEuler": true
            },
            "scale": 1.8959055245581964,
            "viewerData": {
                "model": {
                    "dmsId": "019EBtk7PASJK",
                    "scale": 2,
                    "type": "ImageSingle"
                }
            }
        },
        {
            "addCanvasDepth": true,
            "boundToGrid": false,
            "canvasIndex": 6,
            "dmsId": "019EBtk7S6Pfe",
            "position": {
                "x": -12.435549041803165,
                "y": 1.7501979237825858,
                "z": -19.969
            },
            "rotation": {
                "_order": "XYZ",
                "_x": 0,
                "_y": 0,
                "_z": 0,
                "isEuler": true
            },
            "scale": 1.9121638436848865,
            "viewerData": {
                "model": {
                    "dmsId": "019EBtk7S6Pfe",
                    "scale": 2,
                    "type": "ImageSingle"
                }
            }
        },
        {
            "addCanvasDepth": true,
            "boundToGrid": false,
            "canvasIndex": 9,
            "dmsId": "019EBtk7PAmKj",
            "position": {
                "x": 3.739346723736145,
                "y": 1.9882791580158208,
                "z": -19.979
            },
            "rotation": {
                "_order": "XYZ",
                "_x": 0,
                "_y": 0,
                "_z": 0,
                "isEuler": true
            },
            "scale": 2.3939699803003416,
            "viewerData": {
                "model": {
                    "dmsId": "019EBtk7PAmKj",
                    "scale": 2,
                    "type": "ImageSingle"
                }
            }
        },
        {
            "addCanvasDepth": true,
            "boundToGrid": false,
            "canvasIndex": 9,
            "dmsId": "019EBtk7PAS9S",
            "position": {
                "x": -2.7783859215346243,
                "y": 1.9987458753353686,
                "z": -19.979
            },
            "rotation": {
                "_order": "XYZ",
                "_x": 0,
                "_y": 0,
                "_z": 0,
                "isEuler": true
            },
            "scale": 2.423069607647227,
            "viewerData": {
                "model": {
                    "dmsId": "019EBtk7PAS9S",
                    "scale": 2,
                    "type": "ImageSingle"
                }
            }
        },
        {
            "addCanvasDepth": true,
            "boundToGrid": false,
            "canvasIndex": 8,
            "dmsId": "019EBtk7PAS56",
            "position": {
                "x": 6.986000000000006,
                "y": 2.0052681325482498,
                "z": -16.487633778132427
            },
            "rotation": {
                "_order": "XYZ",
                "_x": 0,
                "_y": -1.5707963267948966,
                "_z": 0,
                "isEuler": true
            },
            "scale": 2.962185638079497,
            "viewerData": {
                "model": {
                    "dmsId": "019EBtk7PAS56",
                    "scale": 2,
                    "type": "ImageSingle"
                }
            }
        },
        {
            "addCanvasDepth": true,
            "boundToGrid": false,
            "canvasIndex": 8,
            "dmsId": "019EBtk7PAmAp",
            "position": {
                "x": 6.986000000000024,
                "y": 1.9886136257182523,
                "z": -12.743931182386314
            },
            "rotation": {
                "_order": "XYZ",
                "_x": 0,
                "_y": -1.5707963267948966,
                "_z": 0,
                "isEuler": true
            },
            "scale": 2.985164660836307,
            "viewerData": {
                "model": {
                    "dmsId": "019EBtk7PAmAp",
                    "scale": 2,
                    "type": "ImageSingle"
                }
            }
        },
        {
            "addCanvasDepth": true,
            "boundToGrid": false,
            "canvasIndex": 8,
            "dmsId": "019EBtk7PAmQ4",
            "position": {
                "x": 6.9860000000000015,
                "y": 2.0031281874143283,
                "z": -9.329720891814862
            },
            "rotation": {
                "_order": "XYZ",
                "_x": 0,
                "_y": -1.5707963267948966,
                "_z": 0,
                "isEuler": true
            },
            "scale": 2.6605675877596733,
            "viewerData": {
                "model": {
                    "dmsId": "019EBtk7PAmQ4",
                    "scale": 2,
                    "type": "ImageSingle"
                }
            }
        },
        {
            "addCanvasDepth": true,
            "boundToGrid": false,
            "canvasIndex": 13,
            "dmsId": "019EBtk7PAmKj",
            "position": {
                "x": -2.533301320056844,
                "y": 2.047073764707081,
                "z": -12.523351894298568
            },
            "rotation": {
                "_order": "XYZ",
                "_x": 0,
                "_y": -0.7766,
                "_z": 0,
                "isEuler": true
            },
            "scale": 3.4999999999999787,
            "viewerData": {
                "model": {
                    "dmsId": "019EBtk7PAmKj",
                    "scale": 2,
                    "type": "ImageSingle"
                }
            }
        },
        {
            "addCanvasDepth": false,
            "boundToGrid": false,
            "canvasIndex": 0,
            "dmsId": "019EBtkE18tog",
            "position": {
                "x": 3.781538355575554,
                "y": 2.983949419931855,
                "z": -5.579000000000003
            },
            "rotation": {
                "_order": "XYZ",
                "_x": 0,
                "_y": 0,
                "_z": 0,
                "isEuler": true
            },
            "scale": 6.436923288849286,
            "viewerData": {
                "model": {
                    "dmsId": "019EBtkE18tog",
                    "scale": 2,
                    "type": "ImageSingle"
                }
            }
        },
        {
            "addCanvasDepth": false,
            "boundToGrid": false,
            "canvasIndex": 0,
            "dmsId": "019EBtkE19ZmW",
            "position": {
                "x": -1.6818823529413538,
                "y": 3.0000000000000018,
                "z": -5.579000000000007
            },
            "rotation": {
                "_order": "XYZ",
                "_x": 0,
                "_y": 0,
                "_z": 0,
                "isEuler": true
            },
            "scale": 6.000000000000128,
            "viewerData": {
                "model": {
                    "dmsId": "019EBtkE19ZmW",
                    "scale": 2,
                    "type": "ImageSingle"
                }
            }
        },
        {
            "addCanvasDepth": false,
            "boundToGrid": false,
            "canvasIndex": 1,
            "dmsId": "019EBtkLLn8kd",
            "position": {
                "x": 2.6506765067649574,
                "y": 3,
                "z": 19.983999999999998
            },
            "rotation": {
                "_order": "XYZ",
                "_x": 0,
                "_y": 3.141592653589793,
                "_z": 0,
                "isEuler": true
            },
            "scale": 8.698646986470088,
            "viewerData": {
                "model": {
                    "dmsId": "019EBtkLLn8kd",
                    "scale": 2,
                    "type": "ImageSingle"
                }
            }
        },
        {
            "addCanvasDepth": true,
            "boundToGrid": false,
            "canvasIndex": 4,
            "dmsId": "019EBtkLLoUq6",
            "position": {
                "x": 6.9890000000000345,
                "y": 2.692982296789718,
                "z": 9.110112814293908
            },
            "rotation": {
                "_order": "XYZ",
                "_x": 0,
                "_y": -1.5707963267948966,
                "_z": 0,
                "isEuler": true
            },
            "scale": 3.0156739540599187,
            "viewerData": {
                "model": {
                    "dmsId": "019EBtkLLoUq6",
                    "scale": 2,
                    "type": "ImageSingle"
                }
            }
        },
        {
            "addCanvasDepth": true,
            "boundToGrid": false,
            "canvasIndex": 4,
            "dmsId": "019EBtkLLpV3d",
            "position": {
                "x": 6.989000000000096,
                "y": 1.840103661383259,
                "z": 1.4761999558803707
            },
            "rotation": {
                "_order": "XYZ",
                "_x": 0,
                "_y": -1.5707963267948966,
                "_z": 0,
                "isEuler": true
            },
            "scale": 1.6803406561996685,
            "viewerData": {
                "model": {
                    "dmsId": "019EBtkLLpV3d",
                    "scale": 2,
                    "type": "ImageSingle"
                }
            }
        },
        {
            "addCanvasDepth": true,
            "boundToGrid": false,
            "canvasIndex": 4,
            "dmsId": "019EBtkLLpq8x",
            "position": {
                "x": 6.989000000000014,
                "y": 1.8470528123123273,
                "z": 15.109354376208051
            },
            "rotation": {
                "_order": "XYZ",
                "_x": 0,
                "_y": -1.5707963267948966,
                "_z": 0,
                "isEuler": true
            },
            "scale": 1.6415791390609769,
            "viewerData": {
                "model": {
                    "dmsId": "019EBtkLLpq8x",
                    "scale": 2,
                    "type": "ImageSingle"
                }
            }
        },
        {
            "addCanvasDepth": true,
            "boundToGrid": false,
            "canvasIndex": 5,
            "dmsId": "019EBtkLNHUBz",
            "position": {
                "x": -6.994000000000024,
                "y": 1.5891803957660173,
                "z": 17.45561535190599
            },
            "rotation": {
                "_order": "XYZ",
                "_x": 0,
                "_y": 1.5707963267948966,
                "_z": 0,
                "isEuler": true
            },
            "scale": 1.490483936906148,
            "viewerData": {
                "model": {
                    "dmsId": "019EBtkLNHUBz",
                    "scale": 2,
                    "type": "ImageSingle"
                }
            }
        },
        {
            "addCanvasDepth": true,
            "boundToGrid": false,
            "canvasIndex": 5,
            "dmsId": "019EBuFnSQ2QN",
            "position": {
                "x": -6.994000000000005,
                "y": 1.583947756298437,
                "z": 15.253281596915114
            },
            "rotation": {
                "_order": "XYZ",
                "_x": 0,
                "_y": 1.5707963267948966,
                "_z": 0,
                "isEuler": true
            },
            "scale": 1.5,
            "viewerData": {
                "model": {
                    "dmsId": "019EBuFnSQ2QN",
                    "scale": 2,
                    "type": "ImageSingle"
                }
            }
        },
        {
            "addCanvasDepth": true,
            "boundToGrid": false,
            "canvasIndex": 5,
            "dmsId": "019EBuFnTtgeW",
            "position": {
                "x": -6.994000000000001,
                "y": 1.7757800152769256,
                "z": 12.881357217494708
            },
            "rotation": {
                "_order": "XYZ",
                "_x": 0,
                "_y": 1.5707963267948966,
                "_z": 0,
                "isEuler": true
            },
            "scale": 1.991,
            "viewerData": {
                "model": {
                    "dmsId": "019EBuFnTtgeW",
                    "scale": 2,
                    "type": "ImageSingle"
                }
            }
        },
        {
            "addCanvasDepth": true,
            "boundToGrid": false,
            "canvasIndex": 5,
            "dmsId": "019EBujecm6fw",
            "position": {
                "x": -6.993999999999998,
                "y": 1.68631605528484,
                "z": 4.613463640589273
            },
            "rotation": {
                "_order": "XYZ",
                "_x": 0,
                "_y": 1.5707963267948966,
                "_z": 0,
                "isEuler": true
            },
            "scale": 1.7322990164492365,
            "viewerData": {
                "model": {
                    "dmsId": "019EBujecm6fw",
                    "scale": 2,
                    "type": "ImageSingle"
                }
            }
        },
        {
            "addCanvasDepth": true,
            "boundToGrid": false,
            "canvasIndex": 5,
            "dmsId": "019EBujeeF5j6",
            "position": {
                "x": -6.993999999999998,
                "y": 1.8562398830952855,
                "z": 2.1535352538761217
            },
            "rotation": {
                "_order": "XYZ",
                "_x": 0,
                "_y": 1.5707963267948966,
                "_z": 0,
                "isEuler": true
            },
            "scale": 2.182,
            "viewerData": {
                "model": {
                    "dmsId": "019EBujeeF5j6",
                    "scale": 2,
                    "type": "ImageSingle"
                }
            }
        },
        {
            "addCanvasDepth": true,
            "boundToGrid": false,
            "canvasIndex": 5,
            "dmsId": "019EBujehEjcz",
            "position": {
                "x": -6.993999999999996,
                "y": 1.8435626571203088,
                "z": -0.9116332019807167
            },
            "rotation": {
                "_order": "XYZ",
                "_x": 0,
                "_y": 1.5707963267948966,
                "_z": 0,
                "isEuler": true
            },
            "scale": 2.1285300872290156,
            "viewerData": {
                "model": {
                    "dmsId": "019EBujehEjcz",
                    "scale": 2,
                    "type": "ImageSingle"
                }
            }
        },
        {
            "addCanvasDepth": true,
            "boundToGrid": false,
            "canvasIndex": 11,
            "dmsId": "019EBvEXqots8",
            "position": {
                "x": -6.988999999999946,
                "y": 2.0917715178143883,
                "z": -13.740047728371879
            },
            "rotation": {
                "_order": "XYZ",
                "_x": 0,
                "_y": 1.5707963267948966,
                "_z": 0,
                "isEuler": true
            },
            "scale": 1.5,
            "viewerData": {
                "model": {
                    "dmsId": "019EBvEXqots8",
                    "scale": 2,
                    "type": "ImageSingle"
                }
            }
        },
        {
            "addCanvasDepth": true,
            "boundToGrid": false,
            "canvasIndex": 11,
            "dmsId": "019EBvEXqpu9z",
            "position": {
                "x": -6.988999999999982,
                "y": 2.090050373029006,
                "z": -16.047640589504773
            },
            "rotation": {
                "_order": "XYZ",
                "_x": 0,
                "_y": 1.5707963267948966,
                "_z": 0,
                "isEuler": true
            },
            "scale": 1.5,
            "viewerData": {
                "model": {
                    "dmsId": "019EBvEXqpu9z",
                    "scale": 2,
                    "type": "ImageSingle"
                }
            }
        },
        {
            "addCanvasDepth": true,
            "boundToGrid": false,
            "canvasIndex": 11,
            "dmsId": "019EBvEXsGDCE",
            "position": {
                "x": -6.989000000000005,
                "y": 2.0808930006258928,
                "z": -18.246008038693088
            },
            "rotation": {
                "_order": "XYZ",
                "_x": 0,
                "_y": 1.5707963267948966,
                "_z": 0,
                "isEuler": true
            },
            "scale": 1.483187613689238,
            "viewerData": {
                "model": {
                    "dmsId": "019EBvEXsGDCE",
                    "scale": 2,
                    "type": "ImageSingle"
                }
            }
        },
        {
            "addCanvasDepth": true,
            "boundToGrid": false,
            "canvasIndex": 6,
            "dmsId": "019EBvEeHQ6zb",
            "position": {
                "x": -27.933212511270728,
                "y": 1.7505788709177637,
                "z": -19.968999999999998
            },
            "rotation": {
                "_order": "XYZ",
                "_x": 0,
                "_y": 0,
                "_z": 0,
                "isEuler": true
            },
            "scale": 1.9329823017835337,
            "viewerData": {
                "model": {
                    "dmsId": "019EBvEeHQ6zb",
                    "scale": 2,
                    "type": "ImageSingle"
                }
            }
        },
        {
            "addCanvasDepth": true,
            "boundToGrid": false,
            "canvasIndex": 6,
            "dmsId": "019EBvEeJs68n",
            "position": {
                "x": -19.915591681016632,
                "y": 1.7507067575393158,
                "z": -19.968999999999998
            },
            "rotation": {
                "_order": "XYZ",
                "_x": 0,
                "_y": 0,
                "_z": 0,
                "isEuler": true
            },
            "scale": 1.9171731757841959,
            "viewerData": {
                "model": {
                    "dmsId": "019EBvEeJs68n",
                    "scale": 2,
                    "type": "ImageSingle"
                }
            }
        },
        {
            "addCanvasDepth": true,
            "boundToGrid": false,
            "canvasIndex": 6,
            "dmsId": "019EBvEeJsR5h",
            "position": {
                "x": -30.446666649150355,
                "y": 1.7518553957928567,
                "z": -19.968999999999998
            },
            "rotation": {
                "_order": "XYZ",
                "_x": 0,
                "_y": 0,
                "_z": 0,
                "isEuler": true
            },
            "scale": 1.984556802081072,
            "viewerData": {
                "model": {
                    "dmsId": "019EBvEeJsR5h",
                    "scale": 2,
                    "type": "ImageSingle"
                }
            }
        },
        {
            "addCanvasDepth": false,
            "boundToGrid": false,
            "canvasIndex": 3,
            "dmsId": "019EBvjQuvm5q",
            "position": {
                "x": -32.989000000000004,
                "y": 2.9999999999999996,
                "z": -12.995327102803522
            },
            "rotation": {
                "_order": "XYZ",
                "_x": 0,
                "_y": 1.5707963267948966,
                "_z": 0,
                "isEuler": true
            },
            "scale": 13.990654205607036,
            "viewerData": {
                "model": {
                    "dmsId": "019EBvjQuvm5q",
                    "scale": 2,
                    "type": "ImageSingle"
                }
            }
        },
        {
            "addCanvasDepth": true,
            "boundToGrid": false,
            "canvasIndex": 6,
            "dmsId": "019EBvjXQVxT4",
            "position": {
                "x": -25.521510867273342,
                "y": 1.7474305819248863,
                "z": -19.968999999999998
            },
            "rotation": {
                "_order": "XYZ",
                "_x": 0,
                "_y": 0,
                "_z": 0,
                "isEuler": true
            },
            "scale": 1.8955052184804793,
            "viewerData": {
                "model": {
                    "dmsId": "019EBvjXQVxT4",
                    "scale": 2,
                    "type": "ImageSingle"
                }
            }
        },
        {
            "addCanvasDepth": false,
            "boundToGrid": false,
            "canvasIndex": 2,
            "dmsId": "019EBvjXQXxt9",
            "position": {
                "x": -7.409000000000007,
                "y": 2.9827878103835,
                "z": -14.495
            },
            "rotation": {
                "_order": "XYZ",
                "_x": 0,
                "_y": -1.5707963267948966,
                "_z": 0,
                "isEuler": true
            },
            "scale": 10.999999999999998,
            "viewerData": {
                "model": {
                    "dmsId": "019EBvjXQXxt9",
                    "scale": 2,
                    "type": "ImageSingle"
                }
            }
        },
        {
            "addCanvasDepth": true,
            "boundToGrid": false,
            "canvasIndex": 9,
            "dmsId": "019EBvjXZQYa3",
            "position": {
                "x": 0.5460698907865926,
                "y": 1.9898842330122344,
                "z": -19.979
            },
            "rotation": {
                "_order": "XYZ",
                "_x": 0,
                "_y": 0,
                "_z": 0,
                "isEuler": true
            },
            "scale": 2.4003319821284133,
            "viewerData": {
                "model": {
                    "dmsId": "019EBvjXZQYa3",
                    "scale": 2,
                    "type": "ImageSingle"
                }
            }
        }
    ],
    "lights": [
        {
            "intensity": 0.95,
            "type": "ambient"
        }
    ],
    "roomModelDmsId": "019EBwEQi34nL",
    "settings": {
        "colors": [
            {
                "key": 0,
                "value": null
            },
            {
                "key": 1,
                "value": null
            },
            {
                "key": 2,
                "value": null
            },
            {
                "key": 3,
                "value": null
            }
        ]
    },
    "startPosition": {
        "x": 0,
        "y": 1.75,
        "z": 18
    },
    "texts": [
        {
            "boundToGrid": false,
            "canvasIndex": 1,
            "color": "#171717",
            "maxWidth": 3,
            "paragraphs": [
                {
                    "fontFamily": "https://dms-cf-01.dimu.org/file/019EBtkSxnGyG",
                    "fontSize": 0.4,
                    "text": "Niels Juel (1884 - 1959)"
                },
                {
                    "fontFamily": "https://dms-cf-01.dimu.org/file/019EBtkSxmweC",
                    "fontSize": 0.15,
                    "lineHeight": 1.4,
                    "margin": {
                        "bottom": 0.2,
                        "top": 0.2
                    },
                    "text": "Godseieren Niels Juel fra Hurdal var en av Norges første hobbyfotografer. Her står han øverst i bildet."
                },
                {
                    "fontFamily": "https://dms-cf-01.dimu.org/file/019EBtkSxmweC",
                    "fontSize": 0.15,
                    "lineHeight": 1.4,
                    "margin": {
                        "bottom": 0,
                        "top": 0
                    },
                    "text": "Juel tok hundrevis av personlige bilder av familien, venner og gjester i løpet av livet. Bildene hans gir et unikt innblikk i hverdagslivet og fest for mer enn 100 år siden."
                }
            ],
            "position": {
                "x": -4.128421692273417,
                "y": 3.826036950777744,
                "z": 19.984
            },
            "rotation": {
                "_order": "XYZ",
                "_x": 0,
                "_y": 3.141592653589793,
                "_z": 0,
                "isEuler": true
            },
            "textAlign": "left"
        },
        {
            "boundToGrid": true,
            "canvasIndex": 4,
            "color": "#171717",
            "maxWidth": 5,
            "paragraphs": [
                {
                    "fontFamily": "https://dms-cf-01.dimu.org/file/019EBtkSxnGyG",
                    "fontSize": 0.6,
                    "text": "Fritid og fest"
                }
            ],
            "position": {
                "x": 6.989,
                "y": 4,
                "z": -1.596
            },
            "rotation": {
                "_order": "XYZ",
                "_x": 0,
                "_y": -1.5707963267948966,
                "_z": 0,
                "isEuler": true
            },
            "textAlign": "left"
        },
        {
            "boundToGrid": true,
            "canvasIndex": 4,
            "color": "#171717",
            "maxWidth": 4.5,
            "paragraphs": [
                {
                    "fontFamily": "https://dms-cf-01.dimu.org/file/019EBtkSxmweC",
                    "fontSize": 0.15,
                    "lineHeight": 1.5,
                    "text": "Fritid – Hva hadde du gjort på fritiden hvis du hverken hadde mobil eller internett? Kanskje kan du få noen ideer av å se hva Niels Juel og hans venner og fylte tiden sin med."
                }
            ],
            "position": {
                "x": 6.988999999999997,
                "y": 3,
                "z": -1.7999999999999998
            },
            "rotation": {
                "_order": "XYZ",
                "_x": 0,
                "_y": -1.5707963267948966,
                "_z": 0,
                "isEuler": true
            },
            "textAlign": "left"
        },
        {
            "boundToGrid": false,
            "canvasIndex": 4,
            "color": "#171717",
            "maxWidth": 1.4274001245051942,
            "paragraphs": [
                {
                    "fontFamily": "https://dms-cf-01.dimu.org/file/019EBtkSxmweC",
                    "fontSize": 0.05,
                    "lineHeight": 1.5,
                    "text": "Skiløp i Hurdal! Her poserer utøver nr. 2 før startskuddet går. "
                },
                {
                    "fontFamily": "https://dms-cf-01.dimu.org/file/019EBtkSxmweC",
                    "fontSize": 0.05,
                    "lineHeight": 1.5,
                    "margin": {},
                    "text": "Bilde: 0239-045:0143. Tatt ca. 1925."
                }
            ],
            "position": {
                "x": 6.989000000000003,
                "y": 1.0773903107186678,
                "z": -3.32269880109983
            },
            "rotation": {
                "_order": "XYZ",
                "_x": 0,
                "_y": -1.5707963267948966,
                "_z": 0,
                "isEuler": true
            },
            "textAlign": "left"
        },
        {
            "boundToGrid": false,
            "canvasIndex": 4,
            "color": "#171717",
            "maxWidth": 1.5157982014841866,
            "paragraphs": [
                {
                    "fontFamily": "https://dms-cf-01.dimu.org/file/019EBtkSxmweC",
                    "fontSize": 0.05,
                    "lineHeight": 1.5,
                    "text": "Ukjent kvinne fordyper seg i en bok. "
                },
                {
                    "fontFamily": "https://dms-cf-01.dimu.org/file/019EBtkSxmweC",
                    "fontSize": 0.05,
                    "lineHeight": 1.5,
                    "margin": {},
                    "text": "Bilde: 0239-045:0167. Tatt ca. 1920."
                }
            ],
            "position": {
                "x": 6.989000000000017,
                "y": 1.071666527816925,
                "z": -0.9924123471037332
            },
            "rotation": {
                "_order": "XYZ",
                "_x": 0,
                "_y": -1.5707963267948966,
                "_z": 0,
                "isEuler": true
            },
            "textAlign": "left"
        },
        {
            "boundToGrid": false,
            "canvasIndex": 4,
            "color": "#171717",
            "maxWidth": 1.3299160824439835,
            "paragraphs": [
                {
                    "fontFamily": "https://dms-cf-01.dimu.org/file/019EBtkSxmweC",
                    "fontSize": 0.05,
                    "lineHeight": 1.5,
                    "text": "På dette bildet sitter Nils Strand med trekkspillet sitt oppi en båt, sammen med Niels Juel, og en ukjent dame. Gården til Niels Juel ligger rett med Hurdalsjøen, og derfor er det ikke utenkelig at de titt og ofte tok seg turer ut i båt på sjøen for å slappe litt av. \nBilde 0239-045:0185 , ca. 1910."
                }
            ],
            "position": {
                "x": 6.989000000000046,
                "y": 0.9276513787342173,
                "z": 1.3040125697410074
            },
            "rotation": {
                "_order": "XYZ",
                "_x": 0,
                "_y": -1.5707963267948966,
                "_z": 0,
                "isEuler": true
            },
            "textAlign": "left"
        },
        {
            "boundToGrid": false,
            "canvasIndex": 4,
            "color": "#171717",
            "maxWidth": 1.2508300562195225,
            "paragraphs": [
                {
                    "fontFamily": "https://dms-cf-01.dimu.org/file/019EBtkSxmweC",
                    "fontSize": 0.05,
                    "lineHeight": 1.5,
                    "text": "En kvinne på ski i skogen i Hurdal. "
                },
                {
                    "fontFamily": "https://fonts.gstatic.com/s/dmsans/v11/rP2Hp2ywxg089UriOZQ.ttf",
                    "fontSize": 0.05,
                    "margin": {},
                    "text": "Bilde: 0239-045:0232, ca. 1925."
                }
            ],
            "position": {
                "x": 6.989000000000006,
                "y": 1.074894995413258,
                "z": 3.5933962914837894
            },
            "rotation": {
                "_order": "XYZ",
                "_x": 0,
                "_y": -1.5707963267948966,
                "_z": 0,
                "isEuler": true
            },
            "textAlign": "left"
        },
        {
            "boundToGrid": false,
            "canvasIndex": 4,
            "color": "#171717",
            "maxWidth": 1.3453016355911922,
            "paragraphs": [
                {
                    "fontFamily": "https://dms-cf-01.dimu.org/file/019EBtkSxmweC",
                    "fontSize": 0.05,
                    "lineHeight": 1.5,
                    "text": "Noen dager må man bare legge seg ned i gresset sammen med gode venner."
                },
                {
                    "fontFamily": "https://dms-cf-01.dimu.org/file/019EBtkLaA3Qa",
                    "fontSize": 0.05,
                    "lineHeight": 1.5,
                    "margin": {},
                    "text": "Bilde: 0239-045:0271, ca. 1920."
                }
            ],
            "position": {
                "x": 6.989000000000023,
                "y": 1.0185578008196594,
                "z": 6.054214657414205
            },
            "rotation": {
                "_order": "XYZ",
                "_x": 0,
                "_y": -1.5707963267948966,
                "_z": 0,
                "isEuler": true
            },
            "textAlign": "left"
        },
        {
            "boundToGrid": false,
            "canvasIndex": 4,
            "color": "#171717",
            "maxWidth": 1.930741269564132,
            "paragraphs": [
                {
                    "fontFamily": "https://dms-cf-01.dimu.org/file/019EBtkSxmweC",
                    "fontSize": 0.05,
                    "lineHeight": 1.5,
                    "text": "Niels Juel var glad i utkledning og fest. Her er han flott utkledd og klar for karneval!  "
                },
                {
                    "fontFamily": "https://dms-cf-01.dimu.org/file/019EBtkLaA3Qa",
                    "fontSize": 0.05,
                    "margin": {},
                    "text": "Bilde: 0239-045:0361, ca. 1930."
                }
            ],
            "position": {
                "x": 6.989000000000033,
                "y": 1.0675865359911403,
                "z": 8.939081367616335
            },
            "rotation": {
                "_order": "XYZ",
                "_x": 0,
                "_y": -1.5707963267948966,
                "_z": 0,
                "isEuler": true
            },
            "textAlign": "left"
        },
        {
            "boundToGrid": false,
            "canvasIndex": 4,
            "color": "#171717",
            "maxWidth": 1.86792568604448,
            "paragraphs": [
                {
                    "fontFamily": "https://dms-cf-01.dimu.org/file/019EBtkSxmweC",
                    "fontSize": 0.05,
                    "lineHeight": 1.5,
                    "text": "Niels Juel var ikke den eneste som likte karneval. Her er de en hel gjeng utkledde – tre rojale damer og en konge i bakgrunnen."
                },
                {
                    "fontFamily": "https://dms-cf-01.dimu.org/file/019EBtkLaA3Qa",
                    "fontSize": 0.05,
                    "margin": {},
                    "text": " Bilde: 0239-045:0362, ca. 1930."
                }
            ],
            "position": {
                "x": 6.989000000000042,
                "y": 1.0672108825323194,
                "z": 11.874595939773721
            },
            "rotation": {
                "_order": "XYZ",
                "_x": 0,
                "_y": -1.5707963267948966,
                "_z": 0,
                "isEuler": true
            },
            "textAlign": "left"
        },
        {
            "boundToGrid": false,
            "canvasIndex": 4,
            "color": "#171717",
            "maxWidth": 1.1990402281827937,
            "paragraphs": [
                {
                    "fontFamily": "https://dms-cf-01.dimu.org/file/019EBtkSxmweC",
                    "fontSize": 0.05,
                    "lineHeight": 1.5,
                    "text": "En avslappet kvinne blir fotografert på gressplenen foran et av husene på Viken gård. "
                },
                {
                    "fontFamily": "https://dms-cf-01.dimu.org/file/019EBtkLaA3Qa",
                    "fontSize": 0.05,
                    "margin": {},
                    "text": "Bilde: 0239-045:0508, ca. 1913."
                }
            ],
            "position": {
                "x": 6.989000000000008,
                "y": 1.0786509307178045,
                "z": 14.896556511229084
            },
            "rotation": {
                "_order": "XYZ",
                "_x": 0,
                "_y": -1.5707963267948966,
                "_z": 0,
                "isEuler": true
            },
            "textAlign": "left"
        },
        {
            "boundToGrid": false,
            "canvasIndex": 4,
            "color": "#171717",
            "maxWidth": 1.4191672622482017,
            "paragraphs": [
                {
                    "fontFamily": "https://fonts.gstatic.com/s/dmsans/v11/rP2Hp2ywxg089UriOZQ.ttf",
                    "fontSize": 0.05,
                    "text": "Det ser ut som om Hurdals egen syklubb er samlet i en av skogene på Niels Juels tomter. De driver med både hekling, brodering og sying."
                }
            ],
            "position": {
                "x": 6.989000000000071,
                "y": 1.0579788677082662,
                "z": 17.469060884070764
            },
            "rotation": {
                "_order": "XYZ",
                "_x": 0,
                "_y": -1.5707963267948966,
                "_z": 0,
                "isEuler": true
            },
            "textAlign": "left"
        },
        {
            "boundToGrid": false,
            "canvasIndex": 5,
            "color": "#171717",
            "maxWidth": 5,
            "paragraphs": [
                {
                    "fontFamily": "https://dms-cf-01.dimu.org/file/019EBtkSxnGyG",
                    "fontSize": 0.6,
                    "text": "Nære og kjære"
                }
            ],
            "position": {
                "x": -6.99400000000001,
                "y": 4.069060297800539,
                "z": 15.644518102338179
            },
            "rotation": {
                "_order": "XYZ",
                "_x": 0,
                "_y": 1.5707963267948966,
                "_z": 0,
                "isEuler": true
            },
            "textAlign": "left"
        },
        {
            "boundToGrid": false,
            "canvasIndex": 5,
            "color": "#171717",
            "maxWidth": 4.18492014171963,
            "paragraphs": [
                {
                    "fontFamily": "https://dms-cf-01.dimu.org/file/019EBtkSxmweC",
                    "fontSize": 0.15,
                    "lineHeight": 1.4,
                    "text": "Juel dokumenterte stadig både familien, venner, sommer- og vintergjester, arbeidere som jobbet på Viken Gård, og ikke mist de flotte dyrene som bodde på gården. Av alle vennene til Niels er det herr Strand som går mest igjen i fotografiene. "
                }
            ],
            "position": {
                "x": -6.994000000000005,
                "y": 3.0723700337721414,
                "z": 16.03979961184352
            },
            "rotation": {
                "_order": "XYZ",
                "_x": 0,
                "_y": 1.5707963267948966,
                "_z": 0,
                "isEuler": true
            },
            "textAlign": "left"
        },
        {
            "boundToGrid": false,
            "canvasIndex": 5,
            "color": "#171717",
            "maxWidth": 1.1190358120116533,
            "paragraphs": [
                {
                    "fontFamily": "https://dms-cf-01.dimu.org/file/019EBtkSxmweC",
                    "fontSize": 0.05,
                    "lineHeight": 1.5,
                    "text": "Niels Juel var glad i sykkelturer, og her deler han gleden med vennen Nils Strand."
                },
                {
                    "fontFamily": "https://dms-cf-01.dimu.org/file/019EBtkLaA3Qa",
                    "fontSize": 0.05,
                    "lineHeight": 1.5,
                    "margin": {},
                    "text": "Bilde 0239-045:0007, ca. 1910. "
                }
            ],
            "position": {
                "x": -6.994000000000004,
                "y": 0.8527425950201555,
                "z": 17.649726342504152
            },
            "rotation": {
                "_order": "XYZ",
                "_x": 0,
                "_y": 1.5707963267948966,
                "_z": 0,
                "isEuler": true
            },
            "textAlign": "left"
        },
        {
            "boundToGrid": false,
            "canvasIndex": 5,
            "color": "#171717",
            "maxWidth": 1.0189219524556883,
            "paragraphs": [
                {
                    "fontFamily": "https://dms-cf-01.dimu.org/file/019EBtkSxmweC",
                    "fontSize": 0.05,
                    "lineHeight": 1.5,
                    "text": "Niels Juel og Nils Strand tar en velfortjent pause fra dagens arbeid. "
                },
                {
                    "fontFamily": "https://fonts.gstatic.com/s/dmsans/v11/rP2Hp2ywxg089UriOZQ.ttf",
                    "fontSize": 0.05,
                    "lineHeight": 1.5,
                    "margin": {},
                    "text": "Bilde 0239-045:0042, ca. 1910."
                }
            ],
            "position": {
                "x": -6.993999999999956,
                "y": 0.8520783348795495,
                "z": 15.490638901601606
            },
            "rotation": {
                "_order": "XYZ",
                "_x": 0,
                "_y": 1.5707963267948966,
                "_z": 0,
                "isEuler": true
            },
            "textAlign": "left"
        },
        {
            "boundToGrid": false,
            "canvasIndex": 5,
            "color": "#171717",
            "maxWidth": 1.544681970352777,
            "paragraphs": [
                {
                    "fontFamily": "https://dms-cf-01.dimu.org/file/019EBtkSxmweC",
                    "fontSize": 0.05,
                    "lineHeight": 1.5,
                    "text": "Niels Juel sitter omgitt av familien sin som han var så glad i. Fremst i midten sitter hans far, Niels Juel, som ifølge Juel selv «var et godt og elskverdig menneske». Til høyre sitter tante Billa, og til venstre sitter tante Nenne. Bak ser du moren, Petra. Niels Juel «hadde alltid vært gladere i henne en nogen annen»."
                },
                {
                    "fontFamily": "https://dms-cf-01.dimu.org/file/019EBtkLaA3Qa",
                    "fontSize": 0.05,
                    "lineHeight": 1.5,
                    "margin": {},
                    "text": "Bilde: 0239-045:0351, ca. 1920."
                }
            ],
            "position": {
                "x": -6.994000000000008,
                "y": 0.718780215309573,
                "z": 13.104755421408813
            },
            "rotation": {
                "_order": "XYZ",
                "_x": 0,
                "_y": 1.5707963267948966,
                "_z": 0,
                "isEuler": true
            },
            "textAlign": "left"
        },
        {
            "boundToGrid": false,
            "canvasIndex": 5,
            "color": "#171717",
            "maxWidth": 1.8770467300284541,
            "paragraphs": [
                {
                    "fontFamily": "https://dms-cf-01.dimu.org/file/019EBtkSxmweC",
                    "fontSize": 0.05,
                    "lineHeight": 1.5,
                    "text": "Niels Juel og Nils Strand hygger seg i en av stuene på Viken gård. De røyker pipe, og hjemmebrenten står på bordet. "
                },
                {
                    "fontFamily": "https://dms-cf-01.dimu.org/file/019EBtkLaA3Qa",
                    "fontSize": 0.05,
                    "lineHeight": 1.5,
                    "margin": {},
                    "text": "Bilde: 0239-045:0080, ca. 1910."
                }
            ],
            "position": {
                "x": -6.993999999999968,
                "y": 0.84298810621224,
                "z": 10.065813241678716
            },
            "rotation": {
                "_order": "XYZ",
                "_x": 0,
                "_y": 1.5707963267948966,
                "_z": 0,
                "isEuler": true
            },
            "textAlign": "left"
        },
        {
            "boundToGrid": false,
            "canvasIndex": 5,
            "color": "#171717",
            "maxWidth": 1.254567278257145,
            "paragraphs": [
                {
                    "fontFamily": "https://dms-cf-01.dimu.org/file/019EBtkSxmweC",
                    "fontSize": 0.05,
                    "lineHeight": 1.5,
                    "text": "Dette brudeparet er Ragna og Aksel Grundset. De var gode venner av Niels Juel. Han tok både brudebildene og bilder fra selve bryllupsfeiringen deres. "
                },
                {
                    "fontFamily": "https://dms-cf-01.dimu.org/file/019EBtkLaA3Qa",
                    "fontSize": 0.05,
                    "lineHeight": 1.5,
                    "margin": {},
                    "text": "Bilde: 0239-045:0178, ca. 1910."
                }
            ],
            "position": {
                "x": -6.994,
                "y": 0.8007984034305746,
                "z": 7.038809670438416
            },
            "rotation": {
                "_order": "XYZ",
                "_x": 0,
                "_y": 1.5707963267948966,
                "_z": 0,
                "isEuler": true
            },
            "textAlign": "left"
        },
        {
            "boundToGrid": false,
            "canvasIndex": 5,
            "color": "#171717",
            "maxWidth": 1.341339670116113,
            "paragraphs": [
                {
                    "fontFamily": "https://dms-cf-01.dimu.org/file/019EBtkSxmweC",
                    "fontSize": 0.05,
                    "lineHeight": 1.5,
                    "text": "Niels Juel ute en vinterdag i Hurdal, sammen med Leif Fagernæs og Harald Bjørnstad. "
                },
                {
                    "fontFamily": "https://dms-cf-01.dimu.org/file/019EBtkLaA3Qa",
                    "fontSize": 0.05,
                    "lineHeight": 1.5,
                    "margin": {},
                    "text": "Bilde 0239-045:0089, ca. 1925."
                }
            ],
            "position": {
                "x": -6.994000000000003,
                "y": 0.8047736593710741,
                "z": 4.813483978534769
            },
            "rotation": {
                "_order": "XYZ",
                "_x": 0,
                "_y": 1.5707963267948966,
                "_z": 0,
                "isEuler": true
            },
            "textAlign": "left"
        },
        {
            "boundToGrid": false,
            "canvasIndex": 5,
            "color": "#171717",
            "maxWidth": 1.6929449698456134,
            "paragraphs": [
                {
                    "fontFamily": "https://dms-cf-01.dimu.org/file/019EBtkSxmweC",
                    "fontSize": 0.05,
                    "lineHeight": 1.5,
                    "text": "Folk på Viken Gård satte stor pris på kjæledyr, og i fotosamlingen til Niels Juel finner vi mange av dem, både hunder og katter."
                },
                {
                    "fontFamily": "https://dms-cf-01.dimu.org/file/019EBtkLaA3Qa",
                    "fontSize": 0.05,
                    "lineHeight": 1.5,
                    "margin": {},
                    "text": "Bilde 0239-045:0114 , tatt cirka 1925."
                }
            ],
            "position": {
                "x": -6.993999999999981,
                "y": 0.8078000546972013,
                "z": 2.388243698108722
            },
            "rotation": {
                "_order": "XYZ",
                "_x": 0,
                "_y": 1.5707963267948966,
                "_z": 0,
                "isEuler": true
            },
            "textAlign": "left"
        },
        {
            "boundToGrid": false,
            "canvasIndex": 5,
            "color": "#171717",
            "maxWidth": 1.7454329592290416,
            "paragraphs": [
                {
                    "fontFamily": "https://dms-cf-01.dimu.org/file/019EBtkSxmweC",
                    "fontSize": 0.05,
                    "lineHeight": 1.5,
                    "text": "En litt eldre Niels Juel på skogtur med med kona Alethe Ziegler og to andre kvinner. Sønnen lille-Niels er opptatt med sitt under fotograferingen."
                },
                {
                    "fontFamily": "https://dms-cf-01.dimu.org/file/019EBtkLaA3Qa",
                    "fontSize": 0.05,
                    "lineHeight": 1.5,
                    "margin": {},
                    "text": "Bilde: 0239-045:0462, ca. 1900-1930."
                }
            ],
            "position": {
                "x": -6.994000000000011,
                "y": 0.8010180563969684,
                "z": -0.7260597696103179
            },
            "rotation": {
                "_order": "XYZ",
                "_x": 0,
                "_y": 1.5707963267948966,
                "_z": 0,
                "isEuler": true
            },
            "textAlign": "left"
        },
        {
            "boundToGrid": false,
            "canvasIndex": 7,
            "color": "#171717",
            "maxWidth": 7.5,
            "paragraphs": [
                {
                    "fontFamily": "https://dms-cf-01.dimu.org/file/019EBtkSxnGyG",
                    "fontSize": 0.4,
                    "text": "Personlige portretter"
                }
            ],
            "position": {
                "x": -14.417876329684196,
                "y": 3.7818007584228126,
                "z": -6.0039999999999925
            },
            "rotation": {
                "_order": "XYZ",
                "_x": 0,
                "_y": 3.141592653589793,
                "_z": 0,
                "isEuler": true
            },
            "textAlign": "left"
        },
        {
            "boundToGrid": false,
            "canvasIndex": 7,
            "color": "#171717",
            "maxWidth": 4.119440584840788,
            "paragraphs": [
                {
                    "fontFamily": "https://dms-cf-01.dimu.org/file/019EBtkSxmweC",
                    "fontSize": 0.15,
                    "lineHeight": 1.4,
                    "text": "Niels Juel fotograferte nære venner og familie. Men han tok også bilder av alle som jobbet på gården – og de som besøkte gården om sommeren. I tillegg finner vi mange bilder av arbeidsdyr og kjæledyr blant bildene han tok."
                }
            ],
            "position": {
                "x": -12.748539196847831,
                "y": 3.042715685732582,
                "z": -6.003999999999957
            },
            "rotation": {
                "_order": "XYZ",
                "_x": 0,
                "_y": 3.141592653589793,
                "_z": 0,
                "isEuler": true
            },
            "textAlign": "left"
        },
        {
            "boundToGrid": false,
            "canvasIndex": 7,
            "color": "#171717",
            "maxWidth": 1.7318831139112194,
            "paragraphs": [
                {
                    "fontFamily": "https://dms-cf-01.dimu.org/file/019EBtkSxmweC",
                    "fontSize": 0.05,
                    "lineHeight": 1.5,
                    "text": "Posten skal alltid frem. Dette sørget Postmester Laurits Kristiansen for i sin tid. Her poserer han foran posthuset på Bertilstua Knai i Hurdal, en gang tidlig på 1900-tallet."
                },
                {
                    "fontFamily": "https://dms-cf-01.dimu.org/file/019EBtkLaA3Qa",
                    "fontSize": 0.05,
                    "lineHeight": 1.5,
                    "margin": {},
                    "text": "Bilde 0239-045:0033."
                }
            ],
            "position": {
                "x": -11.551259417618377,
                "y": 0.8264351668989063,
                "z": -6.003999999999991
            },
            "rotation": {
                "_order": "XYZ",
                "_x": 0,
                "_y": 3.141592653589793,
                "_z": 0,
                "isEuler": true
            },
            "textAlign": "left"
        },
        {
            "boundToGrid": false,
            "canvasIndex": 7,
            "color": "#171717",
            "maxWidth": 1.5376491763722957,
            "paragraphs": [
                {
                    "fontFamily": "https://dms-cf-01.dimu.org/file/019EBtkSxmweC",
                    "fontSize": 0.05,
                    "lineHeight": 1.5,
                    "text": "Kristian Nielsen var postmester helt frem til sin død i 1912. Her sitter han foran posthuset på Bertilstua Knai i Hurdal. I tillegg til jobben som postmester, drev Kristian Nielsen også sin egen gård. "
                },
                {
                    "fontFamily": "https://dms-cf-01.dimu.org/file/019EBtkLaA3Qa",
                    "fontSize": 0.05,
                    "lineHeight": 1.5,
                    "margin": {},
                    "text": "Bilde:0239-045:0074."
                }
            ],
            "position": {
                "x": -13.85809153341189,
                "y": 0.7711079233631741,
                "z": -6.003999999999999
            },
            "rotation": {
                "_order": "XYZ",
                "_x": 0,
                "_y": 3.141592653589793,
                "_z": 0,
                "isEuler": true
            },
            "textAlign": "left"
        },
        {
            "boundToGrid": false,
            "canvasIndex": 7,
            "color": "#171717",
            "maxWidth": 1.6602100728282332,
            "paragraphs": [
                {
                    "fontFamily": "https://dms-cf-01.dimu.org/file/019EBtkSxmweC",
                    "fontSize": 0.05,
                    "lineHeight": 1.5,
                    "text": "Bilde av en gruppe damer, mest sannsynlig sommergjester fra Oslo. Legg merke til den spesielle kameravinkelen!"
                },
                {
                    "fontFamily": "https://dms-cf-01.dimu.org/file/019EBtkLaA3Qa",
                    "fontSize": 0.05,
                    "lineHeight": 1.5,
                    "margin": {},
                    "text": "Bilde: 0239-045:0117, ca. 1925."
                }
            ],
            "position": {
                "x": -16.33956214811723,
                "y": 0.8621301767637917,
                "z": -6.0040000000000076
            },
            "rotation": {
                "_order": "XYZ",
                "_x": 0,
                "_y": 3.141592653589793,
                "_z": 0,
                "isEuler": true
            },
            "textAlign": "left"
        },
        {
            "boundToGrid": false,
            "canvasIndex": 7,
            "color": "#171717",
            "maxWidth": 1.4790973089080395,
            "paragraphs": [
                {
                    "fontFamily": "https://dms-cf-01.dimu.org/file/019EBtkSxmweC",
                    "fontSize": 0.05,
                    "lineHeight": 1.5,
                    "text": "Et gammel ektepar i Hurdal. Det var stas å bli fotografert. Kanskje hinter kvinnens smil om dette?"
                },
                {
                    "fontFamily": "https://dms-cf-01.dimu.org/file/019EBtkLaA3Qa",
                    "fontSize": 0.05,
                    "lineHeight": 1.5,
                    "margin": {},
                    "text": "Bilde: 0239-045:0138, ca. 1920."
                }
            ],
            "position": {
                "x": -18.718371157134335,
                "y": 0.8475172368027457,
                "z": -6.0039999999999925
            },
            "rotation": {
                "_order": "XYZ",
                "_x": 0,
                "_y": 3.141592653589793,
                "_z": 0,
                "isEuler": true
            },
            "textAlign": "left"
        },
        {
            "boundToGrid": false,
            "canvasIndex": 7,
            "color": "#171717",
            "maxWidth": 1.6323258676423353,
            "paragraphs": [
                {
                    "fontFamily": "https://dms-cf-01.dimu.org/file/019EBtkSxmweC",
                    "fontSize": 0.05,
                    "lineHeight": 1.5,
                    "text": "Portrett av Mathilde Froding, tatt i 1904. "
                },
                {
                    "fontFamily": "https://dms-cf-01.dimu.org/file/019EBtkLaA3Qa",
                    "fontSize": 0.05,
                    "lineHeight": 1.5,
                    "margin": {},
                    "text": "Bilde: 0239-045:0201, ca. 1904."
                }
            ],
            "position": {
                "x": -21.285865646539825,
                "y": 0.8865044683645973,
                "z": -6.003999999999995
            },
            "rotation": {
                "_order": "XYZ",
                "_x": 0,
                "_y": 3.141592653589793,
                "_z": 0,
                "isEuler": true
            },
            "textAlign": "left"
        },
        {
            "boundToGrid": false,
            "canvasIndex": 7,
            "color": "#171717",
            "maxWidth": 1.6821516253166284,
            "paragraphs": [
                {
                    "fontFamily": "https://dms-cf-01.dimu.org/file/019EBtkSxmweC",
                    "fontSize": 0.05,
                    "lineHeight": 1.5,
                    "text": "Dette er et bilde av Niels Juel og tre barn som ofte går igjen på bildene i samlingen hans. Antakelig er de barna til venner av Niels. De bærer brusflasker,  kanskje de skal feire noe?"
                },
                {
                    "fontFamily": "https://dms-cf-01.dimu.org/file/019EBtkLaA3Qa",
                    "fontSize": 0.05,
                    "lineHeight": 1.5,
                    "margin": {},
                    "text": "Bilde: 0239-045:0409."
                }
            ],
            "position": {
                "x": -23.929276209171338,
                "y": 0.7852897039214257,
                "z": -6.004000000000007
            },
            "rotation": {
                "_order": "XYZ",
                "_x": 0,
                "_y": 3.141592653589793,
                "_z": 0,
                "isEuler": true
            },
            "textAlign": "left"
        },
        {
            "boundToGrid": false,
            "canvasIndex": 7,
            "color": "#171717",
            "maxWidth": 1.3826667909346777,
            "paragraphs": [
                {
                    "fontFamily": "https://dms-cf-01.dimu.org/file/019EBtkSxmweC",
                    "fontSize": 0.05,
                    "lineHeight": 1.5,
                    "text": "En ukjent jente poserer ved et hvitt gjerde på Viken Gård. Det finnes flere bilder av henne i samlingen til Niels Juel, så trolig var hun en venn av familien."
                },
                {
                    "fontFamily": "https://dms-cf-01.dimu.org/file/019EBtkLaA3Qa",
                    "fontSize": 0.05,
                    "lineHeight": 1.5,
                    "margin": {},
                    "text": "Bilde: 0239-045:0499, ca. 1913."
                }
            ],
            "position": {
                "x": -26.197005288717936,
                "y": 0.7958493168749774,
                "z": -6.003999999999982
            },
            "rotation": {
                "_order": "XYZ",
                "_x": 0,
                "_y": 3.141592653589793,
                "_z": 0,
                "isEuler": true
            },
            "textAlign": "left"
        },
        {
            "boundToGrid": false,
            "canvasIndex": 7,
            "color": "#171717",
            "maxWidth": 1.7160484115842003,
            "paragraphs": [
                {
                    "fontFamily": "https://dms-cf-01.dimu.org/file/019EBtkSxmweC",
                    "fontSize": 0.05,
                    "lineHeight": 1.5,
                    "text": "To ukjente kvinner. En av kvinnene holder en avis – Aftenposten, som ble grunnlagt i 1860. Lite visste de at denne avisen fortsatt ville finnes og leses av mange nå, 100 år senere."
                },
                {
                    "fontFamily": "https://dms-cf-01.dimu.org/file/019EBtkLaA3Qa",
                    "fontSize": 0.05,
                    "lineHeight": 1.5,
                    "margin": {},
                    "text": "Bilde: 0239-045:0560, ca. 1910-25."
                }
            ],
            "position": {
                "x": -28.72171721979875,
                "y": 0.788783438207207,
                "z": -6.0039999999999925
            },
            "rotation": {
                "_order": "XYZ",
                "_x": 0,
                "_y": 3.141592653589793,
                "_z": 0,
                "isEuler": true
            },
            "textAlign": "left"
        },
        {
            "boundToGrid": false,
            "canvasIndex": 7,
            "color": "#171717",
            "maxWidth": 1.5681134861387036,
            "paragraphs": [
                {
                    "fontFamily": "https://dms-cf-01.dimu.org/file/019EBtkSxmweC",
                    "fontSize": 0.05,
                    "lineHeight": 1.5,
                    "text": "En liten jente og en liten gutt fotografert en solskinnsdag på Viken gård. Vi vet ikke sikkert hvem de er, men stilfulle er de i hvert fall."
                },
                {
                    "fontFamily": "https://dms-cf-01.dimu.org/file/019EBtkLaA3Qa",
                    "fontSize": 0.05,
                    "lineHeight": 1.5,
                    "margin": {},
                    "text": "Bilde: 0239-045:0572, ca. 1913."
                }
            ],
            "position": {
                "x": -31.021923919972252,
                "y": 0.8301653685387658,
                "z": -6.004000000000008
            },
            "rotation": {
                "_order": "XYZ",
                "_x": 0,
                "_y": 3.141592653589793,
                "_z": 0,
                "isEuler": true
            },
            "textAlign": "left"
        },
        {
            "boundToGrid": false,
            "canvasIndex": 6,
            "color": "#171717",
            "maxWidth": 4.7678519380345294,
            "paragraphs": [
                {
                    "fontFamily": "https://dms-cf-01.dimu.org/file/019EBtkSxnGyG",
                    "fontSize": 0.5,
                    "text": "Arbeid og hverdag"
                }
            ],
            "position": {
                "x": -29.080031646003263,
                "y": 4.244191464233886,
                "z": -19.969
            },
            "rotation": {
                "_order": "XYZ",
                "_x": 0,
                "_y": 0,
                "_z": 0,
                "isEuler": true
            },
            "textAlign": "left"
        },
        {
            "boundToGrid": false,
            "canvasIndex": 6,
            "color": "#171717",
            "maxWidth": 2.9180291917555907,
            "paragraphs": [
                {
                    "fontFamily": "https://dms-cf-01.dimu.org/file/019EBtkSxmweC",
                    "fontSize": 0.125,
                    "lineHeight": 1.4,
                    "text": "Norske gårder og små landbruk på begynnelsen av 1900-tallet var levende steder hvor det samme familietreet kunne ha bodd i flere generasjoner. Petra Juel, Niels Juel sin mor, arvet Viken gården etter hennes far, Bestefar Larsen. "
                }
            ],
            "position": {
                "x": -29.99297525452611,
                "y": 3.323,
                "z": -19.968999999999916
            },
            "rotation": {
                "_order": "XYZ",
                "_x": 0,
                "_y": 0,
                "_z": 0,
                "isEuler": true
            },
            "textAlign": "left"
        },
        {
            "boundToGrid": false,
            "canvasIndex": 6,
            "color": "#171717",
            "maxWidth": 0.7566233852167809,
            "paragraphs": [
                {
                    "fontFamily": "https://dms-cf-01.dimu.org/file/019EBtkSxmweC",
                    "fontSize": 0.05,
                    "lineHeight": 1.5,
                    "text": ">>> Både menn, kvinner, og hester måtte trå til for å høste alle potetene som trengtes for å brødfø alle på Viken Gård. På bildet kan vi se både potehakker, potevogner, og høygafler."
                }
            ],
            "position": {
                "x": -7.869646076597366,
                "y": 2.2232956574728693,
                "z": -19.969
            },
            "rotation": {
                "_order": "XYZ",
                "_x": 0,
                "_y": 0,
                "_z": 0,
                "isEuler": true
            },
            "textAlign": "left"
        },
        {
            "boundToGrid": false,
            "canvasIndex": 6,
            "color": "#171717",
            "maxWidth": 2.6081996680692354,
            "paragraphs": [
                {
                    "fontFamily": "https://dms-cf-01.dimu.org/file/019EBtkSxmweC",
                    "fontSize": 0.05,
                    "lineHeight": 1.5,
                    "text": "Mann på en kaldblods dølahest. Dølahestene kommer fra Gudbrandsdalen og er en norsk hesterase som ble brukt til mye. De kalles kaldblodshester fordi de holder seg rolige i ulike situasjoner og fordi de er utholdende i kulda."
                },
                {
                    "fontFamily": "https://dms-cf-01.dimu.org/file/019EBtkLaA3Qa",
                    "fontSize": 0.05,
                    "lineHeight": 1.5,
                    "margin": {},
                    "text": "Bilde: 0239-045:0189, ca. 1920.  "
                }
            ],
            "position": {
                "x": -22.859534587081544,
                "y": 0.8239304286428792,
                "z": -19.969000000000005
            },
            "rotation": {
                "_order": "XYZ",
                "_x": 0,
                "_y": 0,
                "_z": 0,
                "isEuler": true
            },
            "textAlign": "left"
        },
        {
            "boundToGrid": false,
            "canvasIndex": 6,
            "color": "#171717",
            "maxWidth": 1.5891043115639265,
            "paragraphs": [
                {
                    "fontFamily": "https://dms-cf-01.dimu.org/file/019EBtkSxmweC",
                    "fontSize": 0.05,
                    "lineHeight": 1.5,
                    "text": "Potethøsting på Viken Gård krevde mange mennesker og dyr i arbeid. Jorda ble kjørt opp ved hjelp av hester slik at arbeiderne kunne plukke potetene fra bakken. Så fraktet de potetene i tønner og bøtter til potetkjelleren. Legg merke til de godt brukte bøttene. "
                },
                {
                    "fontFamily": "https://dms-cf-01.dimu.org/file/019EBtkLaA3Qa",
                    "fontSize": 0.05,
                    "lineHeight": 1.5,
                    "margin": {},
                    "text": "Bilde: 0239-045:0051, ca. 1913."
                }
            ],
            "position": {
                "x": -15.107015727564749,
                "y": 0.7584363009920576,
                "z": -19.969000000000005
            },
            "rotation": {
                "_order": "XYZ",
                "_x": 0,
                "_y": 0,
                "_z": 0,
                "isEuler": true
            },
            "textAlign": "left"
        },
        {
            "boundToGrid": false,
            "canvasIndex": 6,
            "color": "#171717",
            "maxWidth": 1.5621756444877741,
            "paragraphs": [
                {
                    "fontFamily": "https://dms-cf-01.dimu.org/file/019EBtkSxmweC",
                    "fontSize": 0.05,
                    "lineHeight": 1.5,
                    "text": "Tørking av høy på Viken gård. Høyet ble hengt på staur for å tørke før det ble tatt inn i låven og lagret gjennom vinteren. "
                },
                {
                    "fontFamily": "https://dms-cf-01.dimu.org/file/019EBtkLaA3Qa",
                    "fontSize": 0.05,
                    "lineHeight": 1.5,
                    "margin": {},
                    "text": "Bilde: 0239-045:0083, ca. 1909."
                }
            ],
            "position": {
                "x": -17.600227745436566,
                "y": 0.8291883581417916,
                "z": -19.968999999999973
            },
            "rotation": {
                "_order": "XYZ",
                "_x": 0,
                "_y": 0,
                "_z": 0,
                "isEuler": true
            },
            "textAlign": "left"
        },
        {
            "boundToGrid": false,
            "canvasIndex": 6,
            "color": "#171717",
            "maxWidth": 1.5595067503391538,
            "paragraphs": [
                {
                    "fontFamily": "https://dms-cf-01.dimu.org/file/019EBtkSxmweC",
                    "fontSize": 0.05,
                    "lineHeight": 1.5,
                    "text": "Ikke alle som bodde på bygda drev gård. Denne mannen var feier, og her står han ved bryggerhuset på Viken gård. Det er en pipebørste han holder i hånden. "
                },
                {
                    "fontFamily": "https://dms-cf-01.dimu.org/file/019EBtkLaA3Qa",
                    "fontSize": 0.05,
                    "lineHeight": 1.5,
                    "margin": {},
                    "text": "Bilde: 0239-045:0147, ca. 1920."
                }
            ],
            "position": {
                "x": -10.080567769744489,
                "y": 0.7992389091213576,
                "z": -19.969
            },
            "rotation": {
                "_order": "XYZ",
                "_x": 0,
                "_y": 0,
                "_z": 0,
                "isEuler": true
            },
            "textAlign": "left"
        },
        {
            "boundToGrid": false,
            "canvasIndex": 13,
            "color": "#171717",
            "maxWidth": 2.1430456220173273,
            "paragraphs": [
                {
                    "fontFamily": "https://dms-cf-01.dimu.org/file/019EBtkSxnGyG",
                    "fontSize": 0.3,
                    "text": "Hobbyfotografens utstyr"
                },
                {
                    "fontFamily": "https://dms-cf-01.dimu.org/file/019EBtkSxmweC",
                    "fontSize": 0.1,
                    "lineHeight": 1.3,
                    "margin": {
                        "bottom": 0.1,
                        "top": 0.1
                    },
                    "text": "Det var dette kameraet Niels Juel tok bilder med. Kameraet heter Svenska Express og ble laget i et samarbeid mellom produsentene Hasselblad og Svensson."
                },
                {
                    "fontFamily": "https://dms-cf-01.dimu.org/file/019EBtkSxmweC",
                    "fontSize": 0.1,
                    "lineHeight": 1.3,
                    "margin": {
                        "bottom": 0,
                        "top": 0
                    },
                    "text": "Det er på størrelse med en brødrister og ganske lett å bære. Niels Juel hadde kameraet med seg på reiser både i Norge og i utlandet. Men hvordan ble bildene til?"
                },
                {
                    "fontFamily": "https://dms-cf-01.dimu.org/file/019EBtkSxmweC",
                    "fontSize": 0.1,
                    "lineHeight": 1.3,
                    "margin": {
                        "bottom": 0.1,
                        "top": 0.1
                    },
                    "text": "Kom inn og ta en kikk bak kameraet!"
                }
            ],
            "position": {
                "x": -0.2965520490713849,
                "y": 1.9928409889744603,
                "z": -10.32561892933384
            },
            "rotation": {
                "_order": "XYZ",
                "_x": 0,
                "_y": -0.7766,
                "_z": 0,
                "isEuler": true
            },
            "textAlign": "left"
        },
        {
            "boundToGrid": false,
            "canvasIndex": 9,
            "color": "#171717",
            "maxWidth": 2.2654888382222014,
            "paragraphs": [
                {
                    "fontFamily": "https://dms-cf-01.dimu.org/file/019EBtkSxmweC",
                    "fontSize": 0.05,
                    "lineHeight": 1.5,
                    "text": "Niels Juels kamera, forfra. Hvor mange kan ha sett inn i denne linsen?"
                }
            ],
            "position": {
                "x": 0.4874759461296734,
                "y": 0.8788220315436317,
                "z": -19.979000000000006
            },
            "rotation": {
                "_order": "XYZ",
                "_x": 0,
                "_y": 0,
                "_z": 0,
                "isEuler": true
            },
            "textAlign": "left"
        },
        {
            "boundToGrid": false,
            "canvasIndex": 9,
            "color": "#171717",
            "maxWidth": 2.2593264567471483,
            "paragraphs": [
                {
                    "fontFamily": "https://dms-cf-01.dimu.org/file/019EBtkSxmweC",
                    "fontSize": 0.05,
                    "lineHeight": 1.5,
                    "text": "Her er Niels Juels kamera, åpnet fra baksiden. Inni er det ganske tomt, men kameraet skulle fylles med glassplater – inntil 12 stykker."
                }
            ],
            "position": {
                "x": -2.85893151521556,
                "y": 0.8683291820350489,
                "z": -19.97899999999996
            },
            "rotation": {
                "_order": "XYZ",
                "_x": 0,
                "_y": 0,
                "_z": 0,
                "isEuler": true
            },
            "textAlign": "left"
        },
        {
            "boundToGrid": false,
            "canvasIndex": 8,
            "color": "#171717",
            "maxWidth": 2.4968218496923917,
            "paragraphs": [
                {
                    "fontFamily": "https://dms-cf-01.dimu.org/file/019EBtkSxmweC",
                    "fontSize": 0.05,
                    "lineHeight": 1.5,
                    "text": "I eskene ligger glassplater som skulle settes inn i kameraet. Ved fotograferingen festet bildemotivet  seg til glassplaten. Så fremkalte man motivet i kontaktprint-rammen som du kan se på bildet ved siden av."
                }
            ],
            "position": {
                "x": 6.986000000000032,
                "y": 0.8818868458310849,
                "z": -16.709973979314036
            },
            "rotation": {
                "_order": "XYZ",
                "_x": 0,
                "_y": -1.5707963267948966,
                "_z": 0,
                "isEuler": true
            },
            "textAlign": "left"
        },
        {
            "boundToGrid": false,
            "canvasIndex": 8,
            "color": "#171717",
            "maxWidth": 2.532560537814449,
            "paragraphs": [
                {
                    "fontFamily": "https://dms-cf-01.dimu.org/file/019EBtkSxmweC",
                    "fontSize": 0.05,
                    "lineHeight": 1.5,
                    "text": "Dette er en kontaktkopieringsramme. Den ble brukt til å fremkalle bilder med. Glassplatenegativer fra kameraet ble lagt inn i rammen, presset mot et fotopapir. Når rammen ble holdt opp mot lyset, ble motivet kopiert til fotopapiret – og bildet ble skapt!"
                },
                {
                    "fontFamily": "https://dms-cf-01.dimu.org/file/019EBtkLaA3Qa",
                    "fontSize": 0.05,
                    "lineHeight": 1.5,
                    "margin": {},
                    "text": "Gjenstand MIA.00059"
                }
            ],
            "position": {
                "x": 6.986000000000027,
                "y": 0.8008522484698419,
                "z": -12.967250661640236
            },
            "rotation": {
                "_order": "XYZ",
                "_x": 0,
                "_y": -1.5707963267948966,
                "_z": 0,
                "isEuler": true
            },
            "textAlign": "left"
        },
        {
            "boundToGrid": false,
            "canvasIndex": 6,
            "color": "#171717",
            "maxWidth": 2.639645238395448,
            "paragraphs": [
                {
                    "fontFamily": "https://dms-cf-01.dimu.org/file/019EBtkSxmweC",
                    "fontSize": 0.125,
                    "lineHeight": 1.4,
                    "text": "Her fikk Niels tilhøre hele livet, sammen med hans far, mor, tanter, og senere kone og barn. I tillegg til kjernefamilien bodde det både tjenestepiker, arbeidere, og til tider forpaktere på gården."
                }
            ],
            "position": {
                "x": -26.997065962523198,
                "y": 3.3223449026161873,
                "z": -19.968999999999994
            },
            "rotation": {
                "_order": "XYZ",
                "_x": 0,
                "_y": 0,
                "_z": 0,
                "isEuler": true
            },
            "textAlign": "left"
        },
        {
            "boundToGrid": false,
            "canvasIndex": 1,
            "color": "#171717",
            "maxWidth": 1.0348527685291264,
            "paragraphs": [
                {
                    "fontFamily": "https://dms-cf-01.dimu.org/file/019EBtkSxmweC",
                    "fontSize": 0.05,
                    "lineHeight": 1.5,
                    "text": "Niels Juels mor, Petra, tok ofte imot gjester på Viken Gård når det var sommer for å tjene ekstra penger til familien. Niels, som står helt øverst ovenfor alle de andre på bildet, tok ofte bilder av gjestene. Vi har derfor mange bilder av sommergjestene i samlingen.  "
                },
                {
                    "fontFamily": "https://dms-cf-01.dimu.org/file/019EBtkLaA3Qa",
                    "fontSize": 0.05,
                    "lineHeight": 1.5,
                    "margin": {
                        "bottom": 0.1,
                        "top": 0.1
                    },
                    "text": "Bilde 0239-045:0105, ca. 1910."
                }
            ],
            "position": {
                "x": -2.3444362153870073,
                "y": 0.8059091685599999,
                "z": 19.983999999999998
            },
            "rotation": {
                "_order": "XYZ",
                "_x": 0,
                "_y": 3.141592653589793,
                "_z": 0,
                "isEuler": true
            },
            "textAlign": "left"
        },
        {
            "boundToGrid": false,
            "canvasIndex": 11,
            "color": "#171717",
            "maxWidth": 1.0537835053921716,
            "paragraphs": [
                {
                    "fontFamily": "https://dms-cf-01.dimu.org/file/019EBtkSxmweC",
                    "fontSize": 0.05,
                    "text": "Fire ukjente kvinner, fotografert i Kristiania."
                },
                {
                    "fontFamily": "https://dms-cf-01.dimu.org/file/019EBtkLaA3Qa",
                    "fontSize": 0.05,
                    "margin": {},
                    "text": "Bilde: 0239-045:0247, ca. 1920."
                }
            ],
            "position": {
                "x": -6.9889999999999475,
                "y": 1.405573321821075,
                "z": -18.02177433681956
            },
            "rotation": {
                "_order": "XYZ",
                "_x": 0,
                "_y": 1.5707963267948966,
                "_z": 0,
                "isEuler": true
            },
            "textAlign": "left"
        },
        {
            "boundToGrid": false,
            "canvasIndex": 11,
            "color": "#171717",
            "maxWidth": 1.145574244133087,
            "paragraphs": [
                {
                    "fontFamily": "https://dms-cf-01.dimu.org/file/019EBtkSxmweC",
                    "fontSize": 0.05,
                    "lineHeight": 1.5,
                    "text": "Amalienborg i København, fotografert fra høyt oppe i Marmorkirken."
                },
                {
                    "fontFamily": "https://dms-cf-01.dimu.org/file/019EBtkLaA3Qa",
                    "fontSize": 0.05,
                    "lineHeight": 1.5,
                    "margin": {},
                    "text": "Bilde: 0239-045:0327, ca. 1930."
                }
            ],
            "position": {
                "x": -6.988999999999956,
                "y": 1.3952147917253348,
                "z": -15.872238200228997
            },
            "rotation": {
                "_order": "XYZ",
                "_x": 0,
                "_y": 1.5707963267948966,
                "_z": 0,
                "isEuler": true
            },
            "textAlign": "left"
        },
        {
            "boundToGrid": false,
            "canvasIndex": 11,
            "color": "#171717",
            "maxWidth": 1.2052687686575143,
            "paragraphs": [
                {
                    "fontFamily": "https://dms-cf-01.dimu.org/file/019EBtkSxmweC",
                    "fontSize": 0.05,
                    "lineHeight": 1.5,
                    "text": "Syklister, hester, tidsriktige hatter og lange kjoler; et yrende folkeliv på Højbro Plads i København."
                },
                {
                    "fontFamily": "https://dms-cf-01.dimu.org/file/019EBtkLaA3Qa",
                    "fontSize": 0.05,
                    "lineHeight": 1.5,
                    "margin": {},
                    "text": "Bilde: 0239-045:0329, ca. 1930."
                }
            ],
            "position": {
                "x": -6.98899999999995,
                "y": 1.3550031051090798,
                "z": -13.591465490127034
            },
            "rotation": {
                "_order": "XYZ",
                "_x": 0,
                "_y": 1.5707963267948966,
                "_z": 0,
                "isEuler": true
            },
            "textAlign": "left"
        },
        {
            "boundToGrid": false,
            "canvasIndex": 6,
            "color": "#171717",
            "maxWidth": 1.5077548943659225,
            "paragraphs": [
                {
                    "fontFamily": "https://dms-cf-01.dimu.org/file/019EBtkSxmweC",
                    "fontSize": 0.05,
                    "lineHeight": 1.5,
                    "text": "Ved og tømmer var det familien Juel tjente mest på, og dette måtte hentes både om våren og om vinteren. Tømmer var mangelvare under begge verdenskrigene, og det var i krigsårene at familien tjente mest på tømmeret sitt. "
                },
                {
                    "fontFamily": "https://dms-cf-01.dimu.org/file/019EBtkLaA3Qa",
                    "fontSize": 0.05,
                    "lineHeight": 1.5,
                    "margin": {},
                    "text": "Bilde: 0239-045:0058 ca. 1915."
                }
            ],
            "position": {
                "x": -28.146116411074363,
                "y": 0.773296869890058,
                "z": -19.968999999999998
            },
            "rotation": {
                "_order": "XYZ",
                "_x": 0,
                "_y": 0,
                "_z": 0,
                "isEuler": true
            },
            "textAlign": "left"
        },
        {
            "boundToGrid": false,
            "canvasIndex": 6,
            "color": "#171717",
            "maxWidth": 1.438285760095734,
            "paragraphs": [
                {
                    "fontFamily": "https://dms-cf-01.dimu.org/file/019EBtkSxmweC",
                    "fontSize": 0.05,
                    "lineHeight": 1.5,
                    "text": "Hovedhuset på Viken Gård. Dette huset har stått i over hundre år, og det står fremdeles like flott den dag i dag.  "
                },
                {
                    "fontFamily": "https://dms-cf-01.dimu.org/file/019EBtkLaA3Qa",
                    "fontSize": 0.05,
                    "lineHeight": 1.5,
                    "margin": {},
                    "text": "Bilde: 0239-045:0196, ca. 1920."
                }
            ],
            "position": {
                "x": -30.721332567482673,
                "y": 0.8316485759257559,
                "z": -19.968999999999998
            },
            "rotation": {
                "_order": "XYZ",
                "_x": 0,
                "_y": 0,
                "_z": 0,
                "isEuler": true
            },
            "textAlign": "left"
        },
        {
            "boundToGrid": false,
            "canvasIndex": 11,
            "color": "#171717",
            "maxWidth": 1.5592039078864048,
            "paragraphs": [
                {
                    "fontFamily": "https://dms-cf-01.dimu.org/file/019EBtkSxnGyG",
                    "fontSize": 0.25,
                    "text": "På reiser"
                },
                {
                    "fontFamily": "https://dms-cf-01.dimu.org/file/019EBtkSxnGyG",
                    "fontSize": 0.2,
                    "margin": {},
                    "text": "med kamera"
                }
            ],
            "position": {
                "x": -6.988999999999988,
                "y": 3.262244019336533,
                "z": -18.279792784880286
            },
            "rotation": {
                "_order": "XYZ",
                "_x": 0,
                "_y": 1.5707963267948966,
                "_z": 0,
                "isEuler": true
            },
            "textAlign": "left"
        },
        {
            "boundToGrid": false,
            "canvasIndex": 6,
            "color": "#171717",
            "maxWidth": 1.3869655815448148,
            "paragraphs": [
                {
                    "fontFamily": "https://dms-cf-01.dimu.org/file/019EBtkSxmweC",
                    "fontSize": 0.05,
                    "lineHeight": 1.5,
                    "text": "Foreldrene til Niels Juel, Petra og Niels, inspiserer tømmer. "
                },
                {
                    "fontFamily": "https://dms-cf-01.dimu.org/file/019EBtkLaA3Qa",
                    "fontSize": 0.05,
                    "lineHeight": 1.5,
                    "margin": {},
                    "text": "Bilde: 0239-045:0024, ca. 1905."
                }
            ],
            "position": {
                "x": -25.76562079989724,
                "y": 0.8678153515511755,
                "z": -19.96899999999999
            },
            "rotation": {
                "_order": "XYZ",
                "_x": 0,
                "_y": 0,
                "_z": 0,
                "isEuler": true
            },
            "textAlign": "left"
        },
        {
            "boundToGrid": false,
            "canvasIndex": 6,
            "color": "#171717",
            "maxWidth": 1.5613230510826226,
            "paragraphs": [
                {
                    "fontFamily": "https://dms-cf-01.dimu.org/file/019EBtkSxmweC",
                    "fontSize": 0.05,
                    "lineHeight": 1.5,
                    "text": "Niels Juels mor Petra var svært glad i katter, og det var alltid mange på Viken gård. Her blir kattene vist frem for fotografen – ikke av Petra, men av to andre kvinner på gården. "
                },
                {
                    "fontFamily": "https://dms-cf-01.dimu.org/file/019EBtkLaA3Qa",
                    "fontSize": 0.05,
                    "lineHeight": 1.5,
                    "margin": {},
                    "text": "Bilde: 0239-045:0357, ca. 1930."
                }
            ],
            "position": {
                "x": -20.09420571271712,
                "y": 0.7981163906772908,
                "z": -19.968999999999998
            },
            "rotation": {
                "_order": "XYZ",
                "_x": 0,
                "_y": 0,
                "_z": 0,
                "isEuler": true
            },
            "textAlign": "left"
        },
        {
            "boundToGrid": false,
            "canvasIndex": 6,
            "color": "#171717",
            "maxWidth": 1.3396859839557926,
            "paragraphs": [
                {
                    "fontFamily": "https://dms-cf-01.dimu.org/file/019EBtkSxmweC",
                    "fontSize": 0.05,
                    "lineHeight": 1.5,
                    "text": "En ukjent, ung kvinne blir fotografert sittende på en mur. "
                },
                {
                    "fontFamily": "https://dms-cf-01.dimu.org/file/019EBtkLaA3Qa",
                    "fontSize": 0.05,
                    "lineHeight": 1.5,
                    "margin": {},
                    "text": "Bilde: 0239-045:0257, ca. 1920."
                }
            ],
            "position": {
                "x": -12.718095710710873,
                "y": 0.8794764086475482,
                "z": -19.96899999999999
            },
            "rotation": {
                "_order": "XYZ",
                "_x": 0,
                "_y": 0,
                "_z": 0,
                "isEuler": true
            },
            "textAlign": "left"
        },
        {
            "boundToGrid": false,
            "canvasIndex": 9,
            "color": "#171717",
            "maxWidth": 2.3764418433072283,
            "paragraphs": [
                {
                    "fontFamily": "https://dms-cf-01.dimu.org/file/019EBtkSxmweC",
                    "fontSize": 0.05,
                    "lineHeight": 1.5,
                    "text": "Kameraet til Niels Juel var omtrent like stor som en brødrister. Sammenlignet med eldre fotoapparater, var det ganske lite og lett, og det var enkelt å bære det med seg. "
                }
            ],
            "position": {
                "x": 3.7460513321232236,
                "y": 0.858168449562164,
                "z": -19.979
            },
            "rotation": {
                "_order": "XYZ",
                "_x": 0,
                "_y": 0,
                "_z": 0,
                "isEuler": true
            },
            "textAlign": "left"
        }
    ],
    "tours": [],
    "videos": [
        {
            "autoPlay": true,
            "boundToGrid": true,
            "canvasIndex": 12,
            "dmsId": "013AkPV1tL1F",
            "height": 4,
            "loop": true,
            "position": {
                "x": -1.2892991469873407,
                "y": 2,
                "z": -11.670713305723126
            },
            "rotation": {
                "_order": "XYZ",
                "_x": 0,
                "_y": 2.365,
                "_z": 0,
                "isEuler": true
            },
            "volume": 1,
            "width": 7.5
        }
    ],
    "about": {
        "museum": {
            "name": "Museene i Akershus",
            "url": "/owners/AFM"
        },
        "exhibition": {
            "name": "Se meg dypt inn i linsen",
            "url": "/virtual-experiences/ff335e35-98ec-4e36-9c32-79428f917b69"
        }
    }
}