export const demo15 = {
    roomModelDmsId: '013AkP9FqYZy',
    startPosition: { x: 5, y: 1.75, z: 18 },
    directionOfView: { x: -0.001, y: 0, z: -0.001 },
    lights: [
        {
            type: 'ambient',
            intensity: 0.6,
        },
    ],
}