import { useDetectGPU } from '@react-three/drei';
import { useEffect } from 'react';
import { SET_GPU_TIER, useThreeDDispatch } from '../3droom/App/ThreeDContext';

export const GPUAnalyzer = () => {
  const dispatch = useThreeDDispatch();
  const GPUTier = useDetectGPU();

  useEffect(() => {
    dispatch({ type: SET_GPU_TIER, GPUTier: GPUTier });
    console.debug({ GPUTier: GPUTier.tier, GPUFps: GPUTier.fps });
  }, []);
};
