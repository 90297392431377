export const demo32 = {
    "canvases": [
        {
            "dimensions": {
                "cellSize": "0.5",
                "columns": "80",
                "rows": "12"
            },
            "position": {
                "x": 9.985,
                "y": 3,
                "z": 0
            },
            "rotation": {
                "_order": "XYZ",
                "_x": 0,
                "_y": -1.570796326794,
                "_z": 0,
                "isEuler": true
            },
            "type": "Wall"
        },
        {
            "dimensions": {
                "cellSize": "0.5",
                "columns": "80",
                "rows": "12"
            },
            "position": {
                "x": -9.985,
                "y": 3,
                "z": 0
            },
            "rotation": {
                "_order": "XYZ",
                "_x": 0,
                "_y": 1.570796326794,
                "_z": 0,
                "isEuler": true
            },
            "type": "Wall"
        },
        {
            "dimensions": {
                "cellSize": "0.5",
                "columns": "40",
                "rows": "12"
            },
            "position": {
                "x": 0,
                "y": 3,
                "z": -19.98
            },
            "rotation": {
                "_order": "XYZ",
                "_x": 0,
                "_y": 0,
                "_z": 0,
                "isEuler": true
            },
            "type": "Wall"
        },
        {
            "dimensions": {
                "cellSize": "0.5",
                "columns": "40",
                "rows": "12"
            },
            "position": {
                "x": 0,
                "y": 3,
                "z": 19.98
            },
            "rotation": {
                "_order": "XYZ",
                "_x": 0,
                "_y": 3.141592653589,
                "_z": 0,
                "isEuler": true
            },
            "type": "Wall"
        },
        {
            "dimensions": {
                "cellSize": "0.5",
                "columns": "30",
                "rows": "32"
            },
            "position": {
                "x": 0,
                "y": 0.01,
                "z": -9.5
            },
            "rotation": {
                "_order": "XYZ",
                "_x": -1.570796326794,
                "_y": 0,
                "_z": 0,
                "isEuler": true
            },
            "type": "Floor"
        },
        {
            "dimensions": {
                "cellSize": "0.5",
                "columns": "30",
                "rows": "32"
            },
            "position": {
                "x": 0,
                "y": 0.01,
                "z": 9.5
            },
            "rotation": {
                "_order": "XYZ",
                "_x": -1.570796326794,
                "_y": 0,
                "_z": 0,
                "isEuler": true
            },
            "type": "Floor"
        }
    ],
    "directionOfView": {
        "_order": "XYZ",
        "_x": 0.001,
        "_y": 0,
        "_z": 0,
        "isEuler": true
    },
    "groups": [],
    "images": [
        {
            "addCanvasDepth": true,
            "boundToGrid": false,
            "canvasIndex": 3,
            "dmsId": "019EE8EBk2ziH",
            "position": {
                "x": 0,
                "y": 3,
                "z": 19.979
            },
            "rotation": {
                "_order": "XYZ",
                "_x": 0,
                "_y": 3.141592653589,
                "_z": 0,
                "isEuler": true
            },
            "scale": 20,
            "viewerData": {
                "model": {
                    "dmsId": "019EE8EBk2ziH",
                    "type": "ImageSingle"
                }
            }
        }
    ],
    "lights": [
        {
            "intensity": 1,
            "type": "ambient"
        }
    ],
    "roomModelDmsId": "019EBtFo2g3EK",
    "settings": {
        "colors": [
            {
                "key": 0,
                "value": null
            },
            {
                "key": 1,
                "value": null
            },
            {
                "key": 2,
                "value": null
            },
            {
                "key": 3,
                "value": null
            }
        ]
    },
    "startPosition": {
        "x": -9,
        "y": 1.75,
        "z": 0
    },
    "texts": [
        {
            "boundToGrid": false,
            "canvasIndex": 2,
            "color": "#171717",
            "maxWidth": 3.295417854296385,
            "paragraphs": [
                {
                    "fontFamily": "https://fonts.gstatic.com/s/bebasneue/v9/JTUSjIg69CK48gW7PXooxW4.ttf",
                    "fontSize": 0.2,
                    "text": "SKOTTÅL"
                }
            ],
            "position": {
                "x": -0.9531153611486218,
                "y": 0.7674690303880198,
                "z": -19.979
            },
            "rotation": {
                "_order": "XYZ",
                "_x": 0,
                "_y": 0,
                "_z": 0,
                "isEuler": true
            },
            "textAlign": "left"
        },
        {
            "boundToGrid": false,
            "canvasIndex": 2,
            "color": "#171717",
            "maxWidth": 3,
            "paragraphs": [
                {
                    "fontFamily": "https://fonts.gstatic.com/s/bebasneue/v9/JTUSjIg69CK48gW7PXooxW4.ttf",
                    "fontSize": 0.2,
                    "text": "Slingrestokker"
                }
            ],
            "position": {
                "x": -7,
                "y": 0.75,
                "z": -19.979
            },
            "rotation": {
                "_order": "XYZ",
                "_x": 0,
                "_y": 0,
                "_z": 0,
                "isEuler": true
            },
            "textAlign": "left"
        },
        {
            "boundToGrid": false,
            "canvasIndex": 2,
            "color": "#171717",
            "maxWidth": 2.5,
            "paragraphs": [
                {
                    "fontFamily": "https://fonts.gstatic.com/s/bebasneue/v9/JTUSjIg69CK48gW7PXooxW4.ttf",
                    "fontSize": 0.2,
                    "text": "Håndharpun"
                }
            ],
            "position": {
                "x": 4.476637747887113,
                "y": 0.7598874536851143,
                "z": -19.979
            },
            "rotation": {
                "_order": "XYZ",
                "_x": 0,
                "_y": 0,
                "_z": 0,
                "isEuler": true
            },
            "textAlign": "left"
        },
        {
            "boundToGrid": false,
            "canvasIndex": 0,
            "color": "#171717",
            "maxWidth": 3.5,
            "paragraphs": [
                {
                    "fontFamily": "https://fonts.gstatic.com/s/bebasneue/v9/JTUSjIg69CK48gW7PXooxW4.ttf",
                    "fontSize": 0.2,
                    "text": "Frostnavar"
                }
            ],
            "position": {
                "x": 9.98399999998498,
                "y": 0.75,
                "z": -16.75
            },
            "rotation": {
                "_order": "XYZ",
                "_x": 0,
                "_y": -1.570796326794,
                "_z": 0,
                "isEuler": true
            },
            "textAlign": "left"
        },
        {
            "boundToGrid": false,
            "canvasIndex": 0,
            "color": "#171717",
            "maxWidth": 3.7970547675210264,
            "paragraphs": [
                {
                    "fontFamily": "https://fonts.gstatic.com/s/bebasneue/v9/JTUSjIg69CK48gW7PXooxW4.ttf",
                    "fontSize": 0.2,
                    "text": "Garnklubbe"
                }
            ],
            "position": {
                "x": 9.983999999990676,
                "y": 0.75,
                "z": -10.398527383760513
            },
            "rotation": {
                "_order": "XYZ",
                "_x": 0,
                "_y": -1.570796326794,
                "_z": 0,
                "isEuler": true
            },
            "textAlign": "left"
        },
        {
            "boundToGrid": false,
            "canvasIndex": 0,
            "color": "#171717",
            "maxWidth": 5,
            "paragraphs": [
                {
                    "fontFamily": "https://fonts.gstatic.com/s/bebasneue/v9/JTUSjIg69CK48gW7PXooxW4.ttf",
                    "fontSize": 0.2,
                    "text": "Vevlinger "
                }
            ],
            "position": {
                "x": 9.983999999996861,
                "y": 0.75,
                "z": -3.499999999999999
            },
            "rotation": {
                "_order": "XYZ",
                "_x": 0,
                "_y": -1.570796326794,
                "_z": 0,
                "isEuler": true
            },
            "textAlign": "left"
        },
        {
            "boundToGrid": false,
            "canvasIndex": 0,
            "color": "#171717",
            "maxWidth": 4.205557702751063,
            "paragraphs": [
                {
                    "fontFamily": "https://fonts.gstatic.com/s/bebasneue/v9/JTUSjIg69CK48gW7PXooxW4.ttf",
                    "fontSize": 0.2,
                    "text": "Klaverakke"
                }
            ],
            "position": {
                "x": 9.984000000002087,
                "y": 0.7394250334171142,
                "z": 2.3268743002063252
            },
            "rotation": {
                "_order": "XYZ",
                "_x": 0,
                "_y": -1.570796326794,
                "_z": 0,
                "isEuler": true
            },
            "textAlign": "left"
        },
        {
            "boundToGrid": false,
            "canvasIndex": 0,
            "color": "#171717",
            "maxWidth": 3.5,
            "paragraphs": [
                {
                    "fontFamily": "https://fonts.gstatic.com/s/bebasneue/v9/JTUSjIg69CK48gW7PXooxW4.ttf",
                    "fontSize": 0.2,
                    "text": "Navar"
                }
            ],
            "position": {
                "x": 9.984000000007397,
                "y": 0.725254066460451,
                "z": 8.247991139886144
            },
            "rotation": {
                "_order": "XYZ",
                "_x": 0,
                "_y": -1.570796326794,
                "_z": 0,
                "isEuler": true
            },
            "textAlign": "left"
        },
        {
            "boundToGrid": false,
            "canvasIndex": 0,
            "color": "#171717",
            "maxWidth": 4.5,
            "paragraphs": [
                {
                    "fontFamily": "https://fonts.gstatic.com/s/bebasneue/v9/JTUSjIg69CK48gW7PXooxW4.ttf",
                    "fontSize": 0.2,
                    "text": "Medrag"
                }
            ],
            "position": {
                "x": 9.984000000013362,
                "y": 0.7913363470286439,
                "z": 14.906374518307466
            },
            "rotation": {
                "_order": "XYZ",
                "_x": 0,
                "_y": -1.570796326794,
                "_z": 0,
                "isEuler": true
            },
            "textAlign": "left"
        },
        {
            "boundToGrid": false,
            "canvasIndex": 1,
            "color": "#171717",
            "maxWidth": 3,
            "paragraphs": [
                {
                    "fontFamily": "https://fonts.gstatic.com/s/bebasneue/v9/JTUSjIg69CK48gW7PXooxW4.ttf",
                    "fontSize": 0.2,
                    "text": "Rykk"
                }
            ],
            "position": {
                "x": -9.984000000009207,
                "y": 0.7841027707960873,
                "z": 10.268821662819771
            },
            "rotation": {
                "_order": "XYZ",
                "_x": 0,
                "_y": 1.570796326794,
                "_z": 0,
                "isEuler": true
            },
            "textAlign": "left"
        },
        {
            "boundToGrid": false,
            "canvasIndex": 1,
            "color": "#171717",
            "maxWidth": 3,
            "paragraphs": [
                {
                    "fontFamily": "https://fonts.gstatic.com/s/bebasneue/v9/JTUSjIg69CK48gW7PXooxW4.ttf",
                    "fontSize": 0.2,
                    "text": "Drivgarn"
                }
            ],
            "position": {
                "x": -9.98400000000361,
                "y": 0.7668618637037499,
                "z": 4.026999027560275
            },
            "rotation": {
                "_order": "XYZ",
                "_x": 0,
                "_y": 1.570796326794,
                "_z": 0,
                "isEuler": true
            },
            "textAlign": "left"
        },
        {
            "boundToGrid": false,
            "canvasIndex": 1,
            "color": "#171717",
            "maxWidth": 4,
            "paragraphs": [
                {
                    "fontFamily": "https://fonts.gstatic.com/s/bebasneue/v9/JTUSjIg69CK48gW7PXooxW4.ttf",
                    "fontSize": 0.2,
                    "text": "Åre"
                }
            ],
            "position": {
                "x": -9.983999999997438,
                "y": 0.7456085033322006,
                "z": -2.8534925765656434
            },
            "rotation": {
                "_order": "XYZ",
                "_x": 0,
                "_y": 1.570796326794,
                "_z": 0,
                "isEuler": true
            },
            "textAlign": "left"
        },
        {
            "boundToGrid": false,
            "canvasIndex": 1,
            "color": "#171717",
            "maxWidth": 3,
            "paragraphs": [
                {
                    "fontFamily": "https://fonts.gstatic.com/s/bebasneue/v9/JTUSjIg69CK48gW7PXooxW4.ttf",
                    "fontSize": 0.2,
                    "text": "Sjøkiste"
                }
            ],
            "position": {
                "x": -9.983999999992243,
                "y": 0.766912243973787,
                "z": -8.650209621941785
            },
            "rotation": {
                "_order": "XYZ",
                "_x": 0,
                "_y": 1.570796326794,
                "_z": 0,
                "isEuler": true
            },
            "textAlign": "left"
        },
        {
            "boundToGrid": false,
            "canvasIndex": 1,
            "color": "#171717",
            "maxWidth": 4,
            "paragraphs": [
                {
                    "fontFamily": "https://fonts.gstatic.com/s/bebasneue/v9/JTUSjIg69CK48gW7PXooxW4.ttf",
                    "fontSize": 0.2,
                    "text": "Garnkniv"
                }
            ],
            "position": {
                "x": -9.983999999986175,
                "y": 0.7676272535487993,
                "z": -15.414800264080462
            },
            "rotation": {
                "_order": "XYZ",
                "_x": 0,
                "_y": 1.570796326794,
                "_z": 0,
                "isEuler": true
            },
            "textAlign": "left"
        },
        {
            "boundToGrid": false,
            "canvasIndex": 1,
            "color": "#171717",
            "maxWidth": 4,
            "paragraphs": [
                {
                    "fontFamily": "https://fonts.gstatic.com/s/bebasneue/v9/JTUSjIg69CK48gW7PXooxW4.ttf",
                    "fontSize": 0.2,
                    "text": "Forhyse"
                }
            ],
            "position": {
                "x": -9.984000000014795,
                "y": 0.75,
                "z": 16.5
            },
            "rotation": {
                "_order": "XYZ",
                "_x": 0,
                "_y": 1.570796326794,
                "_z": 0,
                "isEuler": true
            },
            "textAlign": "left"
        }
    ],
    "videos": [
        {
            "autoPlay": false,
            "boundToGrid": false,
            "canvasIndex": 2,
            "dmsId": "019EE8EBh4gbJ",
            "height": 3.490666666666671,
            "loop": false,
            "position": {
                "x": -5.877844818689547,
                "y": 2.753627894694441,
                "z": -19.979000000000013
            },
            "rotation": {
                "_order": "XYZ",
                "_x": 0,
                "_y": 0,
                "_z": 0,
                "isEuler": true
            },
            "volume": 1,
            "width": 5.236
        },
        {
            "autoPlay": false,
            "boundToGrid": false,
            "canvasIndex": 2,
            "dmsId": "019EE8EBh4gfb",
            "height": 3.4906666666666633,
            "loop": false,
            "position": {
                "x": 0.020025321007128394,
                "y": 2.7501812041872897,
                "z": -19.978999999999992
            },
            "rotation": {
                "_order": "XYZ",
                "_x": 0,
                "_y": 0,
                "_z": 0,
                "isEuler": true
            },
            "volume": 1,
            "width": 5.236
        },
        {
            "autoPlay": false,
            "boundToGrid": false,
            "canvasIndex": 2,
            "dmsId": "019EE8EBh4gbB",
            "height": 3.4906666666666637,
            "loop": false,
            "position": {
                "x": 5.856607376531785,
                "y": 2.741440419747861,
                "z": -19.97900000000005
            },
            "rotation": {
                "_order": "XYZ",
                "_x": 0,
                "_y": 0,
                "_z": 0,
                "isEuler": true
            },
            "volume": 1,
            "width": 5.236
        },
        {
            "autoPlay": false,
            "boundToGrid": false,
            "canvasIndex": 0,
            "dmsId": "019EE8EBh4gbD",
            "height": 3.4906666666666664,
            "loop": false,
            "position": {
                "x": 9.983999999985771,
                "y": 2.7389690907119086,
                "z": -15.862841268944477
            },
            "rotation": {
                "_order": "XYZ",
                "_x": 0,
                "_y": -1.570796326794,
                "_z": 0,
                "isEuler": true
            },
            "volume": 1,
            "width": 5.236
        },
        {
            "autoPlay": false,
            "boundToGrid": false,
            "canvasIndex": 0,
            "dmsId": "019EE8EBh4gb9",
            "height": 3.4906666666666664,
            "loop": false,
            "position": {
                "x": 9.983999999991346,
                "y": 2.7525127399362184,
                "z": -9.680654682225926
            },
            "rotation": {
                "_order": "XYZ",
                "_x": 0,
                "_y": -1.570796326794,
                "_z": 0,
                "isEuler": true
            },
            "volume": 1,
            "width": 5.236
        },
        {
            "autoPlay": false,
            "boundToGrid": false,
            "canvasIndex": 0,
            "dmsId": "019EE8EBh4gWn",
            "height": 3.4906666666666664,
            "loop": false,
            "position": {
                "x": 9.983999999996929,
                "y": 2.724333333333333,
                "z": -3.4229999999999996
            },
            "rotation": {
                "_order": "XYZ",
                "_x": 0,
                "_y": -1.570796326794,
                "_z": 0,
                "isEuler": true
            },
            "volume": 1,
            "width": 5.236
        },
        {
            "autoPlay": false,
            "boundToGrid": false,
            "canvasIndex": 0,
            "dmsId": "019EE8EBh4gWr",
            "height": 3.4906666666666664,
            "loop": false,
            "position": {
                "x": 9.984000000002602,
                "y": 2.711808488269392,
                "z": 2.9006121175960247
            },
            "rotation": {
                "_order": "XYZ",
                "_x": 0,
                "_y": -1.570796326794,
                "_z": 0,
                "isEuler": true
            },
            "volume": 1,
            "width": 5.236
        },
        {
            "autoPlay": false,
            "boundToGrid": false,
            "canvasIndex": 0,
            "dmsId": "019EE8EBh4gSL",
            "height": 3.4906666666666664,
            "loop": false,
            "position": {
                "x": 9.984000000008185,
                "y": 2.6814343885253433,
                "z": 9.10332535336237
            },
            "rotation": {
                "_order": "XYZ",
                "_x": 0,
                "_y": -1.570796326794,
                "_z": 0,
                "isEuler": true
            },
            "volume": 1,
            "width": 5.236
        },
        {
            "autoPlay": false,
            "boundToGrid": false,
            "canvasIndex": 0,
            "dmsId": "019EE8EBh4gSU",
            "height": 3.3960000000000017,
            "loop": false,
            "position": {
                "x": 9.984000000013651,
                "y": 2.713836576956605,
                "z": 15.21656385934676
            },
            "rotation": {
                "_order": "XYZ",
                "_x": 0,
                "_y": -1.570796326794,
                "_z": 0,
                "isEuler": true
            },
            "volume": 1,
            "width": 5.094
        },
        {
            "autoPlay": false,
            "boundToGrid": false,
            "canvasIndex": 1,
            "dmsId": "019EE8EBh4gfZ",
            "height": 3.490666666666666,
            "loop": false,
            "position": {
                "x": -9.983999999996898,
                "y": 2.7408684381409847,
                "z": -3.4698712918743557
            },
            "rotation": {
                "_order": "XYZ",
                "_x": 0,
                "_y": 1.570796326794,
                "_z": 0,
                "isEuler": true
            },
            "volume": 1,
            "width": 5.236
        },
        {
            "autoPlay": false,
            "boundToGrid": false,
            "canvasIndex": 1,
            "dmsId": "019EE8EBh5Mrp",
            "height": 3.4906666666666655,
            "loop": false,
            "position": {
                "x": -9.984000000002643,
                "y": 2.789761607096282,
                "z": 2.9259235748037105
            },
            "rotation": {
                "_order": "XYZ",
                "_x": 0,
                "_y": 1.570796326794,
                "_z": 0,
                "isEuler": true
            },
            "volume": 1,
            "width": 5.236
        },
        {
            "autoPlay": false,
            "boundToGrid": false,
            "canvasIndex": 1,
            "dmsId": "019EE8EBh5Mw6",
            "height": 3.490666666666665,
            "loop": false,
            "position": {
                "x": -9.984000000008193,
                "y": 2.784613001692438,
                "z": 9.153486225714268
            },
            "rotation": {
                "_order": "XYZ",
                "_x": 0,
                "_y": 1.570796326794,
                "_z": 0,
                "isEuler": true
            },
            "volume": 1,
            "width": 5.236
        },
        {
            "autoPlay": false,
            "boundToGrid": false,
            "canvasIndex": 1,
            "dmsId": "019EE8EBk2KbW",
            "height": 3.490666666666666,
            "loop": false,
            "position": {
                "x": -9.983999999991253,
                "y": 2.758452493107991,
                "z": -9.761970011448847
            },
            "rotation": {
                "_order": "XYZ",
                "_x": 0,
                "_y": 1.570796326794,
                "_z": 0,
                "isEuler": true
            },
            "volume": 1,
            "width": 5.236
        },
        {
            "autoPlay": false,
            "boundToGrid": false,
            "canvasIndex": 1,
            "dmsId": "019EE8EBh4gSP",
            "height": 3.490666666666667,
            "loop": true,
            "position": {
                "x": -9.983999999985652,
                "y": 2.75,
                "z": -16
            },
            "rotation": {
                "_order": "XYZ",
                "_x": 0,
                "_y": 1.570796326794,
                "_z": 0,
                "isEuler": true
            },
            "volume": 1,
            "width": 5.236
        },
        {
            "autoPlay": false,
            "boundToGrid": false,
            "canvasIndex": 1,
            "dmsId": "019EE8EBh4gWm",
            "height": 3.4906666666666664,
            "loop": true,
            "position": {
                "x": -9.984000000014232,
                "y": 2.7616345194387524,
                "z": 15.872606123632977
            },
            "rotation": {
                "_order": "XYZ",
                "_x": 0,
                "_y": 1.570796326794,
                "_z": 0,
                "isEuler": true
            },
            "volume": 1,
            "width": 5.236
        }
    ],
    "about": {
        "museum": {
            "name": "Romsdalsmuseet",
            "url": "/owners/ROM"
        },
        "exhibition": {
            "name": "15 MARITIME GREIER",
            "url": "/virtual-experiences/2fae45d7-3ab5-456a-a723-c256f4a9223e"
        }
    }
}