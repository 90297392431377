import { useEffect, useMemo, useRef, useState } from 'react';
import { useFrame, useLoader, useThree } from '@react-three/fiber';
import { getAudioUrl } from '@ekultur/dms-url-generator';
import { AudioLoader, AudioListener } from 'three';
import { Stopwatch } from '../../components/Stopwatch';
import { SET_HAS_AUDIO, useThreeDDispatch, useThreeDState } from '../App/ThreeDContext';

export const Audio = ({
  url,
  dmsId,
  volume = 1,
  loop = true,
  paused = false,
  onProgressChange,
  onEnded,
}) => {
  const { audioMute, hasAudio } = useThreeDState();
  const dispatch = useThreeDDispatch();
  const { camera } = useThree();
  const [listener] = useState(() => new AudioListener());
  const sound = useRef();
  const buffer = useLoader(AudioLoader, dmsId ? getAudioUrl(dmsId) : url);
  const stopwatch = useMemo(() => new Stopwatch(), []);

  useFrame(() => {
    if (!sound.current || paused) return;

    if (onProgressChange) {
      const percent = stopwatch.elapsedTime() / sound.current.buffer.duration * 100;
      onProgressChange(percent);
    }
  });

  useEffect(() => {
    camera.add(listener);

    return () => {
      camera.remove(listener);
    }
  });

  useEffect(() => {
    if (paused && sound.current.isPlaying) {
      sound.current.pause();
      stopwatch.stop();
    }
    else if (!paused && !sound.current.isPlaying) {
      sound.current.setVolume(audioMute ? 0 : volume);
      sound.current.play();
      stopwatch.start();
    }
  }, [paused]);

  useEffect(() => {
    !hasAudio && dispatch({ type: SET_HAS_AUDIO, hasAudio: true });
    sound.current.setBuffer(buffer);
    sound.current.setLoop(loop);
    sound.current.setVolume(audioMute ? 0 : volume);
  }, [buffer, loop, volume, audioMute]);

  const handleEnded = () => {
    if (!loop) {
      sound.current.stop();
      stopwatch.reset();
    }

    onEnded && onEnded(loop);
  };

  return (
    <audio
      ref={sound}
      args={[listener]}
      onEnded={handleEnded}
    />
  );
};
