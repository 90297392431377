import * as THREE from 'three';
import * as Icon from '@mui/icons-material';
import { AccordionDetails, Button, Checkbox, Divider, FormControlLabel, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { computeBoundingBoxSize, areVectorsEqual } from '../../../helpers';
import { SliderInput } from '../../../components/SliderInput';
import { GroupSegment } from '../../../components/GroupSegment';
import { CanvasItemTypes } from '../../../helpers/enums';
import { SET_MODELVIEWER_DATA, useThreeDDispatch } from '../../App/ThreeDContext';

export const ModelSettings = ({
  index,
  modelItem,
  canvasHeight,
  canvasWidth,
  cellSize,
  groups,
  onUpdate,
  onRemove,
  onGroupUpdate,
  getSceneElements,
}) => {
  const dispatch = useThreeDDispatch();
  const [params, setParams] = useState();
  const defaultPedestalHeight = 0.75;
  const minimumPedestalHeight = 0.15;
  const minimumPedestalScale = cellSize;
  const pedestalMarginFactor = 1.1;

  const update = (model) => {
    setParams(getParams(model));
    onUpdate && onUpdate(index, model);
  };

  const getScaleLimit = (localPosition, size) => {
    const topEdge = localPosition.y + (size.z / 2);
    const bottomEdge = localPosition.y - (size.z / 2);
    const rightEdge = localPosition.x + (size.x / 2);
    const leftEdge = localPosition.x - (size.x / 2);

    const topLimit = canvasHeight / 2;
    const bottomLimit = canvasHeight / -2;
    const rightLimit = canvasWidth / 2;
    const leftLimit = canvasWidth / -2;

    const topScaleMax = modelItem.scale.y * (((topLimit - topEdge) * 2) + size.z) / size.z;
    const bottomScaleMax = modelItem.scale.y * (((bottomEdge - bottomLimit) * 2) + size.z) / size.z;
    const rightScaleMax = modelItem.scale.x * (((rightLimit - rightEdge) * 2) + size.x) / size.x;
    const leftScaleMax = modelItem.scale.x * (((leftEdge - leftLimit) * 2) + size.x) / size.x;

    return Math.min(topScaleMax, bottomScaleMax, rightScaleMax, leftScaleMax);
  };

  const getParams = (obj) => {
    modelItem = obj ?? modelItem;
    let { canvasRef, itemRef: modelRef, pedestalRef, gimRef } = getSceneElements();

    if (!canvasRef || !modelRef) return null;

    const localPosition = canvasRef.worldToLocal(modelItem.position.clone());
    const bbox = new THREE.Box3().setFromObject(modelRef.parent);
    const size = computeBoundingBoxSize(modelRef);
    const scaleLimit = getScaleLimit(localPosition, size);

    return {
      xPosition: {
        value: modelItem.boundToGrid
          ? (localPosition.x - (size.x / 2) + (canvasWidth / 2) + cellSize) / cellSize
          : localPosition.x - (size.x / 2) + (canvasWidth / 2),
        min: modelItem.boundToGrid ? 1 : 0,
        max: modelItem.boundToGrid
          ? (canvasWidth / cellSize) - Math.ceil(size.x / cellSize) + 1
          : canvasWidth - size.x,
        step: modelItem.boundToGrid ? 1 : 0.001,
        onChange: (value, last) => {
          gimRef = gimRef ?? getSceneElements().gimRef;
          const scaleOffset = (Math.ceil(size.x / cellSize) - 1) * cellSize / 2;
          value = modelItem.boundToGrid
            ? ((value - 1) * cellSize) - ((size.x - cellSize) / 2) + scaleOffset
            : value;
          const newPosition = localPosition.clone().setX(value - (canvasWidth / 2) + (size.x / 2));
          newPosition.copy(canvasRef.localToWorld(newPosition));

          if (last && !modelItem.position.equals(newPosition)) {
            modelItem.position.copy(newPosition);
            update(modelItem);
          }
          else {
            modelRef.parent.parent.position.copy(newPosition);
            gimRef?.position?.copy(newPosition);
          }
        },
      },
      yPosition: {
        value: modelItem.boundToGrid
          ? (localPosition.y - (size.y / 2) + (canvasHeight / 2) + cellSize) / cellSize
          : localPosition.y - (size.y / 2) + (canvasHeight / 2),
        min: modelItem.boundToGrid ? 1 : 0,
        max: modelItem.boundToGrid
          ? (canvasHeight / cellSize) - Math.ceil(size.y / cellSize) + 1
          : canvasHeight - size.y,
        step: modelItem.boundToGrid ? 1 : 0.001,
        onChange: (value, last) => {
          gimRef = gimRef ?? getSceneElements().gimRef;
          const scaleOffset = (Math.ceil(size.y / cellSize) - 1) * cellSize / 2;
          value = modelItem.boundToGrid
            ? ((value - 1) * cellSize) - ((size.y - cellSize) / 2) + scaleOffset
            : value;
          const newPosition = localPosition.clone().setY(value - (canvasHeight / 2) + (size.y / 2));
          newPosition.copy(canvasRef.localToWorld(newPosition));

          if (last && !modelItem.position.equals(newPosition)) {
            modelItem.position.copy(newPosition);
            update(modelItem);
          }
          else {
            modelRef.parent.parent.position.copy(newPosition);
            gimRef?.position?.copy(newPosition);
          }
        },
      },
      zPosition: {
        value: localPosition.z - ((bbox.max.y - bbox.min.y) / 2),
        min: 0 + (!!modelItem.pedestal ? minimumPedestalHeight : 0),
        max: Math.min(canvasWidth, canvasHeight),
        step: 0.001,
        onChange: (value, last) => {
          gimRef = gimRef ?? getSceneElements().gimRef;
          const newPosition = localPosition.clone();
          newPosition.setZ(value + ((bbox.max.y - bbox.min.y) / 2));
          newPosition.copy(canvasRef.localToWorld(newPosition));

          if (last && !areVectorsEqual(modelItem.position, newPosition)) {
            modelItem.position.copy(newPosition);

            if (modelItem.pedestal) {
              modelItem.pedestal.scale.y = value;
              modelItem.pedestal.position.setY((value + bbox.max.y - bbox.min.y) / -2);
            }

            update(modelItem);
          }
          else {
            modelRef.parent.parent.position.copy(newPosition);
            gimRef?.position?.copy(newPosition);

            if (pedestalRef) {
              pedestalRef.position.setY((value + bbox.max.y - bbox.min.y) / -2);
              pedestalRef.scale.y = value / modelItem.pedestal.scale.y;
            }
          }
        },
      },
      xRotation: {
        value: THREE.MathUtils.radToDeg(modelItem.rotation.x),
        min: 0,
        max: 360,
        step: 0.1,
        onChange: (value, last) => {
          gimRef = gimRef ?? getSceneElements().gimRef;
          const rotation = modelItem.rotation.clone();
          rotation.x = THREE.MathUtils.degToRad(value);
          modelRef.parent.rotation.setFromVector3(rotation);
          gimRef?.rotation?.copy(rotation);

          const updatedBbox = new THREE.Box3().setFromObject(modelRef.parent);
          const heightOffset = ((updatedBbox.max.y - bbox.max.y) - (updatedBbox.min.y - bbox.min.y)) / 2;
          modelRef.parent.parent.position.setY(modelItem.position.y + heightOffset);
          pedestalRef && pedestalRef.position.setY(modelItem.pedestal.position.y - heightOffset);
          gimRef?.position?.setY(modelItem.position.y + heightOffset);

          if (last && !modelItem.rotation.equals(rotation)) {
            modelItem.rotation.copy(rotation);
            modelItem.position.setY(modelItem.position.y + heightOffset);
            modelItem.pedestal && modelItem.pedestal.position.setY(modelItem.pedestal.position.y - heightOffset);
            update(modelItem);
          }
        },
      },
      yRotation: {
        value: THREE.MathUtils.radToDeg(modelItem.rotation.y),
        min: 0,
        max: 360,
        step: 0.1,
        onChange: (value, last) => {
          gimRef = gimRef ?? getSceneElements().gimRef;
          const rotation = modelItem.rotation.clone();
          rotation.y = THREE.MathUtils.degToRad(value);
          modelRef.parent.rotation.setFromVector3(rotation);
          gimRef?.rotation?.copy(rotation);

          const updatedBbox = new THREE.Box3().setFromObject(modelRef.parent);
          const heightOffset = ((updatedBbox.max.y - bbox.max.y) - (updatedBbox.min.y - bbox.min.y)) / 2;
          modelRef.parent.parent.position.setY(modelItem.position.y + heightOffset);
          pedestalRef && pedestalRef.position.setY(modelItem.pedestal.position.y - heightOffset);
          pedestalRef && modelItem.pedestal.lockedRotation && pedestalRef.rotation.set(0, rotation.y, 0);
          gimRef?.position?.setY(modelItem.position.y + heightOffset);

          if (last && !modelItem.rotation.equals(rotation)) {
            modelItem.rotation.copy(rotation);
            modelItem.position.setY(modelItem.position.y + heightOffset);
            modelItem.pedestal && modelItem.pedestal.position.setY(modelItem.pedestal.position.y - heightOffset);
            update(modelItem);
          }
        },
      },
      zRotation: {
        value: THREE.MathUtils.radToDeg(modelItem.rotation.z),
        min: 0,
        max: 360,
        step: 0.1,
        onChange: (value, last) => {
          gimRef = gimRef ?? getSceneElements().gimRef;
          const rotation = modelItem.rotation.clone();
          rotation.z = THREE.MathUtils.degToRad(value);
          modelRef.parent.rotation.setFromVector3(rotation);
          gimRef?.rotation?.copy(rotation);

          const updatedBbox = new THREE.Box3().setFromObject(modelRef.parent);
          const heightOffset = ((updatedBbox.max.y - bbox.max.y) - (updatedBbox.min.y - bbox.min.y)) / 2;
          modelRef.parent.parent.position.setY(modelItem.position.y + heightOffset);
          pedestalRef && pedestalRef.position.setY(modelItem.pedestal.position.y - heightOffset);
          gimRef?.position?.setY(modelItem.position.y + heightOffset);

          if (last && !modelItem.rotation.equals(rotation)) {
            modelItem.rotation.copy(rotation);
            modelItem.position.setY(modelItem.position.y + heightOffset);
            modelItem.pedestal && modelItem.pedestal.position.setY(modelItem.pedestal.position.y - heightOffset);
            update(modelItem);
          }
        },
      },
      scale: {
        value: modelItem.scale.x,
        min: 0.1,
        max: scaleLimit,
        step: 0.001,
        onChange: (value, last) => {
          gimRef = gimRef ?? getSceneElements().gimRef;
          const scaleDiff = (value - modelItem.scale.x) / modelItem.scale.x;
          const heightOffset = (bbox.max.y - bbox.min.y) * scaleDiff / 2;

          if (last && scaleDiff !== 0) {
            if (modelItem.pedestal) {
              modelItem.pedestal.scale.set(
                Math.max((size.x + (size.x * scaleDiff)) * pedestalMarginFactor, minimumPedestalScale),
                modelItem.pedestal.scale.y,
                Math.max((size.z + (size.z * scaleDiff)) * pedestalMarginFactor, minimumPedestalScale),
              );
              modelItem.pedestal.position.setY(modelItem.pedestal.position.y - heightOffset);
            };

            modelItem.position.setY(modelItem.position.y + heightOffset);
            modelItem.scale.set(value, value, value);
            gimRef?.scale?.set(1, 1, 1);
            update(modelItem);
          }
          else {
            if (pedestalRef) {
              pedestalRef.scale.set(
                (1 + scaleDiff) * modelItem.pedestal.scale.x > minimumPedestalScale
                  ? Math.max(
                      Math.min(
                        1 + scaleDiff,
                        (size.x + (size.x * scaleDiff)) * pedestalMarginFactor / modelItem.pedestal.scale.x),
                      minimumPedestalScale / modelItem.pedestal.scale.x)
                  : minimumPedestalScale / modelItem.pedestal.scale.x,
                1,
                (1 + scaleDiff) * modelItem.pedestal.scale.z > minimumPedestalScale
                  ? Math.max(
                      Math.min(
                        1 + scaleDiff,
                        (size.z + (size.z * scaleDiff)) * pedestalMarginFactor / modelItem.pedestal.scale.z),
                      minimumPedestalScale / modelItem.pedestal.scale.z)
                  : minimumPedestalScale / modelItem.pedestal.scale.z,
              );
              pedestalRef.position.setY(modelItem.pedestal.position.y - heightOffset);
            }

            modelRef.parent.scale.set(value, value, value);
            modelRef.parent.parent.position.setY(modelItem.position.y + heightOffset);
            gimRef?.scale?.set(1 + scaleDiff, 1 + scaleDiff, 1 + scaleDiff);
            gimRef?.position?.setY(modelItem.position.y + heightOffset);
          }
        },
      },
      boundToGrid: {
        value: modelItem.boundToGrid,
        onChange: (event) => {
          modelItem.boundToGrid = event.target.checked;
          update(modelItem);
        },
      },
      pedestal: {
        value: !!modelItem.pedestal,
        onChange: (event) => {
          const height = bbox.max.y - bbox.min.y;

          if (event.target.checked) {
            const pedestalHeight = Math.max(defaultPedestalHeight, localPosition.z - (height / 2));
            modelItem.position.setY(canvasRef.position.y + (height / 2) + pedestalHeight);
            modelItem.pedestal = {
              scale: new THREE.Vector3(
                Math.max(size.x * pedestalMarginFactor, minimumPedestalScale),
                pedestalHeight,
                Math.max(size.z * pedestalMarginFactor, minimumPedestalScale),
              ),
              position: new THREE.Vector3(0, (height + pedestalHeight) / -2, 0),
              rotation: new THREE.Euler(0, modelItem.rotation.y, 0),
              lockedRotation: true,
            };
          }
          else {
            modelItem.position.setY(canvasRef.position.y + (height / 2));
            modelItem.pedestal = null;
          }

          update(modelItem);
        },
      },
      pedestalLockedRotation: {
        value: modelItem.pedestal?.lockedRotation ?? true,
        onChange: (event) => {
          modelItem.pedestal.lockedRotation = event.target.checked;
          modelItem.pedestal.rotation = event.target.checked ? modelItem.rotation.clone() : new THREE.Vector3();
          update(modelItem);
        },
      },
      pedestalRotation: {
        value: THREE.MathUtils.radToDeg(modelItem.pedestal?.rotation?.y ?? 0),
        min: 0,
        max: 360,
        step: 0.1,
        onChange: (value, last) => {
          modelItem.pedestal.rotation.y = THREE.MathUtils.degToRad(value);
          pedestalRef && pedestalRef.rotation.set(
            pedestalRef.rotation.x,
            modelItem.pedestal.rotation.y,
            pedestalRef.rotation.z,
          );
          last && update(modelItem);
        },
      },
    };
  };

  const handleGroupChange = (groupId) => {
    const groupChange = {};

    if (Number.isInteger(groupId)) {
      modelItem.group && (groupChange.oldGroupId = modelItem.group.id);
      groupChange.newGroupId = groupId;

      modelItem.group = {
        id: groupId,
        index: groups.find(group => group.id === groupId).itemCount,
      };
    }
    else {
      groupChange.oldGroupId = modelItem.group.id;
      delete modelItem.group;
    }

    onGroupUpdate(index, modelItem, groupChange);
  };

  const handleGroupIndexChange = (index) => {
    const groupChange = {
      groupId: modelItem.group.id,
      oldIndex: modelItem.group.index,
    };

    modelItem.group.index = index;
    onGroupUpdate(index, modelItem, groupChange);
  };

  const handleModelViewerOpen = () => {
    dispatch({
      type: SET_MODELVIEWER_DATA,
      modelViewerData: {
        index: index,
        type: CanvasItemTypes.MODEL3D,
        viewerData: modelItem.viewerData ?? {
          model: {
            type: 'Object',
            dmsId: modelItem.dmsId,
          },
        },
      },
    });
  };

  useEffect(() => {
    setParams(getParams());
  }, [modelItem, modelItem.boundToGrid, modelItem.pedestal, modelItem.position.x, modelItem.position.y, modelItem.position.z,
    modelItem.rotation.x, modelItem.rotation.y, modelItem.rotation.z, modelItem.scale.x, modelItem.scale.y]);

  return (
    <>
      {params && (
        <>
          <AccordionDetails>
            <div>
              <Typography>Posisjon</Typography>
              <SliderInput title={'X'} {...params.xPosition} />
              <SliderInput title={'Y'} {...params.yPosition} />
              <SliderInput title={'Z'} {...params.zPosition} />
            </div>
            <div>
              <Typography>Rotasjon</Typography>
              <SliderInput title={'X'} {...params.xRotation} />
              <SliderInput title={'Y'} {...params.yRotation} />
              <SliderInput title={'Z'} {...params.zRotation} />
            </div>
            <div>
              <Typography>Skalering</Typography>
              <SliderInput {...params.scale} />
            </div>
            <div>
              <FormControlLabel
                label="Følg grid"
                control={
                  <Checkbox
                    checked={params.boundToGrid.value}
                    onChange={params.boundToGrid.onChange}
                  />
                }
              />
              <FormControlLabel
                label="Står på sokkel"
                control={
                  <Checkbox
                    checked={params.pedestal.value}
                    onChange={params.pedestal.onChange}
                  />
                }
              />
            </div>
          </AccordionDetails>
          {modelItem.pedestal &&
            <>
              <Divider light />
              <AccordionDetails>
                <div>
                  <Typography className='subtitle'>Sokkel</Typography>
                  <FormControlLabel
                    label="Låst rotasjon"
                    control={
                      <Checkbox
                        checked={params.pedestalLockedRotation.value}
                        onChange={params.pedestalLockedRotation.onChange}
                      />
                    }
                  />
                  <SliderInput
                    title={'Y'}
                    {...params.pedestalRotation}
                    disabled={modelItem.pedestal.lockedRotation}
                  />
                </div>
              </AccordionDetails>
            </>
          }
          <Divider light />
          <GroupSegment
            id={modelItem.group?.id}
            index={modelItem.group?.index}
            groups={groups}
            onGroupChange={handleGroupChange}
            onGroupIndexChange={handleGroupIndexChange}
          />
          <Divider light />
          <AccordionDetails>
            <Button
              variant="outlined"
              size="small"
              startIcon={<Icon.EditOutlined fontSize="small" />}
              onClick={handleModelViewerOpen}
            >
              Rediger visning
            </Button>
            <Button
              variant="outlined"
              size="small"
              startIcon={<Icon.DeleteOutlined fontSize="small" />}
              onClick={() => onRemove(index)}
            >
              Slett 3D-modell
            </Button>
          </AccordionDetails>
        </>
      )}
    </>
  );
};
