import * as THREE from 'three';
import * as DREI from '@react-three/drei';
import {useLoader} from '@react-three/fiber';
import {getDmsUrl} from '@ekultur/dms-url-generator';
import {CanvasItemTypes, CursorTypes, VectorDirection} from './enums';
import {clone} from 'three/examples/jsm/utils/SkeletonUtils';
import {GLTFLoader} from 'three/examples/jsm/loaders/GLTFLoader';
import {DRACOLoader} from 'three/examples/jsm/loaders/DRACOLoader';
import {MeshoptDecoder} from 'three/examples/jsm/libs/meshopt_decoder.module.js';
import {KTX2Loader} from "./KTX2Loader";

export const isMobileDevice = () => {
    return /Android|iPhone/i.test(navigator.userAgent);
};

export const getExhibitionItems = (items, adminMode, interactiveMode) => {
    return items
        ? items.filter(item => !adminMode
            || (adminMode && !interactiveMode)
            || [undefined, null].includes(item.canvasIndex)
        ) : [];
};

export const setUserData = (object, userData) => {
    object.userData = userData;
    object.traverse(child => {
        child.userData = userData;
    });
};

export const enableShadows = object => {
    object.traverse(child => {
        if (child instanceof THREE.Mesh) {
            child.castShadow = true;
            child.receiveShadow = true;
        }
    });
};

export const enableLightEmissivity = materials => {
    const lightFixture = Object.values(materials).find(
        material => material.name === 'Ceiling_Lamp_Light' || material.name === 'LED_5500k'
    );

    if (lightFixture) {
        lightFixture.color = new THREE.Color('#FFFFFF');
        lightFixture.emissive = new THREE.Color('#FFFFFF');
        lightFixture.emissiveIntensity = 0.75;
    }
};

export const isCanvasItem = event => {
    const path = event.nativeEvent.path || (event.nativeEvent.composedPath && event.nativeEvent.composedPath());
    return !!path.find(obj => obj.children?.length > 0 && obj.children[0].localName === 'canvas');
};

export const isNavMesh = object => {
    return object.name === 'NavMesh';
};

export const isHidden = object => {
    if (object.visible) {
        return object.parent && isHidden(object.parent)
    } else {
        return true
    }
}

export const isTraversable = object => {
    if (object.userData?.traversable) {
        return true
    } else {
        return object.parent && isTraversable(object.parent)
    }
};

export const isNonInteractable = object => {
    if(object.userData?.nonInteractable) {
        return true
    } else {
        return object.parent && isNonInteractable(object.parent)
    }
};

export const isNavigableSurface = object => {
    const taggedAsFloor =
        object.name?.startsWith('Floor') ||
        object.name?.startsWith('Stairs') ||
        object.name?.startsWith('Ramp') ||
        object.name?.startsWith('Platform') ||
        object.name?.startsWith('Invisible_Stairs') ||
        object.name?.startsWith('Invisible_Floor') ||
        object.name?.startsWith('Invisible_Walkway') ||
        object.name?.startsWith('Road') ||
        object.name?.startsWith('Landscape') ||
        object.name?.startsWith('Path_') ||
        object.name?.startsWith('Cube024') ||
        object.name?.startsWith('Hall_Floor') ||
        object.name?.startsWith('Bridge_Top') ||
        object.name?.startsWith('NEW_Floor');

    if(taggedAsFloor) {
        return true
    } else {
        return object.parent && isNavigableSurface(object.parent)
    }
};

export const isShadow = object => {
    return object.name === 'Shadow';
};

export const getSceneElementByName = (elements, name) => {
    const nestedFindByName = name => elements =>
        elements.reduce((result, element) => {
            if(result) {
                return result
            } else if(element.name === name) {
                return element
            } else {
                return nestedFindByName(name)(element.children || [])
            }
        }, undefined);

    return nestedFindByName(name)(elements);
};

export const isRoom = object => {
    if(object.name?.startsWith('Room')) {
        return true
    } else {
        return object.parent && isRoom(object.parent)
    }
}

export const isWater = object => {
    if(object.name?.startsWith('River')) {
        return true
    } else {
        return object.parent && isWater(object.parent)
    }
}

export const distance = (point1, point2) => {
    const a = point2.x - point1.x;
    const b = point2.y - point1.y;
    const c = point2.z - point1.z;

    return Math.sqrt(a * a + b * b + c * c);
};

export const setCursor = cursorType => {
    const body = document.getElementById('3DCanvasContainer');

    if (body) {
        body.style.cursor = cursorType;

        switch (cursorType) {
            case CursorTypes.SHOE_PRINT:
                body.style.cursor = `url('${getDmsUrl('0136NQiVj3D5', '01')}?mediaType=image/svg%2Bxml') 12 12, auto`;
                break;
            case CursorTypes.EYE:
                body.style.cursor = `url('${getDmsUrl('0136NQiVj3D3', '01')}?mediaType=image/svg%2Bxml') 12 12, auto`;
                break;
            case CursorTypes.PLAY:
                body.style.cursor = `url('${getDmsUrl('0136NvAQwLQG', '01')}?mediaType=image/svg%2Bxml') 12 12, auto`;
                break;
            case CursorTypes.PAUSE:
                body.style.cursor = `url('${getDmsUrl('0136NvAQwLKq', '01')}?mediaType=image/svg%2Bxml') 12 12, auto`;
                break;
            default:
                body.style.cursor = cursorType;
                break;
        }
    }
};

export const getPositionInDirection = (position, direction, distance) => {
    const raycaster = new THREE.Raycaster();
    const target = new THREE.Vector3();

    raycaster.set(position, direction);
    raycaster.ray.at(distance, target);

    return target;
};

export const getPositionFromCamera = (camera, distance) => {
    const raycaster = new THREE.Raycaster();
    const target = new THREE.Vector3();

    raycaster.setFromCamera({x: 0, y: 0}, camera);
    raycaster.ray.at(distance, target);

    return target;
};

export const getIntersectsFromCamera = (camera, scene, coords) => {
    const raycaster = new THREE.Raycaster();
    raycaster.setFromCamera(coords, camera);
    return raycaster.intersectObjects(scene.children, true);
};

export const getPositionAboveFloor = (camera, scene, position) => {
    const raycaster = new THREE.Raycaster();
    raycaster.camera = camera;
    raycaster.set(position, VectorDirection.DOWN);
    const intersections = raycaster.intersectObjects(scene.children, true);
    const closestIntersection = intersections.length > 0 && intersections.reduce((acc, obj) => (obj.distance < acc.distance ? obj : acc));

    if (closestIntersection && (isNavigableSurface(closestIntersection.object) || isNavMesh(closestIntersection.object))) {
        return addPlayerHeightOffset(closestIntersection.point);
    }

    return null;
};


export const getDirectionOfViewTarget = (position, rotation) => {
    const raycaster = new THREE.Raycaster();
    const target = new THREE.Vector3();

    raycaster.set(position, rotation);
    raycaster.ray.at(0.001, target);

    return target;
};

export const playAnimation = (actions, animationName, repeat = true) => {
    const animation = actions[animationName];
    if (animation) {
        !repeat && (animation.repetitions = 1);
        !repeat && (animation.clampWhenFinished = true);
        animation.play();
    }
};

export const addPlayerHeightOffset = (position) => {
    return new THREE.Vector3(
        position?.x ?? 0,
        (position?.y ?? 0) + 1.75,
        position?.z ?? 0
    );
};

export const detectCollisionBetween = (camera, scene, position, destination) => {
    const raycaster = new THREE.Raycaster();
    const distance = position.distanceTo(destination) + 0.1;
    const direction = new THREE.Vector3().subVectors(destination, position).normalize();
    raycaster.camera = camera;
    raycaster.set(position, direction);
    const intersects = raycaster.intersectObjects(scene.children, true);
    let closestIntersection = intersects.filter(obj => !isHidden(obj.object));
    closestIntersection = intersects.length > 0 && closestIntersection.length > 0
        ? closestIntersection.reduce((acc, obj) => (obj.distance < acc.distance ? obj : acc))
        : null;
    return closestIntersection ? closestIntersection.distance <= distance : false;
};

export const detectGeometryCollision = (scene, mesh) => {
    let collision = false;
    let vertices = [];
    const originPoint = mesh.position.clone();
    mesh.updateMatrix();

    for (let i = 0; i < mesh.geometry.attributes.position.count; i++) {
        const vertex = new THREE.Vector3().fromBufferAttribute(mesh.geometry.attributes.position, i);

        if (!vertices.find(obj => obj.equals(vertex))) {
            vertices.push(vertex);
        }
    }

    for (const vertex of vertices) {
        const localVertex = vertex.clone();
        const globalVertex = localVertex.applyMatrix4(mesh.matrix);
        const directionVector = globalVertex.sub(mesh.position);
        const ray = new THREE.Raycaster(
            originPoint,
            directionVector.clone().normalize(),
            0,
            directionVector.length(),
        );
        const collisionResults = ray
            .intersectObjects(scene.children, true)
            .filter(obj => obj.object !== mesh && !isTraversable(obj.object));

        if (collisionResults.length > 0) {
            collision = true;
            break;
        }
    }

    return collision;
};

export const isAnimatedModel = (model) => {
    let animated = false;
    model.traverse(obj => {
        if (obj instanceof THREE.SkinnedMesh)
            animated = true;
    });
    return animated;
};

export const copyScene = (scene) => {
    return isAnimatedModel(scene)
        ? clone(scene)
        : scene.clone();
};

export const preloadMesh = (object) => {
    object && object.traverse(obj => {
        if (obj instanceof THREE.Mesh) {
            obj.frustumCulled = false;
            obj.onAfterRender = () => {
                obj.frustumCulled = true;
                obj.onAfterRender = () => {
                };
            };
        }
    });
};

export const centerModel = (model) => {
    const bbox = new THREE.Box3().setFromObject(model.clone());
    bbox.getCenter(model.position);
    model.position.multiplyScalar(-1);
};

export const sphericalDegToRad = (elevation, azimuth) => {
    const phi = THREE.MathUtils.degToRad(90 - elevation);
    const theta = THREE.MathUtils.degToRad(azimuth);
    return new THREE.Vector3().setFromSphericalCoords(0.001, phi, theta);
};

export const getSpherePositions = (segments) => {
    const geometry = new THREE.SphereGeometry(1, segments.width, segments.height + 1);
    const elements = (Array(geometry.parameters.heightSegments).fill().map((_, x) => x * geometry.parameters.widthSegments + x));
    let positions = [];

    for (let i = 0; i < geometry.attributes.position.count; i++) {
        const position = new THREE.Vector3().fromBufferAttribute(geometry.attributes.position, i);

        if (
            i > geometry.parameters.widthSegments &&
            i < geometry.attributes.position.count - geometry.parameters.widthSegments - 1 &&
            !elements.includes(i)
        ) {
            positions.push(position);
        }
    }

    return positions;
};

export const useGLTF = (url) => {
    return useLoader(GLTFLoader, url, (loader) => {
        const dracoLoader = new DRACOLoader();
        dracoLoader.setDecoderPath('https://www.gstatic.com/draco/versioned/decoders/1.5.5/');
        loader.setDRACOLoader(dracoLoader);

        const ktxLoader = new KTX2Loader()
            .setTranscoderPath('https://kit-artifacts.s3.eu-west-1.amazonaws.com/basis_transcoder/')
            .detectSupport(new THREE.WebGLRenderer());
        loader.setKTX2Loader(ktxLoader);
        loader.setMeshoptDecoder(MeshoptDecoder);
    });
};

export const GetImageDimensions = (url) => {
    return new Promise(resolve => {
        new THREE.TextureLoader().load(url, (texture) => {
            resolve({
                width: texture.image.width,
                height: texture.image.height,
            });
        });
    })
};

export const GetVideoDimensions = (dmsId) => {
    const url = `https://dms-cf-01.dimu.org/multimedia/${dmsId}.mp4?mmid=${dmsId}`;

    return new Promise(resolve => {
        const video = document.createElement('video');
        video.addEventListener('loadedmetadata', () => {
            resolve({
                width: video.videoWidth,
                height: video.videoHeight,
            });
        }, false);
        video.src = url;
    });
};

export const GetModelDimensions = (url) => {
    DREI.useGLTF.preload(url);
    const gltfLoader = new GLTFLoader();

    const dracoLoader = new DRACOLoader();
    dracoLoader.setDecoderPath('https://www.gstatic.com/draco/versioned/decoders/1.5.5/');
    gltfLoader.setDRACOLoader(dracoLoader);

    const ktxLoader = new KTX2Loader()
        .setTranscoderPath('https://kit-artifacts.s3.eu-west-1.amazonaws.com/basis_transcoder/')
        .detectSupport(new THREE.WebGLRenderer());
    gltfLoader.setKTX2Loader(ktxLoader);
    gltfLoader.setMeshoptDecoder(MeshoptDecoder);

    return new Promise(resolve => {
        gltfLoader.load(
            url,
            (data) => {
                const size = new THREE.Vector3();
                const bbox = new THREE.Box3();

                bbox.setFromObject(data.scene);
                bbox.getSize(size);

                resolve(size);
            },
        );
    });
};

export const computeBoundingBoxSize = (object) => {
    const rotation = object.rotation.clone();
    object.rotation.set(0, 0, 0);
    const bbox = new THREE.Box3().setFromObject(object);
    const size = new THREE.Vector3();
    bbox.getSize(size);
    size.setX(parseFloat(size.x.toFixed(12)));
    size.setY(parseFloat(size.y.toFixed(12)));
    size.setZ(parseFloat(size.z.toFixed(12)));
    object.rotation.copy(rotation);
    return size;
};

export const getCanvasItemName = (itemType, canvasIndex, itemIndex) => {
    switch (itemType) {
        case CanvasItemTypes.IMAGE:
            return `ImageEditor-${canvasIndex}-${itemIndex}`;
        case CanvasItemTypes.TEXT:
            return `TextEditor-${canvasIndex}-${itemIndex}`;
        case CanvasItemTypes.MODEL3D:
            return `ModelEditor-${canvasIndex}-${itemIndex}`;
        case CanvasItemTypes.IMAGE_SPHERE:
            return `ImageSphereEditor-${canvasIndex}-${itemIndex}`;
        case CanvasItemTypes.QUIZ:
            return `QuizEditor-${canvasIndex}-${itemIndex}`;
        case CanvasItemTypes.VIDEO:
            return `VideoEditor-${canvasIndex}-${itemIndex}`;
        case CanvasItemTypes.TOUR:
            return `TourEditor-${canvasIndex}-${itemIndex}`;
        case CanvasItemTypes.AUDIO:
            return `AudioEditor-${canvasIndex}-${itemIndex}`;
        default:
            break;
    }
};

export const getPlaneSize = (plane) => {
    return {
        width: parseFloat(plane.geometry.parameters.width.toFixed(12)),
        height: parseFloat(plane.geometry.parameters.height.toFixed(12)),
    };
};


export const areVectorsEqual = (vector1, vector2) => {
    return parseFloat(vector1.x.toFixed(12)) === parseFloat(vector2.x.toFixed(12)) &&
        parseFloat(vector1.y.toFixed(12)) === parseFloat(vector2.y.toFixed(12)) &&
        parseFloat(vector1.z.toFixed(12)) === parseFloat(vector2.z.toFixed(12));
};

export const areVectorsEqualOnOneOrMoreAxis = (vector1, vector2) => {
    return parseFloat(vector1.x.toFixed(12)) === parseFloat(vector2.x.toFixed(12)) ||
        parseFloat(vector1.y.toFixed(12)) === parseFloat(vector2.y.toFixed(12)) ||
        parseFloat(vector1.z.toFixed(12)) === parseFloat(vector2.z.toFixed(12));
};

export const getObjectMeshes = (object) => {
    const meshes = [];
    object && object.traverse(child => {
        if (child instanceof THREE.Mesh) {
            meshes.push(child);
        }
    });
    return meshes;
};


export const exitPointerLock = () => {
    const pointerLockElement = document.pointerLockElement || document.mozPointerLockElement || document.webkitPointerLockElement;
    document.exitPointerLock = document.exitPointerLock || document.mozExitPointerLock || document.webkitExitPointerLock;
    pointerLockElement && document.exitPointerLock();
};

export const getImageDimension = (GPUTier) => {
    if ('true' === window._env_.DIMENSION_MAX) {
        return 'dimension=2048'
    } else if (GPUTier && (GPUTier.tier <= 1 || GPUTier.isMobile)) {
        return 'dimension=1200x1200'
    } else {
        return 'dimension=2048'
    }
};
