import * as Icons from '@mui/icons-material';
import { createUseStyles } from 'react-jss';
import { CanvasTypes, CanvasItemTypes } from '../../helpers/enums';
import { SET_CANVAS_ADDITION_ITEM_TYPE, SET_INVERTED_MOUSE_BUTTONS, useThreeDDispatch, useThreeDState } from '../App/ThreeDContext';

const useStyles = createUseStyles({
  container: {
    position: 'absolute',
    bottom: 50,
    left: '50%',
    transform: 'translate(-50%, 0)',
    background: 'rgb(250, 250, 250)',
    borderRadius: 4,
    zIndex: 1,
    cursor: 'default',
    display: 'flex',
    flexDirection: 'row',
    boxShadow: `0px 8px 9px -5px rgba(0, 0, 0, 0.2),
                0px 15px 22px 2px rgba(0, 0, 0, 0.14),
                0px 6px 28px 5px rgba(0, 0, 0, 0.12)`,
    '& > div': {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      minWidth: 50,
      margin: 6,
      padding: 6,
      '&:hover': {
        background: 'rgba(0, 0, 0, 0.06)',
        borderRadius: 4,
      },
    },
    '& p': {
      fontSize: 12,
      marginBlockStart: 0,
      marginBlockEnd: 0,
      textAlign: 'center',
    },
  },
  iconButton: {
    width: '20px !important',
    height: '20px !important',
    boxSizing: 'border-box',
    margin: 5,
    cursor: 'pointer',
    color: 'rgba(0, 0, 0, 0.6)',
    outline: 'none',
  },
  active: {
    opacity: 0.3,
  },
});

export const NewItemPicker = ({ canvases }) => {
  const { canvasAdditionItemType, canvasSelection } = useThreeDState();
  const dispatch = useThreeDDispatch();
  const styles = useStyles();
  const selectedCanvas = canvases && canvases.find((canvas, index) => 
    canvasSelection?.canvasIndex === index &&
    !canvasSelection?.itemType &&
    !canvasSelection?.itemIndex
  );

  const handleClick = (canvasItemType) => {
    dispatch({
      type: SET_CANVAS_ADDITION_ITEM_TYPE,
      canvasAdditionItemType: canvasAdditionItemType !== canvasItemType ? canvasItemType : null
    });
    dispatch({
      type: SET_INVERTED_MOUSE_BUTTONS,
      invertedMouseButtons: canvasAdditionItemType !== canvasItemType
    });
  };

  const Text = () => {
    return (
      <div onClick={() => handleClick(CanvasItemTypes.TEXT)}>
        <Icons.TextFields
          className={`${styles.iconButton} ${canvasAdditionItemType === CanvasItemTypes.TEXT ? styles.active : ''}`}
        />
        <p>Tekst</p>
      </div>
    );
  };

  const Image = () => {
    return (
      <div onClick={() => handleClick(CanvasItemTypes.IMAGE)}>
        <Icons.PhotoLibrary
          className={`${styles.iconButton} ${canvasAdditionItemType === CanvasItemTypes.IMAGE ? styles.active : ''}`}
        />
        <p>Bilde</p>
      </div>
    );
  };

  const Video = () => {
    return (
      <div onClick={() => handleClick(CanvasItemTypes.VIDEO)}>
        <Icons.VideoLibrary
          className={`${styles.iconButton} ${canvasAdditionItemType === CanvasItemTypes.VIDEO ? styles.active : ''}`}
        />
        <p>Video</p>
      </div>
    );
  };

  const Audio = () => {
    return (
      <div onClick={() => handleClick(CanvasItemTypes.AUDIO)}>
        <Icons.VolumeUp
          className={`${styles.iconButton} ${canvasAdditionItemType === CanvasItemTypes.AUDIO ? styles.active : ''}`}
        />
        <p>Lydpunkt</p>
      </div>
    );
  };

  const Model = () => {
    return (
      <div onClick={() => handleClick(CanvasItemTypes.MODEL3D)}>
        <Icons.ThreeDRotation
          className={`${styles.iconButton} ${canvasAdditionItemType === CanvasItemTypes.MODEL3D ? styles.active : ''}`}
        />
        <p>3D-modell</p>
      </div>
    );
  };

  const ImageSphere = () => {
    return (
      <div onClick={() => handleClick(CanvasItemTypes.IMAGE_SPHERE)}>
        <Icons.Language
          className={`${styles.iconButton} ${canvasAdditionItemType === CanvasItemTypes.IMAGE_SPHERE ? styles.active : ''}`}
        />
        <p>Bildekule</p>
      </div>
    );
  };

  const Quiz = () => {
    return (
      <div onClick={() => handleClick(CanvasItemTypes.QUIZ)}>
        <Icons.LiveHelp
          className={`${styles.iconButton} ${canvasAdditionItemType === CanvasItemTypes.QUIZ ? styles.active : ''}`}
        />
        <p>Quiz</p>
      </div>
    );
  };

  const Tour = () => {
    return (
      <div onClick={() => handleClick(CanvasItemTypes.TOUR)}>
        <Icons.Tour
          className={`${styles.iconButton} ${canvasAdditionItemType === CanvasItemTypes.TOUR ? styles.active : ''}`}
        />
        <p>Omvisning</p>
      </div>
    );
  };

  return (
    <>
      {selectedCanvas &&
        <div className={styles.container}>
          {selectedCanvas.type === CanvasTypes.WALL &&
            <>
              <Text />
              <Image />
              <Video />
              <Audio />
            </>
          }
          {selectedCanvas.type === CanvasTypes.FLOOR &&
            <>
              <Model />
              <ImageSphere />
              <Quiz />
              <Text />
              <Image />
              <Video />
              <Audio />
              <Tour />
            </>
          }
        </div>
      }
    </>
  );
};
