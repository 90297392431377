import { Canvas } from './Canvas';
import { CanvasItemTypes } from '../../helpers/enums';
import { useThreeDProps } from '../App/ThreeDContext';
import {getGroupItems} from "../group/groups";

export const Canvases = () => {
  const { data, onChange } = useThreeDProps();

  const handleItemAdd = (item, itemType) => {
    switch (itemType) {
      case CanvasItemTypes.IMAGE:
        data.images = data.images ?? [];
        data.images.push(item);
        break;
      case CanvasItemTypes.TEXT:
        data.texts = data.texts ?? [];
        data.texts.push(item);
        break;
      case CanvasItemTypes.MODEL3D:
        data.models = data.models ?? [];
        data.models.push(item);
        break;
      case CanvasItemTypes.IMAGE_SPHERE:
        data.imageSpheres = data.imageSpheres ?? [];
        data.imageSpheres.push(item);
        break;
      case CanvasItemTypes.QUIZ:
        data.quizzes = data.quizzes ?? [];
        data.quizzes.push(item);
        break;
      case CanvasItemTypes.VIDEO:
        data.videos = data.videos ?? [];
        data.videos.push(item);
        break;
      case CanvasItemTypes.TOUR:
        data.tours = data.tours ?? [];
        data.tours.push(item);
        break;
      case CanvasItemTypes.AUDIO:
        data.audios = data.audios ?? [];
        data.audios.push(item);
        break;
      default:
        break;
    }

    onChange && onChange(data);
  };

  const handleItemUpdate = (canvasIndex, itemIndex, item, itemType, groupChange) => {
    switch (itemType) {
      case CanvasItemTypes.IMAGE:
        updateItem(data.images, canvasIndex, itemIndex, item);
        groupChange && updateGroup(groupChange, item);
        break;
      case CanvasItemTypes.TEXT:
        updateItem(data.texts, canvasIndex, itemIndex, item);
        break;
      case CanvasItemTypes.MODEL3D:
        updateItem(data.models, canvasIndex, itemIndex, item);
        groupChange && updateGroup(groupChange, item);
        break;
      case CanvasItemTypes.IMAGE_SPHERE:
        updateItem(data.imageSpheres, canvasIndex, itemIndex, item);
        break;
      case CanvasItemTypes.QUIZ:
        updateItem(data.quizzes, canvasIndex, itemIndex, item);
        break;
      case CanvasItemTypes.VIDEO:
        updateItem(data.videos, canvasIndex, itemIndex, item);
        break;
      case CanvasItemTypes.TOUR:
        updateItem(data.tours, canvasIndex, itemIndex, item);
        break;
      case CanvasItemTypes.AUDIO:
        updateItem(data.audios, canvasIndex, itemIndex, item);
        break;
      default:
        break;
    }

    onChange && onChange(data);
  };

  const handleItemRemove = (canvasIndex, itemIndex, itemType) => {
    switch (itemType) {
      case CanvasItemTypes.IMAGE:
        removeItem(data.images, canvasIndex, itemIndex);
        break;
      case CanvasItemTypes.TEXT:
        removeItem(data.texts, canvasIndex, itemIndex);
        break;
      case CanvasItemTypes.MODEL3D:
        removeItem(data.models, canvasIndex, itemIndex);
        break;
      case CanvasItemTypes.IMAGE_SPHERE:
        removeItem(data.imageSpheres, canvasIndex, itemIndex);
        break;
      case CanvasItemTypes.QUIZ:
        removeItem(data.quizzes, canvasIndex, itemIndex);
        break;
      case CanvasItemTypes.VIDEO:
        removeItem(data.videos, canvasIndex, itemIndex);
        break;
      case CanvasItemTypes.TOUR:
        removeItem(data.tours, canvasIndex, itemIndex);
        break;
      case CanvasItemTypes.AUDIO:
        removeItem(data.audios, canvasIndex, itemIndex);
        break;
      default:
        break;
    }

    onChange && onChange(data);
  };

  const updateItem = (array, canvasIndex, itemIndex, item) => {
    const canvasItems = array.filter(obj => obj.canvasIndex === canvasIndex);
    const canvasItem = canvasItems.find((obj, index) => obj.canvasIndex === canvasIndex && index === itemIndex);
    const index = array.indexOf(canvasItem);
    array[index] = item;
  };

  const removeItem = (array, canvasIndex, itemIndex) => {
    const item = array.filter(obj => obj.canvasIndex === canvasIndex)[itemIndex];
    array.splice(array.indexOf(item), 1);
  };

  const updateGroup = (groupChange, item) => {
    updatePossibleNewGroup(groupChange);
    updatePossibleOldGroup(groupChange);
    updatePossibleCurrentGroup(groupChange, item);
  };

  const updatePossibleNewGroup = groupChange => {
    if (!isNaN(groupChange.newGroupId)) {
      const newGroup = data.groups.find(group => group.id === groupChange.newGroupId);
      newGroup.itemCount = getGroupItems(data, groupChange.newGroupId).length;
    }
  };

  const updatePossibleOldGroup = groupChange => {
    if (!isNaN(groupChange.oldGroupId)) {
      const groupItems = getGroupItems(data, groupChange.oldGroupId);
      groupItems.sort((a, b) => a.group.index - b.group.index);
      groupItems.forEach((item, index) => item.group.index = index);
      const oldGroup = data.groups.find(group => group.id === groupChange.oldGroupId);
      oldGroup.itemCount = groupItems.length;
    }
  };


  const updatePossibleCurrentGroup = (groupChange, item) => {
    if (!isNaN(groupChange.groupId)) {
      const groupItems = getGroupItems(data, groupChange.groupId);
      groupItems.sort((a, b) => sortByGroupIndex(a, item, b, groupChange));
      groupItems.forEach((item, index) => item.group.index = index);
    }
  };

  const sortByGroupIndex = (a, item, b, groupChange) => {
    if (a === item && a.group.index === b.group.index) {
      return a.group.index < groupChange.oldIndex ? -1 : 0;
    } else if (b === item && b.group.index === a.group.index) {
      return b.group.index > groupChange.oldIndex ? -1 : 0;
    } else {
      return a.group.index - b.group.index;
    }
  };

  return (
    <group>
      {data.canvases && data.canvases.map((canvas, index) => (
        <Canvas
          key={canvas.id}
          index={index}
          groups={data.groups}
          images={(data.images ?? []).filter(obj => obj.canvasIndex === index)}
          texts={(data.texts ?? []).filter(obj => obj.canvasIndex === index)}
          models={(data.models ?? []).filter(obj => obj.canvasIndex === index)}
          imageSpheres={(data.imageSpheres ?? []).filter(obj => obj.canvasIndex === index)}
          quizzes={(data.quizzes ?? []).filter(obj => obj.canvasIndex === index)}
          videos={(data.videos ?? []).filter(obj => obj.canvasIndex === index)}
          tours={(data.tours ?? []).filter(obj => obj.canvasIndex === index)}
          audios={(data.audios ?? []).filter(obj => obj.canvasIndex === index)}
          onItemAdd={handleItemAdd}
          onItemUpdate={handleItemUpdate}
          onItemRemove={handleItemRemove}
          {...canvas}
        />
      ))}
    </group>
  );
}
