import { useMemo } from 'react';
import { useThreeDProps, useThreeDState } from '../App/ThreeDContext';
import { getExhibitionItems } from '../../helpers';
import { Quiz } from './Quiz';

export const Quizzes = () => {
  const { data, adminMode } = useThreeDProps();
  const { interactiveMode } = useThreeDState();
  const { quizzes } = data;
  const filteredQuizzes = useMemo(() =>
    getExhibitionItems(quizzes, adminMode, interactiveMode),
    [quizzes, adminMode, interactiveMode]
  );

  return filteredQuizzes.map((quiz, index) => (
    <Quiz
      key={index}
      {...quiz}
      name={`Quiz-${index}`}
      editMode={!interactiveMode}
    />
  ));
};
