export const demo9 = {
    roomModelDmsId: '0138Wuk8hf4V',
    startPosition: { x: 0, y: 1.86, z: 0 },
    directionOfView: { x: -Math.PI, y: 0, z: 0 },
    dome: {
        skyBoxDmsId: '0136NvASPyuq',
    },
    models: [
        {
            dmsId: '0136NvAPSgAB',
            position: { x: -7.88, y: 2.64, z: 0.03 },
            rotation: { x: 0, y: Math.PI / -2, z: 0 },
            scale: { x: 0.06, y: 0.06, z: 0.06 },
            viewerData: {
                model: {
                    type: 'Object',
                    dmsId: '0136NvAPSgAB',
                },
                license: {
                    creatorName: '3dhdscan',
                    creatorUrl: 'https://sketchfab.com/3dhdscan',
                    licenseName: 'CC BY 4.0',
                    licenseUrl: 'https://creativecommons.org/licenses/by/4.0/',
                    sourceName: 'Statue of a hunter',
                    sourceUrl:
                        'https://sketchfab.com/3d-models/statue-of-a-hunter-f777e0a58bd84e7f945a9dd688eab27a',
                },
            },
        },
    ],
    quizzes: [
        {
            position: { x: -3.5, y: 1, z: 0 },
            scale: { x: 2.5, y: 2.5, z: 2.5 },
            repeatable: false,
            content: {
                title: 'Test Quiz',
                questions: [
                    {
                        id: 1,
                        question: 'Hvor mange plattformer er det i dette rommet?',
                        a: '6',
                        b: '7',
                        c: '8',
                        d: '9',
                        answer: 'c',
                    },
                ],
            },
            animations: {
                onCompletion: 'Stairs_1Action',
            },
        },
    ],
}