export const demo20 = {
    roomModelDmsId: '013AkPFuLLW3',
    startPosition: { x: 0, y: 1.75, z: 0 },
    directionOfView: { x: 0.001, y: 0, z: 0 },
    lights: [
        {
            type: 'ambient',
            intensity: 1,
        },
    ],
}