export const FloorMarker = ({ isHighlighted }) => {
  return (
    <mesh
      position={[0, 0.01, 0]}
      rotation={[Math.PI / -2, 0, 0]}
    >
      <circleGeometry args={[0.5, 40]} />
      <meshStandardMaterial
        color={0x0db5ba}
        opacity={isHighlighted ? 0.5 : 0.4}
        transparent
      />
    </mesh>
  );
};
